import * as XLSX from 'xlsx-js-style';
import { setFormulaExecuterFn } from '@gorilla/common/src/lib/engine/column_handlers/index';
import S5SCalc from '@gorilla/spreadsheet-shared/src/lib/external/sjscalc.js';

// some libraries expect `window.global` to be set
if (!window.global) {
  window.global = window;
}

S5SCalc.set_XLSX(XLSX);

setFormulaExecuterFn((formula) => {
  return S5SCalc.calculate(formula);
});
