import React, { useState } from 'react';
import * as _ from 'lodash';
import {
  EditableHeading,
  Divider,
  Loader,
  Heading,
  Button,
  Box,
  Flex,
  IconButton,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Tooltip,
  Icon,
  Counter,
  Label,
} from 'monday-ui-react-core';
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
import MoveArrowLeft from 'monday-ui-react-core/dist/icons/MoveArrowLeft';
import ExternalPage from 'monday-ui-react-core/dist/icons/ExternalPage';
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
import LockedIcon from 'monday-ui-react-core/dist/icons/Locked';
import UnlockedIcon from 'monday-ui-react-core/dist/icons/Unlocked';
import CalendarIcon from 'monday-ui-react-core/dist/icons/Calendar';
import FormIcon from 'monday-ui-react-core/dist/icons/Form';
import FeedbackIcon from 'monday-ui-react-core/dist/icons/Feedback';
import { useParams } from 'react-router-dom';
import { removeFieldGroupsWithBoardIds } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/utils';
import { useSpreadsheetTemplate } from '../hooks/use-spreadsheet-template';
import { useBoards } from '../shared/hooks/use-boards';
import useMonday from '../hooks/use-monday';
import { sleep } from '../lib/utils';
import { useNavigate } from 'react-router-dom';
import { Sheets } from './Sheets';
import FeedbackBanner from '../shared/components/FeedbackBanner';
import { CreateSpreadsheetModal } from './CreateSpreadsheetModal';
import { MessagesModal } from './MessagesModal';
import { IntroductionModal } from '../components/IntroductionModal';
import ErrorMessage from './ErrorMessage';

// @ts-ignore-next-line
import RobotIcon from 'monday-ui-react-core/dist/icons/Robot';

function getMissingBoardIds(boardIds: number[], foundBoards: { id: number }[]) {
  return boardIds.filter((boardId) => !foundBoards.find((board) => board.id === boardId));
}

export function EditTemplate() {
  const params = useParams();
  const navigate = useNavigate();
  const { mondayContext } = useMonday();
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [showBoardSelectionModal, setShowBoardSelectionModal] = useState(false);
  const [showMessagesModal, setShowMessagesModal] = useState(false);
  const [showCreateSpreadsheetModal, setShowCreateSpreadsheetModal] = useState(false);
  const [showIntroductionModal, setShowIntroductionModal] = useState<boolean>(false);
  const {
    template,
    config,
    error,
    isUpdatingTemplate,
    updateTemplateDraft,
    updateTemplateDraftConfig,
    saveTemplate,
    deleteTemplate,
    isLoading,
    hasDraft,
    referencedBoardIds,
    isDeletingTemplate,
  } = useSpreadsheetTemplate(parseInt(params.templateId!, 10));
  const { boards, error: boardsError } = useBoards(referencedBoardIds, false);
  const missingBoardIds = boards ? getMissingBoardIds(referencedBoardIds, boards) : [];

  const hasError = !!boardsError;
  let content;

  if (boardsError) {
    content = <ErrorMessage error={new Error('We are unable to load some of the boards used in this template.')} />;
  } else if (config && missingBoardIds.length) {
    content = (
      <>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: 'calc(100vh - 150px)' }}>
          <div style={{ textAlign: 'center' }}>
            <div>
              {missingBoardIds.length < 2 ? (
                <>
                  <h1>Missing board</h1>
                  <p>
                    The following board used in this template either doesn't
                    <br />
                    exist anymore or you lack permissions to access it:
                  </p>
                </>
              ) : (
                <>
                  <h1>Missing boards</h1>
                  <p>
                    The following boards used in this template either don't exist
                    <br />
                    anymore or you lack permissions to access them:
                  </p>
                </>
              )}
              <Flex gap={8} justify={Flex.justify?.CENTER}>
                {missingBoardIds.map((id, index) => (
                  <Label key={index} text={`Board ID: ${id}`} color={Label.colors.DARK} kind={Label.kinds?.LINE} isAnimationDisabled />
                ))}
              </Flex>
            </div>
            <div style={{ marginTop: '40px' }}>
              <Button
                onClick={() => {
                  updateTemplateDraftConfig(removeFieldGroupsWithBoardIds(config, missingBoardIds));
                }}
              >
                {missingBoardIds.length < 2 ? <>Remove board</> : <>Remove boards</>}
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  } else if (config && template) {
    content = (
      <>
        <Sheets
          boards={boards}
          template={template}
          onSave={() => {
            saveTemplate();
          }}
          config={config}
          onSheetAdd={(newSheet) => {
            updateTemplateDraftConfig({ ...config, sheets: [...config.sheets, newSheet] });
          }}
          onSheetRemove={(sheetId) => {
            updateTemplateDraftConfig({ ...config, sheets: config.sheets.filter((sheet) => sheetId !== sheet.id) });
          }}
          onSheetChange={(changedSheet) => {
            updateTemplateDraftConfig({
              ...config,
              sheets: config.sheets.map((sheet) => (sheet.id === changedSheet.id ? changedSheet : sheet)),
            });
          }}
          onOpenBoardManager={() => {
            setShowBoardSelectionModal(true);
          }}
        />

        {/* CTA */}

        <div
          style={{
            margin: '32px 32px 32px 32px',
          }}
        >
          <Flex gap={32} align={Flex.align?.STRETCH} justify={Flex.justify?.STRETCH} direction={Flex.directions?.ROW}>
            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: '#f6f7fb',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #d0d4e4',
                  borderRadius: '8px',
                  color: '#323338',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(0,0,0,0.05)',
                  }}
                >
                  <Icon icon={CalendarIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={CalendarIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Demo</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          Book a demo and <br />
                          talk to an expert
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.PRIMARY as any}
                      onClick={() => {
                        window.open('https://calendly.com/d/z5z-yb9-bxw/30min', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: '#f6f7fb',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #d0d4e4',
                  borderRadius: '8px',
                  color: '#323338',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(0,0,0,0.05)',
                  }}
                >
                  <Icon icon={FormIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={FormIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Support</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          We're here to help and <br />
                          answer any question
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.PRIMARY as any}
                      onClick={() => {
                        window.open('https://getgorilla.app/contact', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'stretch',
                width: '50%',
              }}
            >
              <div
                style={{
                  overflow: 'hidden',
                  position: 'relative',
                  padding: '32px',
                  width: '100%',
                  backgroundColor: '#f6f7fb',
                  boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
                  border: '1px solid #d0d4e4',
                  borderRadius: '8px',
                  color: '#323338',
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: '20%',
                    bottom: '-50px',
                    color: 'rgba(0,0,0,0.05)',
                  }}
                >
                  <Icon icon={FeedbackIcon} iconSize={150} ignoreFocusStyle clickable={false} />
                </div>
                <Flex gap={20} justify={Flex.justify?.CENTER} align={Flex.align?.END}>
                  <div>
                    <Flex gap={15}>
                      <div>
                        <Flex gap={5}>
                          <Icon icon={FeedbackIcon} iconSize={18} ignoreFocusStyle clickable={false} />
                          <span>Feedback</span>
                        </Flex>
                        <h5 style={{ marginTop: '5px' }}>
                          Share your feedback <br />
                          and user experience
                        </h5>
                      </div>
                    </Flex>
                  </div>
                  <div
                    style={{
                      marginLeft: 'auto',
                    }}
                  >
                    <IconButton
                      icon={ExternalPage}
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.SECONDARY as any}
                      color={Button.colors?.PRIMARY as any}
                      onClick={() => {
                        window.open('https://wkf.ms/3RMU0MH', '_blank');
                      }}
                    />
                  </div>
                </Flex>
              </div>
            </div>
          </Flex>
        </div>
      </>
    );
  } else {
    content = (
      <>
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '32px',
            width: '100%',
            height: 'calc(100vh - 210px)',
          }}
        >
          <Loader size={Loader.sizes?.SMALL as any} color={Loader.colors?.DARK as any} />
        </div>
      </>
    );
  }

  return (
    <>
      {showCreateSpreadsheetModal ? (
        <CreateSpreadsheetModal
          onClose={() => setShowCreateSpreadsheetModal(false)}
          show={!!showCreateSpreadsheetModal}
          initialCtx={{ templateId: template?.id, disableTemplateSelection: true }}
        />
      ) : null}
      {showIntroductionModal ? <IntroductionModal show={showIntroductionModal} onClose={() => setShowIntroductionModal(false)} /> : null}

      {showMessagesModal && template ? (
        <MessagesModal
          show={showMessagesModal}
          onClose={async (newConfig) => {
            if (newConfig) {
              saveTemplate({ ...template, config: newConfig });
            }
            setShowMessagesModal(false);
          }}
          template={template}
        />
      ) : null}

      <FeedbackBanner />

      {/* HEADER */}

      {/* zIndex: 1000 doesn't work with tooltips */}

      <div
        style={{
          zIndex: 1000,
          position: 'sticky',
          top: 0,
          boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div
          style={{
            padding: '16px',
            backgroundColor: '#ffffff',
          }}
        >
          <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.STRETCH as any}>
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Flex gap={10}>
                <IconButton
                  kind={IconButton.kinds?.TERTIARY as any}
                  size={IconButton.sizes?.MEDIUM as any}
                  icon={MoveArrowLeft}
                  onClick={() => {
                    navigate(`/`);
                  }}
                />
                <Tooltip content="Save template" zIndex={2000}>
                  <Button
                    onClick={() => {
                      saveTemplate();
                    }}
                    disabled={!hasDraft}
                    loading={isUpdatingTemplate}
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.SECONDARY as any}
                  >
                    Save
                  </Button>
                </Tooltip>
                <Tooltip content="Delete template" zIndex={2000}>
                  <Button
                    size={Button.sizes?.MEDIUM as any}
                    kind={Button.kinds?.SECONDARY as any}
                    onClick={() => {
                      setShowConfirmDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </Tooltip>
                <Modal
                  data-testid="confirm-delete-modal"
                  show={showConfirmDeleteModal}
                  onClose={() => {
                    setShowConfirmDeleteModal(false);
                  }}
                  width={Modal.width?.DEFAULT as any}
                  alertDialog={true}
                >
                  <ModalHeader title="Warning" icon={AlertIcon} hideCloseButton={true} />
                  <ModalContent>
                    <Box>
                      <>
                        You are about to delete the spreadsheet template <strong>{template?.name}</strong> for everyone on your team. Once
                        deleted, the template can't be restored. Are you sure to proceed?
                      </>
                    </Box>
                  </ModalContent>
                  <ModalFooter>
                    <Flex gap={12} justify={Flex.justify?.END as any}>
                      <Button
                        kind={Button.kinds?.TERTIARY as any}
                        onClick={() => {
                          setShowConfirmDeleteModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        loading={isDeletingTemplate}
                        onClick={async () => {
                          await deleteTemplate();
                          setShowConfirmDeleteModal(false);
                          navigate('/');
                        }}
                      >
                        Confirm
                      </Button>
                    </Flex>
                  </ModalFooter>
                </Modal>
              </Flex>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <Flex gap={0} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
                <div>
                  <Tooltip content="Click to edit" zIndex={2000}>
                    <EditableHeading
                      onFinishEditing={(value) => {
                        updateTemplateDraft({ name: value });
                      }}
                      type={EditableHeading.types?.h4 as any}
                      value={template?.name}
                      autoSize
                    />
                  </Tooltip>
                </div>
                {_.toNumber(_.get(mondayContext, 'user.id')) === _.get(template, 'creatorId') ? (
                  <Flex align={Flex.align?.CENTER as any}>
                    <Tooltip
                      content={
                        template?.private
                          ? 'Private template: Click to share it across all board views'
                          : 'Shared template: Click to make it private'
                      }
                      zIndex={2000}
                    >
                      <IconButton
                        disabled={boardsError}
                        size={IconButton.sizes?.MEDIUM as any}
                        kind={IconButton.kinds?.TERTIARY as any}
                        icon={template?.private ? LockedIcon : UnlockedIcon}
                        onClick={(ev) => {
                          updateTemplateDraft({ private: !template?.private });
                        }}
                      />
                    </Tooltip>
                  </Flex>
                ) : (
                  <></>
                )}
              </Flex>
            </div>
            <div
              style={{
                textAlign: 'right',
              }}
            >
              <Flex gap={12} justify={Flex.justify?.END}>
                {config && missingBoardIds.length ? (
                  <>
                    <Button disabled size={Button.sizes?.MEDIUM as any} kind={Button.kinds?.SECONDARY as any}>
                      Preview
                    </Button>
                    <Button disabled size={Button.sizes?.MEDIUM as any} kind={Button.kinds?.PRIMARY as any}>
                      Create
                    </Button>
                  </>
                ) : (
                  <>
                    <Tooltip content="Preview spreadsheet" zIndex={2000}>
                      <Button
                        disabled={hasError}
                        onClick={async () => {
                          await saveTemplate();
                          await sleep(1000); // temp fix for loading of preview sometimes failing
                          navigate(`/template/${params.templateId}/render`);
                        }}
                        size={Button.sizes?.MEDIUM as any}
                        kind={Button.kinds?.SECONDARY as any}
                      >
                        Preview
                      </Button>
                    </Tooltip>
                    <Tooltip content="Create spreadsheet" zIndex={2000}>
                      <Button
                        disabled={hasError}
                        onClick={async () => {
                          await saveTemplate();
                          //await sleep(1000); // temp fix for loading of preview sometimes failing
                          //navigate(`/template/${params.templateId}/render`);
                          setShowCreateSpreadsheetModal(true);
                        }}
                        size={Button.sizes?.MEDIUM as any}
                        kind={Button.kinds?.PRIMARY as any}
                      >
                        Create
                      </Button>
                    </Tooltip>
                  </>
                )}
              </Flex>
            </div>
          </Flex>
        </div>
        <Divider withoutMargin />
      </div>

      <div style={{ position: 'relative' }}>
        {!boardsError && !missingBoardIds.length ? (
          <div
            style={{
              position: 'absolute',
              top: '25px',
              right: '32px',
            }}
          >
            <Flex>
              <Tooltip content="Edit email messages for your automations" zIndex={2000}>
                <Button
                  disabled={boardsError || missingBoardIds.length > 0}
                  size={Button.sizes?.MEDIUM as any}
                  kind={Button.kinds?.TERTIARY as any}
                  leftIcon={RobotIcon}
                  onClick={() => {
                    setShowMessagesModal(true);
                  }}
                >
                  Message manager &nbsp;{' '}
                  <Counter count={config?.messages ? config?.messages.length : 0} size={Counter.sizes.LARGE} className="inlinecounter" />
                </Button>
              </Tooltip>
            </Flex>
          </div>
        ) : null}

        {content}
      </div>
    </>
  );
}
