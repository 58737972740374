import * as _ from 'lodash';
import { getSessionToken } from './monday';
import { handleFetchErrorResponse } from './utils';

type Execution = {
  id: string;
  created_at: string;
  automation_name: string;
  completed_at: string;
  bucket: string;
  account_id: string;
  user_id: string;
  result?: { type: 'success' | 'error'; message: '' };
};

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function getExecutions(planId: string) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/executions?plan_id=${planId}`, {
    method: 'GET',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
  });

  await handleFetchErrorResponse('failed to get executions', response);

  return (await response.json()) as {
    scope: 'user' | 'account';
    executions: Execution[];
    executionsCount: number;
  };
}
