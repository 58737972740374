import React, { useState } from 'react';
import useSWR from 'swr';
import _ from 'lodash';
import 'monday-ui-react-core/dist/main.css';
import { useParams } from 'react-router-dom';
import Luckysheet from '../../components/Luckysheet';
import { getSpreadsheet } from '../../services/spreadsheets';
import LoadingSpreadsheet from '../../components/LoadingSpreadsheet';
import ErrorMessage from '../../components/ErrorMessage';
import ExperimentalSpreadsheetModal from '../../components/ExperimentalSpreadsheetModal';
import './RenderSpreadsheetPage.css';

function RenderSpreadsheetPage() {
  const params = useParams();

  const [showExperimentalModal, setShowExperimentalModal] = useState<boolean>(
    window.localStorage.getItem('seen-experimental-spreadsheet-modal') === 'yes' ? false : true
  );

  const {
    data: spreadsheet,
    error,
    mutate,
  } = useSWR(
    `/api/get-spreadsheet/${params.spreadsheetId}`,
    async () => {
      return (await getSpreadsheet(params.spreadsheetId!)).value;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      revalidateOnMount: true,
    }
  );

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className="Spreadsheet">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
        }}
      >
        {spreadsheet ? (
          <Luckysheet
            spreadsheetId={params.spreadsheetId}
            initialData={spreadsheet.data}
            title={spreadsheet.title}
            preview={false}
            onSave={() => {
              mutate();
            }}
          />
        ) : (
          <LoadingSpreadsheet />
        )}
      </div>

      {spreadsheet && showExperimentalModal ? (
        <ExperimentalSpreadsheetModal
          show={showExperimentalModal}
          onClose={() => {
            window.localStorage.setItem('seen-experimental-spreadsheet-modal', 'yes');
            setShowExperimentalModal(false);
          }}
        />
      ) : null}
    </div>
  );
}

export default RenderSpreadsheetPage;
