import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { MultiplePersonColumnType } from '../../monday-api/api';

export type MultiplePersonColumnValue = {
  text: string | null;
  person_ids: number[] | null;
  team_ids: number[] | null;
  changed_at: string | null;
};

const handler: ColumnHandler<MultiplePersonColumnType, MultiplePersonColumnValue> = {
  type: 'people',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['user_id_string', 'assigned_to_me', 'empty_user'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['user_id_string', 'assigned_to_me', 'empty_user'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'not_contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'starts_with',
      compare_value_ids: ['text'],
    },
    {
      operator: 'ends_with',
      compare_value_ids: ['text'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    let personIds: number[] | null = null;
    let teamIds: number[] | null = null;
    let changedAt: string | null = null;

    if (Array.isArray(_.get(value, 'personsAndTeams'))) {
      personIds = ((_.get(value, 'personsAndTeams') || []) as Array<{ id: number; kind: 'person' }>)
        .filter(({ kind }) => kind === 'person')
        .map(({ id }) => parseNumber(id))
        .filter((id) => id !== null) as number[];

      teamIds = ((_.get(value, 'personsAndTeams') || []) as Array<{ id: number; kind: 'team' }>)
        .filter(({ kind }) => kind === 'team')
        .map(({ id }) => parseNumber(id))
        .filter((id) => id !== null) as number[];

      changedAt = parseString(_.get(value, 'changed_at'));
    }

    return {
      text,
      person_ids: personIds,
      team_ids: teamIds,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Count') {
      return (engineValue.person_ids?.length || 0) + (engineValue.team_ids?.length || 0);
    }

    if (modifier === 'Ids') {
      return [...(engineValue.person_ids || []), ...(engineValue.team_ids || [])].join(',');
    }

    if (modifier === 'Emails') {
      // TODO: support 'Emails' modifier
      throw new Error('Emails modifier not supported yet');
    }

    return engineValue.text === null ? '' : engineValue.text;
  },
};

export default handler;
