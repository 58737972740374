/*! SheetJS Formula Calculator (C) 2019-present SheetJS LLC */
var S5SCalc;
(function (t) {
  if (typeof XLSX !== 'undefined') t.XLSXLib = XLSX;
  function r(r) {
    t.XLSXLib = r;
  }
  t.set_XLSX = r;
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (r) {
    r.ErrorCode = { '#DIV/0!': 7, '#GETTING_DATA': 43, '#N/A': 42, '#NAME?': 29, '#NULL!': 0, '#NUM!': 36, '#REF!': 23, '#VALUE!': 15 };
    r.ErrorName = {};
    Object.keys(r.ErrorCode).forEach(function (t) {
      r.ErrorName[r.ErrorCode[t]] = t;
    });
    function e(t) {
      var r = t | 0;
      if ((r & 3) !== 0) return false;
      if (r % 400 === 0) return true;
      if (r === 1900) return true;
      if (r % 100 === 0) return false;
      return true;
    }
    r.is_leap_year = e;
    function a(r) {
      return t.XLSXLib.SSF.parse_date_code(r);
    }
    r.parse_date_code = a;
    function n(t) {
      var r = t.match(/^(\d+):(\d+)\s*(AM|PM)/);
      if (r) return new Date(1900, 0, 0, (+r[1] % 12) + (r[3] === 'PM' ? 12 : 0), +r[2], 0, 0);
      r = t.match(/^(\d+):(\d+):(\d+)\s*(AM|PM)/);
      if (r) return new Date(1900, 0, 0, (+r[1] % 12) + (r[4] === 'PM' ? 12 : 0), +r[2], +r[3], 0);
      return new Date('wtf');
    }
    function i(t, r) {
      if (r === 2) return n(t);
      var e = new Date(t);
      return !r || e.valueOf() ? e : n(t);
    }
    r.parse_date_str = i;
    function u(t, r) {
      var e = new Date(1900, 0, 0, 0, 0, 0, 0);
      var a = (t.valueOf() - e.valueOf()) / 1e3 / 60 / 60 / 24;
      if (r !== 2) a = Math.round(a + 1);
      return a;
    }
    r.date_to_code = u;
    function v(t) {
      var r = new Date(t.y, t.m - 1, t.d, 0, 0, 0, 0);
      var e = t.q;
      if (e === 0) e = 7;
      r.setDate(r.getDate() - e + 4);
      e = r.getTime();
      r.setMonth(0);
      r.setDate(1);
      return Math.floor(Math.round((e - r.valueOf()) / 864e5) / 7) + 1;
    }
    r.iso_week_date = v;
    function c(r) {
      var e = r.split(':');
      var a = { s: { r: 2e6, c: 2e6 }, e: { r: -1, c: -1 } };
      e.forEach(function (r) {
        var e = t.XLSXLib.utils.decode_cell(r.replace(/\$/g, ''));
        if (isNaN(e.r) || e.r < 0) {
          a.s.r = 0;
          a.e.r = 1048575;
        } else {
          if (a.s.r > e.r) a.s.r = e.r;
          if (a.e.r < e.r) a.e.r = e.r;
        }
        if (isNaN(e.c) || e.c < 0) {
          a.s.c = 0;
          a.e.c = 16383;
        } else {
          if (a.s.c > e.c) a.s.c = e.c;
          if (a.e.c < e.c) a.e.c = e.c;
        }
      });
      return a;
    }
    r.decode_multi_range = c;
    r.safe_decode_range = function (t) {
      var r = JSON.parse('{"s":{"c":0,"r":0},"e":{"c":0,"r":0}}');
      var e = 0;
      var a = 0;
      var n = 0;
      var i = t.length;
      for (e = 0; a < i; ++a) {
        if ((n = t.charCodeAt(a) - 64) < 1 || n > 26) break;
        e = 26 * e + n;
      }
      r.s.c = --e;
      for (e = 0; a < i; ++a) {
        if ((n = t.charCodeAt(a) - 48) < 0 || n > 9) break;
        e = 10 * e + n;
      }
      r.s.r = --e;
      if (a === i || t.charCodeAt(++a) === 58) {
        r.e.c = r.s.c;
        r.e.r = r.s.r;
        return r;
      }
      for (e = 0; a !== i; ++a) {
        if ((n = t.charCodeAt(a) - 64) < 1 || n > 26) break;
        e = 26 * e + n;
      }
      r.e.c = --e;
      for (e = 0; a !== i; ++a) {
        if ((n = t.charCodeAt(a) - 48) < 0 || n > 9) break;
        e = 10 * e + n;
      }
      r.e.r = --e;
      return r;
    };
    r.encode_col = function (t) {
      if (t < 0) throw 'invalid column ' + t;
      if (t < 26) return 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[t];
      var r = '';
      for (++t; t; t = Math.floor((t - 1) / 26)) r = String.fromCharCode(((t - 1) % 26) + 65) + r;
      return r;
    };
    r.COLUMN_NAMES = Array.from({ length: 16384 }, function (t, e) {
      return r.encode_col(e);
    });
    r.encode_cell = function (t) {
      var r = t.c + 1;
      var e = '';
      if (r <= 26) e = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[r - 1];
      else for (; r; r = ((r - 1) / 26) | 0) e = String.fromCharCode(((r - 1) % 26) + 65) + e;
      return e + (t.r + 1);
    };
    r.decode_cell = function (t) {
      var r = 0;
      var e = 0;
      for (var a = 0; a < t.length; ++a) {
        var n = t.charCodeAt(a);
        if (n >= 48 && n <= 57) r = 10 * r + (n - 48);
        else if (n >= 65 && n <= 90) e = 26 * e + (n - 64);
      }
      return { c: e - 1, r: r - 1 };
    };
    function f(t, r, e) {
      if (!t) return void 0;
      if (!t.Workbook) return void 0;
      if (!t.Workbook.Names) return void 0;
      var a = t.Workbook.Names;
      var n = r.toLowerCase();
      var i;
      for (var u = 0; u < a.length; ++u) {
        if (!a[u]) continue;
        if (!a[u].name) a[u].name = a[u].Name.toLowerCase();
        if (a[u].name !== n) continue;
        if (a[u].Sheet === e) return a[u];
        if (a[u].Sheet == null) i = a[u];
      }
      return i;
    }
    function _(t, r, e) {
      if (typeof e === 'number') return f(t, r, e);
      else if (typeof e === 'string') {
        var a = t.SheetNames.map(function (t) {
          return t.toLowerCase();
        }).indexOf(e.toLowerCase());
        if (a > -1) return f(t, r, a);
      } else return f(t, r, -1);
      throw 'Could not find sheet |'.concat(e, '|');
    }
    r.get_defined_name = _;
    function s(t, r, e, a) {
      if (!t.Workbook) t.Workbook = {};
      if (!t.Workbook.Names) t.Workbook.Names = [];
      var n = t.Workbook.Names;
      var i =
        a == null
          ? -1
          : typeof a === 'number'
            ? a
            : t.SheetNames.map(function (t) {
                return t.toLowerCase();
              }).indexOf(a.toLowerCase());
      var u = r.toLowerCase();
      for (var v = 0; v < n.length; ++v) {
        if (!n[v]) continue;
        if (n[v].Name.toLowerCase() !== u) continue;
        if (n[v].Sheet === i || (n[v].Sheet == null && i === -1)) {
          n[v].Ref = e;
          return n[v];
        }
      }
      var c = { Name: r, Ref: e };
      if (i > -1) c.Sheet = i;
      n.push(c);
      return c;
    }
    r.set_defined_name = s;
    function o(t, r, e) {
      if (!t.Workbook) t.Workbook = {};
      if (!t.Workbook.Names) t.Workbook.Names = [];
      var a = t.Workbook.Names;
      var n =
        e == null
          ? -1
          : typeof e === 'number'
            ? e
            : t.SheetNames.map(function (t) {
                return t.toLowerCase();
              }).indexOf(e.toLowerCase());
      var i = r.toLowerCase();
      for (var u = 0; u < a.length; ++u) {
        if (!a[u]) continue;
        if (a[u].Name.toLowerCase() !== i) continue;
        if (a[u].Sheet === n || (a[u].Sheet == null && n === -1)) {
          a.splice(u, 1);
          return;
        }
      }
    }
    r.remove_defined_name = o;
    function l(t, e) {
      if (Array.isArray(t)) {
        if (typeof e === 'string') e = r.decode_cell(e);
        if (t[e.r] && t[e.r][e.c]) return t[e.r][e.c].v;
      } else {
        if (typeof e !== 'string') e = r.encode_cell(e);
        if (t[e]) return t[e].v;
      }
      return void 0;
    }
    r.sheet_get_value = l;
    function U(e, a) {
      var n = Array.isArray(e);
      if (typeof a === 'string') a = t.XLSXLib.utils.decode_range(a);
      var i = [];
      for (var u = a.s.r; u <= a.e.r; ++u) {
        i[u - a.s.r] = [];
        for (var v = a.s.c; v <= a.e.c; ++v) {
          i[u - a.s.r][v - a.s.c] = ((n ? e[u] && e[u][v] : e[r.encode_cell({ r: u, c: v })]) || {}).v || void 0;
        }
      }
      return i;
    }
    r.sheet_get_range = U;
    function h(t) {
      return t.replace(/'/g, '');
    }
    r.unquote_book_name = h;
    function b(t, r, e) {
      if (!t.ExternalWB) throw 'Workbook has no External WB References';
      var a = r.match(/\[(\d+)\](.*)$/);
      var n = +a[1] - 1;
      var i = a[2].toLowerCase();
      if (!t.ExternalWB[n]) throw 'Workbook External WB '.concat(n, ' missing');
      var u = t.ExternalWB[n];
      var v = u.SheetNames.map(function (t) {
        return t.toLowerCase();
      }).indexOf(i);
      if (v === -1)
        throw ''.concat(i, ' |').concat(
          u.SheetNames.map(function (t) {
            return t.toLowerCase();
          }).join('|'),
          '|',
        );
      var c = u.Sheets[u.SheetNames[v]];
      return U(c, e.replace(/\$/g, ''));
    }
    function m(t, r) {
      if (r.indexOf('!') === -1) {
        if (r.indexOf('[') > -1) {
          var e = r.split('[');
          var a = h(e[0]);
          var n = e[1].replace(/\]/, '');
          var i;
          t.SheetNames.map(function (r, e) {
            return [e, r, t.Sheets[r]];
          }).forEach(function (r) {
            var e = r[0],
              u = r[1],
              v = r[2];
            if (!v['!tables']) return;
            v['!tables'].forEach(function (r) {
              if (r.name !== a) return;
              var e = m(t, u + '!' + r.ref);
              var v = e[0]
                .map(function (t) {
                  return String(t).toLowerCase();
                })
                .indexOf(n.toLowerCase());
              if (v === -1) throw new Error('Table |'.concat(a, '| has no column |').concat(n, '|'));
              i = e.slice(1).map(function (t) {
                return t[v];
              });
            });
          });
          if (i) return i;
        }
        throw new Error('Reference |'.concat(r, '| is unscoped'));
      }
      var u = r.split('!');
      u[0] = h(u[0]);
      if (u[0].indexOf('[') > -1) return b(t, u[0], u[1]);
      var v = t.Sheets[u[0]];
      return U(v, u[1].replace(/\$/g, ''));
    }
    r.book_get_range = m;
    function E(t) {
      var r = t[0].length;
      for (var e = 1; e < t.length; ++e) if (t[e].length !== r) return true;
      return false;
    }
    r._aoa_is_jagged = E;
    function p(t) {
      if (t.length === 1) return true;
      for (var r = 0; r < t.length; ++r) if (t[r] && t[r].length > 1) return false;
      return true;
    }
    r._aoa_is_linear = p;
    function A(t) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'e':
          return t;
      }
      return { t: 'e', v: 15 };
    }
    r._cast_err = A;
    function x(t) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'e':
          return t;
        case 'b':
          return t;
        case 'n':
          return { t: 'b', v: t.v !== 0 };
        case 'z':
          return { t: 'b', v: false };
        case 's':
          if (t.v.toUpperCase() === 'TRUE') return { t: 'b', v: true };
          if (t.v.toUpperCase() === 'FALSE') return { t: 'b', v: false };
      }
      return { t: 'e', v: 15 };
    }
    r._cast_bool = x;
    function S(t) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'e':
          return t;
        case 'b':
          return { t: 'n', v: t.v ? 1 : 0 };
        case 'n':
          return t;
        case 'z':
          return { t: 'n', v: 0 };
        case 's':
          if (t.v === '') return { t: 'n', v: 0 };
          if (!isNaN(+t.v)) return { t: 'n', v: +t.v };
          return { t: 'e', v: 15 };
      }
      return { t: 'e', v: 15 };
    }
    r._cast_num = S;
    function d(r) {
      if (!r) return { t: 'e', v: 15 };
      switch (r.t) {
        case 'e':
          return r;
        case 's':
          return r;
        case 'n':
          return { t: 's', v: t.XLSXLib != null ? t.XLSXLib.SSF._general_num(r.v) : r.v.toString() };
        case 'z':
          return { t: 's', v: '' };
        case 'b':
          return { t: 's', v: r.v ? 'TRUE' : 'FALSE' };
      }
      return { t: 'e', v: 15 };
    }
    r._cast_str = d;
    function M(t) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'e':
          return t;
        case 'b':
          return t;
        case 'n':
          return t;
        case 's':
          return t;
        case 'A':
          if (t.v.length === 1 && t.v[0].length === 1) return M(t.v[0][0]);
      }
      return { t: 'e', v: 15 };
    }
    r._cast_val = M;
    function N(t, r) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'e':
          return t;
        case 'b':
          return { t: 'n', v: t.v ? 1 : 0 };
        case 'n':
          return t;
        case 'z':
          return { t: 'n', v: 0 };
        case 's':
          var e = i(t.v, r);
          if (e && !isNaN(e.valueOf())) return { t: 'n', v: u(e, r) };
      }
      return { t: 'e', v: 15 };
    }
    r._cast_date_code = N;
    function g(t, r) {
      if (!t) return { t: 'e', v: 15 };
      var e = [];
      switch (t.t) {
        case 'A':
          try {
            t.v.forEach(function (t) {
              return t.forEach(function (t) {
                switch (t.t) {
                  case 'z':
                    break;
                  case 'b':
                    break;
                  case 'n':
                    e.push(t.v);
                    break;
                  case 'e':
                    if (!r) throw t;
                    break;
                  default:
                    if (!r) throw 'wafuqB';
                }
              });
            });
            break;
          } catch (t) {
            return t.t === 'e' ? t : { t: 'e', v: 15 };
          }
        case 'n':
          e.push(t.v);
          break;
        default:
          if (!r) return { t: 'e', v: 15 };
      }
      return e;
    }
    r._cast_num_arr = g;
    function L(t, r) {
      if (!t) return { t: 'e', v: 15 };
      var e = [];
      switch (t.t) {
        case 'A':
          try {
            t.v.forEach(function (t) {
              return t.forEach(function (t) {
                switch (t.t) {
                  case 'z':
                    break;
                  case 'n':
                    e.push(t.v);
                    break;
                  case 's':
                    e.push(0);
                    break;
                  case 'b':
                    e.push(+t.v);
                    break;
                  case 'e':
                    if (!r) throw t;
                    break;
                  default:
                    if (!r) throw 'wafuqC';
                }
              });
            });
            break;
          } catch (t) {
            return t.t === 'e' ? t : { t: 'e', v: 15 };
          }
        case 'n':
          e.push(t.v);
          break;
        case 's':
          e.push(0);
          break;
        case 'b':
          e.push(+t.v);
          break;
        default:
          if (!r) return { t: 'e', v: 15 };
      }
      return e;
    }
    r._cast_num_arr_a = L;
    function I(t, r) {
      if (!t) return { t: 'e', v: 15 };
      var e = [];
      switch (t.t) {
        case 'A':
          try {
            t.v.forEach(function (t) {
              return t.forEach(function (t) {
                switch (t.t) {
                  case 'z':
                    break;
                  case 'n':
                    e.push(t.v);
                    break;
                  case 's':
                    e.push(u(i(t.v)));
                    break;
                  default:
                    if (!r) throw 'wafuqB';
                }
              });
            });
            break;
          } catch (t) {
            return { t: 'e', v: 15 };
          }
        case 'n':
          e.push(t.v);
          break;
        case 's':
          e.push(u(i(t.v)));
          break;
        default:
          if (!r) return { t: 'e', v: 15 };
      }
      return e;
    }
    r._cast_numdate_arr = I;
    function k(t, e) {
      if (!t) return { t: 'e', v: 15 };
      switch (t.t) {
        case 'n':
          return [[t.v]];
        case 'A':
          var a = [];
          for (var n = 0; n < t.v.length; ++n) {
            a[n] = [];
            for (var i = 0; i < t.v[n].length; ++i) {
              switch (t.v[n][i].t) {
                case 'n':
                  a[n][i] = t.v[n][i].v;
                  break;
                case 'e':
                  if (e) return t.v[n][i];
                case 's':
                case 'z':
                  if (e) {
                    a[n][i] = 0;
                    break;
                  }
                default:
                  return r._box_err('#VALUE!');
              }
            }
          }
          return a;
        case 'e':
          if (e) return t;
        case 's':
        case 'z':
          if (e) return [[0]];
        default:
          return { t: 'e', v: 15 };
      }
    }
    r._cast_num_aoa = k;
    function T(t, e) {
      if (!t || t.length === 0) return e || r._box_err('#VALUE!');
      var a = function (r) {
        var e = t[r];
        switch (e.t) {
          case 'A': {
            var a;
            e.v.forEach(function (t) {
              if (a) return;
              t.forEach(function (t) {
                if (a) return;
                if (t.t === 'e') a = t;
              });
            });
            if (a) return { value: a };
            break;
          }
          case 'e':
            return { value: e };
        }
      };
      for (var n = 0; n < t.length; ++n) {
        var i = a(n);
        if (typeof i === 'object') return i.value;
      }
      return r._box_err('#VALUE!');
    }
    r._first_err = T;
    function w(t, r) {
      if (isNaN(t)) return { t: 'e', v: r != null ? r : 36 };
      else if (!isFinite(t)) return { t: 'e', v: r != null ? r : 7 };
      return { t: 'n', v: t };
    }
    r._box_num = w;
    function y(t) {
      return { t: 'e', v: typeof t === 'string' ? r.ErrorCode[t] || 0 : t };
    }
    r._box_err = y;
    function O(t) {
      return { t: 's', v: t };
    }
    r._box_str = O;
    function D(t) {
      switch (typeof t) {
        case 'number':
          return { t: 'n', v: t };
        case 'string':
          return { t: 's', v: t };
        case 'boolean':
          return { t: 'b', v: t };
      }
      throw 'Cannot box '.concat(JSON.stringify(t), ' as value');
    }
    r._box_val = D;
    function C(t, r) {
      var e = { t: 'A', v: [], a: r || '' };
      t.forEach(function (t, r) {
        e.v[r] = [];
        t.forEach(function (t, a) {
          e.v[r][a] = D(t);
        });
      });
      return e;
    }
    r._box_aoa = C;
  })((r = t.Util || (t.Util = {})));
  //if (Date.now() > new Date(2024, 1, 15).valueOf()) throw 7262;
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (r) {
    var e = Math;
    function a(t) {
      if (t < 0) return NaN;
      if (t <= 1) return 1;
      return t * a(t - 1);
    }
    function n(t, r) {
      var e = 1;
      for (var a = 1; a <= r; ++a) e *= t + 1 - a;
      for (var a = 1; a <= r; ++a) e /= a;
      return e;
    }
    r.n_choose_k = n;
    function i(t, r, e) {
      var a = t >= 0 ? Math.floor(t) : Math.ceil(t);
      var n = t - a;
      var i = Math.pow(10, Math.ceil(Math.log(r) * Math.LOG10E));
      if (e) return a + (n * r) / i;
      return a + (n * i) / r;
    }
    r.DOLLAR = i;
    var u;
    (function (t) {
      var r = 1e-8;
      var e = 20;
      function a(t, n, i) {
        var u = t(n);
        var v = (t(n + r) - t(n - r)) / (2 * r);
        var c = n - u / v;
        if (Math.abs(c - n) < r) return c;
        if (i > e) return NaN;
        return a(t, c, i + 1);
      }
      t.raphit = a;
      function n(t, r) {
        var e = a(t, r, 0);
        return e;
      }
      t.solvit = n;
    })(u || (u = {}));
    var v = u.solvit;
    function c(t, r) {
      var e = '';
      while (t >= 1e3) {
        e += 'M';
        t -= 1e3;
      }
      if (t >= 900) {
        e += 'CM';
        t -= 900;
      }
      if (t >= 500) {
        e += 'D';
        t -= 500;
      }
      if (t >= 400) {
        e += 'CD';
        t -= 400;
      }
      while (t >= 100) {
        e += 'C';
        t -= 100;
      }
      if (t >= 90) {
        e += 'XC';
        t -= 90;
      }
      if (t >= 50) {
        e += 'L';
        t -= 50;
      }
      if (t >= 40) {
        e += 'XL';
        t -= 40;
      }
      while (t >= 10) {
        e += 'X';
        t -= 10;
      }
      if (t >= 9) {
        e += 'IX';
        t -= 9;
      }
      if (t >= 5) {
        e += 'V';
        t -= 5;
      }
      if (t >= 4) {
        e += 'IV';
        t -= 4;
      }
      while (t >= 1) {
        e += 'I';
        t -= 1;
      }
      if (r === 0) return e;
      e = e
        .replace(/X([LC])V/, 'V$1')
        .replace(/C([DM])L/, 'L$1')
        .replace(/CMVC/, 'LMVL');
      if (r === 1) return e.replace(/C(DX|DV|MX)C/, 'L$1L').replace(/X(CI|LI)X/, 'V$1V');
      e = e
        .replace(/X([LC])IX/, 'I$1')
        .replace('CDXC', 'XD')
        .replace('CDVC', 'XDV')
        .replace('CDIC', 'XDIX')
        .replace('LMVL', 'XMV')
        .replace('CMIC', 'XMIX')
        .replace('CMXC', 'XM');
      if (r === 2) return e;
      e = e.replace('XDV', 'VD').replace('XDIX', 'VDIV').replace('XMV', 'VM').replace('XMIX', 'VMIV');
      if (r === 3) return e;
      return e.replace('VDIV', 'ID').replace('VMIV', 'IM');
    }
    r.ROMAN = c;
    function f(t) {
      var r = t.toUpperCase().trim();
      var e = { M: 1e3, D: 500, C: 100, L: 50, X: 10, V: 5, I: 1 };
      var a = 0;
      var n = 0;
      var i = 0;
      var u = 1;
      if (r.charAt(0) === '-') {
        u = -1;
        i = 1;
      }
      for (; i < r.length; ++i) {
        var v = r.charAt(i);
        if (!e[v]) throw 'Unrecognized character |'.concat(r.charAt(i), '| in ARABIC');
        a += n * (e[v] > n ? -1 : 1);
        n = e[v];
      }
      return u * (a + n);
    }
    r.ARABIC = f;
    function _(t, r, e, a) {
      var n = 0;
      var i = 0;
      if (e >= 11 && e <= 17) n = i = e - 11;
      else
        switch (e) {
          case 0:
          case 1:
            n = 6;
            i = 0;
            break;
          case 2:
            n = 0;
            i = 1;
            break;
          case 3:
            n = 1;
            i = 2;
            break;
          case 4:
            n = 2;
            i = 3;
            break;
          case 5:
            n = 3;
            i = 4;
            break;
          case 6:
            n = 4;
            i = 5;
            break;
          case 7:
            n = 5;
            i = 6;
            break;
          default:
            throw 'unsupported weekend spec '.concat(e);
        }
      var u = a
        .filter(function (e) {
          return e.D >= t.D && e.D < r.D && e.q !== n && e.q !== i;
        })
        .filter(function (t, r, e) {
          return (
            e.slice(0, r).filter(function (r) {
              return r.D === t.D;
            }).length === 0
          );
        });
      var v = (((r.D - t.D + 1) / 7) | 0) * (n === i ? 6 : 5);
      for (var c = t.q; c <= (t.q > r.q ? 7 + r.q : r.q); ++c) {
        if (c === n || c === i || c === 7 + n || c === 7 + i) continue;
        ++v;
      }
      return v - u.length;
    }
    r.NETWORKDAYS_INTL = _;
    function s(r, e, a, n) {
      var i = 0;
      var u = 0;
      if (a >= 11 && a <= 17) i = u = a - 11;
      else
        switch (a) {
          case 0:
          case 1:
            i = 6;
            u = 0;
            break;
          case 2:
            i = 0;
            u = 1;
            break;
          case 3:
            i = 1;
            u = 2;
            break;
          case 4:
            i = 2;
            u = 3;
            break;
          case 5:
            i = 3;
            u = 4;
            break;
          case 6:
            i = 4;
            u = 5;
            break;
          case 7:
            i = 5;
            u = 6;
            break;
          default:
            throw 'unsupported weekend spec '.concat(a);
        }
      var v = r.D;
      while (e > 0) {
        --e;
        var c = t.Util.parse_date_code(++v);
        while (c.q === i || c.q === u) c = t.Util.parse_date_code(++v);
      }
      while (e < 0) {
        ++e;
        var c = t.Util.parse_date_code(--v);
        while (c.q === i || c.q === u) c = t.Util.parse_date_code(--v);
      }
      return v;
    }
    r.WORKDAY_INTL = s;
    var o;
    (function (t) {
      var r = ['ถ้วน', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
      var e = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];
      function a(t, n) {
        if (n === void 0) {
          n = false;
        }
        if (n) {
          if (t === 1) return 'เอ็ด';
          if (t === 0) return '';
        }
        if (r[t]) return r[t];
        if (t < 100) return i[(t / 10) >>> 0] + 'บ' + (t % 10 ? r[t % 10] : '');
        if (t >= 1e7) {
          var u = t % 1e6;
          if (t > 1e11) u = 0;
          var v = Math.round((t - u) / 1e6);
          return a(v) + 'ล้าน' + (u ? a(u, true) : '');
        }
        var c = '';
        var f = 0;
        var _ = 0;
        while (t > 0) {
          f = t % 10;
          if (f > 0) c = (_ === 0 && f === 1 ? 'เอ็ด' : r[f]) + e[_] + c;
          _++;
          t = (t / 10) | 0;
        }
        return c;
      }
      var n = r.map(function (t, r) {
        return t + (r > 0 ? 'สตางค์' : '');
      });
      var i = ['', 'สิ', 'ยี่สิ', 'สามสิ', 'สี่สิ', 'ห้าสิ', 'หกสิ', 'เจ็ดสิ', 'แปดสิ', 'เก้าสิ'];
      var u = ['', 'เอ็ด', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'].map(function (t, r) {
        return t + 'สตางค์';
      });
      function v(t) {
        if (t < 0) return t > -0.005 ? 'ลบศูนย์บาทถ้' : 'ลบ' + v(-t);
        if (isNaN(t) || t < 0.005) return 'ศูนย์บาทถ้วน';
        var r = Math.round(t * 100);
        var e = r % 100;
        r -= e;
        if (t > 1e11) e = 0;
        var c = n[e] || i[(e / 10) >>> 0] + 'บ' + u[e % 10];
        if (r > 0 && e === 0) c = 'ถ้วน';
        var f = r === 0 ? '' : a(Math.round(r / 100)) + 'บาท';
        f = f.replace(new RegExp('หนึ่งสิบ', ''), 'สิบ');
        f = f.replace(new RegExp('สองสิบ', ''), 'ยี่สิบ');
        f = f.replace(new RegExp('สิบหนึ่ง', ''), 'สิบเอ็ด');
        return f + c;
      }
      t.FUNC = v;
    })(o || (o = {}));
    r.BAHTTEXT = o.FUNC;
    var l;
    (function (r) {
      function e(t, r, e, a, n) {
        if (t === 0) return -(a + e * r);
        var i = Math.pow(1 + t, r);
        if (n === 1) return -(a * i + (e * (1 + t) * (i - 1)) / t);
        return -(a * i + (e * (i - 1)) / t);
      }
      r.FV = e;
      function a(t, r, e, a, n) {
        var i = Math.pow(1 + t, r);
        if (t === 0) return -(a / i + e * r);
        return -(a / i + (e * (1 + t * n) * (1 - 1 / Math.pow(1 + t, r))) / t);
      }
      r.PV = a;
      function n(t, r, e, a, n) {
        if (t === 0 && r !== 0) return -(a * e) / r;
        return Math.log((-a * t + r * (1 + t * n)) / (e * t + r * (1 + t * n))) / Math.log(t + 1);
      }
      r.NPER = n;
      function i(t, r, e, a, n) {
        var i = t === 0 ? r : ((1 + t * n) * (1 - Math.pow(1 + t, -r))) / t;
        return -(e + a * Math.pow(1 + t, -r)) / i;
      }
      r.PMT = i;
      function u(t, r, e, a, n, u) {
        if (r === 1 && u === 1) return 0;
        if (t === -1) return -n;
        var v = -(a * Math.pow(1 + t, r - 1) * t + i(t, e, a, n, 0) * (Math.pow(1 + t, r - 1) - 1));
        return u === 0 ? v : v / (1 + t);
      }
      r.IPMT = u;
      function c(t, r, e, a, n, v) {
        return i(t, e, a, n, v) - u(t, r, e, a, n, v);
      }
      r.PPMT = c;
      function f(t, r, e, a) {
        if (r < 1 || r > e) return NaN;
        if (e <= 0) return NaN;
        var n = -a * t;
        return n - (n / e) * r;
      }
      r.ISPMT = f;
      function _(t, r, e, a, n) {
        var i = parseFloat((1 - Math.pow(r / t, 1 / e)).toFixed(3));
        var u = (t * i * n) / 12;
        if ((a | 0) <= 1) return u;
        for (var v = 1; v < a; ++v) {
          if (a <= e) {
            if (v === (a | 0) - 1) return (t - u) * i;
            else if (v === (e | 0) - 1) return ((t - u) * i * (12 - n)) / 12;
          } else {
            if (v === (a | 0) - 1) return ((t - u) * i * (12 - n)) / 12;
          }
          u += (t - u) * i;
        }
        return NaN;
      }
      r.DB = _;
      function s(t, r, e, a, n, i) {
        var u = a - (a | 0);
        var v = 0;
        for (var c = 0; ; ++c) {
          var f = Math.min((t - v) * (n / e), t - r - v);
          var _ = (t - v - r) / (e - c);
          var s = i && f < _ ? _ : f;
          v += s;
          if ((a | 0) === 0) return v * u;
          if ((c | 0) !== (a | 0) - 1) continue;
          f = Math.min((t - v) * (n / e), t - r - v);
          _ = (t - v - r) / (e - (c + 1));
          if (i && f < _) {
            if ((a | 0) === (e | 0)) s = 0;
            else s = _;
          } else s = f;
          return v + s * u;
        }
      }
      function o(t, r, e, a, n) {
        if (a >= 2) return s(t, r, e, a, n, false) - s(t, r, e, a - 1, n, false);
        return s(t, r, e, a, n, false);
      }
      r.DDB = o;
      function l(t, r, e, a, n, i, u) {
        return s(t, r, e, n, i, !u) - s(t, r, e, a, i, !u);
      }
      r.VDB = l;
      function U(t, r) {
        return v(function (r) {
          return b(r, t);
        }, r);
      }
      r.IRR = U;
      function h(t, r, e) {
        return v(function (e) {
          return E(e, t, r);
        }, e);
      }
      r.XIRR = h;
      function b(t, r) {
        var e = 0;
        var a = 1 + t;
        r.forEach(function (r) {
          e += r / a;
          a *= 1 + t;
        });
        return e;
      }
      r.NPV = b;
      function m(t, r, e) {
        var a = b(
          e,
          t.map(function (t) {
            return Math.max(t, 0);
          }),
        );
        var n = b(
          r,
          t.map(function (t) {
            return Math.min(t, 0);
          }),
        );
        var i = t.length;
        return Math.pow((-a * Math.pow(1 + e, i)) / (n * (1 + r)), 1 / (i - 1)) - 1;
      }
      r.MIRR = m;
      function E(t, r, e) {
        var a = 0;
        r.forEach(function (r, n) {
          a += r / Math.pow(1 + t, (e[n] - e[0]) / 365);
        });
        return a;
      }
      r.XNPV = E;
      function p(t, r, a, n, i, u) {
        return v(function (n) {
          return e(n, t, r, a, i);
        }, u);
      }
      r.RATE = p;
      function A(r, e, a) {
        var n = t.Util.parse_date_code(r);
        var i = t.Util.parse_date_code(e);
        switch (a) {
          case 1: {
            if (i.y === n.y) return (e | 0) - (r | 0);
            var u = 365;
            var v = e - t.Util.date_to_code(new Date(i.y, 0, 0, 0, 0, 0, 0));
            u += v;
            var c = r - t.Util.date_to_code(new Date(n.y, 0, 0, 0, 0, 0, 0));
            u -= c;
            if (n.y % 4 === 0 && (n.y % 400 === 0 || n.y % 100 !== 0) && c >= 60) ++u;
            if (i.y % 4 === 0 && (i.y % 400 === 0 || i.y % 100 !== 0) && v >= 60) --u;
            return u;
          }
          case 2:
            return (e | 0) - (r | 0);
        }
        return 0;
      }
      function x(t, r, e) {
        var a = A(t, r, 2);
        if (a <= 182) return (365 * e) / (360 - e * a);
        var n = (100 - (e * 100 * a) / 360) / 100;
        var i = a === 366 ? 366 : 365;
        var u = Math.pow(a / i, 2) - ((2 * a) / i - 1) * (1 - 1 / n);
        var v = Math.sqrt(u);
        var c = (2 * a) / i - 1;
        return (2 * (v - a / i)) / c;
      }
      r.TBILLEQ = x;
      function S(t, r, e) {
        return 100 * (1 - (e * A(t, r, 1)) / 360);
      }
      r.TBILLPRICE = S;
      function d(t, r, e) {
        return ((100 - e) / e) * (360 / A(t, r, 1));
      }
      r.TBILLYIELD = d;
      function M(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPDAYS = M;
      function N(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPPCD = N;
      function g(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPNCD = g;
      function L(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPNUM = L;
      function I(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPDAYBS = I;
      function k(t, r, e, a) {
        switch (a) {
        }
        return NaN;
      }
      r.COUPDAYSNC = k;
      function T(t, r, e, a, n, i, u) {
        return NaN;
      }
      r.AMORDEGRC = T;
      function w(t, r, e, a, n, i, u) {
        if (t === a || n > Math.ceil(1 / i)) return 0;
        return NaN;
      }
      r.AMORLINC = w;
    })((l = r.Financial || (r.Financial = {})));
    var U;
    (function (t) {
      function r(t) {
        var r = {};
        var e = [];
        t.forEach(function (t) {
          if (!r[t]) {
            r[t] = 0;
            e.push(t);
          }
          r[t] = r[t] + 1;
        });
        var a = -1;
        var n = -1;
        e.forEach(function (t) {
          if (r[t] <= n) return;
          n = r[t];
          a = t;
        });
        return n < 2 ? NaN : a;
      }
      t.MODE_SNGL = r;
      function e(t) {
        var r = {};
        var e = [];
        t.forEach(function (t) {
          if (!r[t]) {
            r[t] = 0;
            e.push(t);
          }
          r[t] = r[t] + 1;
        });
        var a = -1;
        var n = [];
        e.forEach(function (t) {
          if (r[t] < a) return;
          if (r[t] === a) {
            n.push(t);
            return;
          }
          a = r[t];
          n = [t];
        });
        return a < 2 ? [] : n;
      }
      t.MODE_MULT = e;
      function i(t, r) {
        var e = r
          .map(function (t, r) {
            return [t, r, 0];
          })
          .sort(function (t, r) {
            return t[0] - r[0];
          });
        var a = 0;
        t.forEach(function (t) {
          for (var r = 0; r < e.length; ++r) if (t <= e[r][0]) return e[r][2]++;
          return a++;
        });
        return e
          .sort(function (t, r) {
            return t[1] - r[1];
          })
          .map(function (t) {
            return t[2];
          })
          .concat([a]);
      }
      t.FREQUENCY = i;
      function u(t, r) {
        var e = t.sort(function (t, r) {
          return t - r;
        });
        var a = r * (e.length - 1);
        var n = Math.floor(a);
        return a === n ? e[a] : e[n] + (a - n) * (e[n + 1] - e[n]);
      }
      t.PERCENTILE_INC = u;
      function v(t, r) {
        var e = t.sort(function (t, r) {
          return t - r;
        });
        switch (r) {
          case 1:
            return u(t, 0.25);
          case 2:
            return u(t, 0.5);
          case 3:
            return u(t, 0.75);
          default:
            return NaN;
        }
      }
      t.QUARTILE_INC = v;
      function c(t, r) {
        var e = t.sort(function (t, r) {
          return t - r;
        });
        if (r < 1 / (e.length + 1) || r > 1 - 1 / (e.length + 1)) return NaN;
        var a = r * (e.length + 1) - 1;
        var n = Math.floor(a);
        return a === n ? e[a] : e[n] + (a - n) * (e[n + 1] - e[n]);
      }
      t.PERCENTILE_EXC = c;
      function f(t, r) {
        var e = t.sort(function (t, r) {
          return t - r;
        });
        switch (r) {
          case 1:
            return c(t, 0.25);
          case 2:
            return c(t, 0.5);
          case 3:
            return c(t, 0.75);
          default:
            return NaN;
        }
      }
      t.QUARTILE_EXC = f;
      function _(t) {
        var r = [
          -1.3026537197817094, 0.6419697923564902, 0.019476473204185836, -0.00956151478680863, -0.000946595344482036, 0.000366839497852761,
          42523324806907e-18, -20278578112534e-18, -1624290004647e-18, 130365583558e-17, 1.5626441722e-8, -8.5238095915e-8, 6.529054439e-9,
          5.059343495e-9, -9.91364156e-10, -2.27365122e-10, 9.6467911e-11, 2.394038e-12, -6.886027e-12, 8.94487e-13, 3.13092e-13,
          -1.12708e-13, 3.81e-16, 7.106e-15, -1.523e-15, -9.4e-17, 1.21e-16, -2.8e-17,
        ];
        var e = r.length - 1;
        var a = false;
        var n = 0;
        var i = 0;
        var u = 0;
        var v = 0;
        if (t < 0) {
          t = -t;
          a = true;
        }
        u = 2 / (2 + t);
        v = 4 * u - 2;
        for (; e > 0; e--) {
          var c = n;
          n = v * n - i + r[e];
          i = c;
        }
        var f = u * Math.exp(-t * t + 0.5 * (r[0] + v * n) - i);
        return a ? f - 1 : 1 - f;
      }
      t.ERF = _;
      function s(t) {
        return 1 - _(t);
      }
      t.ERFC = s;
      function o(t) {
        if (t >= 2) return -100;
        if (t <= 0) return 100;
        var r = t < 1 ? t : 2 - t;
        var e = Math.sqrt(-2 * Math.log(r / 2));
        var a = -0.70711 * ((2.30753 + e * 0.27061) / (1 + e * (0.99229 + e * 0.04481)) - e);
        for (var n = 0; n < 2; n++) {
          var i = s(a) - r;
          a += i / (1.1283791670955126 * Math.exp(-(a * a)) - a * i);
        }
        return t < 1 ? a : -a;
      }
      var l;
      (function (t) {
        var r = 7;
        var e = [
          0.9999999999998099, 676.5203681218851, -1259.1392167224028, 771.3234287776531, -176.6150291621406, 12.507343278686905,
          -0.13857109526572012, 9984369578019572e-21, 1.5056327351493116e-7,
        ];
        var a = 607 / 128;
        var n = [
          0.9999999999999971, 57.15623566586292, -59.59796035547549, 14.136097974741746, -0.4919138160976202, 3399464998481189e-20,
          4652362892704858e-20, -9837447530487956e-20, 0.0001580887032249125, -0.00021026444172410488, 0.00021743961811521265,
          -0.0001643181065367639, 8441822398385275e-20, -26190838401581408e-21, 36899182659531625e-22,
        ];
        function i(t) {
          if (t < 0) return NaN;
          var r = n[0];
          for (var e = n.length - 1; e > 0; --e) r += n[e] / (t + e);
          var i = t + a + 0.5;
          return 0.5 * Math.log(2 * Math.PI) + (t + 0.5) * Math.log(i) - i + Math.log(r) - Math.log(t);
        }
        t._GAMMALN = i;
        function u(t) {
          if (t < 0.5) return Math.PI / (Math.sin(Math.PI * t) * u(1 - t));
          else if (t > 100) return Math.exp(i(t));
          else {
            t -= 1;
            var a = e[0];
            for (var n = 1; n < r + 2; n++) a += e[n] / (t + n);
            var v = t + r + 0.5;
            return Math.sqrt(2 * Math.PI) * Math.pow(v, t + 0.5) * Math.exp(-v) * a;
          }
        }
        t._GAMMA = u;
        function v(t, r) {
          var e = i(t);
          var a = 1;
          var n = -~(Math.log(t >= 1 ? t : 1 / t) * 8.5 + t * 0.4 + 17);
          if (r < 0 || t <= 0) {
            return NaN;
          } else if (r < t + 1) {
            var u = t;
            var v = 1 / t;
            var c = v;
            for (; a <= n; a++) {
              v += c *= r / ++u;
            }
            return v * Math.exp(-r + t * Math.log(r) - e);
          }
          var f = 0;
          var _ = r + 1 - t;
          var s = 1 / 1e-30;
          var o = 1 / _;
          var l = o;
          for (; a <= n; a++) {
            f = -a * (a - t);
            _ += 2;
            o = f * o + _;
            s = _ + f / s;
            o = 1 / o;
            l *= o * s;
          }
          return 1 - l * Math.exp(-r + t * Math.log(r) - e);
        }
        t.lower = v;
        function c(t, r) {
          var e = 0;
          var a = i(r);
          var n;
          var u;
          var c;
          var f;
          var _;
          var s;
          var o = 0;
          if (t <= 0) return 0;
          if (t >= 1) return Math.max(100, r + 100 * Math.sqrt(r));
          if (r > 1) {
            s = Math.log(r - 1);
            o = Math.exp((r - 1) * (s - 1) - a);
            _ = t < 0.5 ? t : 1 - t;
            c = Math.sqrt(-2 * Math.log(_));
            n = (2.30753 + c * 0.27061) / (1 + c * (0.99229 + c * 0.04481)) - c;
            if (t < 0.5) n = -n;
            n = Math.max(0.001, r * Math.pow(1 - 1 / (9 * r) - n / (3 * Math.sqrt(r)), 3));
          } else {
            c = 1 - r * (0.253 + r * 0.12);
            if (t < c) n = Math.pow(t / c, 1 / r);
            else n = 1 - Math.log(1 - (t - c) / (1 - c));
          }
          for (; e < 12; e++) {
            if (n <= 0) return 0;
            u = v(r, n) - t;
            if (r > 1) c = o * Math.exp(-(n - (r - 1)) + (r - 1) * (Math.log(n) - s));
            else c = Math.exp(-n + (r - 1) * Math.log(n) - a);
            f = u / c;
            n -= c = f / (1 - 0.5 * Math.min(1, f * ((r - 1) / n - 1)));
            if (n <= 0) n = 0.5 * (n + c);
            if (Math.abs(c) < 1e-8 * n) break;
          }
          return n;
        }
        t.lower_inv = c;
      })((l = t.Gamma || (t.Gamma = {})));
      t.GAMMA = l._GAMMA;
      t.GAMMALN = l._GAMMALN;
      function U(r, e) {
        if (r <= 0 || e <= 0) return NaN;
        return r + e > 170 ? Math.exp(t.GAMMALN(r) + t.GAMMALN(e) - t.GAMMALN(r + e)) : (t.GAMMA(r) * t.GAMMA(e)) / t.GAMMA(r + e);
      }
      function h(t, r, e) {
        var a = 1e-30;
        var n = 1;
        var i = r + e;
        var u = r + 1;
        var v = r - 1;
        var c = 1;
        var f = 1 - (i * t) / u;
        if (Math.abs(f) < a) f = a;
        f = 1 / f;
        var _ = f;
        for (; n <= 100; n++) {
          var s = 2 * n;
          var o = (n * (e - n) * t) / ((v + s) * (r + s));
          f = 1 + o * f;
          if (Math.abs(f) < a) f = a;
          c = 1 + o / c;
          if (Math.abs(c) < a) c = a;
          f = 1 / f;
          _ *= f * c;
          o = (-(r + n) * (i + n) * t) / ((r + s) * (u + s));
          f = 1 + o * f;
          if (Math.abs(f) < a) f = a;
          c = 1 + o / c;
          if (Math.abs(c) < a) c = a;
          f = 1 / f;
          var l = f * c;
          _ *= l;
          if (Math.abs(l - 1) < 3e-7) break;
        }
        return _;
      }
      function b(r, e, a) {
        var n = r === 0 || r === 1 ? 0 : Math.exp(t.GAMMALN(e + a) - t.GAMMALN(e) - t.GAMMALN(a) + e * Math.log(r) + a * Math.log(1 - r));
        if (r < 0 || r > 1) return NaN;
        if (r < (e + 1) / (e + a + 2)) return (n * h(r, e, a)) / e;
        return 1 - (n * h(1 - r, a, e)) / a;
      }
      function m(r, e, a, n) {
        if (n) return r > 1 || r < 0 ? +!!(r > 1) * 1 : b(r, e, a);
        if (r > 1 || r < 0) return 0;
        if (e === 1 && a === 1) return 1;
        if (e < 512 && a < 512) return (Math.pow(r, e - 1) * Math.pow(1 - r, a - 1)) / U(e, a);
        return Math.exp((e - 1) * Math.log(r) + (a - 1) * Math.log(1 - r) - (t.GAMMALN(e) + t.GAMMALN(a) - t.GAMMALN(e + a)));
      }
      t.BETA_DIST = m;
      function E(r, e, a) {
        var n = e - 1;
        var i = a - 1;
        var u = 0;
        var v = 0;
        var c = 0;
        var f = 0;
        var _ = 0;
        var s = 0;
        if (r <= 0) return 0;
        if (r >= 1) return 1;
        if (e >= 1 && a >= 1) {
          var o = r < 0.5 ? r : 1 - r;
          v = Math.sqrt(-2 * Math.log(o));
          f = (2.30753 + v * 0.27061) / (1 + v * (0.99229 + v * 0.04481)) - v;
          if (r < 0.5) f = -f;
          var l = (f * f - 3) / 6;
          _ = 2 / (1 / (2 * e - 1) + 1 / (2 * a - 1));
          s = (f * Math.sqrt(l + _)) / _ - (1 / (2 * a - 1) - 1 / (2 * e - 1)) * l + 5 / 6 - 2 / (3 * _);
          f = e / (e + a * Math.exp(2 * s));
        } else {
          var U = Math.log(e / (e + a));
          var h = Math.log(a / (e + a));
          v = Math.exp(e * U) / e;
          c = Math.exp(a * h) / a;
          s = v + c;
          if (r < v / s) f = Math.pow(e * s * r, 1 / e);
          else f = 1 - Math.pow(a * s * (1 - r), 1 / a);
        }
        var m = -t.GAMMALN(e) - t.GAMMALN(a) + t.GAMMALN(e + a);
        for (; u < 10; u++) {
          if (f === 0 || f === 1) return f;
          var E = b(f, e, a) - r;
          v = Math.exp(n * Math.log(f) + i * Math.log(1 - f) + m);
          c = E / v;
          f -= v = c / (1 - 0.5 * Math.min(1, c * (n / f - i / (1 - f))));
          if (f <= 0) f = 0.5 * (f + v);
          if (f >= 1) f = 0.5 * (f + v + 1);
          if (Math.abs(v) < 1e-8 * f && u > 0) break;
        }
        return f;
      }
      t.BETA_INV = E;
      function p(t, r, e, a) {
        if (!a) {
          if (e === 0 || e === 1) return +(r * e === t);
          return n(r, t) * Math.pow(e, t) * Math.pow(1 - e, r - t);
        }
        var i = 0;
        for (var u = 0; u <= t; ++u) i += p(u, r, e, false);
        return i;
      }
      t.BINOM_DIST = p;
      function A(t, r, e, a) {
        if (!a) return n(t + r - 1, r - 1) * Math.pow(e, r) * Math.pow(1 - e, t);
        var i = 0;
        for (var u = 0; u <= t; ++u) i += A(u, r, e, false);
        return i;
      }
      t.NEGBINOM_DIST = A;
      function x(r, e, a) {
        if (r < 0) return 0;
        if (!a) {
          if (r === 0 && e === 2) return 0.5;
          return Math.exp((e / 2 - 1) * Math.log(r) - r / 2 - (e / 2) * Math.log(2) - t.GAMMALN(e / 2));
        }
        return l.lower(e / 2, r / 2);
      }
      t.CHISQ_DIST = x;
      function S(t, r) {
        return 2 * l.lower_inv(t, r / 2);
      }
      t.CHISQ_INV = S;
      function d(t) {
        if (t <= -1 || t >= 1) return NaN;
        return (Math.log(1 + t) - Math.log(1 - t)) / 2;
      }
      t.FISHER = d;
      function M(t) {
        return (Math.exp(2 * t) - 1) / (Math.exp(2 * t) + 1);
      }
      t.FISHERINV = M;
      function N(t, r, e, a) {
        var n = Math.exp(-Math.pow(t / e, r));
        return a ? 1 - n : (r / Math.pow(e, r)) * Math.pow(t, r - 1) * n;
      }
      t.WEIBULL = N;
      function g(t, r, e, a) {
        return L((t - r) / e, a);
      }
      t.NORM_DIST = g;
      function L(t, r) {
        if (!r) return Math.exp((-t * t) / 2) / Math.sqrt(2 * Math.PI);
        return (1 + _(t / Math.sqrt(2))) / 2;
      }
      t.NORM_S_DIST = L;
      function I(t) {
        return -Math.sqrt(2) * o(2 * t);
      }
      t.NORM_S_INV = I;
      function k(t, r, e) {
        if (!e) return (Math.exp(-r) * Math.pow(r, t)) / a(t | 0);
        var n = 0;
        for (var i = 0; i <= t; ++i) n += k(i, r, false);
        return n;
      }
      t.POISSON = k;
      function T(t, r, e) {
        if (!e) {
          var a = r > 1e100 ? 1e100 : r;
          return (1 / (Math.sqrt(a) * U(0.5, a / 2))) * Math.pow(1 + (t * t) / a, -((a + 1) / 2));
        } else return b((t + Math.sqrt(t * t + r)) / (2 * Math.sqrt(t * t + r)), r / 2, r / 2);
      }
      t.T_DIST = T;
      function w(t, r) {
        var e = E(2 * Math.min(t, 1 - t), r / 2, 1 / 2);
        e = Math.sqrt((r * (1 - e)) / e);
        return t > 0.5 ? e : -e;
      }
      t.T_INV = w;
      function y(t, r, e, a) {
        if (!a) {
          if (t < 0) return 0;
          return (Math.pow(t, r / 2 - 1) * Math.pow(r / e, r / 2) * Math.pow(1 + (r * t) / e, -(r + e) / 2)) / U(r / 2, e / 2);
        }
        return t < 0 ? 0 : b((r * t) / (r * t + e), r / 2, e / 2);
      }
      t.F_DIST = y;
      function O(t, r, e) {
        return e / (r * (1 / E(t, r / 2, e / 2) - 1));
      }
      t.F_INV = O;
    })((U = r.Statistical || (r.Statistical = {})));
    function h(t) {
      switch (t.length) {
        case 0:
          return NaN;
        case 1:
          return t[0][0];
        case 2:
          return t[0][0] * t[1][1] - t[1][0] * t[0][1];
        case 3:
          return (
            0 +
            +(t[0][0] * t[1][1] * t[2][2]) +
            +(t[0][1] * t[1][2] * t[2][0]) +
            +(t[0][2] * t[1][0] * t[2][1]) +
            -(t[2][0] * t[1][1] * t[0][2]) +
            -(t[2][1] * t[1][2] * t[0][0]) +
            -(t[2][2] * t[1][0] * t[0][1])
          );
      }
      var r = 0;
      var e = function (e) {
        r +=
          t[0][e] *
          (e % 2 === 0 ? 1 : -1) *
          h(
            t.slice(1).map(function (t) {
              return t.filter(function (t, r) {
                return r !== e;
              });
            }),
          );
      };
      for (var a = 0; a < t[0].length; ++a) {
        e(a);
      }
      return r;
    }
    r.MDETERM = h;
    function b(t, r) {
      var e = [];
      for (var a = 0; a < t.length; ++a) {
        e[a] = [];
        for (var n = 0; n < r[0].length; ++n) {
          e[a][n] = 0;
          for (var i = 0; i < t[0].length; ++i) e[a][n] += t[a][i] * r[i][n];
        }
      }
      return e;
    }
    r.MMULT = b;
    function m(t) {
      var r = [];
      for (var e = 0; e < t.length; ++e) {
        r[e] = [];
        for (var a = 0; a < t.length; ++a) r[e][a] = t[e][a];
        for (var a = 0; a < t.length; ++a) r[e][a + t.length] = e === a ? 1 : 0;
      }
      for (var e = 0; e < r.length; ++e) {
        var n = r[e][e];
        for (var a = 0; a < r[e].length; ++a) r[e][a] /= n;
        for (var i = e + 1; i < r.length; ++i) {
          var u = r[i][e] / r[e][e];
          for (var a = 0; a < r[i].length; ++a) r[i][a] -= r[e][a] * u;
        }
      }
      for (var e = 0; e < r.length; ++e) {
        for (var a = e + 1; a < r.length; ++a) {
          var u = r[e][a] / r[a][a];
          for (var i = 0; i < r[0].length; ++i) r[e][i] -= r[a][i] * u;
        }
      }
      return r.map(function (r, e) {
        return r.slice(t.length);
      });
    }
    function E(t) {
      var r = [];
      switch (t.length) {
        case 1:
          return [[1 / t[0][0]]];
        case 2:
          var e = h(t);
          return [
            [t[1][1] / e, -t[1][0] / e],
            [-t[0][1], t[0][0] / e],
          ];
      }
      return m(t);
    }
    r.MINVERSE = E;
    var p;
    (function (t) {
      function a(t, r) {
        var e = 0;
        for (var a = 0; a < t.length; ++a) e = r * e + t[a];
        return e;
      }
      function n(t, r, e, a, n) {
        if (r === 0) return e;
        if (r === 1) return a;
        var i = 2 / t;
        var u = a;
        for (var v = 1; v < r; ++v) {
          u = a * v * i + n * e;
          e = a;
          a = u;
        }
        return u;
      }
      function i(t, r, e, a, i) {
        return function e(u, v) {
          if (a) {
            if (u === 0) return a === 1 ? -Infinity : Infinity;
            else if (u < 0) return NaN;
          }
          if (v === 0) return t(u);
          if (v === 1) return r(u);
          if (v < 0) return NaN;
          v |= 0;
          var c = t(u);
          var f = r(u);
          return n(u, v, c, f, i);
        };
      }
      t.J = (function () {
        var t = 0.636619772;
        var i = [57568490574, -13362590354, 651619640.7, -11214424.18, 77392.33017, -184.9052456].reverse();
        var u = [57568490411, 1029532985, 9494680.718, 59272.64853, 267.8532712, 1].reverse();
        var v = [1, -0.001098628627, 2734510407e-14, -2073370639e-15, 2.093887211e-7].reverse();
        var c = [-0.01562499995, 0.0001430488765, -6911147651e-15, 7.621095161e-7, -9.34935152e-8].reverse();
        function f(r) {
          var n = 0;
          var f = 0;
          var _ = 0;
          var s = r * r;
          if (r < 8) {
            f = a(i, s);
            _ = a(u, s);
            n = f / _;
          } else {
            var o = r - 0.785398164;
            s = 64 / s;
            f = a(v, s);
            _ = a(c, s);
            n = e.sqrt(t / r) * (e.cos(o) * f - (e.sin(o) * _ * 8) / r);
          }
          return n;
        }
        var _ = [72362614232, -7895059235, 242396853.1, -2972611.439, 15704.4826, -30.16036606].reverse();
        var s = [144725228442, 2300535178, 18583304.74, 99447.43394, 376.9991397, 1].reverse();
        var o = [1, 0.00183105, -3516396496e-14, 2457520174e-15, -2.40337019e-7].reverse();
        var l = [0.04687499995, -0.0002002690873, 8449199096e-15, -8.8228987e-7, 1.05787412e-7].reverse();
        function U(r) {
          var n = 0;
          var i = 0;
          var u = 0;
          var v = r * r;
          var c = e.abs(r) - 2.356194491;
          if (Math.abs(r) < 8) {
            i = r * a(_, v);
            u = a(s, v);
            n = i / u;
          } else {
            v = 64 / v;
            i = a(o, v);
            u = a(l, v);
            n = e.sqrt(t / e.abs(r)) * (e.cos(c) * i - (e.sin(c) * u * 8) / e.abs(r));
            if (r < 0) n = -n;
          }
          return n;
        }
        return function t(a, i) {
          i = Math.round(i);
          if (!isFinite(a)) return isNaN(a) ? a : 0;
          if (i < 0) return (i % 2 ? -1 : 1) * r.BESSELJ(a, -i);
          if (a < 0) return (i % 2 ? -1 : 1) * r.BESSELJ(-a, i);
          if (i === 0) return f(a);
          if (i === 1) return U(a);
          if (a === 0) return 0;
          var u = 0;
          if (a > i) {
            u = n(a, i, f(a), U(a), -1);
          } else {
            var v = 2 * e.floor((i + e.floor(e.sqrt(40 * i))) / 2);
            var c = false;
            var _ = 0;
            var s = 0;
            var o = 1;
            var l = 0;
            var h = 2 / a;
            for (var b = v; b > 0; b--) {
              l = b * h * o - _;
              _ = o;
              o = l;
              if (e.abs(o) > 1e10) {
                o *= 1e-10;
                _ *= 1e-10;
                u *= 1e-10;
                s *= 1e-10;
              }
              if (c) s += o;
              c = !c;
              if (b === i) u = _;
            }
            s = 2 * s - o;
            u /= s;
          }
          return u;
        };
      })();
      t.Y = (function () {
        var t = 0.636619772;
        var n = [-2957821389, 7062834065, -512359803.6, 10879881.29, -86327.92757, 228.4622733].reverse();
        var u = [40076544269, 745249964.8, 7189466.438, 47447.2647, 226.1030244, 1].reverse();
        var v = [1, -0.001098628627, 2734510407e-14, -2073370639e-15, 2.093887211e-7].reverse();
        var c = [-0.01562499995, 0.0001430488765, -6911147651e-15, 7.621095161e-7, -9.34945152e-8].reverse();
        function f(i) {
          var f = 0;
          var _ = 0;
          var s = 0;
          var o = i * i;
          var l = i - 0.785398164;
          if (i < 8) {
            _ = a(n, o);
            s = a(u, o);
            f = _ / s + t * r.BESSELJ(i, 0) * e.log(i);
          } else {
            o = 64 / o;
            _ = a(v, o);
            s = a(c, o);
            f = e.sqrt(t / i) * (e.sin(l) * _ + (e.cos(l) * s * 8) / i);
          }
          return f;
        }
        var _ = [-4900604943e3, 127527439e4, -51534381390, 734926455.1, -4237922.726, 8511.937935].reverse();
        var s = [249958057e5, 424441966400, 3733650367, 22459040.02, 102042.605, 354.9632885, 1].reverse();
        var o = [1, 0.00183105, -3516396496e-14, 2457520174e-15, -2.40337019e-7].reverse();
        var l = [0.04687499995, -0.0002002690873, 8449199096e-15, -8.8228987e-7, 1.05787412e-7].reverse();
        function U(n) {
          var i = 0;
          var u = 0;
          var v = 0;
          var c = n * n;
          var f = n - 2.356194491;
          if (n < 8) {
            u = n * a(_, c);
            v = a(s, c);
            i = u / v + t * (r.BESSELJ(n, 1) * e.log(n) - 1 / n);
          } else {
            c = 64 / c;
            u = a(o, c);
            v = a(l, c);
            i = e.sqrt(t / n) * (e.sin(f) * u + (e.cos(f) * v * 8) / n);
          }
          return i;
        }
        return i(f, U, 'BESSELY', 1, -1);
      })();
      t.I = (function () {
        var t = [1, 3.5156229, 3.0899424, 1.2067492, 0.2659732, 0.0360768, 0.0045813].reverse();
        var n = [0.39894228, 0.01328592, 0.00225319, -0.00157565, 0.00916281, -0.02057706, 0.02635537, -0.01647633, 0.00392377].reverse();
        function i(r) {
          if (r <= 3.75) return a(t, (r * r) / (3.75 * 3.75));
          return (e.exp(e.abs(r)) / e.sqrt(e.abs(r))) * a(n, 3.75 / e.abs(r));
        }
        var u = [0.5, 0.87890594, 0.51498869, 0.15084934, 0.02658733, 0.00301532, 32411e-8].reverse();
        var v = [0.39894228, -0.03988024, -0.00362018, 0.00163801, -0.01031555, 0.02282967, -0.02895312, 0.01787654, -0.00420059].reverse();
        function c(t) {
          if (t < 3.75) return t * a(u, (t * t) / (3.75 * 3.75));
          return (((t < 0 ? -1 : 1) * e.exp(e.abs(t))) / e.sqrt(e.abs(t))) * a(v, 3.75 / e.abs(t));
        }
        return function t(a, n) {
          n = Math.round(n);
          if (n === 0) return i(a);
          if (n === 1) return c(a);
          if (n < 0) return NaN;
          if (e.abs(a) === 0) return 0;
          if (a === Infinity) return Infinity;
          var u = 0;
          var v = 0;
          var f = 1;
          var _ = 0;
          var s = 2 / e.abs(a);
          var o = 2 * e.round((n + e.round(e.sqrt(40 * n))) / 2);
          for (var l = o; l > 0; l--) {
            _ = l * s * f + v;
            v = f;
            f = _;
            if (e.abs(f) > 1e10) {
              f *= 1e-10;
              v *= 1e-10;
              u *= 1e-10;
            }
            if (l === n) u = v;
          }
          u *= r.BESSELI(a, 0) / f;
          return a < 0 && n % 2 ? -u : u;
        };
      })();
      t.K = (function () {
        var t = [-0.57721566, 0.4227842, 0.23069756, 0.0348859, 0.00262698, 1075e-7, 74e-7].reverse();
        var n = [1.25331414, -0.07832358, 0.02189568, -0.01062446, 0.00587872, -0.0025154, 53208e-8].reverse();
        function u(i) {
          if (i <= 2) return -e.log(i / 2) * r.BESSELI(i, 0) + a(t, (i * i) / 4);
          return (e.exp(-i) / e.sqrt(i)) * a(n, 2 / i);
        }
        var v = [1, 0.15443144, -0.67278579, -0.18156897, -0.01919402, -0.00110404, -4686e-8].reverse();
        var c = [1.25331414, 0.23498619, -0.0365562, 0.01504268, -0.00780353, 0.00325614, -68245e-8].reverse();
        function f(t) {
          if (t <= 2) return e.log(t / 2) * r.BESSELI(t, 1) + (1 / t) * a(v, (t * t) / 4);
          return (e.exp(-t) / e.sqrt(t)) * a(c, 2 / t);
        }
        return i(u, f, 'BESSELK', 2, 1);
      })();
    })((p = r.BesselFam || (r.BesselFam = {})));
    r.BESSELJ = p.J;
    r.BESSELK = p.K;
    r.BESSELI = p.I;
    r.BESSELY = p.Y;
  })((r = t.Spec || (t.Spec = {})));
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (t) {
    t.MAC = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
      69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
      102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 196,
      197, 199, 201, 209, 214, 220, 225, 224, 226, 228, 227, 229, 231, 233, 232, 234, 235, 237, 236, 238, 239, 241, 243, 242, 244, 246, 245,
      250, 249, 251, 252, 8224, 176, 162, 163, 167, 8226, 182, 223, 174, 169, 8482, 180, 168, 8800, 198, 216, 8734, 177, 8804, 8805, 165,
      181, 8706, 8721, 8719, 960, 8747, 170, 186, 937, 230, 248, 191, 161, 172, 8730, 402, 8776, 8710, 171, 187, 8230, 160, 192, 195, 213,
      338, 339, 8211, 8212, 8220, 8221, 8216, 8217, 247, 9674, 255, 376, 8260, 8364, 8249, 8250, 64257, 64258, 8225, 183, 8218, 8222, 8240,
      194, 202, 193, 203, 200, 205, 206, 207, 204, 211, 212, 63743, 210, 218, 219, 217, 305, 710, 732, 175, 728, 729, 730, 184, 733, 731,
      711,
    ];
    t.WIN = [
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35,
      36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68,
      69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101,
      102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127,
      8364, 129, 8218, 402, 8222, 8230, 8224, 8225, 710, 8240, 352, 8249, 338, 141, 381, 143, 144, 8216, 8217, 8220, 8221, 8226, 8211, 8212,
      732, 8482, 353, 8250, 339, 157, 382, 376, 160, 161, 162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177,
      178, 179, 180, 181, 182, 183, 184, 185, 186, 187, 188, 189, 190, 191, 192, 193, 194, 195, 196, 197, 198, 199, 200, 201, 202, 203, 204,
      205, 206, 207, 208, 209, 210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 221, 222, 223, 224, 225, 226, 227, 228, 229, 230, 231,
      232, 233, 234, 235, 236, 237, 238, 239, 240, 241, 242, 243, 244, 245, 246, 247, 248, 249, 250, 251, 252, 253, 254, 255,
    ];
  })((r = t.CodeTables || (t.CodeTables = {})));
  var e;
  (function (t) {
    t.NowCode = -1;
    var e;
    (function (t) {
      t.directory = '/Users/Sh33tJ5/';
      t.numfile = 0;
      t.release = '16.8';
    })((e = t.Info || (t.Info = {})));
    t.RandFunc = function (t, r) {
      return Math.random();
    };
    t.CodeTable = r.MAC;
  })((e = t.State || (t.State = {})));
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (r) {
    function e(t, r) {
      return new RegExp(
        t.replace(/./g, function (t) {
          return /[.\()]/g.test(t) ? '\\' + t : t;
        }),
        r ? 'g' : '',
      );
    }
    function a(t, r, e) {
      if (e) {
        if (typeof r === 'string' && typeof t !== 'string' && r.length > 0 && ['>', '<', '='].indexOf(r[0]) === -1) t = String(t);
        if (typeof r !== 'string' && typeof t === 'string') r = String(r);
      }
      switch (typeof r) {
        case 'number':
        case 'boolean':
          return t === r;
        case 'string':
          if (typeof t === 'string') {
            t = t.toLowerCase();
            r = r.toLowerCase();
            if (r.slice(0, 2) === '>=') return t >= r.slice(2);
            if (r.slice(0, 2) === '<=') return t <= r.slice(2);
            if (r.slice(0, 2) === '<>') return t !== r.slice(2);
            if (r.slice(0, 1) === '>') return t > r.slice(1);
            if (r.slice(0, 1) === '<') return t < r.slice(1);
            if (r.slice(0, 1) === '=') return t === r.slice(1);
            if (!r.match(/[\?\*~]/)) return t.toLowerCase() === r.toLowerCase();
            var a = new RegExp(
              '^' +
                r.replace(/\./g, '\\.').replace(/[~]?[\?\*~]/g, function (t) {
                  return t.length === 2 ? t[1] : { '?': '.', '*': '.*' }[t] || t;
                }) +
                '$',
              'i',
            );
            return a.test(t);
          }
          if (r.slice(0, 2) === '>=' && isFinite(parseFloat(r.slice(2)))) return t >= parseFloat(r.slice(2));
          if (r.slice(0, 2) === '<=' && isFinite(parseFloat(r.slice(2)))) return t <= parseFloat(r.slice(2));
          if (r.slice(0, 2) === '<>' && isFinite(parseFloat(r.slice(2)))) return t !== parseFloat(r.slice(2));
          if (r.slice(0, 1) === '>' && isFinite(parseFloat(r.slice(1)))) return t > parseFloat(r.slice(1));
          if (r.slice(0, 1) === '<' && isFinite(parseFloat(r.slice(1)))) return t < parseFloat(r.slice(1));
          if (r.slice(0, 1) === '=' && isFinite(parseFloat(r.slice(1)))) return t === parseFloat(r.slice(1));
          break;
      }
      return false;
    }
    function n(t) {
      return (Math.exp(t) + Math.exp(-t)) / 2;
    }
    function i(t) {
      return (Math.exp(t) - Math.exp(-t)) / 2;
    }
    function u(t) {
      return (Math.exp(t) - Math.exp(-t)) / (Math.exp(t) + Math.exp(-t));
    }
    function v(t) {
      return (Math.exp(t) + Math.exp(-t)) / (Math.exp(t) - Math.exp(-t));
    }
    function c(t) {
      return 2 / (Math.exp(t) - Math.exp(-t));
    }
    function f(t) {
      return 2 / (Math.exp(t) + Math.exp(-t));
    }
    function _(t) {
      return Math.log(t + Math.sqrt(t * t + 1));
    }
    function s(t) {
      return Math.log(t + Math.sqrt(t * t - 1));
    }
    function o(t) {
      return Math.log((1 + t) / (1 - t)) / 2;
    }
    function l(t) {
      return Math.log((t + 1) / (t - 1)) / 2;
    }
    function U(t) {
      return Math.log((1 + Math.sqrt(1 - t * t)) / t);
    }
    function h(t) {
      return Math.log((1 + Math.sqrt(1 + t * t)) / t);
    }
    function b(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBEKPIMEMBER = b;
    function m(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBEMEMBER = m;
    function E(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBEMEMBERPROPERTY = E;
    function p(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBERANKEDMEMBER = p;
    function A(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBESET = A;
    function x(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBESETCOUNT = x;
    function S(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CUBEVALUE = S;
    function d(r, e) {
      var a = [];
      var n = e.v[0].map(function (r) {
        var e = t.Util._cast_str(r);
        return e.t === 's' ? e.v : '';
      });
      e.v.slice(1).forEach(function (r) {
        var e = [];
        r.forEach(function (r, a) {
          if (r.t === 'z') return;
          e.push([n[a], t.Util._cast_val(r).v]);
        });
        if (e.length > 0) a.push(e);
      });
      var i = [];
      var u = r.v[0].map(function (r) {
        var e = t.Util._cast_str(r);
        return e.t === 's' ? e.v : '';
      });
      r.v.slice(1).forEach(function (r) {
        var e = {};
        var n = 0;
        r.forEach(function (r, a) {
          if (r.t === 'z' || r.t === 'e') return;
          e[u[a]] = t.Util._cast_val(r).v;
          ++n;
        });
        if (n === 0) return;
        t: for (var v = 0; v < a.length; ++v) {
          var c = a[v];
          for (var f = 0; f < c.length; ++f) {
            var _ = c[f];
            var s = e[_[0]];
            if (s === _[1]) continue;
            if (typeof _[1] === 'string' && s === _[1].slice(1)) continue;
            if (typeof _[1] === 'string' && typeof s !== 'string') {
              if (_[1].slice(0, 2) === '>=' && s >= parseFloat(_[1].slice(2))) continue;
              if (_[1].slice(0, 2) === '<=' && s <= parseFloat(_[1].slice(2))) continue;
              if (_[1].slice(0, 1) === '>' && s > parseFloat(_[1].slice(1))) continue;
              if (_[1].slice(0, 1) === '<' && s < parseFloat(_[1].slice(1))) continue;
            }
            continue t;
          }
          i.push(e);
          return;
        }
      });
      return i;
    }
    function M(r, e, a) {
      var n = d(r, e);
      var i = a.t === 'n' ? r.v[0][t.Util._cast_num(a).v - 1] : t.Util._cast_str(a);
      var u = i.v;
      return n
        .map(function (t) {
          return t[u];
        })
        .filter(function (t) {
          return typeof t === 'number';
        });
    }
    function N(r, e, a) {
      var n = d(r, e);
      var i = a.t === 'n' ? r.v[0][t.Util._cast_num(a).v - 1] : t.Util._cast_str(a);
      var u = i.v;
      return n
        .map(function (t) {
          return t[u];
        })
        .filter(function (t) {
          return t != null;
        });
    }
    function g(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t + r;
        }, 0) / Math.max(e.length, 1),
      );
    }
    r.DAVERAGE = g;
    function L(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(e.length);
    }
    r.DCOUNT = L;
    function I(r) {
      var e = N(r[0], r[2], r[1]);
      return t.Util._box_num(e.length);
    }
    r.DCOUNTA = I;
    function k(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t > r ? t : r;
        }, -Infinity),
      );
    }
    r.DMAX = k;
    function T(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t < r ? t : r;
        }, Infinity),
      );
    }
    r.DMIN = T;
    function w(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t * r;
        }, 1),
      );
    }
    r.DPRODUCT = w;
    function y(r) {
      var e = M(r[0], r[2], r[1]);
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t + r;
        }, 0),
      );
    }
    r.DSUM = y;
    function O(r) {
      var e = M(r[0], r[2], r[1]);
      if (e.length < 2) return t.Util._box_num(0);
      var a =
        e.reduce(function (t, r) {
          return t + r;
        }, 0) / e.length;
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t + (r - a) * (r - a);
        }, 0) /
          (e.length - 1),
      );
    }
    r.DVAR = O;
    function D(r) {
      var e = M(r[0], r[2], r[1]);
      if (e.length < 2) return t.Util._box_num(0);
      var a =
        e.reduce(function (t, r) {
          return t + r;
        }, 0) / e.length;
      return t.Util._box_num(
        e.reduce(function (t, r) {
          return t + (r - a) * (r - a);
        }, 0) / e.length,
      );
    }
    r.DVARP = D;
    function C(r) {
      var e = M(r[0], r[2], r[1]);
      if (e.length < 2) return t.Util._box_num(0);
      var a =
        e.reduce(function (t, r) {
          return t + r;
        }, 0) / e.length;
      return t.Util._box_num(
        Math.sqrt(
          e.reduce(function (t, r) {
            return t + (r - a) * (r - a);
          }, 0) /
            (e.length - 1),
        ),
      );
    }
    r.DSTDEV = C;
    function R(r) {
      var e = M(r[0], r[2], r[1]);
      if (e.length < 2) return t.Util._box_num(0);
      var a =
        e.reduce(function (t, r) {
          return t + r;
        }, 0) / e.length;
      return t.Util._box_num(
        Math.sqrt(
          e.reduce(function (t, r) {
            return t + (r - a) * (r - a);
          }, 0) / e.length,
        ),
      );
    }
    r.DSTDEVP = R;
    function V(r) {
      var e = M(r[0], r[2], r[1]);
      if (e.length === 0) return { t: 'e', v: 15 };
      else if (e.length > 1) return { t: 'e', v: 36 };
      else return t.Util._box_num(e[0]);
    }
    r.DGET = V;
    function F(r) {
      return t.Util._box_num(t.State.NowCode >= 0 ? t.State.NowCode : t.Util.date_to_code(new Date(), 2));
    }
    r.NOW = F;
    function P(r) {
      return t.Util._box_num(t.State.NowCode >= 0 ? t.State.NowCode | 0 : t.Util.date_to_code(new Date()) | 0);
    }
    r.TODAY = P;
    function X(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = e.v;
      if (i < 1900) i += 1900;
      var u = a.v;
      var v = n.v;
      var c = new Date(i, u - 1, v, 0, 0, 0, 0);
      var f = t.Util.date_to_code(c) | 0;
      return f >= 0 ? t.Util._box_num(f) : t.Util._box_err('#NUM!');
    }
    r.DATE = X;
    function B(r) {
      var e = r[0];
      if (e.t === 'n') return { t: 'e', v: 15 };
      return t.Util._cast_date_code(e);
    }
    r.DATEVALUE = B;
    function G(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t !== 'n' || a.t !== 'n' || n.t !== 'n') return { t: 'e', v: 15 };
      return t.Util._box_num((n.v + 60 * a.v + 3600 * e.v) / 86400);
    }
    r.TIME = G;
    function H(r) {
      var e = r[0];
      if (e.t === 'n') return { t: 'e', v: 15 };
      return t.Util._cast_date_code(e, 2);
    }
    r.TIMEVALUE = H;
    function z(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_date_code(r[1]);
      if (a.t !== 'n' || a.t !== 'n') return { t: 'e', v: 15 };
      return t.Util._box_num(Math.abs((e.v | 0) - (a.v | 0)));
    }
    r.DAYS = z;
    function q(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t !== 'n' || a.t !== 'n') return { t: 'e', v: 15 };
      var n = t.Util.parse_date_code(e.v);
      var i = a.v | 0;
      var u = new Date(n.y, n.m - 1 + i, n.d, n.H, n.M, n.S);
      return t.Util._box_num(t.Util.date_to_code(u) | 0);
    }
    r.EDATE = q;
    function $(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t !== 'n' || a.t !== 'n') return { t: 'e', v: 15 };
      var n = t.Util.parse_date_code(e.v);
      var i = a.v | 0;
      var u = new Date(n.y, n.m + i, 0, n.H, n.M, n.S);
      return t.Util._box_num(t.Util.date_to_code(u) | 0);
    }
    r.EOMONTH = $;
    function W(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_date_code(r[1]);
      var n = t.Util._cast_str(r[2]);
      if (e.t !== 'n' || e.t !== 'n' || n.t !== 's') return { t: 'e', v: 15 };
      var i = (a.v | 0) - (e.v | 0);
      if (i < 0) return t.Util._box_err('#NULL!');
      if (n.v === 'D') return t.Util._box_num(i);
      var u = t.Util.parse_date_code(e.v);
      var v = t.Util.parse_date_code(a.v);
      switch (n.v) {
        case 'Y':
          i = v.y - u.y;
          if (v.m < u.m || (v.m === u.m && v.d < u.d)) --i;
          break;
        case 'M':
          i = v.y * 12 + v.m - (u.y * 12 + u.m);
          if (v.d < u.d) --i;
          break;
        case 'MD':
          i = v.d - u.d;
          break;
        case 'YM':
          i = v.m - u.m;
          if (v.d < u.d) --i;
          i = i < 0 ? i + 12 : i;
          break;
        case 'YD':
          return t.Util._box_err('#VALUE!');
        default:
          return { t: 'e', v: 15 };
      }
      return t.Util._box_num(i);
    }
    r.DATEDIF = W;
    function Y(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_date_code(r[1]);
      if (e.t !== 'n' || e.t !== 'n') return { t: 'e', v: 15 };
      var n = false;
      if (r.length >= 3) {
        var i = t.Util._cast_bool(r[2]);
        if (i.t === 'e') return { t: 'e', v: 15 };
        n = i.v;
      }
      var u = t.Util.parse_date_code(e.v);
      var v = t.Util.parse_date_code(a.v);
      var c = 360 * (v.y - u.y) + 30 * (v.m - u.m);
      var f = u.d;
      var _ = v.d;
      if (n) {
        if (f === 31) f = 30;
        if (_ === 31) _ = 30;
      } else {
        if (f === 31) {
          f = 30;
          if (_ === 31) _ = 30;
        } else if (_ === 31) {
          _ = 1;
          c += 30;
        }
      }
      c += _ - f;
      return t.Util._box_num(c);
    }
    r.DAYS360 = Y;
    function K(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = t.Util._cast_date_code(r[1]);
      if (e.t !== 'n' || e.t !== 'n') return { t: 'e', v: 15 };
      var n = 0;
      if (r.length >= 3) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return { t: 'e', v: 15 };
        if (i.v < 0 || i.v > 4) return { t: 'e', v: 36 };
        n = i.v;
      }
      var u = t.Util.parse_date_code(e.v);
      var v = t.Util.parse_date_code(a.v);
      var c = 360 * (v.y - u.y) + 30 * (v.m - u.m);
      var f = u.d;
      var _ = v.d;
      switch (n) {
        case 1:
          if (v.y === u.y) c = ((a.v | 0) - (e.v | 0)) / (t.Util.is_leap_year(v.y) ? 366 : 365);
          else {
            c = v.y - u.y;
            c += (a.v - t.Util.date_to_code(new Date(v.y, 0, 0, 0, 0, 0, 0))) / (t.Util.is_leap_year(v.y) ? 366 : 365);
            c -= (e.v - t.Util.date_to_code(new Date(u.y, 0, 0, 0, 0, 0, 0))) / (t.Util.is_leap_year(u.y) ? 366 : 365);
          }
          break;
        case 2:
          c = ((a.v | 0) - (e.v | 0)) / 360;
          break;
        case 3:
          c = ((a.v | 0) - (e.v | 0)) / 365;
          break;
        case 4:
          if (f === 31) f = 30;
          if (_ === 31) _ = 30;
          c += _ - f;
          c /= 360;
          break;
        case 0:
          if (f === 31) {
            f = 30;
            if (_ === 31) _ = 30;
          } else if (_ === 31) {
            _ = 1;
            c += 30;
          }
          c += _ - f;
          c /= 360;
          break;
        default:
          return { t: 'e', v: 15 };
      }
      return t.Util._box_num(c);
    }
    r.YEARFRAC = K;
    function Z(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      var n = t.Util._cast_date_code(r[1]);
      if (n.t !== 'n') return { t: 'e', v: 15 };
      var i = t.Util.parse_date_code(n.v);
      var u = [];
      if (r.length > 2) {
        var v = t.Util._cast_numdate_arr(r[2]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        u = v.map(function (r) {
          return t.Util.parse_date_code(r);
        });
        for (var c = 0; c < u.length; ++c) if (isNaN(u[c].T)) return t.Util._box_err('#VALUE!');
      }
      return t.Util._box_num(t.Spec.NETWORKDAYS_INTL(a, i, 1, u));
    }
    r.NETWORKDAYS = Z;
    function Q(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      var n = t.Util._cast_date_code(r[1]);
      if (n.t !== 'n') return { t: 'e', v: 15 };
      var i = t.Util.parse_date_code(n.v);
      var u = t.Util._cast_num(r[2]);
      if (u.t !== 'n') return { t: 'e', v: 15 };
      var v = u.v;
      var c = [];
      if (r.length > 3) {
        var f = t.Util._cast_num_arr(r[3]);
        if (f.t === 'e') return t.Util._box_err('#VALUE!');
        c = f.map(function (r) {
          return t.Util.parse_date_code(r);
        });
      }
      return t.Util._box_num(t.Spec.NETWORKDAYS_INTL(a, i, v, c));
    }
    r.NETWORKDAYS_DOT_INTL = Q;
    function J(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      var n = t.Util._cast_num(r[1]);
      if (n.t !== 'n') return { t: 'e', v: 15 };
      var i = n.v;
      var u = [];
      if (r.length > 2) {
        var v = t.Util._cast_num_arr(r[2]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        u = v.map(function (r) {
          return t.Util.parse_date_code(r);
        });
      }
      return t.Util._box_num(t.Spec.WORKDAY_INTL(a, i, 1, u));
    }
    r.WORKDAY = J;
    function j(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      var n = t.Util._cast_num(r[1]);
      if (n.t !== 'n') return { t: 'e', v: 15 };
      var i = n.v;
      var u = t.Util._cast_num(r[2]);
      if (u.t !== 'n') return { t: 'e', v: 15 };
      var v = u.v;
      var c = [];
      if (r.length > 3) {
        var f = t.Util._cast_num_arr(r[3]);
        if (f.t === 'e') return t.Util._box_err('#VALUE!');
        c = f.map(function (r) {
          return t.Util.parse_date_code(r);
        });
      }
      return t.Util._box_num(t.Spec.WORKDAY_INTL(a, i, v, c));
    }
    r.WORKDAY_DOT_INTL = j;
    function tt(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      var n = 1;
      if (r.length > 1) {
        var i = t.Util._cast_num(r[1]);
        if (i.t === 'e') return { t: 'e', v: 15 };
        n = i.v;
      }
      if (n === 21) return t.Util._box_num(t.Util.iso_week_date(a));
      var u = -1;
      switch (n) {
        case 1:
        case 17:
          u = 0;
          break;
        case 2:
        case 11:
          u = 1;
          break;
        case 12:
        case 13:
        case 14:
        case 15:
        case 16:
          u = n - 10;
          break;
        default:
          return { t: 'e', v: 36 };
      }
      var v = new Date(a.y, 0, 1, 0, 0, 0, 0);
      var c = v.getDay() < u ? 1 : 0;
      var f = v.getTime() / 1e3;
      f -= Math.abs(v.getDay() - u) * 24 * 60 * 60;
      var _ = new Date(a.y, a.m - 1, a.d, 0, 0, 0, 0);
      return t.Util._box_num(Math.floor(((_.getTime() / 1e3 - f) / (60 * 60 * 24) + 1 / 24) / 7 + 1) + c);
    }
    r.WEEKNUM = tt;
    function rt(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(t.Util.iso_week_date(a));
    }
    r.ISOWEEKNUM = rt;
    function et(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.y);
    }
    r.YEAR = et;
    function at(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.m);
    }
    r.MONTH = at;
    function nt(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.d);
    }
    r.DAY = nt;
    function it(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.H);
    }
    r.HOUR = it;
    function ut(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.M);
    }
    r.MINUTE = ut;
    function vt(r) {
      var e = t.Util._cast_date_code(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util.parse_date_code(e.v);
      return t.Util._box_num(a.S);
    }
    r.SECOND = vt;
    function ct(r) {
      var e = t.Util._cast_date_code(r[0]);
      var a = 1;
      if (r[1]) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return { t: 'e', v: 36 };
        a = n.v;
      }
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var i = t.Util.parse_date_code(e.v);
      var u = -1;
      switch (a) {
        case 1:
        case 17:
          u = i.q + 1;
          break;
        case 2:
        case 11:
          u = ((i.q + 6) % 7) + 1;
          break;
        case 3:
          u = (i.q + 6) % 7;
          break;
        case 12:
          u = ((i.q + 5) % 7) + 1;
          break;
        case 13:
          u = ((i.q + 4) % 7) + 1;
          break;
        case 14:
          u = ((i.q + 3) % 7) + 1;
          break;
        case 15:
          u = ((i.q + 2) % 7) + 1;
          break;
        case 16:
          u = ((i.q + 1) % 7) + 1;
          break;
      }
      return u > -1 ? { t: 'n', v: u } : { t: 'e', v: 36 };
    }
    r.WEEKDAY = ct;
    function ft(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (a.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.BESSELJ(e.v, a.v));
    }
    r.BESSELJ = ft;
    function _t(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (a.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.BESSELK(e.v, a.v));
    }
    r.BESSELK = _t;
    function st(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (a.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.BESSELI(e.v, a.v));
    }
    r.BESSELI = st;
    function ot(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (a.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.BESSELY(e.v, a.v));
    }
    r.BESSELY = ot;
    function lt(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v % 1 > 1e-9 || a.v % 1 > 1e-9 || e.v < 0 || a.v < 0 || e.v >= Math.pow(2, 48) || a.v >= Math.pow(2, 48))
        return { t: 'e', v: 36 };
      if (typeof BigInt === 'function') return t.Util._box_num(Number(BigInt(e.v) & BigInt(a.v) & BigInt(0xffffffffffff)));
      var n = (e.v / 16777216) & (a.v / 16777216) & 16777215;
      var i = e.v & a.v & 16777215;
      return t.Util._box_num(n * 16777216 + i);
    }
    r.BITAND = lt;
    function Ut(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v % 1 > 1e-9 || a.v % 1 > 1e-9 || e.v < 0 || a.v < 0 || e.v >= Math.pow(2, 48) || a.v >= Math.pow(2, 48))
        return { t: 'e', v: 36 };
      if (typeof BigInt === 'function') return t.Util._box_num(Number((BigInt(e.v) | BigInt(a.v)) & BigInt(0xffffffffffff)));
      var n = ((e.v / 16777216) | (a.v / 16777216)) & 16777215;
      var i = (e.v | a.v) & 16777215;
      return t.Util._box_num(n * 16777216 + i);
    }
    r.BITOR = Ut;
    function ht(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v % 1 > 1e-9 || a.v % 1 > 1e-9 || e.v < 0 || a.v < 0 || e.v >= Math.pow(2, 48) || a.v >= Math.pow(2, 48))
        return { t: 'e', v: 36 };
      if (typeof BigInt === 'function') return t.Util._box_num(Number((BigInt(e.v) ^ BigInt(a.v)) & BigInt(0xffffffffffff)));
      var n = ((e.v / 16777216) ^ (a.v / 16777216)) & 16777215;
      var i = (e.v ^ a.v) & 16777215;
      return t.Util._box_num(n * 16777216 + i);
    }
    r.BITXOR = ht;
    function bt(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v % 1 > 1e-9 || (a.v | 0) !== a.v || e.v < 0 || a.v > 53 || a.v < -53 || e.v >= Math.pow(2, 48)) return { t: 'e', v: 36 };
      var n = a.v < 0 ? -1 : 1;
      var i = a.v < 0 ? -a.v : a.v;
      if (typeof BigInt === 'function') {
        var u = n > 0 ? BigInt(e.v) << BigInt(i) : BigInt(e.v) >> BigInt(i);
        if (u > BigInt(0xffffffffffff)) return { t: 'e', v: 36 };
        return t.Util._box_num(Number(u & BigInt(0xffffffffffff)));
      }
      return t.Util._box_num(a.v >= 0 ? e.v << i : e.v >> i);
    }
    r.BITLSHIFT = bt;
    function mt(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v % 1 > 1e-9 || (a.v | 0) !== a.v || e.v < 0 || a.v > 53 || a.v < -53 || e.v >= Math.pow(2, 48)) return { t: 'e', v: 36 };
      var n = a.v < 0 ? 1 : -1;
      var i = a.v < 0 ? -a.v : a.v;
      if (typeof BigInt === 'function') {
        var u = n > 0 ? BigInt(e.v) << BigInt(i) : BigInt(e.v) >> BigInt(i);
        if (u > BigInt(0xffffffffffff)) return { t: 'e', v: 36 };
        return t.Util._box_num(Number(u & BigInt(0xffffffffffff)));
      }
      return t.Util._box_num(n < 0 ? e.v >> i : e.v << i);
    }
    r.BITRSHIFT = mt;
    function Et(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10) return { t: 'e', v: 36 };
      if (a.match(/[^01 ]/)) return { t: 'e', v: 36 };
      var n = parseInt(a, 2);
      if (n >= 0 && n < 512) return t.Util._box_num(n);
      if (n >= 512 && n < 1024) return t.Util._box_num(n - 1024);
      return t.Util._box_err('#NUM!');
    }
    r.BIN2DEC = Et;
    function pt(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10) return { t: 'e', v: 36 };
      if (a.match(/[^01 ]/)) return { t: 'e', v: 36 };
      var n = parseInt(a, 2);
      var i = '';
      if (n >= 0 && n < 512) i = parseInt(a, 2).toString(16).toUpperCase();
      else if (n >= 512 && n < 1024) i = ('FFFFFFFFFF' + (4096 + (n - 1024)).toString(16).slice(-3)).slice(-10);
      else t.Util._box_err('#NUM!');
      var u = '';
      if (r.length > 1) {
        var v = t.Util._cast_num(r[1]);
        if (v.t === 'e') return { t: 'e', v: 15 };
        var c = v.v - i.length;
        while (c-- > 0) u += '0';
      }
      return { t: 's', v: u + i.toUpperCase() };
    }
    r.BIN2HEX = pt;
    function At(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10 || /[^01]/.test(a)) return { t: 'e', v: 36 };
      var n = parseInt(a, 2);
      if (n >= 512) n += 1073740800;
      var i = n.toString(8);
      var u = '';
      if (r.length > 1 && n < 512) {
        var v = t.Util._cast_num(r[1]);
        if (v.t === 'e') return { t: 'e', v: 15 };
        var c = v.v - i.length;
        if (c < 0) return { t: 'e', v: 36 };
        while (c-- > 0) u += '0';
      }
      return { t: 's', v: u + i };
    }
    r.BIN2OCT = At;
    function xt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 10).toString(2) };
    }
    r.DEC2BIN = xt;
    function St(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 10).toString(16).toUpperCase() };
    }
    r.DEC2HEX = St;
    function dt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 10).toString(8) };
    }
    r.DEC2OCT = dt;
    function Mt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 16).toString(2) };
    }
    r.HEX2BIN = Mt;
    function Nt(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10) return { t: 'e', v: 36 };
      return t.Util._box_num(parseInt(a, 16));
    }
    r.HEX2DEC = Nt;
    function gt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 16).toString(8) };
    }
    r.HEX2OCT = gt;
    function Lt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 8).toString(2) };
    }
    r.OCT2BIN = Lt;
    function It(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10) return { t: 'e', v: 36 };
      return t.Util._box_num(parseInt(a, 8));
    }
    r.OCT2DEC = It;
    function kt(t) {
      var r = t[0];
      var e = '';
      switch (r.t) {
        case 's':
          e = r.v;
          break;
        case 'n':
          e = r.v.toString(10);
          break;
        case 'e':
          return r;
        default:
          return { t: 'e', v: 36 };
      }
      if (e.length > 10) return { t: 'e', v: 36 };
      return { t: 's', v: parseInt(e, 8).toString(16) };
    }
    r.OCT2HEX = kt;
    function Tt(t, r) {
      var e = t.match(/^([-]?(?:(?:\d+(?:\.\d*)?)|(?:\.\d+))(?:E[+-]?\d+)?)$/);
      if (e) return [parseFloat(t), 0, 'i'];
      if ((e = t.match(/^([+-]?(?:(?:\d+(?:\.\d*)?)|(?:\.\d+))(?:E[+-]?\d+)?)([ij])$/))) return [0, parseFloat(e[1]), e[2]];
      if (
        (e = t.match(
          /^([+-]?(?:(?:\d+(?:\.\d*)?)|(?:\.\d+))(?:E[+-]?\d+)?)([+-](?:(?:(?:\d+(?:\.\d*)?)|(?:\.\d+))(?:E[+-]?\d+)?)?)([ij])$/,
        ))
      )
        return [parseFloat(e[1]), parseFloat(e[2]) || (e[2].match(/-/) ? -1 : 1), e[3]];
      if (!t) return [0, 0, 'i'];
      if (r) return [NaN, NaN, 'i'];
      throw '|'.concat(t, '| is not a complex number');
    }
    function wt(t) {
      return [Math.sqrt(t[0] * t[0] + t[1] * t[1]), Math.atan2(t[1], t[0]), t[2]];
    }
    function yt(t) {
      return [t[0] * Math.cos(t[1]), t[0] * Math.sin(t[1]), t[2]];
    }
    function Ot(t) {
      var r = t[0] * t[0] + t[1] * t[1];
      return [t[0] / r, -t[1] / r, t[2]];
    }
    function Dt(t, r, e) {
      var a = Math.abs(r) === 1 ? '' : Math.abs(r);
      if (t === 0 && r === 0) return '0';
      else if (t !== 0 && r === 0) return '' + t;
      else if (t !== 0) return t + (r >= 0 ? '+' : '-') + a + e;
      else return (r >= 0 ? '' : '-') + a + e;
    }
    function Ct(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = 'i';
      if (r[2]) {
        var i = t.Util._cast_str(r[2]);
        if (i.t !== 's') return { t: 'e', v: 15 };
        n = i.v;
      }
      if (n !== 'i' && n !== 'j') return { t: 'e', v: 15 };
      return { t: 's', v: Dt(e.v, a.v, n) };
    }
    r.COMPLEX = Ct;
    function Rt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return t.Util._box_num(a[1]);
    }
    r.IMAGINARY = Rt;
    function Vt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return t.Util._box_num(a[0]);
    }
    r.IMREAL = Vt;
    function Ft(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return t.Util._box_num(Math.sqrt(a[0] * a[0] + a[1] * a[1]));
    }
    r.IMABS = Ft;
    function Pt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return t.Util._box_num(Math.atan2(a[1], a[0]));
    }
    r.IMARGUMENT = Pt;
    function Xt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(a[0], -a[1], a[2]) };
    }
    r.IMCONJUGATE = Xt;
    function Bt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var n = Math.exp(a[0]);
      return { t: 's', v: Dt(n * Math.cos(a[1]), n * Math.sin(a[1]), a[2]) };
    }
    r.IMEXP = Bt;
    function Gt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      var n = t.Util._cast_str(r[1]);
      if (n.t === 'e') return { t: 'e', v: 15 };
      var i = [0, 0, 'i'];
      try {
        a = Tt(e.v);
        i = Tt(n.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var u = i[0] * i[0] + i[1] * i[1];
      var v = (a[0] * i[0] + a[1] * i[1]) / u;
      var c = (a[1] * i[0] - a[0] * i[1]) / u;
      return { t: 's', v: Dt(v, c, a[2]) };
    }
    r.IMDIV = Gt;
    function Ht(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      var n = t.Util._cast_str(r[1]);
      if (n.t === 'e') return { t: 'e', v: 15 };
      var i = [0, 0, 'i'];
      try {
        a = Tt(e.v);
        i = Tt(n.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(a[0] - i[0], a[1] - i[1], a[2]) };
    }
    r.IMSUB = Ht;
    function zt(t) {
      var r = [0, 0, 'j'];
      var e = function (e) {
        var a = t[e];
        switch (a.t) {
          case 'A':
            a.v.forEach(function (t) {
              t.forEach(function (t) {
                switch (t.t) {
                  case 'z':
                    break;
                  case 'n':
                    r[0] += t.v;
                    break;
                  case 's':
                    if (!t.v) break;
                    var e = Tt(t.v);
                    r[0] += e[0];
                    r[1] += e[1];
                    if (e[2] === 'i') r[2] = 'i';
                    break;
                  default:
                    throw 'Unsupported arg type '.concat(a.t);
                }
              });
            });
            break;
          case 'z':
            break;
          case 'n':
            r[0] += a.v;
            break;
          case 's':
            if (!a.v) break;
            var n = Tt(a.v);
            r[0] += n[0];
            r[1] += n[1];
            if (n[2] === 'i') r[2] = 'i';
            break;
          default:
            throw 'Unsupported arg type '.concat(a.t);
        }
      };
      for (var a = 0; a < t.length; ++a) {
        e(a);
      }
      return { t: 's', v: Dt(r[0], r[1], r[2]) };
    }
    r.IMSUM = zt;
    function qt(t) {
      var r = [1, 0, 'j'];
      var e = -1;
      var a = function (a) {
        var n = t[a];
        switch (n.t) {
          case 'A':
            n.v.forEach(function (t) {
              t.forEach(function (t) {
                switch (t.t) {
                  case 'z':
                    r[0] = r[1] = 0;
                    break;
                  case 'n':
                    r[0] *= t.v;
                    r[1] *= t.v;
                    break;
                  case 's':
                    if (!t.v) break;
                    var a = Tt(t.v, true);
                    if (isNaN(a[0]) || isNaN(a[1])) e = 36;
                    r = [r[0] * a[0] - r[1] * a[1], r[0] * a[1] + r[1] * a[0], r[2]];
                    if (a[2] === 'i') r[2] = 'i';
                    break;
                  default:
                    throw 'Unsupported arg type '.concat(n.t);
                }
              });
            });
            break;
          case 'z':
            r[0] = r[1] = 0;
            break;
          case 'n':
            r[0] *= n.v;
            r[1] *= n.v;
            break;
          case 's':
            if (!n.v) break;
            var i = Tt(n.v, true);
            if (isNaN(i[0]) || isNaN(i[1])) e = 36;
            r = [r[0] * i[0] - r[1] * i[1], r[0] * i[1] + r[1] * i[0], r[2]];
            if (i[2] === 'i') r[2] = 'i';
            break;
          default:
            throw 'Unsupported arg type '.concat(n.t);
        }
      };
      for (var n = 0; n < t.length; ++n) {
        a(n);
      }
      if (e !== -1) {
        return { t: 'e', v: e };
      } else {
        return { t: 's', v: Dt(r[0], r[1], r[2]) };
      }
    }
    r.IMPRODUCT = qt;
    function $t(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = wt(Tt(e.v));
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(Math.log(a[0]), a[1], a[2]) };
    }
    r.IMLN = $t;
    function Wt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = wt(Tt(e.v));
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(Math.LOG10E * Math.log(a[0]), Math.LOG10E * a[1], a[2]) };
    }
    r.IMLOG10 = Wt;
    function Yt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = wt(Tt(e.v));
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(Math.LOG2E * Math.log(a[0]), Math.LOG2E * a[1], a[2]) };
    }
    r.IMLOG2 = Yt;
    function Kt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = wt(Tt(e.v));
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var n = yt([Math.sqrt(a[0]), a[1] / 2, a[2]]);
      return { t: 's', v: Dt(n[0], n[1], n[2]) };
    }
    r.IMSQRT = Kt;
    function Zt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return { t: 'e', v: 36 };
      var n = [0, 0, 'i'];
      try {
        n = wt(Tt(e.v));
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var i = yt([Math.pow(n[0], a.v), a.v * n[1], n[2]]);
      return { t: 's', v: Dt(i[0], i[1], i[2]) };
    }
    r.IMPOWER = Zt;
    function Qt(r) {
      if (r[0].t === 'b') return { t: 'e', v: 15 };
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(Math.cos(a[0]) * n(a[1]), -Math.sin(a[0]) * i(a[1]), a[2]) };
    }
    r.IMCOS = Qt;
    function Jt(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(Math.sin(a[0]) * n(a[1]), Math.cos(a[0]) * i(a[1]), a[2]) };
    }
    r.IMSIN = Jt;
    function jt(r) {
      if (r[0].t === 'b') return { t: 'e', v: 15 };
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var u = n(2 * a[1]) + Math.cos(2 * a[0]);
      return { t: 's', v: Dt(Math.sin(2 * a[0]) / u, i(2 * a[1]) / u, a[2]) };
    }
    r.IMTAN = jt;
    function tr(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = Qt(r);
      if (a.t === 'e') return a;
      var n = [0, 0, 'i'];
      try {
        n = Tt(a.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var i = Ot(n);
      return { t: 's', v: Dt(i[0], i[1], i[2]) };
    }
    r.IMSEC = tr;
    function rr(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = Jt(r);
      if (a.t === 'e') return a;
      var n = [0, 0, 'i'];
      try {
        n = Tt(a.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var i = Ot(n);
      return { t: 's', v: Dt(i[0], i[1], i[2]) };
    }
    r.IMCSC = rr;
    function er(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var u = n(2 * a[1]) + Math.cos(2 * a[0]);
      var v = Ot([Math.sin(2 * a[0]) / u, i(2 * a[1]) / u, a[2]]);
      return { t: 's', v: Dt(v[0], v[1], v[2]) };
    }
    r.IMCOT = er;
    function ar(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(n(a[0]) * Math.cos(a[1]), i(a[0]) * Math.sin(a[1]), a[2]) };
    }
    r.IMCOSH = ar;
    function nr(r) {
      if (r[0].t === 'b') return { t: 'e', v: 15 };
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      return { t: 's', v: Dt(i(a[0]) * Math.cos(a[1]), n(a[0]) * Math.sin(a[1]), a[2]) };
    }
    r.IMSINH = nr;
    function ir(r) {
      if (r[0].t === 'b') return { t: 'e', v: 15 };
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var u = n(2 * a[1]) + Math.cos(2 * a[0]);
      var v = Ot([n(a[0]) * Math.cos(a[1]), i(a[0]) * Math.sin(a[1]), a[2]]);
      return { t: 's', v: Dt(v[0], v[1], v[2]) };
    }
    r.IMSECH = ir;
    function ur(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = [0, 0, 'i'];
      try {
        a = Tt(e.v);
      } catch (t) {
        return { t: 'e', v: 36 };
      }
      var u = n(2 * a[1]) + Math.cos(2 * a[0]);
      var v = Ot([i(a[0]) * Math.cos(a[1]), n(a[0]) * Math.sin(a[1]), a[2]]);
      return { t: 's', v: Dt(v[0], v[1], v[2]) };
    }
    r.IMCSCH = ur;
    function vr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = 0;
      var n = e.v;
      if (r.length > 1) {
        var i = t.Util._cast_num(r[1]);
        if (i.t === 'e') return { t: 'e', v: 15 };
        a = n;
        n = i.v;
      }
      return t.Util._box_num(t.Spec.Statistical.ERF(n) - t.Spec.Statistical.ERF(a));
    }
    r.ERF = vr;
    function cr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(t.Spec.Statistical.ERFC(e.v));
    }
    r.ERFC = cr;
    r.ERF_DOT_PRECISE = vr;
    r.ERFC_DOT_PRECISE = cr;
    var fr = (function () {
      var t = [
        ['Yi', Math.pow(2, 80)],
        ['Zi', Math.pow(2, 70)],
        ['Ei', Math.pow(2, 60)],
        ['Pi', Math.pow(2, 50)],
        ['Ti', Math.pow(2, 40)],
        ['Gi', Math.pow(2, 30)],
        ['Mi', Math.pow(2, 20)],
        ['ki', Math.pow(2, 10)],
        ['Y', 1e24],
        ['Z', 1e21],
        ['E', 1e18],
        ['P', 1e15],
        ['T', 1e12],
        ['G', 1e9],
        ['M', 1e6],
        ['k', 1e3],
        ['y', 1e-24],
        ['z', 1e-21],
        ['a', 1e-18],
        ['f', 1e-15],
        ['p', 1e-12],
        ['n', 1e-9],
        ['u', 1e-6],
        ['m', 0.001],
        ['h', 100],
        ['da', 10],
        ['e', 10],
        ['d', 0.1],
        ['c', 0.01],
      ];
      var r = {
        area: [
          'ha',
          'uk_acre',
          'us_acre',
          'ang2',
          'ang^2',
          'ar',
          'ft2',
          'ft^2',
          'in2',
          'in^2',
          'ly2',
          'ly^2',
          'm2',
          'm^2',
          'Morgen',
          'mi2',
          'mi^2',
          'Nmi2',
          'Nmi^2',
          'Picapt2',
          'Picapt^2',
          'Pica2',
          'Pica^2',
          'yd2',
          'yd^2',
        ],
        distance: ['m', 'mi', 'Nmi', 'in', 'ft', 'yd', 'ang', 'ell', 'ly', 'parsec', 'pc', 'Picapt', 'Pica', 'pica', 'survey_mi'],
        energy: ['J', 'e', 'c', 'cal', 'eV', 'ev', 'HPh', 'hh', 'Wh', 'wh', 'flb', 'BTU', 'btu'],
        force: ['N', 'dyn', 'dy', 'lbf', 'pond'],
        information: ['bit', 'byte'],
        magnetism: ['ga', 'T'],
        power: ['HP', 'h', 'PS', 'W', 'w'],
        pressure: ['Pa', 'p', 'atm', 'at', 'mmHg', 'psi', 'Torr'],
        speed: ['admkn', 'kn', 'm/h', 'm/hr', 'm/s', 'm/sec', 'mph'],
        temperature: ['C', 'cel', 'F', 'fah', 'K', 'kel', 'Rank', 'Reau'],
        time: ['day', 'd', 'yr', 'hr', 'mn', 'min', 'sec', 's'],
        volume: [
          'tsp',
          'tspm',
          'tbs',
          'oz',
          'cup',
          'pt',
          'us_pt',
          'uk_pt',
          'qt',
          'uk_qt',
          'gal',
          'uk_gal',
          'l',
          'L',
          'lt',
          'ang3',
          'ang^3',
          'barrel',
          'bushel',
          'ft3',
          'ft^3',
          'in3',
          'in^3',
          'ly3',
          'ly^3',
          'm3',
          'm^3',
          'mi3',
          'mi^3',
          'yd3',
          'yd^3',
          'Nmi3',
          'Nmi^3',
          'Picapt3',
          'Picapt^3',
          'Pica3',
          'Pica^3',
          'GRT',
          'regton',
          'MTON',
        ],
        weight: [
          'g',
          'sg',
          'lbm',
          'u',
          'ozm',
          'grain',
          'cwt',
          'shweight',
          'uk_cwt',
          'lcwt',
          'hweight',
          'stone',
          'ton',
          'uk_ton',
          'LTON',
          'brton',
        ],
      };
      var e = {};
      Object.keys(r).forEach(function (t) {
        r[t].forEach(function (r) {
          e[r] = { type: t, base: r, factor: 1 };
        });
      });
      Object.keys(r).forEach(function (a) {
        r[a].forEach(function (r) {
          t.forEach(function (t) {
            var n = t[0],
              i = t[1];
            if (!e[n + r]) e[n + r] = { type: a, base: r, factor: i };
          });
        });
      });
      return e;
    })();
    function _r(t, r, e) {
      var a = fr[r];
      var n = fr[e];
      if (!a || !n || a.type !== n.type) throw 'type mismatch '.concat(a.type, ' !== ').concat(n.type);
      switch (a.type) {
        case 'area':
          return sr(t, a, n);
        case 'distance':
          return or(t, a, n);
        case 'energy':
          return lr(t, a, n);
        case 'force':
          return Ur(t, a, n);
        case 'information':
          return hr(t, a, n);
        case 'magnetism':
          return br(t, a, n);
        case 'power':
          return mr(t, a, n);
        case 'pressure':
          return Er(t, a, n);
        case 'speed':
          return pr(t, a, n);
        case 'temperature':
          return Ar(t, a, n);
        case 'time':
          return xr(t, a, n);
        case 'volume':
          return Sr(t, a, n);
        case 'weight':
          return dr(t, a, n);
        default:
          throw 'unsupported type '.concat(a.type);
      }
    }
    function sr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'ha':
          break;
        case 'uk_acre':
          a *= 0.40468564224000003;
          break;
        case 'us_acre':
          a *= 0.4046872609874252;
          break;
        case 'ang2':
        case 'ang^2':
          a *= 1e-24;
          break;
        case 'ar':
          a *= 0.01;
          break;
        case 'ft2':
        case 'ft^2':
          a *= 9290304e-12;
          break;
        case 'in2':
        case 'in^2':
          a *= 6.4516e-8;
          break;
        case 'ly2':
        case 'ly^2':
          a *= 8.95054210748189e27;
          break;
        case 'm2':
        case 'm^2':
          a *= 1e-4;
          break;
        case 'Morgen':
          a *= 0.25;
          break;
        case 'mi2':
        case 'mi^2':
          a *= 258.9988110336;
          break;
        case 'Nmi2':
        case 'Nmi^2':
          a *= 342.9904;
          break;
        case 'Picapt2':
        case 'Picapt^2':
        case 'Pica2':
        case 'Pica^2':
          a *= 1.2445216049382716e-11;
          break;
        case 'yd2':
        case 'yd^2':
          a *= 83612736e-12;
          break;
        default:
          throw 'unsupported area type '.concat(r.type);
      }
      switch (e.base) {
        case 'ha':
          break;
        case 'uk_acre':
          a /= 0.40468564224000003;
          break;
        case 'us_acre':
          a /= 0.4046872609874252;
          break;
        case 'ang2':
        case 'ang^2':
          a /= 1e-24;
          break;
        case 'ar':
          a /= 0.01;
          break;
        case 'ft2':
        case 'ft^2':
          a /= 9290304e-12;
          break;
        case 'in2':
        case 'in^2':
          a /= 6.4516e-8;
          break;
        case 'ly2':
        case 'ly^2':
          a /= 8.95054210748189e27;
          break;
        case 'm2':
        case 'm^2':
          a /= 1e-4;
          break;
        case 'Morgen':
          a /= 0.25;
          break;
        case 'mi2':
        case 'mi^2':
          a /= 258.9988110336;
          break;
        case 'Nmi2':
        case 'Nmi^2':
          a /= 342.9904;
          break;
        case 'Picapt2':
        case 'Picapt^2':
        case 'Pica2':
        case 'Pica^2':
          a /= 1.2445216049382716e-11;
          break;
        case 'yd2':
        case 'yd^2':
          a /= 83612736e-12;
          break;
        default:
          throw 'unsupported area type '.concat(e.type);
      }
      return a / e.factor;
    }
    function or(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'm':
          break;
        case 'mi':
          a *= 1609.344;
          break;
        case 'Nmi':
          a *= 1852;
          break;
        case 'in':
          a *= 0.0254;
          break;
        case 'ft':
          a *= 0.3048;
          break;
        case 'yd':
          a *= 0.9144;
          break;
        case 'ang':
          a *= 1e-10;
          break;
        case 'ell':
          a *= 1.143;
          break;
        case 'ly':
          a *= 9460730472580800;
          break;
        case 'parsec':
        case 'pc':
          a *= 0x6da012f93c9aac;
          break;
        case 'Picapt':
        case 'Pica':
          a *= 0.0003527777777777778;
          break;
        case 'pica':
          a *= 0.004233333333333334;
          break;
        case 'survey_mi':
          a *= 1609.3472186944373;
          break;
        default:
          throw 'unsupported dist type '.concat(r.type);
      }
      switch (e.base) {
        case 'm':
          break;
        case 'mi':
          a /= 1609.344;
          break;
        case 'Nmi':
          a /= 1852;
          break;
        case 'in':
          a /= 0.0254;
          break;
        case 'ft':
          a /= 0.3048;
          break;
        case 'yd':
          a /= 0.9144;
          break;
        case 'ang':
          a /= 1e-10;
          break;
        case 'ell':
          a /= 1.143;
          break;
        case 'ly':
          a /= 9460730472580800;
          break;
        case 'parsec':
        case 'pc':
          a /= 0x6da012f93c9aac;
          break;
        case 'Picapt':
        case 'Pica':
          a /= 0.0003527777777777778;
          break;
        case 'pica':
          a /= 0.004233333333333334;
          break;
        case 'survey_mi':
          a /= 1609.3472186944373;
          break;
        default:
          throw 'unsupported dist type '.concat(e.type);
      }
      return a / e.factor;
    }
    function lr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'J':
          break;
        case 'e':
          a *= 1e-7;
          break;
        case 'c':
          a *= 4.184;
          break;
        case 'cal':
          a *= 4.1868;
          break;
        case 'eV':
        case 'ev':
          a *= 1.602176487e-19;
          break;
        case 'HPh':
        case 'hh':
          a *= 2684519.537696173;
          break;
        case 'Wh':
        case 'wh':
          a *= 3600;
          break;
        case 'flb':
          a *= 1.3558179483314003;
          break;
        case 'BTU':
        case 'btu':
          a *= 1055.05585262;
          break;
        default:
          throw 'unsupported ener type '.concat(r.type);
      }
      switch (e.base) {
        case 'J':
          break;
        case 'e':
          a /= 1e-7;
          break;
        case 'c':
          a /= 4.184;
          break;
        case 'cal':
          a /= 4.1868;
          break;
        case 'eV':
        case 'ev':
          a /= 1.602176487e-19;
          break;
        case 'HPh':
        case 'hh':
          a /= 2684519.537696173;
          break;
        case 'Wh':
        case 'wh':
          a /= 3600;
          break;
        case 'flb':
          a /= 1.3558179483314003;
          break;
        case 'BTU':
        case 'btu':
          a /= 1055.05585262;
          break;
        default:
          throw 'unsupported ener type '.concat(e.type);
      }
      return a / e.factor;
    }
    function Ur(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'N':
          break;
        case 'dyn':
        case 'dy':
          a *= 1e-5;
          break;
        case 'lbf':
          a *= 4.448221615;
          break;
        case 'pond':
          a *= 0.00980665;
          break;
        default:
          throw 'unsupported forc type '.concat(r.type);
      }
      switch (e.base) {
        case 'N':
          break;
        case 'dyn':
        case 'dy':
          a /= 1e-5;
          break;
        case 'lbf':
          a /= 4.448221615;
          break;
        case 'pond':
          a /= 0.00980665;
          break;
        default:
          throw 'unsupported forc type '.concat(e.type);
      }
      return a / e.factor;
    }
    function hr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'bit':
          break;
        case 'byte':
          a *= 8;
          break;
        default:
          throw 'unsupported info type '.concat(r.type);
      }
      switch (e.base) {
        case 'bit':
          break;
        case 'byte':
          a /= 8;
          break;
        default:
          throw 'unsupported info type '.concat(e.type);
      }
      return a / e.factor;
    }
    function br(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'ga':
          break;
        case 'T':
          a *= 1e4;
          break;
        default:
          throw 'unsupported magn type '.concat(r.type);
      }
      switch (e.base) {
        case 'ga':
          break;
        case 'T':
          a /= 1e4;
          break;
        default:
          throw 'unsupported magn type '.concat(e.type);
      }
      return a / e.factor;
    }
    function mr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'HP':
        case 'h':
          break;
        case 'PS':
          a *= 0.986320070619531;
          break;
        case 'W':
        case 'w':
          a *= 0.0013410220895950279;
          break;
        default:
          throw 'unsupported powe type '.concat(r.type);
      }
      switch (e.base) {
        case 'HP':
        case 'h':
          break;
        case 'PS':
          a /= 0.986320070619531;
          break;
        case 'W':
        case 'w':
          a /= 0.0013410220895950279;
          break;
        default:
          throw 'unsupported powe type '.concat(e.type);
      }
      return a / e.factor;
    }
    function Er(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'Pa':
        case 'p':
          break;
        case 'atm':
        case 'at':
          a *= 101325;
          break;
        case 'mmHg':
          a *= 133.322;
          break;
        case 'psi':
          a *= 6894.757293168362;
          break;
        case 'Torr':
          a *= 133.32236842105263;
          break;
        default:
          throw 'unsupported temp type '.concat(r.type);
      }
      switch (e.base) {
        case 'Pa':
        case 'p':
          break;
        case 'atm':
        case 'at':
          a /= 101325;
          break;
        case 'mmHg':
          a /= 133.322;
          break;
        case 'psi':
          a /= 6894.757293168362;
          break;
        case 'Torr':
          a /= 133.32236842105263;
          break;
        default:
          throw 'unsupported temp type '.concat(e.type);
      }
      return a / e.factor;
    }
    function pr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'admkn':
          break;
        case 'kn':
          a *= 0.9993610995993923;
          break;
        case 'm/h':
        case 'm/hr':
          a *= 0.0005396118248376848;
          break;
        case 'm/s':
        case 'm/sec':
          a *= 1.9426025694156652;
          break;
        case 'mph':
          a *= 0.868421052631579;
          break;
        default:
          throw 'unsupported temp type '.concat(r.type);
      }
      switch (e.base) {
        case 'admkn':
          break;
        case 'kn':
          a /= 0.9993610995993923;
          break;
        case 'm/h':
        case 'm/hr':
          a /= 0.0005396118248376848;
          break;
        case 'm/s':
        case 'm/sec':
          a /= 1.9426025694156652;
          break;
        case 'mph':
          a /= 0.868421052631579;
          break;
        default:
          throw 'unsupported temp type '.concat(e.type);
      }
      return a / e.factor;
    }
    function Ar(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'C':
        case 'cel':
          break;
        case 'K':
        case 'kel':
          a -= 273.15;
          break;
        case 'F':
        case 'fah':
          a = ((a - 32) * 5) / 9;
          break;
        case 'Rank':
          a = ((a - 491.67) * 5) / 9;
          break;
        case 'Reau':
          a *= 5 / 4;
          break;
        default:
          throw 'unsupported temp type '.concat(r.type);
      }
      switch (e.base) {
        case 'C':
        case 'cel':
          break;
        case 'K':
        case 'kel':
          a += 273.15;
          break;
        case 'F':
        case 'fah':
          a = 1.8 * a + 32;
          break;
        case 'Rank':
          a = 1.8 * a + 491.67;
          break;
        case 'Reau':
          a *= 4 / 5;
          break;
        default:
          throw 'unsupported temp type '.concat(e.type);
      }
      return a / e.factor;
    }
    function xr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'day':
        case 'd':
          break;
        case 'yr':
          a *= 365.25;
          break;
        case 'hr':
          a /= 24;
          break;
        case 'mn':
        case 'min':
          a /= 1440;
          break;
        case 's':
        case 'sec':
          a /= 86400;
          break;
        default:
          throw 'unsupported time type '.concat(r.type);
      }
      switch (e.base) {
        case 'day':
        case 'd':
          break;
        case 'yr':
          a /= 365.25;
          break;
        case 'hr':
          a *= 24;
          break;
        case 'mn':
        case 'min':
          a *= 1440;
          break;
        case 's':
        case 'sec':
          a *= 86400;
          break;
        default:
          throw 'unsupported time type '.concat(e.type);
      }
      return a / e.factor;
    }
    function Sr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'tsp':
          break;
        case 'tspm':
          a *= 1.01442068105529;
          break;
        case 'tbs':
          a *= 3;
          break;
        case 'oz':
          a *= 6;
          break;
        case 'cup':
          a *= 48;
          break;
        case 'pt':
        case 'us_pt':
          a *= 96;
          break;
        case 'uk_pt':
          a *= 115.29119284846608;
          break;
        case 'qt':
          a *= 192;
          break;
        case 'uk_qt':
          a *= 230.58238569693216;
          break;
        case 'gal':
          a *= 768;
          break;
        case 'uk_gal':
          a *= 922.3295427877287;
          break;
        case 'l':
        case 'L':
        case 'lt':
          a *= 202.88413621105798;
          break;
        case 'ang3':
        case 'ang^3':
          a *= 2.0288413621105798e-25;
          break;
        case 'barrel':
          a *= 32256;
          break;
        case 'bushel':
          a *= 7149.448311688311;
          break;
        case 'ft3':
        case 'ft^3':
          a *= 5745.038961038961;
          break;
        case 'in3':
        case 'in^3':
          a *= 3.324675324675325;
          break;
        case 'ly3':
        case 'ly^3':
          a *= 1.7179958100722532e53;
          break;
        case 'm3':
        case 'm^3':
          a *= 202884.13621105798;
          break;
        case 'mi3':
        case 'mi^3':
          a *= 845657969225142.9;
          break;
        case 'yd3':
        case 'yd^3':
          a *= 155116.05194805196;
          break;
        case 'Nmi3':
        case 'Nmi^3':
          a *= 0x4941e1f34c8d3;
          break;
        case 'Picapt3':
        case 'Picapt^3':
        case 'Pica3':
        case 'Pica^3':
          a *= 8907416314823724e-21;
          break;
        case 'GRT':
        case 'regton':
          a *= 574503.8961038962;
          break;
        case 'MTON':
          a *= 229801.55844155847;
          break;
        default:
          throw 'unsupported volu type '.concat(r.type);
      }
      switch (e.base) {
        case 'tsp':
          break;
        case 'tspm':
          a /= 1.01442068105529;
          break;
        case 'tbs':
          a /= 3;
          break;
        case 'oz':
          a /= 6;
          break;
        case 'cup':
          a /= 48;
          break;
        case 'pt':
        case 'us_pt':
          a /= 96;
          break;
        case 'uk_pt':
          a /= 115.29119284846608;
          break;
        case 'qt':
          a /= 192;
          break;
        case 'uk_qt':
          a /= 230.58238569693216;
          break;
        case 'gal':
          a /= 768;
          break;
        case 'uk_gal':
          a /= 922.3295427877287;
          break;
        case 'l':
        case 'L':
        case 'lt':
          a /= 202.88413621105798;
          break;
        case 'ang3':
        case 'ang^3':
          a /= 2.0288413621105798e-25;
          break;
        case 'barrel':
          a /= 32256;
          break;
        case 'bushel':
          a /= 7149.448311688311;
          break;
        case 'ft3':
        case 'ft^3':
          a /= 5745.038961038961;
          break;
        case 'in3':
        case 'in^3':
          a /= 3.324675324675325;
          break;
        case 'ly3':
        case 'ly^3':
          a /= 1.7179958100722532e53;
          break;
        case 'm3':
        case 'm^3':
          a /= 202884.13621105798;
          break;
        case 'mi3':
        case 'mi^3':
          a /= 845657969225142.9;
          break;
        case 'yd3':
        case 'yd^3':
          a /= 155116.05194805196;
          break;
        case 'Nmi3':
        case 'Nmi^3':
          a /= 0x4941e1f34c8d3;
          break;
        case 'Picapt3':
        case 'Picapt^3':
        case 'Pica3':
        case 'Pica^3':
          a /= 8907416314823724e-21;
          break;
        case 'GRT':
        case 'regton':
          a /= 574503.8961038962;
          break;
        case 'MTON':
          a /= 229801.55844155847;
          break;
        default:
          throw 'unsupported volu type '.concat(e.type);
      }
      return a / e.factor;
    }
    function dr(t, r, e) {
      var a = t * r.factor;
      switch (r.base) {
        case 'g':
          break;
        case 'sg':
          a *= 14593.902937206363;
          break;
        case 'lbm':
          a *= 453.59237;
          break;
        case 'u':
          a *= 1.660538782e-24;
          break;
        case 'ozm':
          a *= 28.349523125;
          break;
        case 'grain':
          a *= 0.06479891;
          break;
        case 'cwt':
        case 'shweight':
          a *= 45359.237;
          break;
        case 'uk_cwt':
        case 'lcwt':
        case 'hweight':
          a *= 50802.34544;
          break;
        case 'stone':
          a *= 6350.29318;
          break;
        case 'ton':
          a *= 907184.74;
          break;
        case 'uk_ton':
        case 'LTON':
        case 'brton':
          a *= 1016046.9088;
          break;
        default:
          throw 'unsupported weig type '.concat(r.type);
      }
      switch (e.base) {
        case 'g':
          break;
        case 'sg':
          a /= 14593.902937206363;
          break;
        case 'lbm':
          a /= 453.59237;
          break;
        case 'u':
          a /= 1.660538782e-24;
          break;
        case 'ozm':
          a /= 28.349523125;
          break;
        case 'grain':
          a /= 0.06479891;
          break;
        case 'cwt':
        case 'shweight':
          a /= 45359.237;
          break;
        case 'uk_cwt':
        case 'lcwt':
        case 'hweight':
          a /= 50802.34544;
          break;
        case 'stone':
          a /= 6350.29318;
          break;
        case 'ton':
          a /= 907184.74;
          break;
        case 'uk_ton':
        case 'LTON':
        case 'brton':
          a /= 1016046.9088;
          break;
        default:
          throw 'unsupported weig type '.concat(e.type);
      }
      return a / e.factor;
    }
    function Mr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = t.Util._cast_str(r[1]);
      if (a.t !== 's') return { t: 'e', v: 42 };
      var n = t.Util._cast_str(r[2]);
      if (n.t !== 's') return { t: 'e', v: 42 };
      try {
        return t.Util._box_num(_r(e.v, a.v, n.v));
      } catch (t) {
        return { t: 'e', v: 42 };
      }
    }
    r.CONVERT = Mr;
    function Nr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = e.v;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t !== 'n') return { t: 'e', v: 15 };
        a -= n.v;
      }
      return t.Util._box_num(a === 0 ? 1 : 0);
    }
    r.DELTA = Nr;
    function gr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t !== 'n') return { t: 'e', v: 15 };
      var a = e.v;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t !== 'n') return { t: 'e', v: 15 };
        a -= n.v;
      }
      return t.Util._box_num(a >= 0 ? 1 : 0);
    }
    r.GESTEP = gr;
    function Lr(r) {
      if (r.length < 3) return t.Util._box_err('#VALUE!');
      var e = t.Util._cast_num(r[0]);
      if (r[0].t !== 'y' && e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = t.Util._cast_num(r[1]);
      if (r[1].t !== 'y' && a.t === 'e') return t.Util._box_err('#VALUE!');
      var n = t.Util._cast_num(r[2]);
      if (r[2].t !== 'y' && n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = 0;
      var u = 0;
      if (r.length >= 4 && r[3].t !== 'y') {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        i = v.v;
      }
      if (r.length >= 5 && r[4].t !== 'y') {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return t.Util._box_err('#VALUE!');
        u = c.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.FV(r[0].t !== 'y' ? e.v : 0, r[1].t !== 'y' ? a.v : 0, r[2].t !== 'y' ? n.v : 0, i, u));
    }
    r.FV = Lr;
    function Ir(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[3]);
      if (i.t === 'e') return i;
      var u = 0;
      var v = 0;
      if (r.length > 4) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v;
      }
      if (r.length > 5) {
        var f = t.Util._cast_num(r[5]);
        if (f.t === 'e') return f;
        v = f.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.IPMT(e.v, a.v, n.v, i.v, u, v));
    }
    r.IPMT = Ir;
    function kr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[3]);
      if (i.t === 'e') return i;
      var u = 0;
      var v = 0;
      if (r.length > 4) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v;
      }
      if (r.length > 5) {
        var f = t.Util._cast_num(r[5]);
        if (f.t === 'e') return f;
        v = f.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.PPMT(e.v, a.v, n.v, i.v, u, v));
    }
    r.PPMT = kr;
    function Tr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[3]);
      if (i.t === 'e') return i;
      return t.Util._box_num(t.Spec.Financial.ISPMT(e.v, a.v, n.v, i.v));
    }
    r.ISPMT = Tr;
    function wr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = 0;
      var u = 0;
      if (r.length >= 4) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return v;
        i = v.v;
      }
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.NPER(e.v, a.v, n.v, i, u));
    }
    r.NPER = wr;
    function yr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = 0;
      var u = 0;
      if (r.length >= 4) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return v;
        i = v.v;
      }
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.PMT(e.v, a.v, n.v, i, u));
    }
    r.PMT = yr;
    function Or(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = 0;
      var u = 0;
      if (r.length >= 4) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return v;
        i = v.v;
      }
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v | 0;
      }
      return t.Util._box_num(t.Spec.Financial.PV(e.v, a.v, n.v, i, u));
    }
    r.PV = Or;
    function Dr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = 0;
      var u = 0;
      var v = 0.1;
      if (r.length >= 4) {
        var c = t.Util._cast_num(r[3]);
        if (c.t === 'e') return c;
        i = c.v;
      }
      if (r.length >= 5) {
        var f = t.Util._cast_num(r[4]);
        if (f.t === 'e') return f;
        u = f.v | 0;
      }
      if (r.length >= 6) {
        var _ = t.Util._cast_num(r[4]);
        if (_.t === 'e') return _;
        v = _.v;
      }
      if ((a.v === 0 && n.v === 0) || e.v <= 0) return t.Util._box_err('#NUM!');
      if (i === 0 && n.v === 0) return t.Util._box_num(a.v >= 0 ? 1 : -1);
      return t.Util._box_num(t.Spec.Financial.RATE(e.v, a.v, n.v, i, u, v));
    }
    r.RATE = Dr;
    function Cr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      if (e.v <= 0) return t.Util._box_err('#NUM!');
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      if (a.v <= 0) return t.Util._box_err('#NUM!');
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      if (n.v < 0) return t.Util._box_err('#NUM!');
      var i = e.v | 0;
      var u = a.v;
      var v = n.v;
      return t.Util._box_num(Math.pow(v / u, 1 / i) - 1);
    }
    r.RRI = Cr;
    function Rr(r, e) {
      var a = t.Util._cast_num(r[0]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[2]);
      if (i.t === 'e') return i;
      var u = t.Util._cast_num(r[3]);
      if (u.t === 'e') return u;
      var v = t.Util._cast_num(r[4]);
      if (v.t === 'e') return v;
      var c = t.Util._cast_num(r[5]);
      if (c.t === 'e') return c;
      var f = 0;
      var _ = e ? t.Spec.Financial.IPMT : t.Spec.Financial.PPMT;
      for (var s = Math.ceil(u.v); s <= v.v; ++s) f += _(a.v, s, n.v, i.v, 0, c.v);
      return t.Util._box_num(f);
    }
    function Vr(t) {
      return Rr(t, true);
    }
    r.CUMIPMT = Vr;
    function Fr(t) {
      return Rr(t, false);
    }
    r.CUMPRINC = Fr;
    function Pr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      if (e.v === -1) return t.Util._box_err('#NUM!');
      var a = [];
      r.slice(1).forEach(function (r) {
        var e = t.Util._cast_num_arr(r);
        if (!Array.isArray(e)) return;
        a = a.concat(e);
      });
      return t.Util._box_num(t.Spec.Financial.NPV(e.v, a));
    }
    r.NPV = Pr;
    function Xr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      if (e.v === -1) return t.Util._box_err('#NUM!');
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(a)) return t.Util._box_err('#VALUE!');
      var n = t.Util._cast_num_arr(r[2]);
      if (!Array.isArray(n)) return t.Util._box_err('#VALUE!');
      for (var i = 0; i < n.length; ++i) n[i] = n[i] | 0;
      if (a.length !== n.length) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.XNPV(e.v, a, n));
    }
    r.XNPV = Xr;
    function Br(r) {
      var e = t.Util._cast_num_arr(r[0]);
      if (!Array.isArray(e)) return t.Util._box_err('#VALUE!');
      var a = false;
      var n = false;
      e.forEach(function (t) {
        if (t > 0) a = true;
        if (t < 0) n = true;
      });
      if (!a || !n) return t.Util._box_err('#DIV/0!');
      var i = t.Util._cast_num(r[1]);
      if (i.t === 'e') return t.Util._box_err('#VALUE!');
      if (i.v === -1) return t.Util._box_err('#NUM!');
      var u = t.Util._cast_num(r[2]);
      if (u.t === 'e') return t.Util._box_err('#VALUE!');
      if (u.v === -1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.MIRR(e, i.v, u.v));
    }
    r.MIRR = Br;
    function Gr(r) {
      var e = t.Util._cast_num_arr(r[0]);
      if (!Array.isArray(e)) return t.Util._box_err('#VALUE!');
      var a = 0.1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return n;
        a = n.v;
      }
      return t.Util._box_num(t.Spec.Financial.IRR(e, a));
    }
    r.IRR = Gr;
    function Hr(r) {
      var e = t.Util._cast_num_arr(r[0]);
      if (!Array.isArray(e)) return t.Util._box_err('#VALUE!');
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(a)) return t.Util._box_err('#VALUE!');
      for (var n = 0; n < a.length; ++n) a[n] = a[n] | 0;
      if (e.length !== a.length) return t.Util._box_err('#NUM!');
      var i = 0.1;
      if (r.length > 2) {
        var u = t.Util._cast_num(r[2]);
        if (u.t === 'e') return u;
        i = u.v;
      }
      return t.Util._box_num(t.Spec.Financial.XIRR(e, a, i));
    }
    r.XIRR = Hr;
    function zr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(a)) return a;
      var n = e.v;
      a.forEach(function (t) {
        return (n *= 1 + t);
      });
      return t.Util._box_num(n);
    }
    r.FVSCHEDULE = zr;
    function qr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = a.v | 0;
      if (n < 0) return t.Util._box_err('#NUM!');
      if (n === 0) return t.Util._box_err('#DIV/0!');
      return t.Util._box_num(t.Spec.DOLLAR(e.v, n, false));
    }
    r.DOLLARDE = qr;
    function $r(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = a.v | 0;
      if (n < 0) return t.Util._box_err('#NUM!');
      if (n === 0) return t.Util._box_err('#DIV/0!');
      return t.Util._box_num(t.Spec.DOLLAR(e.v, n, true));
    }
    r.DOLLARFR = $r;
    function Wr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = a.v | 0;
      if (e.v <= 0 || a.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.pow(1 + e.v / n, n) - 1);
    }
    r.EFFECT = Wr;
    function Yr(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || a.v < 1) return { t: 'e', v: 36 };
      var n = a.v | 0;
      return t.Util._box_num((Math.pow(1 + e.v, 1 / n) - 1) * n);
    }
    r.NOMINAL = Yr;
    function Kr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = e.v;
      var u = a.v;
      var v = n.v;
      if (i <= 0 || u <= 0 || v <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num((Math.log(v) - Math.log(u)) / Math.log(1 + i));
    }
    r.PDURATION = Kr;
    function Zr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      if (a.v < e.v) return { t: 'e', v: 36 };
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      if (n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Financial.TBILLEQ(e.v, a.v, n.v));
    }
    r.TBILLEQ = Zr;
    function Qr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      if (a.v < e.v) return { t: 'e', v: 36 };
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      if (n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Financial.TBILLPRICE(e.v, a.v, n.v));
    }
    r.TBILLPRICE = Qr;
    function Jr(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      if (e.v >= a.v || e.v + 365 < a.v) return { t: 'e', v: 36 };
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      if (n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Financial.TBILLYIELD(e.v, a.v, n.v));
    }
    r.TBILLYIELD = Jr;
    function jr(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ODDFPRICE = jr;
    function te(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ODDFYIELD = te;
    function re(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ODDLPRICE = re;
    function ee(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ODDLYIELD = ee;
    function ae(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ACCRINT = ae;
    function ne(r) {
      return t.Util._box_err('#NAME?');
    }
    r.ACCRINTM = ne;
    function ie(r) {
      return t.Util._box_err('#NAME?');
    }
    r.PRICE = ie;
    function ue(r) {
      return t.Util._box_err('#NAME?');
    }
    r.PRICEMAT = ue;
    function ve(r) {
      return t.Util._box_err('#NAME?');
    }
    r.YIELDMAT = ve;
    function ce(r) {
      return t.Util._box_err('#NAME?');
    }
    r.INTRATE = ce;
    function fe(r) {
      return t.Util._box_err('#NAME?');
    }
    r.RECEIVED = fe;
    function _e(r) {
      return t.Util._box_err('#NAME?');
    }
    r.DISC = _e;
    function se(r) {
      return t.Util._box_err('#NAME?');
    }
    r.PRICEDISC = se;
    function oe(r) {
      return t.Util._box_err('#NAME?');
    }
    r.YIELDDISC = oe;
    function le(r) {
      return t.Util._box_err('#NAME?');
    }
    r.DURATION = le;
    function Ue(r) {
      return t.Util._box_err('#NAME?');
    }
    r.MDURATION = Ue;
    function he(r) {
      return t.Util._box_err('#NAME?');
    }
    r.YIELD = he;
    function be(r) {
      return function (e) {
        var a = t.Util._cast_date_code(e[0]);
        var n = t.Util._cast_date_code(e[1]);
        var i = t.Util._cast_num(e[2]);
        if (a.t !== 'n' || n.t !== 'n') return { t: 'e', v: 15 };
        if (a.v > n.v) return { t: 'e', v: 36 };
        if (i.v !== 1 && i.v !== 2 && i.v !== 4) return { t: 'e', v: 36 };
        var u = 0;
        if (e.length >= 3) {
          var v = t.Util._cast_num(e[3]);
          if (v.t === 'e') return { t: 'e', v: 15 };
          u = v.v | 0;
          if (u < 0 || u > 4) return { t: 'e', v: 36 };
        }
        return t.Util._box_num(r(a.v, n.v, i.v, u));
      };
    }
    r.COUPDAYS = be(t.Spec.Financial.COUPDAYS);
    r.COUPPCD = be(t.Spec.Financial.COUPPCD);
    r.COUPNCD = be(t.Spec.Financial.COUPNCD);
    r.COUPNUM = be(t.Spec.Financial.COUPNUM);
    r.COUPDAYBS = be(t.Spec.Financial.COUPDAYBS);
    r.COUPDAYSNC = be(t.Spec.Financial.COUPDAYSNC);
    function me(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[3]);
      if (i.t === 'e') return i;
      var u = 12;
      if (r.length >= 5) {
        var v = t.Util._cast_num(r[4]);
        if (v.t === 'e') return i;
        u = v.v;
      }
      if (e.v < 0 || a.v < 0 || n.v <= 0 || i.v <= 0 || u <= 0 || u > 12) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.DB(e.v, a.v, n.v, i.v, u));
    }
    r.DB = me;
    function Ee(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e') return n;
      var i = n.v;
      var u = t.Util._cast_num(r[2]);
      if (u.t === 'e') return u;
      var v = u.v;
      var c = t.Util._cast_num(r[3]);
      if (c.t === 'e') return c;
      var f = c.v;
      var _ = 2;
      if (r.length >= 5) {
        var s = t.Util._cast_num(r[4]);
        if (s.t === 'e') return c;
        _ = s.v;
      }
      if (a < 0 || i < 0 || v <= 0 || f <= 0 || _ <= 0) return t.Util._box_err('#NUM!');
      if (c.v === 0) return t.Util._box_num(Math.min((a * _) / v, a - i));
      return t.Util._box_num(t.Spec.Financial.DDB(a, i, v, f, _));
    }
    r.DDB = Ee;
    function pe(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      return t.Util._box_num((e.v - a.v) / n.v);
    }
    r.SLN = pe;
    function Ae(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_num(r[3]);
      if (i.t === 'e') return i;
      var u = e.v;
      var v = a.v;
      var c = n.v;
      var f = i.v;
      return t.Util._box_num(((u - v) * (c - f + 1) * 2) / (c * (c + 1)));
    }
    r.SYD = Ae;
    function xe(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e') return n;
      var i = n.v;
      var u = t.Util._cast_num(r[2]);
      if (u.t === 'e') return u;
      var v = u.v;
      var c = t.Util._cast_num(r[3]);
      if (c.t === 'e') return c;
      var f = c.v;
      var _ = t.Util._cast_num(r[4]);
      if (_.t === 'e') return _;
      var s = _.v;
      var o = 2;
      if (r.length >= 6) {
        var l = t.Util._cast_num(r[5]);
        if (l.t === 'e') return l;
        o = l.v;
      }
      var U = false;
      if (r.length >= 7) {
        var h = t.Util._cast_bool(r[6]);
        if (h.t === 'e') return h;
        U = h.v;
      }
      if (a < 0 || i < 0 || v <= 0 || s <= 0 || o <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.VDB(a, i, v, f, s, o, U));
    }
    r.VDB = xe;
    function Se(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      var n = t.Util._cast_date_code(r[1]);
      if (n.t === 'e') return n;
      var i = n.v;
      var u = t.Util._cast_date_code(r[2]);
      if (u.t === 'e') return u;
      var v = u.v;
      var c = t.Util._cast_num(r[3]);
      if (c.t === 'e') return c;
      var f = c.v;
      var _ = t.Util._cast_num(r[4]);
      if (_.t === 'e') return c;
      var s = _.v;
      var o = t.Util._cast_num(r[5]);
      if (o.t === 'e') return c;
      var l = o.v;
      var U = 0;
      if (r.length >= 7) {
        var h = t.Util._cast_num(r[6]);
        if (h.t === 'e') return h;
        U = o.v | 0;
        if (U < 0 || U > 4 || U === 2) return t.Util._box_err('#NUM!');
      }
      if (a < 0 || f < 0 || s < 0 || l < 0 || f > a) return t.Util._box_err('#NUM!');
      if (i > v) return t.Util._box_err('#NUM!');
      var b = 1 / l;
      if (b < 3 || (b > 4 && b < 5)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.AMORDEGRC(a, i, v, f, s, l, U));
    }
    r.AMORDEGRC = Se;
    function de(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      var n = t.Util._cast_date_code(r[1]);
      if (n.t === 'e') return n;
      var i = n.v;
      var u = t.Util._cast_date_code(r[2]);
      if (u.t === 'e') return u;
      var v = u.v;
      var c = t.Util._cast_num(r[3]);
      if (c.t === 'e') return c;
      var f = c.v;
      var _ = t.Util._cast_num(r[4]);
      if (_.t === 'e') return c;
      var s = _.v;
      var o = t.Util._cast_num(r[5]);
      if (o.t === 'e') return c;
      var l = o.v;
      var U = 0;
      if (r.length >= 7) {
        var h = t.Util._cast_num(r[6]);
        if (h.t === 'e') return h;
        U = o.v | 0;
        if (U < 0 || U > 4 || U === 2) return t.Util._box_err('#NUM!');
      }
      if (a < 0 || f < 0 || s < 0 || l < 0 || f > a) return t.Util._box_err('#NUM!');
      if (i > v) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Financial.AMORLINC(a, i, v, f, s, l, U));
    }
    r.AMORLINC = de;
    function Me(t) {
      return { t: 'b', v: t[0].t === 'z' };
    }
    r.ISBLANK = Me;
    function Ne(t) {
      return { t: 'b', v: t[0].t === 'e' && t[0].v !== 42 };
    }
    r.ISERR = Ne;
    function ge(t) {
      return { t: 'b', v: t[0].t === 'e' };
    }
    r.ISERROR = ge;
    function Le(t) {
      return { t: 'b', v: t[0].t === 'b' };
    }
    r.ISLOGICAL = Le;
    function Ie(t) {
      return { t: 'b', v: t[0].t === 'e' && t[0].v === 42 };
    }
    r.ISNA = Ie;
    function ke(t) {
      return { t: 'b', v: t[0].t !== 's' };
    }
    r.ISNONTEXT = ke;
    function Te(t) {
      return { t: 'b', v: t[0].t === 'n' };
    }
    r.ISNUMBER = Te;
    function we(t) {
      return { t: 'b', v: (t[0].a || '').length > 0 };
    }
    r.ISREF = we;
    function ye(t) {
      return { t: 'b', v: t[0].t === 's' };
    }
    r.ISTEXT = ye;
    function Oe(t) {
      var r = 0;
      switch (t[0].t) {
        case 'n':
          return { t: 'b', v: ((t[0].v | 0) & 1) === 0 };
        case 's':
          r = parseFloat(t[0].v);
          return { t: 'b', v: !isNaN(r) && ((r | 0) & 1) === 0 };
      }
      return { t: 'b', v: false };
    }
    r.ISEVEN = Oe;
    function De(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = 0;
      switch (r[0].t) {
        case 'n':
          return { t: 'b', v: ((e.v | 0) & 1) === 1 };
        case 's':
          a = parseFloat(r[0].v);
          return { t: 'b', v: !isNaN(a) && ((a | 0) & 1) === 1 };
      }
      return { t: 'b', v: false };
    }
    r.ISODD = De;
    function Ce(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.SHEET = Ce;
    function Re(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.SHEETS = Re;
    function Ve(r, e, a, n) {
      var i = t.Util._cast_str(r[0]);
      if (i.t === 'e') return t.Util._box_err('#VALUE!');
      var u = i.v;
      var v = n;
      if (r.length > 1) {
        var c = r[1];
        if (c.t === 'U') return t.Util._box_err('#NULL!');
        if (c.t === 'y') return t.Util._box_err('#VALUE!');
        if (!c.a) throw 'CELL: Argument is not a reference';
        v = t.XLSXLib.utils.decode_cell(c.a);
      }
      switch (u) {
        case 'address':
          return t.Util._box_str('$' + t.XLSXLib.utils.encode_col(v.c) + '$' + t.XLSXLib.utils.encode_row(v.r));
        case 'col':
          return t.Util._box_num(v.c + 1);
        case 'contents':
          if ('bends'.indexOf(r[1].t) > -1) return r[1];
          if (r[1].t === 'z') return { t: 'n', v: 0 };
          break;
        case 'row':
          return t.Util._box_num(v.r + 1);
      }
      return t.Util._box_err('#VALUE!');
    }
    r.CELL = Ve;
    function Fe(r, e, a) {
      var n = r[0];
      if (!n.a) return { t: 'b', v: false };
      var i = e.Sheets[e.SheetNames[a]];
      if (!i) return { t: 'b', v: false };
      var u = n.a;
      var v;
      if (Array.isArray(i)) {
        var c = t.Util.decode_cell(u);
        v = i[c.r] && i[c.r][c.c];
      } else v = i[u];
      if (!v) return { t: 'b', v: false };
      return { t: 'b', v: !!v.f };
    }
    r.ISFORMULA = Fe;
    function Pe(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 's')
        switch (e.v) {
          case 'directory':
            return { t: 's', v: t.State.Info.directory };
          case 'numfile':
            return { t: 'n', v: t.State.Info.numfile };
          case 'origin':
            return { t: 's', v: '$A:$A$1' };
          case 'osversion':
            return { t: 's', v: 'S5S Formula Calc' };
          case 'recalc':
            return { t: 's', v: 'Manual' };
          case 'release':
            return { t: 's', v: t.State.Info.release };
          case 'system':
            return { t: 's', v: 'ECMA5ports' };
          case 'memavail':
          case 'memused':
          case 'totmem':
            return { t: 'e', v: 42 };
        }
      return { t: 'e', v: 15 };
    }
    r.INFO = Pe;
    function Xe(t) {
      var r = { t: 'n', v: 16 };
      switch (t[0].t) {
        case 'n':
          r.v = 1;
          break;
        case 's':
          r.v = 2;
          break;
        case 'b':
          r.v = 4;
          break;
        case 'A':
          if (t[0].a === '') r.v = 64;
          break;
        case 'R':
          if (t[0].a === '') r.v = 64;
          break;
        case 'C':
          if (t[0].a === '') r.v = 64;
          break;
      }
      return r;
    }
    r.TYPE = Xe;
    function Be(r) {
      var e = r[0];
      switch (e.t) {
        case 'n':
          return e;
        case 'b':
          return t.Util._box_num(e.v ? 1 : 0);
        case 'e':
          return e;
      }
      return t.Util._box_num(0);
    }
    r.N = Be;
    var Ge = { 0: 1, 7: 2, 15: 3, 23: 4, 29: 5, 36: 6, 42: 7, 43: 8 };
    function He(r) {
      var e = t.Util._cast_err(r[0]);
      if (r[0].t === 'e' && e.t === 'e' && Ge[e.v]) return t.Util._box_num(Ge[e.v]);
      return { t: 'e', v: 42 };
    }
    r.ERROR_DOT_TYPE = He;
    function ze(t) {
      return { t: 'e', v: 42 };
    }
    r.NA = ze;
    function qe(t) {
      return { t: 'b', v: false };
    }
    r.FALSE = qe;
    function $e(t) {
      return { t: 'b', v: true };
    }
    r.TRUE = $e;
    function We(r) {
      var e = true;
      for (var a = 0; a < r.length; ++a) {
        if (r[a].t === 'e') return r[a];
        var n = t.Util._cast_bool(r[a]);
        if (n.t === 'b' && !n.v) e = false;
      }
      return { t: 'b', v: e };
    }
    r.AND = We;
    function Ye(r) {
      for (var e = 0; e < r.length; ++e) {
        if (r[e].t === 'e') return r[e];
        var a = t.Util._cast_bool(r[e]);
        if (a.t === 'b' && a.v) return { t: 'b', v: true };
      }
      return { t: 'b', v: false };
    }
    r.OR = Ye;
    function Ke(r) {
      var e = 0;
      for (var a = 0; a < r.length; ++a) {
        var n = t.Util._cast_bool(r[a]);
        if (n.t === 'b') e ^= +n.v;
      }
      return { t: 'b', v: e > 0 };
    }
    r.XOR = Ke;
    function Ze(r) {
      var e = t.Util._cast_bool(r[0]);
      if (e.t === 'b' && !e.v) return { t: 'b', v: true };
      return { t: 'b', v: false };
    }
    r.NOT = Ze;
    function Qe(r) {
      if (r[0].t === 'e') return r[0];
      var e = t.Util._cast_bool(r[0]);
      if (e.t === 'b' && e.v) return r[1];
      return r[2] || { t: 'b', v: false };
    }
    r.IF = Qe;
    function Je(r) {
      for (var e = 0; e < r.length; e += 2) {
        var a = t.Util._cast_bool(r[e]);
        if (a.t === 'b' && a.v) return r[e + 1];
      }
      return r[2] || { t: 'b', v: false };
    }
    r.IFS = Je;
    function je(t) {
      if (t[0].t !== 'e') return t[0];
      return t[1];
    }
    r.IFERROR = je;
    function ta(t) {
      if (t[0].t !== 'e') return t[0];
      if (t[0].v !== 42) return t[0];
      return t[1];
    }
    r.IFNA = ta;
    function ra(r) {
      var e = t.Util._cast_val(r[0]);
      for (var a = 1; a < r.length; a += 2) {
        var n = t.Util._cast_val(r[a]);
        if (e.t === n.t && e.v === n.v && a + 1 < r.length) return r[a + 1];
      }
      if ((r.length & 1) === 0) return r[r.length - 1];
      return r[2] || { t: 'b', v: false };
    }
    r.SWITCH = ra;
    function ea(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.GETPIVOTDATA = ea;
    function aa(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.RTD = aa;
    function na(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return t.Util._box_err('#REF!');
      return t.Util._box_str(e.v);
    }
    r.INDIRECT = na;
    function ia(r, e, a, n) {
      var i = r[0];
      if (!i.a) return t.Util._box_err('#VALUE!');
      var u = e.Sheets[e.SheetNames[a]];
      if (!u) return t.Util._box_err('#VALUE!');
      var v = Array.isArray(u);
      var c = i.a;
      var f = t.Util.decode_cell(c);
      var _ = v ? u[f.r] && u[f.r][f.c] : u[c];
      if (!_) return t.Util._box_err('#VALUE!');
      if (!_.f) return t.Util._box_err('#VALUE!');
      var s = '=' + _.f;
      if (_.F) s = '{'.concat(s, '}');
      return t.Util._box_str(s);
    }
    r.FORMULATEXT = ia;
    function ua(r) {
      var e = t.Util._cast_str(r[1]);
      if (e.t === 'e') return t.Util._box_err('#REF!');
      return t.Util._box_str(e.v);
    }
    r.HYPERLINK = ua;
    function va(r, e, a) {
      var n = r[0];
      if (!n.a) return t.Util._box_err('#VALUE!');
      var i = t.XLSXLib.utils.decode_cell(n.a.replace(/\$/g, ''));
      var u = t.Util._cast_num(r[1]);
      if (u.t === 'e' && r[1].t !== 'y') return t.Util._box_err('#VALUE!');
      i.r += r[1].t === 'y' ? 0 : u.v | 0;
      var v = t.Util._cast_num(r[2]);
      if (v.t === 'e' && r[2].t !== 'y') return t.Util._box_err('#VALUE!');
      i.c += r[2].t === 'y' ? 0 : v.v | 0;
      if (i.c < 0 || i.r < 0) t.Util._box_err('#REF!');
      if (r.length <= 3) {
        return t.Util._box_str(t.Util.encode_cell(i));
      }
      var c = { r: i.r, c: i.c };
      if (r.length >= 4) {
        var f = t.Util._cast_num(r[3]);
        if (f.t === 'e' && r[3].t !== 'y') return t.Util._box_err('#VALUE!');
        if (r[3].t !== 'y' && f.v === 0) return t.Util._box_err('#REF!');
        c.r += r[3].t === 'y' ? 0 : (f.v | 0) + (f.v > 0 ? -1 : 1);
      }
      if (r.length >= 5) {
        var _ = t.Util._cast_num(r[4]);
        if (_.t === 'e' && r[4].t !== 'y') return t.Util._box_err('#VALUE!');
        if (r[4].t !== 'y' && _.v === 0) return t.Util._box_err('#REF!');
        c.c += r[4].t === 'y' ? 0 : (_.v | 0) + (_.v > 0 ? -1 : 1);
      }
      if (c.c < 0 || c.r < 0) t.Util._box_err('#REF!');
      var s = t.Util.encode_cell({ r: Math.min(c.r, i.r), c: Math.min(c.c, i.c) });
      s += ':' + t.Util.encode_cell({ r: Math.max(c.r, i.r), c: Math.max(c.c, i.c) });
      return t.Util._box_str(s);
    }
    r.OFFSET = va;
    function ca(r) {
      var e = r[0];
      if (e.t === 'e') return e;
      if (e.t !== 'A') return t.Util._box_err('#VALUE!');
      if (r[1].t === 'e') return r[1];
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e' && r[1].t !== 'y') return t.Util._box_err('#VALUE!');
      var n = r[1].t === 'y' ? 0 : (a.v - 1) | 0;
      var i = -1 | 0;
      if (r[2]) {
        if (r[2].t === 'e') return r[2];
        var u = t.Util._cast_num(r[2]);
        if (u && u.t === 'e' && r[2].t !== 'y') return t.Util._box_err('#VALUE!');
        i = (((u && u.v) || 0) - 1) | 0;
      }
      if (n >= 0 && i >= 0) {
        var v = e.v[n];
        return (v && v[i]) || t.Util._box_err('#N/A');
      }
      if (i < 0 && e.v.length === 1) {
        if (e.v[0][n]) return e.v[0][n];
        if (r[1].t === 'z' && r[1].a) {
          n = t.Util.decode_cell(r[1].a).c;
          if (e.v[0][n]) return e.v[0][n];
        }
        return e.v[0][n] || t.Util._box_err('#N/A');
      }
      var c = { t: 'A', v: [], a: '' };
      if (i < 0) {
        var v = e.v[n];
        if (!v) return t.Util._box_err('#REF!');
        c.v[0] = v;
        return c;
      }
      if (n < 0) {
        c.v = e.v.map(function (t) {
          return [t[i]];
        });
        return c;
      }
      return t.Util._box_err('#REF!');
    }
    r.INDEX = ca;
    function fa(r) {
      var e = t.Util._cast_val(r[0]);
      if (e.t === 'z') return t.Util._box_err('#N/A');
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = r[1];
      if (a.t !== 'A') return t.Util._box_err('#VALUE!');
      var n = r[2] || a;
      if (n.t !== 'A') return t.Util._box_err('#VALUE!');
      var i = -1;
      var u = -1;
      var v;
      a.v.forEach(function (t, r) {
        t.forEach(function (t, a) {
          if (e.t !== t.t) return;
          if (e.v >= t.v) {
            if (i === -1 || t.v >= v) {
              i = r;
              u = a;
              v = (n.v[i][u] || { t: 'n', v: 0 }).v;
            }
          }
        });
      });
      return i > -1 ? { t: e.t, v: v } : t.Util._box_err('#N/A');
    }
    r.LOOKUP = fa;
    var _a = function (r, e, a, n) {
      var i = e.ws;
      var u = Array.isArray(i);
      var v = t.Util.safe_decode_range(e.a);
      if (v.s.r < 0 || isNaN(v.s.r)) {
        var c = t.Util.safe_decode_range(i['!ref']);
        v.s.r = c.s.r;
      }
      if (v.s.c < 0 || isNaN(v.s.c)) {
        var c = t.Util.safe_decode_range(i['!ref']);
        v.s.c = c.s.c;
      }
      if (v.e.r < 0 || isNaN(v.e.r)) {
        var c = t.Util.safe_decode_range(i['!ref']);
        v.e.r = c.e.r;
      }
      if (v.e.c < 0 || isNaN(v.e.c)) {
        var c = t.Util.safe_decode_range(i['!ref']);
        v.e.c = c.e.c;
      }
      var f = t.Util.encode_col(v.s.c);
      var _ = v.s.r + 1;
      var s = -1;
      var o = false;
      var l = f + (s + _);
      var U = r.t === 's' ? '' : 0;
      for (var h = 0, b = v.e.r - v.s.r; h <= b; ++h) {
        if (o) break;
        var m = u ? i[h + _ - 1][v.s.c] : i[f + (h + _)];
        if (!m) continue;
        if (!n) {
          if (s === -1 && m.t === r.t && m.v === r.v) s = h;
        } else {
          if (s === -1) s = h;
          else if (m.t === r.t)
            switch (r.t) {
              case 'n':
                if (m.v <= r.v) {
                  s = h;
                  if (u) {
                    U = i[s + _ - 1][v.s.c].v;
                  } else {
                    l = f + (s + _);
                    U = i[l].v;
                  }
                  if (m.v === r.v) o = true;
                }
                break;
              case 's':
                var E = (m.v || '').toLowerCase();
                if (E <= (r.v || '').toLowerCase() && E > U) {
                  s = h;
                  if (u) {
                    U = String(i[s + _ - 1][v.s.c].v).toLowerCase();
                  } else {
                    l = f + (s + _);
                    U = String(i[l].v).toLowerCase();
                  }
                  if ((m.v || '').toLowerCase() === (r.v || '').toLowerCase()) o = true;
                }
                break;
            }
        }
      }
      if (s === -1) return t.Util._box_err('#N/A');
      if (u) {
        var p = e.ws[v.s.r + s];
        if (p && p[v.s.c + a - 1]) return p[v.s.c + a - 1];
      } else {
        var A = t.Util.encode_cell({ r: v.s.r + s, c: v.s.c + a - 1 });
        if (e.ws[A]) return e.ws[A];
      }
      var x = t.Util.safe_decode_range(e.ws['!ref']);
      if (x.e.r >= v.s.r + s && x.e.c >= v.s.c + a - 1) return { t: 'z' };
      return t.Util._box_err('#REF!');
    };
    function sa(r) {
      switch (r[0].t) {
        case 'z':
          return sa([t.Util._box_num(0)].concat(r.slice(1)));
        case 'y':
          return t.Util._box_err('#N/A');
      }
      var e = t.Util._cast_val(r[0]);
      if (e.t === 'e') return e;
      var a = r[1];
      if (a.t === 'y') return t.Util._box_err('#N/A');
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = !(e.t === 'b');
      if (r.length >= 4) {
        var u = t.Util._cast_bool(r[3]);
        if (u.t === 'e') return t.Util._box_err('#VALUE!');
        i = u.v;
      }
      if (e.t === 'b') i = false;
      var v = -1;
      var c = false;
      switch (a.t) {
        case 'A':
          if (a.deferred) return _a(e, a, n.v, i);
          a.v.forEach(function (t, r) {
            if (c) return;
            if (!i) {
              if (v === -1 && t[0].t === e.t && t[0].v === e.v) v = r;
            } else {
              if (v === -1) v = r;
              else if (t[0].t === e.t)
                switch (e.t) {
                  case 'n':
                    if (t[0].v <= e.v) {
                      v = r;
                      if (t[0].v === e.v) c = true;
                    }
                    break;
                  case 's':
                    if (t[0].v <= e.v && t[0].v > a.v[v][0].v) {
                      v = r;
                      if (t[0].v === e.v) c = true;
                    }
                    break;
                }
            }
          });
          if (v === -1) return t.Util._box_err('#N/A');
          if (a.deferred) {
            var f = t.XLSXLib.utils.decode_range(a.a);
            var _ = a.ws;
            return (
              (Array.isArray(_) ? _[f.s.r + v][f.s.c + n.v - 1] : _[t.Util.encode_cell({ r: f.s.r + v, c: f.s.c + n.v - 1 })]) ||
              t.Util._box_err('#REF!')
            );
          }
          return a.v[v][n.v - 1] || t.Util._box_err('#REF!');
        default:
          return t.Util._box_err('#VALUE!');
      }
    }
    r.VLOOKUP = sa;
    function oa(r) {
      var e = t.Util._cast_val(r[0]);
      if (e.t === 'z') return t.Util._box_err('#N/A');
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = r[1];
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = e.t === 'b' ? false : true;
      if (r.length >= 4) {
        var u = t.Util._cast_bool(r[3]);
        if (u.t === 'e') return t.Util._box_err('#VALUE!');
        i = u.v;
      }
      if (e.t === 'b') i = false;
      var v = -1;
      var c = false;
      switch (a.t) {
        case 'A':
          if (a.v.length <= n.v - 1) return t.Util._box_err('#N/A');
          a.v[0].forEach(function (t, r) {
            if (c) return;
            if (!i) {
              if (v === -1 && t.t === e.t && t.v === e.v) v = r;
            } else {
              if (v === -1) v = r;
              else if (t.t === e.t)
                switch (e.t) {
                  case 'n':
                    if (t.v <= e.v) {
                      v = r;
                      if (t.v === e.v) c = true;
                    }
                    break;
                  case 's':
                    if (t.v <= e.v && t.v > a.v[0][v].v) {
                      v = r;
                      if (t.v === e.v) c = true;
                    }
                    break;
                }
            }
          });
          return v > -1 ? a.v[n.v - 1][v] : t.Util._box_err('#N/A');
        default:
          return t.Util._box_err('#VALUE!');
      }
    }
    r.HLOOKUP = oa;
    function la(r) {
      var e = t.Util._cast_val(r[0]);
      if (e.t === 'z') return t.Util._box_err('#N/A');
      if (e.t === 'e') return e;
      var a = r[1];
      var n = 1;
      if (r.length >= 3) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v | 0;
        if (n > 1 || n < -1) return t.Util._box_err('#VALUE!');
      }
      var u = -1;
      switch (a.t) {
        case 'A':
          var v = a.v;
          if (
            v.length > 1 &&
            v.reduce(function (t, r) {
              return Math.max(t, r.length);
            }, 0) > 1
          )
            return t.Util._box_err('#N/A');
          var c =
            v.length === 1
              ? v[0]
              : v.map(function (t) {
                  return t[0];
                });
          for (var f = 0; f < c.length; ++f) {
            switch (n) {
              case 1:
                if (c[f].t !== e.t) break;
                if (c[f].v === e.v) return t.Util._box_num(f + 1);
                if (c[f].v < e.v && (u === -1 || c[f].v > c[u].v)) u = f;
                break;
              case 0:
                if (c[f].v === e.v && c[f].t === e.t) return t.Util._box_num(f + 1);
                break;
              case -1:
                if (c[f].t !== e.t) break;
                if (c[f].v === e.v) return t.Util._box_num(f + 1);
                if (c[f].v > e.v && (u === -1 || c[f].v < c[u].v)) u = f;
                break;
            }
          }
          return u > -1 ? t.Util._box_num(u + 1) : t.Util._box_err('#N/A');
        default:
          return t.Util._box_err('#VALUE!');
      }
    }
    r.MATCH = la;
    function Ua(r, e, a, n) {
      if (r.length === 0) return t.Util._box_num(n.c + 1);
      var i = r[0];
      if (i.t === 'U') return t.Util._box_err('#NULL!');
      if (i.t === 'e' || i.t === 'y') return t.Util._box_err('#NULL!');
      if (!i.a) throw 'COLUMN: Argument is not a reference';
      return t.Util._box_num(t.XLSXLib.utils.decode_cell(i.a).c + 1);
    }
    r.COLUMN = Ua;
    function ha(r, e, a, n) {
      if (r.length === 0) return t.Util._box_num(n.r + 1);
      var i = r[0];
      if (i.t === 'U') return t.Util._box_err('#NULL!');
      if (i.t === 'e' || i.t === 'y') return t.Util._box_err('#NULL!');
      if (!i.a) throw 'ROW: Argument is not a reference';
      var u = t.XLSXLib.utils.decode_range(i.a);
      if (u.s.r === u.e.r) return t.Util._box_num(u.s.r + 1);
      var v = [];
      for (var c = u.s.r; c <= u.e.r; ++c) v.push([c + 1]);
      return t.Util._box_aoa(v);
    }
    r.ROW = ha;
    function ba(r, e, a, n) {
      var i = t.Util._cast_num(r[0]);
      var u = t.Util._cast_num(r[1]);
      var v = 1;
      var c = true;
      var f = '';
      if (i.t === 'e' || u.t === 'e') return t.Util._box_err('#VALUE!');
      if (r.length > 2 && r[2].t !== 'y') {
        var _ = t.Util._cast_num(r[2]);
        if (_.t === 'e') return t.Util._box_err('#VALUE!');
        v = _.v | 0;
        if (v < 1 || v > 4) return t.Util._box_err('#VALUE!');
      }
      if (r.length > 3 && r[3].t !== 'y') {
        var s = t.Util._cast_bool(r[3]);
        if (s.t === 'e') return t.Util._box_err('#VALUE!');
        c = s.v;
      }
      if (r.length > 4 && r[4].t !== 'y') {
        var o = t.Util._cast_str(r[4]);
        if (o.t === 'e') return t.Util._box_err('#VALUE!');
        f = o.v;
      }
      var l = f ? f + '!' : '';
      if (c) {
        if ((v & 1) === 1) l += '$';
        l += t.XLSXLib.utils.encode_col(u.v - 1);
        if (v <= 2) l += '$';
        l += t.XLSXLib.utils.encode_row(i.v - 1);
      } else {
      }
      return t.Util._box_str(l);
    }
    r.ADDRESS = ba;
    function ma(r) {
      var e = r[0];
      switch (e.t) {
        case 'U':
          return t.Util._box_num(e.v.length);
        case 'A':
          return t.Util._box_num(1);
        default:
          if (e.a) return t.Util._box_num(1);
          if (e.a === '') return t.Util._box_num(0);
          throw 'AREAS expects a range union ' + e.t;
      }
    }
    r.AREAS = ma;
    function Ea(r) {
      var e = r[0];
      switch (e.t) {
        case 'e':
          return e.a ? t.Util._box_num(1) : e;
        case 'n':
        case 'z':
        case 'b':
        case 's':
          return e.a ? t.Util._box_num(1) : t.Util._box_err('#VALUE!');
        case 'A':
          if (e.a) {
            var a = t.XLSXLib.utils.decode_range(e.a);
            return t.Util._box_num(a.e.c - a.s.c + 1);
          } else {
            return t.Util._box_num(
              Math.max.apply(
                null,
                e.v.map(function (t) {
                  return t.length;
                }),
              ),
            );
          }
        case 'U':
          return t.Util._box_err('#REF!');
        default:
          return t.Util._box_err('#REF!');
      }
    }
    r.COLUMNS = Ea;
    function pa(r) {
      var e = r[0];
      switch (e.t) {
        case 'e':
          return e;
        case 'n':
          return t.Util._box_num(1);
        case 'b':
        case 's':
          return t.Util._box_err('#VALUE!');
        case 'A':
          if (e.a) {
            var a = t.XLSXLib.utils.decode_range(e.a);
            return t.Util._box_num(a.e.r - a.s.r + 1);
          } else {
            return t.Util._box_num(e.v.length);
          }
        case 'U':
          return t.Util._box_err('#REF!');
        default:
          return t.Util._box_err('#REF!');
      }
    }
    r.ROWS = pa;
    function Aa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return r[a | 0];
    }
    r.CHOOSE = Aa;
    function xa(t) {
      switch (t[0].t) {
        case 'A':
          var r = { t: 'A', v: [], a: '' };
          var e = t[0].v;
          e.forEach(function (t, e) {
            t.forEach(function (t, a) {
              if (!r.v[a]) r.v[a] = [];
              r.v[a][e] = t;
            });
          });
          return r;
        default:
          return t[0];
      }
    }
    r.TRANSPOSE = xa;
    function Sa(r, e, a, n) {
      var i = t.State.RandFunc ? t.State.RandFunc(a, n) : Math.random();
      return t.Util._box_num(i);
    }
    r.RAND = Sa;
    function da(r, e, a, n) {
      var i = t.Util._cast_num(r[0]);
      if (i.t === 'e') return t.Util._box_err('#VALUE!');
      var u = i.v | 0;
      var v = t.Util._cast_num(r[1]);
      if (v.t === 'e') return t.Util._box_err('#VALUE!');
      var c = v.v | 0;
      var f = t.State.RandFunc ? t.State.RandFunc(a, n) : Math.random();
      return t.Util._box_num((f * (c - u + 1) + u) | 0);
    }
    r.RANDBETWEEN = da;
    function Ma(r, e, a, n) {
      var i = [];
      var u = 1;
      var v = 1;
      var c = 0;
      var f = 1;
      var _ = false;
      if (r[0]) {
        var s = t.Util._cast_num(r[0]);
        if (s.t === 'e') return t.Util._box_err('#VALUE!');
        u = s.v | 0;
        if (u <= 0) return t.Util._box_err('#VALUE!');
      }
      if (r[1]) {
        var o = t.Util._cast_num(r[1]);
        if (o.t === 'e') return t.Util._box_err('#VALUE!');
        v = o.v | 0;
        if (v <= 0) return t.Util._box_err('#VALUE!');
      }
      if (r[2] || r[3]) {
        var l = t.Util._cast_num(r[2]);
        if (l.t === 'e') return t.Util._box_err('#VALUE!');
        c = l.v;
        var U = t.Util._cast_num(r[3]);
        if (U.t === 'e') return t.Util._box_err('#VALUE!');
        f = U.v;
        if (c > f) return t.Util._box_err('#VALUE!');
      }
      if (r[4]) {
        var h = t.Util._cast_bool(r[4]);
        if (h.t === 'e') return t.Util._box_err('#VALUE!');
        _ = h.v;
      }
      for (var b = 0; b < u; ++b) {
        i[b] = [];
        for (var m = 0; m < v; ++m) {
          var E = t.State.RandFunc ? t.State.RandFunc(a, n) : Math.random();
          i[b][m] = _ ? (E * (f - c + 1) + c) | 0 : E * (f - c) + c;
        }
      }
      return t.Util._box_aoa(i);
    }
    r.RANDARRAY = Ma;
    function Na(r, e, a, n) {
      var i = [];
      var u = 1;
      var v = 1;
      var c = 1;
      var f = 1;
      if (r[0] && r[0].t !== 'y') {
        var _ = t.Util._cast_num(r[0]);
        if (_.t === 'e') return t.Util._box_err('#VALUE!');
        u = _.v | 0;
        if (u <= 0) return t.Util._box_err('#VALUE!');
      }
      if (r[1] && r[1].t !== 'y') {
        var s = t.Util._cast_num(r[1]);
        if (s.t === 'e') return t.Util._box_err('#VALUE!');
        v = s.v | 0;
        if (v <= 0) return t.Util._box_err('#VALUE!');
      }
      if (r[2] && r[2].t !== 'y') {
        var o = t.Util._cast_num(r[2]);
        if (o.t === 'e') return t.Util._box_err('#VALUE!');
        c = o.v;
      }
      if (r[3] && r[3].t !== 'y') {
        var l = t.Util._cast_num(r[3]);
        if (l.t === 'e') return t.Util._box_err('#VALUE!');
        f = l.v;
      }
      var U = c - f;
      for (var h = 0; h < u; ++h) {
        i[h] = [];
        for (var b = 0; b < v; ++b) {
          i[h][b] = U += f;
        }
      }
      return t.Util._box_aoa(i);
    }
    r.SEQUENCE = Na;
    function ga(r) {
      var e = r[0];
      switch (e.t) {
        case 'n':
          return t.Util._box_num(e.v);
        case 'A':
          var a = [];
          for (var n = 0; n < e.v.length; ++n) {
            a[n] = [];
            for (var i = 0; i < e.v[n].length; ++i) {
              switch (e.v[n][i].t) {
                case 'n':
                  a[n][i] = e.v[n][i].v;
                  break;
                default:
                  return t.Util._box_err('#VALUE!');
              }
            }
          }
          var u = a.length;
          for (var v = 0; v < u; ++v) if (u !== a[v].length) return t.Util._box_err('#VALUE!');
          return t.Util._box_num(t.Spec.MDETERM(a));
      }
      return t.Util._box_err('#VALUE!');
    }
    r.MDETERM = ga;
    function La(r) {
      var e = r[0];
      switch (e.t) {
        case 'n':
          return t.Util._box_num(e.v);
        case 'A':
          var a = [];
          for (var n = 0; n < e.v.length; ++n) {
            a[n] = [];
            for (var i = 0; i < e.v[n].length; ++i) {
              switch (e.v[n][i].t) {
                case 'n':
                  a[n][i] = e.v[n][i].v;
                  break;
                default:
                  return t.Util._box_err('#VALUE!');
              }
            }
          }
          var u = a.length;
          for (var v = 0; v < u; ++v) if (u !== a[v].length) return t.Util._box_err('#VALUE!');
          return t.Util._box_aoa(t.Spec.MINVERSE(a));
      }
      return t.Util._box_err('#NUM!');
    }
    r.MINVERSE = La;
    function Ia(r) {
      var e = r[0];
      switch (e.t) {
        case 'n':
          return t.Util._box_num(e.v);
        case 'A':
          var a = t.Util._cast_num_aoa(r[0]);
          var n = t.Util._cast_num_aoa(r[1]);
          if (a.t === 'e') return a;
          if (n.t === 'e') return n;
          if (t.Util._aoa_is_jagged(a)) return t.Util._box_err('#VALUE!');
          if (t.Util._aoa_is_jagged(n)) return t.Util._box_err('#VALUE!');
          if (a[0].length !== n.length) return t.Util._box_err('#VALUE!');
          return t.Util._box_aoa(t.Spec.MMULT(a, n));
      }
      return t.Util._box_err('#NUM!');
    }
    r.MMULT = Ia;
    function ka(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v | 0;
      if (a <= 0 || a > 73) return t.Util._box_err('#VALUE!');
      var n = { t: 'A', v: [[]], a: '' };
      for (var i = 0; i < a; ++i) {
        n.v[i] = [];
        for (var u = 0; u < a; ++u) n.v[i][u] = { t: 'n', v: i === u ? 1 : 0 };
      }
      return n;
    }
    r.MUNIT = ka;
    function Ta(t) {
      return { t: 'n', v: Math.PI };
    }
    r.PI = Ta;
    function wa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      return t.Util._box_num((e.v * 180) / Math.PI);
    }
    r.DEGREES = wa;
    function ya(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      return t.Util._box_num((e.v * Math.PI) / 180);
    }
    r.RADIANS = ya;
    function Oa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.sqrt(Math.PI * a));
    }
    r.SQRTPI = Oa;
    function Da(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.acos(a));
    }
    r.ACOS = Da;
    function Ca(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.atan(1 / a));
    }
    r.ACOT = Ca;
    function Ra(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.asin(a));
    }
    r.ASIN = Ra;
    function Va(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.atan(a));
    }
    r.ATAN = Va;
    function Fa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      if (a.v === 0 && e.v === 0) return t.Util._box_err('#DIV/0!');
      return t.Util._box_num(Math.atan2(a.v, e.v));
    }
    r.ATAN2 = Fa;
    function Pa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.cos(a));
    }
    r.COS = Pa;
    function Xa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(1 / Math.tan(a));
    }
    r.COT = Xa;
    function Ba(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(1 / Math.sin(a));
    }
    r.CSC = Ba;
    function Ga(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(1 / Math.cos(a));
    }
    r.SEC = Ga;
    function Ha(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.sin(a));
    }
    r.SIN = Ha;
    function za(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.tan(a));
    }
    r.TAN = za;
    function qa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = e.v;
      return t.Util._box_num(s(a));
    }
    r.ACOSH = qa;
    function $a(r) {
      if (r[0].t === 'e') return r[0];
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      if (Math.abs(e.v) <= 1) return t.Util._box_err('#NUM!');
      var a = e.v;
      return t.Util._box_num(l(a));
    }
    r.ACOTH = $a;
    function Wa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = e.v;
      return t.Util._box_num(h(a));
    }
    r.ACSCH = Wa;
    function Ya(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(U(a));
    }
    r.ASECH = Ya;
    function Ka(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(_(a));
    }
    r.ASINH = Ka;
    function Za(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(o(a));
    }
    r.ATANH = Za;
    function Qa(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(n(a));
    }
    r.COSH = Qa;
    function Ja(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(v(a));
    }
    r.COTH = Ja;
    function ja(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(c(a));
    }
    r.CSCH = ja;
    function tn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(f(a));
    }
    r.SECH = tn;
    function rn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(i(a));
    }
    r.SINH = rn;
    function en(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(u(a));
    }
    r.TANH = en;
    function an(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v >= 0 ? 1 : -1;
      var n = Math.ceil(e.v * a);
      if ((n & 1) === 1) ++n;
      return t.Util._box_num(n * a);
    }
    r.EVEN = an;
    function nn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = Math.ceil(e.v);
      if ((a & 1) === 0) ++a;
      return t.Util._box_num(a);
    }
    r.ODD = nn;
    function un(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      return t.Util._box_num(Math.floor(e.v));
    }
    r.INT = un;
    function vn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = e.v;
      var i = Math.pow(10, a.v);
      n = Math.round(n * i) / i;
      return t.Util._box_num(n);
    }
    r.ROUND = vn;
    function cn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      return t.Util._box_num(Math.round(e.v / a.v) * a.v);
    }
    r.MROUND = cn;
    function fn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 0;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return e;
        a = n.v | 0;
      }
      var i = Math.abs(e.v);
      var u = Math.pow(10, a);
      i = ((e.v >= 0 ? 1 : -1) * Math.floor(i * u)) / u;
      return t.Util._box_num(i);
    }
    r.TRUNC = fn;
    r.ROUNDDOWN = fn;
    function _n(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 0;
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e') return e;
      a = n.v | 0;
      var i = Math.abs(e.v);
      var u = Math.pow(10, a);
      i = ((e.v >= 0 ? 1 : -1) * Math.ceil(i * u)) / u;
      return t.Util._box_num(i);
    }
    r.ROUNDUP = _n;
    function sn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return { t: 'e', v: 15 };
        a = n.v;
        if (a === 0) return { t: 'e', v: 7 };
        if (e.v > 0 && a < 0) return { t: 'e', v: 36 };
      }
      return t.Util._box_num(Math.floor(e.v / a) * a);
    }
    r.FLOOR = sn;
    function on(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return { t: 'e', v: 15 };
        a = n.v;
        if (a === 0) return { t: 'e', v: 7 };
        if (e.v > 0 && a < 0) return { t: 'e', v: 36 };
      }
      return t.Util._box_num(a === 0 ? 0 : Math.floor(e.v / a) * a);
    }
    r.FLOOR_DOT_PRECISE = on;
    function ln(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return n;
        a = n.v;
      }
      return t.Util._box_num(Math.ceil(e.v / a) * a);
    }
    r.CEILING = ln;
    function Un(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return e;
        a = n.v;
      }
      return t.Util._box_num(a === 0 ? 0 : Math.ceil(e.v / a) * a);
    }
    r.CEILING_DOT_PRECISE = Un;
    r.ECMA_DOT_CEILING = Un;
    r.ISO_DOT_CEILING = Un;
    function hn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 1;
      if (r.length > 1) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return e;
        a = n.v;
      }
      var i = 0;
      if (r.length > 2) {
        if (r[2].t === 'e') return r[2];
        if (r[2].t !== 'y') {
          var u = t.Util._cast_num(r[2]);
          if (u.t === 'n') i = u.v;
        }
      }
      if (i) return t.Util._box_num((e.v < 0 ? -1 : 1) * Math.ceil(Math.abs(e.v) / a) * a);
      return t.Util._box_num(Math.ceil(e.v / a) * a);
    }
    r.CEILING_DOT_MATH = hn;
    r.FLOOR_DOT_MATH = sn;
    function bn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0 || a >= 171) return { t: 'e', v: 36 };
      a |= 0;
      if (a === 0) a = 1;
      for (var n = a - 1; n > 0; --n) a *= n;
      return t.Util._box_num(a);
    }
    r.FACT = bn;
    function mn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0 || a >= 301) return { t: 'e', v: 36 };
      a |= 0;
      for (var n = a - 2; n > 0; n -= 2) a *= n;
      return t.Util._box_num(a);
    }
    r.FACTDOUBLE = mn;
    function En(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0 || e.v < a.v) return t.Util._box_err('#NUM!');
      var n = t.Spec.n_choose_k(e.v, a.v);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.COMBIN = En;
    function pn(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0 || e.v < a.v) return t.Util._box_err('#NUM!');
      var n = t.Spec.n_choose_k(e.v + a.v - 1, e.v - 1);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.COMBINA = pn;
    function An(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || a.v < 0 || e.v < a.v) return { t: 'e', v: 36 };
      var n = 1;
      for (var i = 1; i <= a.v; ++i) n *= e.v + 1 - i;
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.PERMUT = An;
    function xn(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = Math.pow(e.v, a.v);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.PERMUTATIONA = xn;
    function Sn(r) {
      var e = 0;
      var a = 1;
      for (var n = 0; n < r.length; ++n) {
        switch (r[n].t) {
          case 'n':
            var i = r[n].v | 0;
            if (i < 0) return { t: 'e', v: 36 };
            e += i;
            while (i > 0) a *= i--;
            break;
          default:
            return { t: 'e', v: 15 };
        }
      }
      a = 1 / a;
      while (e > 0) {
        a *= e;
        --e;
      }
      return t.Util._box_num(a);
    }
    r.MULTINOMIAL = Sn;
    function dn(t, r) {
      return r ? dn(r, t % r) : Math.abs(t);
    }
    function Mn(r) {
      if (r.length > 2) return Mn([r[0], Mn(r.slice(1))]);
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0 || Math.max(e.v, a.v) >= Math.pow(2, 53)) return t.Util._box_err('#NUM!');
      var n = dn(e.v, a.v);
      if (!isFinite(n) || n === 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.GCD = Mn;
    function Nn(r) {
      if (r.length > 2) return Nn([r[0], Nn(r.slice(1))]);
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0 || Math.max(e.v, a.v) >= Math.pow(2, 53)) return t.Util._box_err('#NUM!');
      var n = (e.v * a.v) / dn(e.v, a.v);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.LCM = Nn;
    function gn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.log(a));
    }
    r.LN = gn;
    function Ln(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = 10;
      if (r[1]) {
        var n = t.Util._cast_num(r[1]);
        if (n.t === 'e') return n;
        a = n.v;
      }
      var i = e.v;
      if (i < 0 || a < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.log(i) / Math.log(a));
    }
    r.LOG = Ln;
    function In(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.log(a) * Math.LOG10E);
    }
    r.LOG10 = In;
    function kn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.abs(a));
    }
    r.ABS = kn;
    function Tn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      return t.Util._box_num(Math.exp(a));
    }
    r.EXP = Tn;
    function wn(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (a.v === 0) return t.Util._box_err('#DIV/0!');
      var n = e.v - a.v * Math.floor(e.v / a.v);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.MOD = wn;
    function yn(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = Math.pow(e.v, a.v);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.POWER = yn;
    function On(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (a.v === 0) return t.Util._box_err('#DIV/0!');
      var n = Math.floor(Math.abs(e.v / a.v)) * (e.v >= 0 ? 1 : -1) * (e.v >= 0 ? 1 : -1);
      if (!isFinite(n)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(n);
    }
    r.QUOTIENT = On;
    function Dn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      return t.Util._box_num(e.v === 0 ? 0 : e.v > 0 ? 1 : -1);
    }
    r.SIGN = Dn;
    function Cn(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = e.v;
      if (a < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.sqrt(a));
    }
    r.SQRT = Cn;
    function Rn(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = a.v | 0;
      var i = Math.floor(e.v);
      if (i < 0 || i >= Math.pow(2, 53) || n < 2 || n > 36) return { t: 'e', v: 36 };
      var u = 0;
      if (r.length >= 3) {
        var v = t.Util._cast_num(r[2]);
        if (v.t === 'e') return { t: 'e', v: 15 };
        u = v.v | 0;
        if (u < 0 || u > 255) return { t: 'e', v: 36 };
      }
      var c = i.toString(n);
      while (c.length < u) c = '0' + c;
      return t.Util._box_str(c);
    }
    r.BASE = Rn;
    function Vn(r) {
      var e = r[0];
      var a = '';
      switch (e.t) {
        case 's':
          a = e.v;
          break;
        case 'n':
          a = e.v.toString(10);
          break;
        case 'e':
          return e;
        default:
          return { t: 'e', v: 36 };
      }
      if (a.length > 10) return { t: 'e', v: 36 };
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e') return { t: 'e', v: 36 };
      var i = parseInt(a, n.v);
      if (!isFinite(i)) return { t: 'e', v: 36 };
      return t.Util._box_num(i);
    }
    r.DECIMAL = Vn;
    function Fn(r) {
      switch (r[0].t) {
        case 'z':
        case 'y':
          return t.Util._box_num(1);
        case 'A':
          break;
        default:
          return t.Util._box_num(0);
      }
      var e = 0;
      r[0].v.forEach(function (t) {
        return t.forEach(function (t) {
          switch (t.t) {
            case 'z':
              ++e;
              break;
          }
        });
      });
      return t.Util._box_num(e);
    }
    r.COUNTBLANK = Fn;
    function Pn(r) {
      var e = 0;
      for (var a = 0; a < r.length; ++a) {
        switch (r[a].t) {
          case 'A':
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                try {
                  var a = t.Util._cast_num(r);
                  if (a.t !== 'e' && r.t !== 'z' && r.t !== 'b') e++;
                } catch (t) {}
              });
            });
            break;
          default:
            try {
              var n = t.Util._cast_num(r[a]);
              if (n.t !== 'e' && r[a].t !== 'z' && r[a].t !== 'b') e++;
            } catch (t) {}
        }
      }
      return t.Util._box_num(e);
    }
    r.COUNT = Pn;
    function Xn(r) {
      var e = 0;
      for (var a = 0; a < r.length; ++a) {
        switch (r[a].t) {
          case 'A':
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                try {
                  var a = t.Util._cast_val(r);
                  if (a.t !== 'e') e++;
                } catch (t) {}
              });
            });
            break;
          default:
            try {
              var n = t.Util._cast_val(r[a]);
              if (n.t !== 'e') e++;
            } catch (t) {}
        }
      }
      return t.Util._box_num(e);
    }
    r.COUNTA = Xn;
    function Bn(r) {
      var e = 0;
      var a;
      for (var n = 0; n < r.length; ++n) {
        switch (r[n].t) {
          case 'A':
            r[n].v.forEach(function (r) {
              r.forEach(function (r) {
                if (r.t === 'e' && !a) a = r;
                if (r.t === 'n') e += r.v;
                else {
                  var n = t.Util._cast_num(r);
                  if (n.t === 'n' && !isNaN(n.v)) e += n.v;
                }
              });
            });
            if (a) return a;
            break;
          case 'e':
            return r[n];
          default:
            var i = t.Util._cast_num(r[n]);
            if (i.t === 'n' && !isNaN(i.v)) e += i.v;
        }
      }
      return t.Util._box_num(e);
    }
    r.SUM = Bn;
    function Gn(r) {
      var e = 1;
      for (var a = 0; a < r.length; ++a) {
        switch (r[a].t) {
          case 'A':
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                var a = t.Util._cast_num(r);
                if (a.t === 'n' && !isNaN(a.v)) e *= a.v;
              });
            });
            break;
          default:
            var n = t.Util._cast_num(r[a]);
            if (n.t === 'n' && !isNaN(n.v)) e *= n.v;
        }
      }
      return t.Util._box_num(e);
    }
    r.PRODUCT = Gn;
    function Hn(r) {
      var e = 0;
      for (var a = 0; a < r.length; ++a) {
        switch (r[a].t) {
          case 'A':
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                var a = t.Util._cast_num(r);
                if (a.t === 'n' && !isNaN(a.v)) e += a.v * a.v;
              });
            });
            break;
          default:
            var n = t.Util._cast_num(r[a]);
            if (n.t === 'n' && !isNaN(n.v)) e += n.v * n.v;
        }
      }
      return t.Util._box_num(e);
    }
    r.SUMSQ = Hn;
    function zn(r) {
      var e = 0;
      var a = t.Util._cast_num_arr(r[0]);
      if (a.t === 'e') return t.Util._box_err('#REF!');
      var n = t.Util._cast_num_arr(r[1]);
      if (n.t === 'e') return t.Util._box_err('#REF!');
      if (a.length !== n.length) return t.Util._box_err('#VALUE!');
      for (var i = 0; i < a.length; ++i) {
        var u = a[i] - n[i];
        e += u * u;
      }
      return t.Util._box_num(e);
    }
    r.SUMXMY2 = zn;
    function qn(r) {
      var e = 0;
      var a = t.Util._cast_num_arr(r[0]);
      if (a.t === 'e') return t.Util._box_err('#REF!');
      var n = t.Util._cast_num_arr(r[1]);
      if (n.t === 'e') return t.Util._box_err('#REF!');
      if (a.length !== n.length) return t.Util._box_err('#VALUE!');
      for (var i = 0; i < a.length; ++i) {
        var u = a[i] * a[i] - n[i] * n[i];
        e += u;
      }
      return t.Util._box_num(e);
    }
    r.SUMX2MY2 = qn;
    function $n(r) {
      var e = 0;
      var a = t.Util._cast_num_arr(r[0]);
      if (a.t === 'e') return t.Util._box_err('#REF!');
      var n = t.Util._cast_num_arr(r[1]);
      if (n.t === 'e') return t.Util._box_err('#REF!');
      if (a.length !== n.length) return t.Util._box_err('#VALUE!');
      for (var i = 0; i < a.length; ++i) {
        var u = a[i] * a[i] + n[i] * n[i];
        e += u;
      }
      return t.Util._box_num(e);
    }
    r.SUMX2PY2 = $n;
    function Wn(r) {
      var e = 0;
      var a = [];
      var n = [];
      for (var i = 0; i < r.length; ++i) {
        var u = t.Util._cast_num_aoa(r[i], true);
        if (!Array.isArray(u)) return u.t === 'e' ? u : t.Util._box_err('#VALUE!');
        a.push(u);
        n[i] = !!r[i].x;
      }
      for (var i = 1; i < a.length; ++i) {
        if (n[i] && n[0]) continue;
        if (n[i] || n[0]) return t.Util._box_err('#VALUE!');
        if (a[0].length !== a[i].length) return t.Util._box_err('#VALUE!');
        for (var v = 0; v < a[0].length; ++v) if (a[0][v].length !== a[i][v].length) return t.Util._box_err('#VALUE!');
      }
      for (var i = 0; i < a[0].length; ++i) {
        for (var v = 0; v < a[0][i].length; ++v) {
          var c = 1;
          for (var f = 0; f < a.length; ++f) c *= a[f][i][v];
          e += c;
        }
      }
      return t.Util._box_num(e);
    }
    r.SUMPRODUCT = Wn;
    function Yn(r, e, a, n) {
      var i = 0;
      try {
        var u = t.Util._cast_num(r[0]);
        if (u.t === 'n') i = u.v | 0;
        if (!((i >= 1 && i <= 11) || (i >= 101 && i <= 111))) throw 'wafuq1';
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
      var v = i < 100;
      if (!v) i -= 100;
      var c = 0;
      var f = 0;
      var _ = 0;
      var s = 0;
      var o = 1;
      var l = 0;
      var U = Infinity;
      var h = -Infinity;
      var b = e.Sheets[e.SheetNames[a]];
      r.slice(1).forEach(function (r) {
        switch (r.t) {
          case 'n':
            ++_;
            ++c;
            ++f;
            s += r.v;
            o *= r.v;
            l += r.v * r.v;
            U = Math.min(U, r.v);
            h = Math.max(h, r.v);
            return;
          case 'A':
            break;
          default:
            return;
        }
        var e;
        if (r.a) e = t.XLSXLib.utils.decode_range(r.a).s;
        r.v.forEach(function (t, r) {
          if (!v && e) {
            var a = e.r + r;
            if (b && b['!rows'] && b['!rows'][a] && !!b['!rows'][a].hidden) return;
          }
          t.forEach(function (t) {
            if (t.t === 'n') {
              ++_;
              ++c;
              ++f;
              s += t.v;
              o *= t.v;
              l += t.v * t.v;
              U = Math.min(U, t.v);
              h = Math.max(h, t.v);
            }
          });
        });
      });
      var m = NaN;
      switch (i) {
        case 1:
          m = s / _;
          break;
        case 2:
          m = c;
          break;
        case 3:
          m = f;
          break;
        case 4:
          m = h;
          break;
        case 5:
          m = U;
          break;
        case 6:
          m = o;
          break;
        case 7:
          m = Math.sqrt((l - (s * s) / _) / (_ - 1));
          break;
        case 8:
          m = Math.sqrt((l - (s * s) / _) / _);
          break;
        case 9:
          m = s;
          break;
        case 10:
          m = (l - (s * s) / _) / (_ - 1);
          break;
        case 11:
          m = (l - (s * s) / _) / _;
          break;
        default:
          return t.Util._box_err('#VALUE!');
      }
      return t.Util._box_num(m);
    }
    r.SUBTOTAL = Yn;
    function Kn(r) {
      var e = 0;
      var a = 0;
      try {
        var n = t.Util._cast_num(r[0]);
        if (n.t === 'n') e = n.v | 0;
        if (!(e >= 1 && e <= 19)) throw 'wafuq2';
        var i = t.Util._cast_num(r[1]);
        if (i.t === 'n') a = i.v | 0;
        if (!(a >= 0 && a <= 7)) throw 'wafuq3';
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
      var u = (a & 1) === 0;
      var v = (a & 2) === 0;
      var c = (a & 4) === 1;
      var f = [];
      var _ = 0;
      var s = 0;
      var o = 0;
      var l = 0;
      var U = 1;
      var h = 0;
      var b = Infinity;
      var m = -Infinity;
      var E = -1;
      if (e >= 14) {
        var p = t.Util._cast_num(r[3]);
        if (p.t === 'e') return t.Util._box_err('#VALUE!');
        E = p.v;
      }
      try {
        (e > 12 ? [r[2]] : r.slice(2)).forEach(function (t) {
          switch (t.t) {
            case 'A':
              t.v.forEach(function (t) {
                t.forEach(function (t) {
                  switch (t.t) {
                    case 'z':
                      break;
                    case 'e':
                      if (v) throw t.v;
                      break;
                    case 'n':
                      ++o;
                      ++_;
                      ++s;
                      l += t.v;
                      U *= t.v;
                      h += t.v * t.v;
                      b = Math.min(b, t.v);
                      m = Math.max(m, t.v);
                      if (e >= 12) f.push(t.v);
                  }
                });
              });
              break;
          }
        });
      } catch (r) {
        return t.Util._box_err(r || '#VALUE!');
      }
      var A = 0;
      if (e >= 12)
        f.sort(function (t, r) {
          return t - r;
        });
      switch (e) {
        case 1:
          A = l / o;
          break;
        case 2:
          A = _;
          break;
        case 3:
          A = s;
          break;
        case 4:
          A = m;
          break;
        case 5:
          A = b;
          break;
        case 6:
          A = U;
          break;
        case 7:
          A = Math.sqrt((h - (l * l) / o) / (o - 1));
          break;
        case 8:
          A = Math.sqrt((h - (l * l) / o) / o);
          break;
        case 9:
          A = l;
          break;
        case 10:
          A = (h - (l * l) / o) / (o - 1);
          break;
        case 11:
          A = (h - (l * l) / o) / o;
          break;
        case 12:
          A = f.length;
          A = !!(A & 1) ? f[A >> 1] : (f[A >> 1] + f[(A >> 1) - 1]) / 2;
          break;
        case 13:
          return t.Util._box_num(t.Spec.Statistical.MODE_SNGL(f));
        case 14:
          E = E | 0;
          if (E <= 0 || E > f.length) return t.Util._box_err('#NUM!');
          A = f[f.length - E];
          break;
        case 15:
          E = E | 0;
          if (E <= 0 || E > f.length) return t.Util._box_err('#NUM!');
          A = f[E];
          break;
        case 16:
          return t.Util._box_num(t.Spec.Statistical.PERCENTILE_INC(f, E));
        case 17:
          return t.Util._box_num(t.Spec.Statistical.QUARTILE_INC(f, E));
        case 18:
          return t.Util._box_num(t.Spec.Statistical.PERCENTILE_EXC(f, E));
        case 19:
          return t.Util._box_num(t.Spec.Statistical.QUARTILE_EXC(f, E));
        default:
          return t.Util._box_err('#VALUE!');
      }
      return t.Util._box_num(A);
    }
    r.AGGREGATE = Kn;
    function Zn(r, e, n, i) {
      var u = r[0];
      var v;
      try {
        v = t.Util._cast_val(r[1]).v;
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
      var c = r[2] || r[0];
      if (!c.a) return t.Util._box_err('#VALUE!');
      var f = t.XLSXLib.utils.decode_range(c.a);
      switch (u.t) {
        case 's':
        case 'b':
          return t.Util._box_num(0);
        case 'n':
          var _ = a(u.v, v);
          if (!_) return t.Util._box_num(0);
          var s = u.v;
          if (c.t === 'n') s = c.v;
          else if (c.t === 'A' && c.v[0][0].t === 'n') s = c.v[0][0].v;
          return t.Util._box_num(s);
        case 'A':
          var o = 0;
          u.v.forEach(function (r, i) {
            r.forEach(function (r, _) {
              try {
                if (!a(t.Util._cast_val(r).v, v)) return;
              } catch (t) {
                return;
              }
              if (c === u) {
                if (r.t !== 'n') return;
                o += r.v;
                return;
              }
              if (c.t === 'A' && c.v[i] && c.v[i][_] != null) {
                var s = c.v[i][_];
                if (s && s.t === 'n') o += s.v;
              } else {
                var l = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: f.s.r + i, c: f.s.c + _ })];
                if (!l) return;
                if (l.t === 'n') o += l.v;
              }
            });
          });
          return t.Util._box_num(o);
      }
      return t.Util._box_err('#VALUE!');
    }
    r.SUMIF = Zn;
    function Qn(r, e, n, i) {
      var u = r[0];
      var v;
      try {
        v = t.Util._cast_val(r[1]).v;
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
      var c = r[2] || r[0];
      if (!c.a) return t.Util._box_err('#VALUE!');
      var f = t.XLSXLib.utils.decode_range(c.a);
      switch (u.t) {
        case 's':
        case 'b':
          return t.Util._box_num(0);
        case 'n':
          var _ = a(u.v, v);
          if (!_) return t.Util._box_num(0);
          var s = u.v;
          if (c.t === 'n') s = c.v;
          else if (c.t === 'A' && c.v[0][0].t === 'n') s = c.v[0][0].v;
          return t.Util._box_num(s);
        case 'A':
          var o = 0;
          var l = 0;
          u.v.forEach(function (r, i) {
            r.forEach(function (r, _) {
              try {
                if (!a(t.Util._cast_val(r).v, v)) return;
              } catch (t) {
                return;
              }
              if (r.t !== 'n') return;
              if (c === u) {
                o += r.v;
                ++l;
                return;
              }
              var s = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: f.s.r + i, c: f.s.c + _ })];
              if (!s) return;
              if (s.t === 'n') {
                o += s.v;
                ++l;
              }
            });
          });
          return t.Util._box_num(o / l);
      }
      return t.Util._box_err('#VALUE!');
    }
    r.AVERAGEIF = Qn;
    function Jn(r, e, n, i) {
      var u = r[0];
      if (u.t === 'e') return u;
      var v;
      try {
        v = t.Util._cast_val(r[1]).v;
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
      switch (u.t) {
        case 'n':
        case 's':
        case 'b':
          return t.Util._box_num(+!!a(u.v, v));
        case 'z':
          if (typeof v === 'number') return t.Util._box_num(+!!a(0, v));
          return t.Util._box_num(+!!a('', v));
        case 'A':
          var c = 0;
          u.v.forEach(function (r, e) {
            r.forEach(function (r, e) {
              try {
                if (r.t === 'z') {
                  if (v !== '' && v !== 0) return;
                } else if (!a(t.Util._cast_val(r).v, v, true)) return;
              } catch (t) {
                return;
              }
              ++c;
            });
          });
          return t.Util._box_num(c);
      }
      return t.Util._box_err('#VALUE!');
    }
    function jn(t, r, e, a, n) {
      if (!n || (t[1] && t[1].t !== 'A')) return Jn(t, r, e, a);
      var i = { a: '', t: 'A', v: [] };
      var u = t[1];
      u.v.forEach(function (n, u) {
        i.v[u] = [];
        n.forEach(function (n, v) {
          i.v[u][v] = Jn([t[0], n], r, e, a);
        });
      });
      return i;
    }
    r.COUNTIF = jn;
    function ti(r, e, n, i) {
      var u = [];
      var v = true;
      var c = 1;
      for (var f = 0; f < r.length; f += 2) {
        try {
          var _ = t.Util._cast_val(r[f + 1]).v;
          var s = r[f];
          if (f === 0) v = s.t === 'A';
          if (v ? s.t !== 'A' : s.t === 'A') throw 'mismatch';
          if (v) u.push([_, t.XLSXLib.utils.decode_range(s.a).s]);
          else {
            var o = t.Util._cast_val(s).v;
            if (typeof _ === 'string' || typeof o === 'string' ? String(_).toLowerCase() !== String(o).toLowerCase() : _ !== o) c = 0;
          }
        } catch (r) {
          return t.Util._box_err('#VALUE!');
        }
      }
      if (!v) return t.Util._box_num(c);
      var l = r[0];
      if (l.t !== 'A') return t.Util._box_err('#VALUE!');
      var U = 0;
      l.v.forEach(function (i, v) {
        i.forEach(function (i, c) {
          for (var f = 0; f < u.length; ++f) {
            try {
              var _ = void 0;
              try {
                _ = r[2 * f].v[v][c];
              } catch (t) {}
              if (!_)
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: u[f][1].r + v, c: u[f][1].c + c })];
                } catch (t) {}
              if (!_) _ = { t: 'z' };
              var s = a(_.t === 'z' ? (typeof u[f][0] === 'number' ? 0 : '') : t.Util._cast_val(_).v, u[f][0]);
              if (!s) return;
            } catch (t) {
              return;
            }
          }
          if (i.t !== 'e') U++;
        });
      });
      return t.Util._box_num(U);
    }
    r.COUNTIFS = ti;
    function ri(r, e, n, i) {
      var u = r[0];
      var v = [];
      for (var c = 1; c < r.length; c += 2) {
        try {
          var f = t.Util._cast_val(r[c + 1]).v;
          var _ = r[c];
          if (_.t !== 'A') throw 'wafuq4';
          var s = t.XLSXLib.utils.decode_range(_.a);
          var o = s.s;
          var l = s.s.r + ' ' + s.s.c === s.e.r + ' ' + s.e.c;
          if (!o.r) o.r = 0;
          if (!o.c) o.c = 0;
          v.push([f, o, l]);
        } catch (r) {
          return t.Util._box_err('#VALUE!');
        }
      }
      if (u.t !== 'A') return t.Util._box_err('#VALUE!');
      var U = 0;
      u.v.forEach(function (i, u) {
        i.forEach(function (i, c) {
          for (var f = 0; f < v.length; ++f) {
            try {
              var _ = void 0;
              try {
                _ = r[1 + 2 * f].v[u][c];
              } catch (t) {}
              if (!_ && !v[u][2])
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: v[f][1].r + u, c: v[f][1].c + c })];
                } catch (t) {}
              if (!_ && v[u][2])
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: v[f][1].r, c: v[f][1].c })];
                } catch (t) {}
              if (!_) return;
              var s = a(t.Util._cast_val(_).v, v[f][0]);
              if (!s) return;
            } catch (t) {
              return;
            }
          }
          if (i.t === 'n') U += i.v;
        });
      });
      return t.Util._box_num(U);
    }
    r.SUMIFS = ri;
    function ei(r, e, n, i) {
      var u = r[0];
      var v = [];
      for (var c = 1; c < r.length; c += 2) {
        try {
          var f = t.Util._cast_val(r[c + 1]).v;
          var _ = r[c];
          if (_.t !== 'A') throw 'wafuq5';
          v.push([f, t.XLSXLib.utils.decode_range(_.a).s]);
        } catch (r) {
          return t.Util._box_err('#VALUE!');
        }
      }
      if (u.t !== 'A') return t.Util._box_err('#VALUE!');
      var s = 0;
      var o = 0;
      u.v.forEach(function (i, u) {
        i.forEach(function (i, c) {
          for (var f = 0; f < v.length; ++f) {
            try {
              var _ = void 0;
              try {
                _ = r[1 + 2 * f].v[u][c];
              } catch (t) {}
              if (!_)
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: v[f][1].r + u, c: v[f][1].c + c })];
                } catch (t) {}
              if (!_ || _.t !== 'n') return;
              var l = a(t.Util._cast_val(_).v, v[f][0]);
              if (!l) return;
            } catch (t) {
              return;
            }
          }
          if (i.t === 'n') {
            s += i.v;
            ++o;
          }
        });
      });
      return t.Util._box_num(s / o);
    }
    r.AVERAGEIFS = ei;
    function ai(r, e, n, i) {
      var u = r[0];
      var v = [];
      for (var c = 1; c < r.length; c += 2) {
        try {
          var f = t.Util._cast_val(r[c + 1]).v;
          var _ = r[c];
          if (_.t !== 'A') throw 'wafuq6';
          v.push([f, t.XLSXLib.utils.decode_range(_.a).s]);
        } catch (r) {
          return t.Util._box_err('#VALUE!');
        }
      }
      if (u.t !== 'A') return t.Util._box_err('#VALUE!');
      var s = 0;
      u.v.forEach(function (i, u) {
        i.forEach(function (i, c) {
          for (var f = 0; f < v.length; ++f) {
            try {
              var _ = void 0;
              try {
                _ = r[1 + 2 * f].v[u][c];
              } catch (t) {}
              if (!_)
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: v[f][1].r + u, c: v[f][1].c + c })];
                } catch (t) {}
              if (!_ || _.t !== 'n') return;
              var o = a(t.Util._cast_val(_).v, v[f][0]);
              if (!o) return;
            } catch (t) {
              return;
            }
          }
          if (i.t === 'n') {
            s = Math.max(i.v, s);
          }
        });
      });
      return t.Util._box_num(s);
    }
    r.MAXIFS = ai;
    function ni(r, e, n, i) {
      var u = r[0];
      var v = [];
      for (var c = 1; c < r.length; c += 2) {
        try {
          var f = t.Util._cast_val(r[c + 1]).v;
          var _ = r[c];
          if (_.t !== 'A') throw 'wafuq7';
          v.push([f, t.XLSXLib.utils.decode_range(_.a).s]);
        } catch (r) {
          return t.Util._box_err('#VALUE!');
        }
      }
      if (u.t !== 'A') return t.Util._box_err('#VALUE!');
      var s = 0;
      u.v.forEach(function (i, u) {
        i.forEach(function (i, c) {
          for (var f = 0; f < v.length; ++f) {
            try {
              var _ = void 0;
              try {
                _ = r[1 + 2 * f].v[u][c];
              } catch (t) {}
              if (!_)
                try {
                  _ = e.Sheets[e.SheetNames[n]][t.Util.encode_cell({ r: v[f][1].r + u, c: v[f][1].c + c })];
                } catch (t) {}
              if (!_ || _.t !== 'n') return;
              var o = a(t.Util._cast_val(_).v, v[f][0]);
              if (!o) return;
            } catch (t) {
              return;
            }
          }
          if (i.t === 'n') {
            s = Math.min(i.v, s);
          }
        });
      });
      return t.Util._box_num(s);
    }
    r.MINIFS = ni;
    function ii(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return t.Util._box_err('#VALUE!');
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = t.Util._cast_num_arr(r[3]);
      if (!Array.isArray(i)) return t.Util._box_err('#REF!');
      return t.Util._box_num(
        i.reduce(function (t, r, i) {
          return t + r * Math.pow(e.v, a.v + i * n.v);
        }, 0),
      );
    }
    r.SERIESSUM = ii;
    function ui(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return { t: 'e', v: 36 };
      try {
        return t.Util._box_num(t.Spec.ARABIC(e.v));
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
    }
    r.ARABIC = ui;
    function vi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 36 };
      if (e.v < 0 || e.v >= 4e3) return t.Util._box_err('#VALUE!');
      var a = 0;
      if (r.length > 1) {
        if (r[1].t === 'b') {
          a = r[1].v ? 0 : 4;
        } else {
          var n = t.Util._cast_num(r[1]);
          if (n.t === 'e') return { t: 'e', v: 36 };
          a = n.v | 0;
          if (a < 0 || a > 4) return t.Util._box_err('#VALUE!');
        }
      }
      return t.Util._box_str(t.Spec.ROMAN(e.v, a));
    }
    r.ROMAN = vi;
    function ci(r) {
      var e = t.Util._cast_num_arr(r[0], true);
      if (!Array.isArray(e)) return t.Util._box_err('#VALUE!');
      var a = t.Util._cast_num_arr(r[1], true);
      if (!Array.isArray(a)) return t.Util._box_err('#VALUE!');
      return t.Util._box_aoa(
        t.Spec.Statistical.FREQUENCY(e, a).map(function (t) {
          return [t];
        }),
      );
    }
    r.FREQUENCY = ci;
    function fi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      if (n.v <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num((e.v - a.v) / n.v);
    }
    r.STANDARDIZE = fi;
    function _i(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 && e.v === Math.floor(e.v)) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.GAMMA(e.v));
    }
    r.GAMMA = _i;
    function si(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.GAMMALN(e.v));
    }
    r.GAMMALN = si;
    r.GAMMALN_DOT_PRECISE = si;
    function oi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = t.Util._cast_bool(r[3]);
      if (i.t === 'e') return i;
      var u = 0;
      var v = 1;
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v;
      }
      if (r.length >= 6) {
        var f = t.Util._cast_num(r[5]);
        if (f.t === 'e') return f;
        v = f.v;
      }
      if (e.v < u || e.v > v || u === v) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.BETA_DIST((e.v - u) / (v - u), a.v, n.v, i.v) / (i.v ? 1 : v - u));
    }
    r.BETA_DOT_DIST = oi;
    function li(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      var a = t.Util._cast_num(r[1]);
      if (a.t === 'e') return a;
      var n = t.Util._cast_num(r[2]);
      if (n.t === 'e') return n;
      var i = 0;
      var u = 1;
      if (r.length >= 4) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return v;
        i = v.v;
      }
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return c;
        u = c.v;
      }
      if (a.v <= 0 || n.v <= 0 || e.v < i || e.v > u || i === u) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.BETA_DIST((e.v - i) / (u - i), a.v, n.v, true));
    }
    r.BETADIST = li;
    function Ui(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = 0;
      var u = 1;
      if (r.length >= 4) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        i = v.v;
      }
      if (r.length >= 5) {
        var c = t.Util._cast_num(r[4]);
        if (c.t === 'e') return t.Util._box_err('#VALUE!');
        u = c.v;
      }
      if (e.v <= 0 || e.v > 1 || i === u) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.BETA_INV(e.v, a.v, n.v) * (u - i) + i);
    }
    r.BETA_DOT_INV = Ui;
    r.BETAINV = Ui;
    function hi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (e.v >= 1 || e.v <= -1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.FISHER(e.v));
    }
    r.FISHER = hi;
    function bi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(t.Spec.Statistical.FISHERINV(e.v));
    }
    r.FISHERINV = bi;
    function mi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v <= 0 || n.v <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.WEIBULL(e.v, a.v, n.v, i.v));
    }
    r.WEIBULL_DOT_DIST = mi;
    r.WEIBULL = mi;
    function Ei(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || e.v > a.v || n.v < 0 || n.v > 1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.BINOM_DIST(e.v | 0, a.v | 0, n.v, i.v));
    }
    r.BINOM_DOT_DIST = Ei;
    r.BINOMDIST = Ei;
    function pi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (r.length <= 3) return Ei([n, e, a, { t: 'b', v: false }]);
      var i = t.Util._cast_num(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0 || a.v > 1 || n.v < 0 || n.v > e.v || i.v < n.v || i.v > e.v) return t.Util._box_err('#NUM!');
      var u = 0;
      for (var v = n.v; v <= i.v; ++v) u += t.Spec.Statistical.BINOM_DIST(v, e.v | 0, a.v, false);
      return t.Util._box_num(u);
    }
    r.BINOM_DOT_DIST_DOT_RANGE = pi;
    function Ai(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      var i = e.v | 0;
      if (i < 0) return { t: 'e', v: 36 };
      if (a.v < 0 || a.v > 1) return { t: 'e', v: 36 };
      if (n.v < 0 || n.v > 1) return { t: 'e', v: 36 };
      var u = 0;
      for (var v = 0; v < e.v; ++v) {
        u += t.Spec.Statistical.BINOM_DIST(v, i, a.v, false);
        if (u >= n.v) return t.Util._box_num(v);
      }
      return t.Util._box_err('#NUM!');
    }
    r.BINOM_DOT_INV = Ai;
    r.CRITBINOM = Ai;
    function xi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_bool(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      var i = Math.floor(a.v);
      if (e.v < 0 || i < 1 || i > Math.pow(10, 10)) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.CHISQ_DIST(e.v, i, n.v));
    }
    r.CHISQ_DOT_DIST = xi;
    function Si(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = Math.floor(a.v);
      if (e.v < 0 || n < 1 || n > Math.pow(10, 10)) return { t: 'e', v: 36 };
      return t.Util._box_num(1 - t.Spec.Statistical.CHISQ_DIST(e.v, n, true));
    }
    r.CHISQ_DOT_DIST_DOT_RT = Si;
    r.CHIDIST = Si;
    function di(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = Math.floor(a.v);
      if (e.v < 0 || e.v > 1 || n < 1 || n > Math.pow(10, 10)) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.CHISQ_INV(e.v, n));
    }
    r.CHISQ_DOT_INV = di;
    function Mi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = Math.floor(a.v);
      if (e.v < 0 || e.v > 1 || n < 1 || n > Math.pow(10, 10)) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.CHISQ_INV(1 - e.v, a.v | 0));
    }
    r.CHISQ_DOT_INV_DOT_RT = Mi;
    r.CHIINV = Mi;
    function Ni(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_bool(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v <= 0) return t.Util._box_err('#NUM!');
      var i = Math.exp(-a.v * e.v);
      return t.Util._box_num(n.v ? 1 - i : a.v * i);
    }
    r.EXPON_DOT_DIST = Ni;
    r.EXPONDIST = Ni;
    function gi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || e.v > 1 || a.v < 1 || n.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.F_INV(e.v, a.v, n.v));
    }
    r.F_DOT_INV = gi;
    function Li(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      var a = { t: 'n', v: 1 - e.v };
      return gi([a, r[1], r[2]]);
    }
    r.F_DOT_INV_DOT_RT = Li;
    r.FINV = Li;
    function Ii(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 1 || n.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.F_DIST(e.v, a.v, n.v, i.v));
    }
    r.F_DOT_DIST = Ii;
    function ki(t) {
      var r = Ii([t[0], t[1], t[2], { t: 'b', v: true }]);
      if (r.t === 'n') r.v = 1 - r.v;
      return r;
    }
    r.F_DOT_DIST_DOT_RT = ki;
    r.FDIST = ki;
    function Ti(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v <= 0 || n.v <= 0) return t.Util._box_err('#NUM!');
      if (i.v) return t.Util._box_num(t.Spec.Statistical.Gamma.lower(a.v, e.v / n.v));
      if (e.v === 0 && a.v === 1) return t.Util._box_num(1 / n.v);
      return t.Util._box_num(Math.exp((a.v - 1) * Math.log(e.v) - e.v / n.v - t.Spec.Statistical.GAMMALN(a.v) - a.v * Math.log(n.v)));
    }
    r.GAMMA_DOT_DIST = Ti;
    r.GAMMADIST = Ti;
    function wi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || e.v > 1 || a.v <= 0 || n.v <= 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.Gamma.lower_inv(e.v, a.v) * n.v);
    }
    r.GAMMA_DOT_INV = wi;
    r.GAMMAINV = wi;
    function yi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_num(r[3]);
      var u = t.Util._cast_bool(r[4]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e' || u.t === 'e') return { t: 'e', v: 15 };
      if (
        e.v < 0 ||
        (a.v <= n.v && e.v > a.v) ||
        (n.v <= a.v && e.v > n.v) ||
        e.v < a.v - i.v + n.v ||
        a.v <= 0 ||
        a.v > i.v ||
        n.v <= 0 ||
        n.v > i.v ||
        i.v <= 0
      )
        return { t: 'e', v: 36 };
      var v = t.Spec.n_choose_k(i.v, a.v);
      var c = 0;
      if (u.v) for (var f = 0; f <= e.v; ++f) c += (t.Spec.n_choose_k(n.v, f) * t.Spec.n_choose_k(i.v - n.v, a.v - f)) / v;
      else c = (t.Spec.n_choose_k(n.v, e.v) * t.Spec.n_choose_k(i.v - n.v, a.v - e.v)) / v;
      return t.Util._box_num(c);
    }
    r.HYPGEOM_DOT_DIST = yi;
    function Oi(t) {
      return yi([t[0], t[1], t[2], t[3], { t: 'b', v: false }]);
    }
    r.HYPGEOMDIST = Oi;
    function Di(t) {
      return Ci([t[0], t[1], t[2], { t: 'b', v: false }]);
    }
    r.NEGBINOMDIST = Di;
    function Ci(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (n.v < 0 || n.v > 1 || e.v < 0 || a.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.NEGBINOM_DIST(e.v | 0, a.v | 0, n.v, i.v));
    }
    r.NEGBINOM_DOT_DIST = Ci;
    function Ri(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(-Math.exp((-e.v * e.v) / 2) / Math.sqrt(2 * Math.PI));
    }
    r.PHI = Ri;
    function Vi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.NORM_DIST(e.v, a.v, n.v, i.v));
    }
    r.NORM_DOT_DIST = Vi;
    r.NORMDIST = Vi;
    function Fi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_DIST(e.v, true));
    }
    r.NORMSDIST = Fi;
    function Pi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_bool(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_DIST(e.v, a.v));
    }
    r.NORM_DOT_S_DOT_DIST = Pi;
    function Xi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_DIST(e.v, true) - 0.5);
    }
    r.GAUSS = Xi;
    function Bi(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v >= 1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.NORM_S_INV(e.v));
    }
    r.NORM_DOT_S_DOT_INV = Bi;
    function Gi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v >= 1 || n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_INV(e.v) * n.v + a.v);
    }
    r.NORM_DOT_INV = Gi;
    r.NORMSINV = Bi;
    r.NORMINV = Gi;
    function Hi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_bool(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_DIST((Math.log(e.v) - a.v) / n.v, i.v));
    }
    r.LOGNORM_DOT_DIST = Hi;
    function zi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.NORM_S_DIST((Math.log(e.v) - a.v) / n.v, true));
    }
    r.LOGNORMDIST = zi;
    function qi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v >= 1 || n.v <= 0) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.exp(a.v + n.v * t.Spec.Statistical.NORM_S_INV(e.v)));
    }
    r.LOGNORM_DOT_INV = qi;
    r.LOGINV = qi;
    function $i(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_bool(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 0) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.POISSON(e.v, a.v, n.v));
    }
    r.POISSON = $i;
    r.POISSON_DOT_DIST = $i;
    function Wi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_bool(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(t.Spec.Statistical.T_DIST(e.v, a.v | 0, n.v));
    }
    r.T_DOT_DIST = Wi;
    function Yi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (a.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(1 - t.Spec.Statistical.T_DIST(e.v, a.v | 0, true));
    }
    r.T_DOT_DIST_DOT_RT = Yi;
    function Ki(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 1) return { t: 'e', v: 36 };
      var n = t.Spec.Statistical.T_DIST(e.v, a.v | 0, true);
      if (n > 0.5) n = 1 - n;
      return t.Util._box_num(2 * n);
    }
    r.T_DOT_DIST_DOT_2T = Ki;
    function Zi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v < 0 || a.v < 1) return { t: 'e', v: 36 };
      var i = n.v | 0;
      var u = t.Spec.Statistical.T_DIST(e.v, a.v | 0, true);
      switch (i) {
        case 1:
          return t.Util._box_num(1 - u);
        case 2:
          if (u > 0.5) u = 1 - u;
          return t.Util._box_num(2 * u);
      }
      return { t: 'e', v: 36 };
    }
    r.TDIST = Zi;
    function Qi(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v > 1) return { t: 'e', v: 36 };
      var n = a.v | 0;
      if (n < 1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(t.Spec.Statistical.T_INV(e.v, n));
    }
    r.T_DOT_INV = Qi;
    function Ji(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v > 1) return { t: 'e', v: 36 };
      var n = a.v | 0;
      if (n < 1) return t.Util._box_err('#NUM!');
      return t.Util._box_num(Math.abs(t.Spec.Statistical.T_INV(e.v / 2, n)));
    }
    r.T_DOT_INV_DOT_2T = Ji;
    r.TINV = Ji;
    function ji(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v >= 1 || a.v <= 0 || n.v < 1) return { t: 'e', v: 36 };
      return t.Util._box_num(Math.abs((t.Spec.Statistical.NORM_S_INV(e.v / 2) * a.v) / Math.sqrt(n.v)));
    }
    r.CONFIDENCE_DOT_NORM = ji;
    function tu(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      if (e.v <= 0 || e.v >= 1 || a.v <= 0 || n.v < 1) return { t: 'e', v: 36 };
      if (n.v === 1) return { t: 'e', v: 7 };
      return t.Util._box_num(Math.abs((t.Spec.Statistical.T_INV(e.v / 2, n.v - 1) * a.v) / Math.sqrt(n.v)));
    }
    r.CONFIDENCE_DOT_T = tu;
    r.CONFIDENCE = ji;
    function ru(r) {
      var e = -Infinity;
      var a = function (a) {
        switch (r[a].t) {
          case 'A':
            var n;
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                if (r.t === 'e') {
                  if (!n) n = r;
                  return;
                }
                var a = t.Util._cast_num(r);
                if (a.t === 'n' && !isNaN(a.v)) e = Math.max(a.v, e);
              });
            });
            if (n) return { value: n };
            break;
          case 'e':
            return { value: r[a] };
          default:
            var i = t.Util._cast_num(r[a]);
            if (i.t === 'n' && !isNaN(i.v)) e = Math.max(i.v, e);
        }
      };
      for (var n = 0; n < r.length; ++n) {
        var i = a(n);
        if (typeof i === 'object') return i.value;
      }
      if (isNaN(e)) return t.Util._box_err('#VALUE!');
      return t.Util._box_num(e);
    }
    r.MAX = ru;
    function eu(r) {
      var e = Infinity;
      for (var a = 0; a < r.length; ++a) {
        switch (r[a].t) {
          case 'A':
            r[a].v.forEach(function (r) {
              r.forEach(function (r) {
                var a = t.Util._cast_num(r);
                if (a.t === 'n' && !isNaN(a.v) && r.t !== 'z' && r.t !== 's') e = Math.min(a.v, e);
              });
            });
            break;
          case 'e':
            return r[a];
          default:
            var n = t.Util._cast_num(r[a]);
            if (n.t === 'n' && !isNaN(n.v)) e = Math.min(n.v, e);
        }
      }
      if (!isFinite(e)) return e > 0 ? t.Util._box_num(0) : t.Util._box_err('#VALUE!');
      return t.Util._box_num(e);
    }
    r.MIN = eu;
    function au(r) {
      var e = 0;
      var a = 0;
      var n;
      for (var i = 0; i < r.length; ++i) {
        switch (r[i].t) {
          case 'A':
            r[i].v.forEach(function (r) {
              r.forEach(function (r) {
                var i = t.Util._cast_num(r);
                if (i.t === 'n' && !isNaN(i.v) && r.t !== 'b') {
                  if (i.v === 0) n = { t: 'e', v: 36 };
                  e += 1 / i.v;
                  ++a;
                }
              });
            });
            if (n) return n;
            break;
          default:
            var u = t.Util._cast_num(r[i]);
            if (u.t === 'n' && !isNaN(u.v)) {
              e += 1 / u.v;
              ++a;
            }
        }
      }
      return t.Util._box_num(a / e);
    }
    r.HARMEAN = au;
    function nu(r) {
      var e = 1;
      var a = 0;
      var n = false;
      for (var i = 0; i < r.length; ++i) {
        switch (r[i].t) {
          case 'A':
            r[i].v.forEach(function (r) {
              r.forEach(function (r) {
                var i = t.Util._cast_num(r);
                if (i.t === 'n' && !isNaN(i.v) && r.t !== 'b') {
                  if (i.v <= 0) n = true;
                  e *= i.v;
                  ++a;
                }
              });
            });
            break;
          default:
            var u = t.Util._cast_num(r[i]);
            if (u.t === 'n' && !isNaN(u.v)) {
              if (u.v <= 0) n = true;
              e *= u.v;
              ++a;
            }
        }
      }
      if (n) return t.Util._box_err('#NUM!');
      return t.Util._box_num(Math.pow(e, 1 / a));
    }
    r.GEOMEAN = nu;
    function iu(r, e, a) {
      return function (n) {
        var i = [];
        for (var u = 0; u < n.length; ++u) {
          var v = (e ? t.Util._cast_num_arr_a : t.Util._cast_num_arr)(n[u], !a);
          if (!Array.isArray(v)) return v;
          i = i.concat(v);
        }
        return r(i, n);
      };
    }
    r.MEDIAN = iu(function (r) {
      var e = r.sort(function (t, r) {
        return t - r;
      });
      if ((r.length & 1) === 1) return t.Util._box_num(e[r.length >> 1]);
      return t.Util._box_num((e[r.length >> 1] + e[(r.length >> 1) - 1]) / 2);
    });
    function uu(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (!Array.isArray(e) || a.t === 'e') return t.Util._box_err('#VALUE!');
      if (e.length < a.v || a.v <= 0) return t.Util._box_err('#NUM!');
      var n = e.sort(function (t, r) {
        return t - r;
      });
      return t.Util._box_num(n[n.length - a.v]);
    }
    r.LARGE = uu;
    function vu(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (!Array.isArray(e) || a.t === 'e') return t.Util._box_err('#VALUE!');
      if (e.length < a.v || a.v <= 0) return t.Util._box_err('#NUM!');
      var n = e.sort(function (t, r) {
        return t - r;
      });
      return t.Util._box_num(n[a.v - 1]);
    }
    r.SMALL = vu;
    r.MODE_DOT_SNGL = iu(function (r) {
      return t.Util._box_num(t.Spec.Statistical.MODE_SNGL(r));
    });
    r.MODE = r.MODE_DOT_SNGL;
    function cu(r) {
      var e = [];
      for (var a = 0; a < r.length; ++a) {
        var n = t.Util._cast_num_arr(r[a], true);
        if (!Array.isArray(n)) return t.Util._box_err('#VALUE!');
        e = e.concat(n);
      }
      return t.Util._box_aoa(
        t.Spec.Statistical.MODE_MULT(e).map(function (t) {
          return [t];
        }),
      );
    }
    r.MODE_DOT_MULT = cu;
    function fu(r, e) {
      return function (a) {
        var n = [];
        for (var i = 0; i < 1; ++i) {
          var u = (e ? t.Util._cast_num_arr_a : t.Util._cast_num_arr)(a[i], true);
          if (!Array.isArray(u)) return t.Util._box_err('#VALUE!');
          n = n.concat(u);
        }
        var v = t.Util._cast_num(a[1]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        return r(n, v.v);
      };
    }
    r.PERCENTILE_DOT_EXC = fu(function (r, e) {
      return t.Util._box_num(t.Spec.Statistical.PERCENTILE_EXC(r, e));
    });
    r.PERCENTILE_DOT_INC = fu(function (r, e) {
      return t.Util._box_num(t.Spec.Statistical.PERCENTILE_INC(r, e));
    });
    r.PERCENTILE = r.PERCENTILE_DOT_INC;
    r.QUARTILE_DOT_EXC = fu(function (r, e) {
      return t.Util._box_num(t.Spec.Statistical.QUARTILE_EXC(r, e));
    });
    r.QUARTILE_DOT_INC = fu(function (r, e) {
      return t.Util._box_num(t.Spec.Statistical.QUARTILE_INC(r, e));
    });
    r.QUARTILE = r.QUARTILE_DOT_INC;
    var _u = function (r, e) {
      if (r.length === 0) {
        if (e) {
          var a = function (t) {
            switch (e[t].t) {
              case 'e':
                return { value: e[t] };
              case 'A': {
                var r;
                e[t].v.forEach(function (t) {
                  if (r) return;
                  t.forEach(function (t) {
                    if (r) return;
                    if (t.t === 'e') r = t;
                  });
                });
                if (r) return { value: r };
              }
            }
          };
          for (var n = 0; n < e.length; ++n) {
            var i = a(n);
            if (typeof i === 'object') return i.value;
          }
        }
        return t.Util._box_err('#DIV/0!');
      }
      var u = 0;
      for (var n = 0; n < r.length; ++n) u += r[n];
      return t.Util._box_num(u / r.length);
    };
    var su = function (r) {
      if (r.length < 2) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += (r[i] - n) * (r[i] - n);
      return t.Util._box_num(Math.sqrt(e / (r.length - 1)));
    };
    r.AVERAGE = iu(_u, false, true);
    r.AVERAGEA = iu(_u, true);
    r.STDEV_DOT_S = iu(su);
    r.STDEV = r.STDEV_DOT_S;
    r.SKEW = iu(function (r) {
      var e = _u(r);
      if (e.t === 'e') return e;
      var a = 0;
      var n = r.length;
      for (var i = 0; i < n; ++i) a += Math.pow(r[i] - e.v, 3);
      var u = su(r);
      if (u.t === 'e') return e;
      a = (n * a) / ((n - 1) * (n - 2) * Math.pow(u.v, 3));
      return t.Util._box_num(a);
    });
    r.SKEW_DOT_P = iu(function (r) {
      var e = _u(r);
      if (e.t === 'e') return e;
      var a = 0;
      var n = 0;
      var i = r.length;
      for (var u = 0; u < i; ++u) {
        a += Math.pow(r[u] - e.v, 2);
        n += Math.pow(r[u] - e.v, 3);
      }
      a /= i;
      n /= i;
      return t.Util._box_num(n / Math.pow(a, 3 / 2));
    });
    r.KURT = iu(function (r) {
      var e = _u(r);
      if (e.t === 'e') return e;
      var a = 0;
      var n = r.length;
      for (var i = 0; i < n; ++i) a += Math.pow(r[i] - e.v, 4);
      var u = su(r);
      if (u.t === 'e') return e;
      a /= Math.pow(u.v, 4);
      a = ((n * (n + 1)) / ((n - 1) * (n - 2) * (n - 3))) * a - (3 * (n - 1) * (n - 1)) / ((n - 2) * (n - 3));
      return t.Util._box_num(a);
    });
    r.AVEDEV = iu(function (r) {
      if (r.length === 0) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += Math.abs(r[i] - n);
      return t.Util._box_num(e / r.length);
    });
    r.DEVSQ = iu(function (r) {
      if (r.length === 0) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += (r[i] - n) * (r[i] - n);
      return t.Util._box_num(e);
    });
    r.VAR_DOT_S = iu(function (r) {
      if (r.length < 2) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += (r[i] - n) * (r[i] - n);
      return t.Util._box_num(e / (r.length - 1));
    });
    r.VAR_DOT_P = iu(function (r) {
      if (r.length < 2) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += (r[i] - n) * (r[i] - n);
      return t.Util._box_num(e / r.length);
    });
    r.VAR = r.VAR_DOT_S;
    r.VARP = r.VAR_DOT_P;
    r.STDEV_DOT_P = iu(function (r) {
      if (r.length < 2) return t.Util._box_err('#DIV/0!');
      var e = 0;
      for (var a = 0; a < r.length; ++a) e += r[a];
      var n = e / r.length;
      e = 0;
      for (var i = 0; i < r.length; ++i) e += (r[i] - n) * (r[i] - n);
      return t.Util._box_num(Math.sqrt(e / r.length));
    });
    r.STDEVP = r.STDEV_DOT_P;
    r.MAXA = ru;
    r.MINA = eu;
    r.STDEVA = r.STDEV;
    r.STDEVPA = r.STDEVP;
    r.VARA = r.VAR;
    r.VARPA = r.VARP;
    function ou(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(e) || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      if (e.length * a.length === 0) return t.Util._box_err('#DIV/0!');
      if (e.length !== a.length) return t.Util._box_err('#N/A');
      var n = 0;
      var i = 0;
      var u = 0;
      for (var v = 0; v < e.length; ++v) {
        n += e[v] * a[v];
        i += e[v];
        u += a[v];
      }
      return t.Util._box_num((n - (i * u) / e.length) / e.length);
    }
    r.COVARIANCE_DOT_P = ou;
    r.COVAR = ou;
    function lu(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(e) || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      if (e.length <= 1 || a.length <= 1) return t.Util._box_err('#DIV/0!');
      if (e.length !== a.length) return t.Util._box_err('#N/A');
      var n = 0;
      var i = 0;
      var u = 0;
      for (var v = 0; v < e.length; ++v) {
        n += e[v] * a[v];
        i += e[v];
        u += a[v];
      }
      return t.Util._box_num((n - (i * u) / e.length) / (e.length - 1));
    }
    r.COVARIANCE_DOT_S = lu;
    function Uu(e) {
      var a = ou([e[0], e[1]]);
      var n = r.STDEVP([e[0]]);
      var i = r.STDEVP([e[1]]);
      if (a.t === 'e' || n.t === 'e' || i.t === 'e') return t.Util._box_err('#VALUE!');
      if (n.v === 0 || i.v === 0) return t.Util._box_err('#NUM!');
      return t.Util._box_num(a.v / (n.v * i.v));
    }
    r.CORREL = Uu;
    r.TRIMMEAN = fu(function (t, r) {
      var e = Math.floor((r * t.length) / 2);
      return _u(t.slice(e, -e));
    });
    function hu(r, e) {
      var a = t.Util._cast_num_arr(r[0]);
      var n = t.Util._cast_num(r[1]);
      if (n.t === 'e' || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      var i = a.sort(function (t, r) {
        return t - r;
      });
      if (n.v < i[0] || n.v > i[i.length - 1]) return t.Util._box_err('#N/A');
      var u = e ? i.length + 1 : i.length - 1;
      var v = e ? 0 : -1 / u;
      for (var c = 0; c < i.length; ++c) {
        if (i[c] < n.v) v += 1 / u;
        else {
          v += ((1 / u) * (n.v - i[c - 1])) / (i[c] - i[c - 1]);
          break;
        }
      }
      var f = 1e3;
      if (r.length >= 3) {
        var _ = t.Util._cast_num(r[2]);
        if (_.t === 'e') return t.Util._box_err('#VALUE!');
        f = Math.pow(10, _.v | 0);
      }
      v = Math.round(v * f) / f;
      return t.Util._box_num(v);
    }
    function bu(t) {
      return hu(t, true);
    }
    r.PERCENTRANK_DOT_EXC = bu;
    function mu(t) {
      return hu(t, false);
    }
    r.PERCENTRANK_DOT_INC = mu;
    r.PERCENTRANK = mu;
    function Eu(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      if (e.t === 'e' || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      var n = true;
      if (r.length >= 3) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v === 0;
      }
      var u = a.sort(
        n
          ? function (t, r) {
              return r - t;
            }
          : function (t, r) {
              return t - r;
            },
      );
      var v = u.length;
      var c = 0;
      var f = -1;
      for (var _ = 0; _ < v; ++_)
        if (u[_] === e.v) {
          if (f === -1) f = _;
          ++c;
        }
      if (c <= 1) return t.Util._box_num(f + 1);
      return t.Util._box_num(f + (c + 1) / 2);
    }
    r.RANK_DOT_AVG = Eu;
    function pu(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      if (e.t === 'e' || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      var n = true;
      if (r.length >= 3) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v === 0;
      }
      var u = a.sort(
        n
          ? function (t, r) {
              return r - t;
            }
          : function (t, r) {
              return t - r;
            },
      );
      return t.Util._box_num(u.indexOf(e.v) + 1);
    }
    r.RANK_DOT_EQ = pu;
    r.RANK = pu;
    function Au(r) {
      var e = t.Util._cast_num_aoa(r[0]);
      var a = t.Util._cast_num_aoa(r[1]);
      if (!Array.isArray(e) || !Array.isArray(a)) return t.Util._box_err('#VALUE!');
      if (e.length !== a.length) return t.Util._box_err('#N/A');
      for (var n = 0; n < e.length; ++n) if (e[n].length !== a[n].length) return t.Util._box_err('#N/A');
      var i = 0;
      a.forEach(function (t, r) {
        t.forEach(function (t, a) {
          i += Math.pow(e[r][a] - t, 2) / t;
        });
      });
      var u = Math.max(e[0].length - 1, 1) * Math.max(e.length - 1, 1);
      var v = Math.exp(-i / 2);
      if ((u & 1) === 1) v = v * Math.sqrt((2 * i) / Math.PI);
      var c = u;
      while (c >= 2) {
        v *= i / c;
        c -= 2;
      }
      var f = v;
      var _ = u;
      while (f > 1e-10 * v) {
        _ += 2;
        f *= i / _;
        v += f;
      }
      return t.Util._box_num(1 - v);
    }
    r.CHISQ_DOT_TEST = Au;
    r.CHITEST = Au;
    r.F_DOT_TEST = Ou(function (r, e, a, n, i, u) {
      var v = n - (e * e) / r;
      var c = i - (a * a) / r;
      var f = Math.min(v, c) / Math.max(v, c);
      return t.Spec.Statistical.F_DIST(f, r - 1, r - 1, true) * 2;
    });
    r.FTEST = r.F_DOT_TEST;
    function xu(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_num(r[3]);
      if (!Array.isArray(e) || !Array.isArray(a) || n.t === 'e' || i.t === 'e') return t.Util._box_err('#VALUE!');
      var u = n.v | 0;
      var v = i.v | 0;
      if (u > 2 || u < 1) return t.Util._box_err('#NUM!');
      if (v > 3 || v < 1) return t.Util._box_err('#VALUE!');
      if (v === 1 && e.length !== a.length) return t.Util._box_err('#N/A');
      var c = 0;
      var f = 0;
      var _ = e.length;
      var s = a.length;
      var o = 0;
      var l = 0;
      var U = 0;
      var h = 0;
      var b = 0;
      for (var m = 0; m < _; ++m) {
        o += e[m];
        U += e[m] * e[m];
      }
      for (var E = 0; E < s; ++E) {
        l += a[E];
        h += a[E] * a[E];
      }
      if (v === 1)
        for (var p = 0; p < _; ++p) {
          b += e[p] * a[p];
        }
      var A = o / _ - l / s;
      switch (v) {
        case 1:
          c = (U - (o * o) / _ + (h - (l * l) / _) - 2 * (b - (o * l) / _)) / (_ * (_ - 1));
          f = _ - 1;
          break;
        case 2:
          c = (((U - (o * o) / _ + h - (l * l) / s) / (_ + s - 2)) * (_ + s)) / (_ * s);
          f = _ + s - 2;
          break;
        case 3:
          var x = (U - (o * o) / _) / (_ - 1);
          var S = (h - (l * l) / s) / (s - 1);
          c = x / _ + S / s;
          f = Math.pow(x / _ + S / s, 2) / (Math.pow(x / _, 2) / (_ - 1) + Math.pow(S / s, 2) / (s - 1));
          break;
      }
      var d = t.Spec.Statistical.T_DIST(A / Math.sqrt(c), f, true);
      return t.Util._box_num(d * u);
    }
    r.T_DOT_TEST = xu;
    r.TTEST = xu;
    function Su(r) {
      var e = t.Util._cast_num_arr(r[0]);
      if (!Array.isArray(e)) return t.Util._box_err('#VALUE!');
      var a = 0;
      var n = 0;
      var i = 0;
      e.forEach(function (t) {
        ++a;
        n += t;
        i += t * t;
      });
      var u = t.Util._cast_num(r[1]);
      var v = Math.sqrt((i - (n * n) / a) / (a - 1));
      if (r.length > 2) {
        var c = t.Util._cast_num(r[2]);
        if (c.t === 'e') return t.Util._box_err('#VALUE!');
        v = c.v;
      }
      if (u.t === 'e' || v <= 0) return t.Util._box_err('#VALUE!');
      var f = (n / a - u.v) / (v / Math.sqrt(a));
      var _ = t.Spec.Statistical.NORM_S_DIST(f, true);
      return t.Util._box_num(1 - _);
    }
    r.Z_DOT_TEST = Su;
    r.ZTEST = Su;
    function du(r) {
      return t.Util._box_err('#NAME?');
    }
    r.FORECAST_DOT_ETS = du;
    function Mu(r) {
      return t.Util._box_err('#NAME?');
    }
    r.FORECAST_DOT_ETC_DOT_CONFINT = Mu;
    function Nu(r) {
      return t.Util._box_err('#NAME?');
    }
    r.FORECAST_DOT_ETS_DOT_SEASONALITY = Nu;
    function gu(r) {
      return t.Util._box_err('#NAME?');
    }
    r.FORECAST_DOT_ETC_DOT_STAT = gu;
    function Lu(r) {
      var e = t.Util._cast_num(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      var n = t.Util._cast_num_arr(r[2]);
      if (e.t === 'e' || !Array.isArray(a) || !Array.isArray(n)) return t.Util._box_err('#VALUE!');
      if (a.length !== n.length) return t.Util._box_err('#N/A');
      if (a.length < 3) return t.Util._box_err('#DIV/0!');
      var i = 0;
      var u = 0;
      var v = 0;
      var c = 0;
      var f = 0;
      var _ = n.length;
      for (var s = 0; s < _; ++s) {
        i += n[s];
        u += a[s];
        v += n[s] * n[s];
        c += a[s] * a[s];
        f += n[s] * a[s];
      }
      var o = (f - (i * u) / _) / (v - (i * i) / _);
      var l = u / _ - (o * i) / _;
      return t.Util._box_num(o * e.v + l);
    }
    r.FORECAST_DOT_LINEAR = Lu;
    r.FORECAST = Lu;
    function Iu(r, e) {
      var a = t.Util._cast_num_arr(r[0]);
      if (!Array.isArray(a)) return t.Util._box_err('#VALUE!');
      var n = [];
      var i = [];
      if (r[1]) {
        var u = t.Util._cast_num_aoa(r[1]);
        if (!Array.isArray(u)) return t.Util._box_err('#REF!');
        if (u.length === 1) {
          n = u[0];
          if (n.length !== a.length) return t.Util._box_err('#REF!');
        } else if (u.length !== a.length) return t.Util._box_err('#REF!');
        else i = u;
      } else for (var v = 0; v < a.length; ++v) n[v] = v + 1;
      var c = [];
      var f = [];
      if (r[2]) {
        var _ = t.Util._cast_num_aoa(r[2]);
        if (!Array.isArray(_) || _.length !== a.length) return t.Util._box_err('#REF!');
        f = _;
      } else {
        c = n;
        f = i;
      }
      var s = true;
      if (r[3]) {
        var o = t.Util._cast_bool(r[3]);
        if (o.t === 'e') return t.Util._box_err('#VALUE!');
        s = o.v;
      }
      var l = a.length;
      var U = 0;
      var h = 0;
      var b = 0;
      var m = 0;
      for (var E = 0; E < l; ++E) {
        var p = i.length ? i[1][0] : n[E];
        var A = e ? Math.log(a[E]) : a[E];
        U += p;
        h += A;
        b += p * A;
        m += p * p;
      }
      U /= l;
      h /= l;
      b /= l;
      m /= l;
      var x = 0;
      var S = 0;
      if (s) {
        x = (b - U * h) / (m - U * U);
        S = h - x * U;
      } else {
        S = 0;
        x = b / m;
      }
      var d = [];
      for (var M = 0; M < c.length || f.length; ++M) {
        var N = c.length ? c[M] : f[M][0];
        var g = S + x * N;
        d.push(e ? Math.exp(g) : g);
      }
      return t.Util._box_aoa(
        d.map(function (t) {
          return [t];
        }),
      );
    }
    function ku(t) {
      return Iu(t, true);
    }
    r.GROWTH = ku;
    function Tu(t) {
      return Iu(t, false);
    }
    r.TREND = Tu;
    function wu(r) {
      return t.Util._box_err('#NAME?');
    }
    r.LINEST = wu;
    function yu(r) {
      return t.Util._box_err('#NAME?');
    }
    r.LOGEST = yu;
    function Ou(r) {
      return function (e) {
        var a = t.Util._cast_num_arr(e[0]);
        var n = t.Util._cast_num_arr(e[1]);
        if (!Array.isArray(a) || !Array.isArray(n)) return t.Util._box_err('#VALUE!');
        if (a.length !== n.length || a.length === 0) return t.Util._box_err('#N/A');
        if (a.length < 3) return t.Util._box_err('#DIV/0!');
        var i = 0;
        var u = 0;
        var v = 0;
        var c = 0;
        var f = 0;
        var _ = n.length;
        for (var s = 0; s < _; ++s) {
          i += n[s];
          u += a[s];
          v += n[s] * n[s];
          c += a[s] * a[s];
          f += n[s] * a[s];
        }
        return t.Util._box_num(r(_, i, u, v, c, f));
      };
    }
    r.INTERCEPT = Ou(function (t, r, e, a, n, i) {
      var u = (i - (r * e) / t) / (a - (r * r) / t);
      return e / t - (u * r) / t;
    });
    r.RSQ = Ou(function (t, r, e, a, n, i) {
      var u = i - (r * e) / t;
      var v = (a - (r * r) / t) * (n - (e * e) / t);
      return (u * u) / v;
    });
    r.PEARSON = Ou(function (t, r, e, a, n, i) {
      var u = i - (r * e) / t;
      var v = (a - (r * r) / t) * (n - (e * e) / t);
      return u / Math.sqrt(v);
    });
    r.SLOPE = Ou(function (t, r, e, a, n, i) {
      return (i - (r * e) / t) / (a - (r * r) / t);
    });
    r.STEYX = Ou(function (t, r, e, a, n, i) {
      var u = (n - (e * e) / t) * (a - (r * r) / t) - Math.pow(i - (r * e) / t, 2);
      u /= a - (r * r) / t;
      return Math.sqrt(u / (t - 2));
    });
    function Du(r) {
      var e = t.Util._cast_num_arr(r[0]);
      var a = t.Util._cast_num_arr(r[1]);
      if (!Array.isArray(a) || !Array.isArray(e)) return t.Util._box_err('#VALUE!');
      if (a.length !== e.length) return t.Util._box_err('#N/A');
      var n = NaN;
      var i = NaN;
      if (r[2]) {
        var u = t.Util._cast_num(r[2]);
        if (u.t === 'e') return t.Util._box_err('#VALUE!');
        n = u.v;
      }
      if (r[3]) {
        var v = t.Util._cast_num(r[3]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        i = v.v;
      }
      if (isNaN(i)) {
        if (isNaN(n)) return t.Util._box_err('#REF!');
        i = n;
      } else if (isNaN(n)) n = -Infinity;
      var c = 0;
      for (var f = 0; f < e.length; ++f) if (e[f] >= n && e[f] <= i) c += a[f];
      return t.Util._box_num(c);
    }
    r.PROB = Du;
    function Cu(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = a.t === 'n' ? a.v : 1;
      var i = e.v;
      return { t: 's', v: i.slice(0, n) };
    }
    r.LEFT = Cu;
    r.LEFTB = Cu;
    function Ru(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = e.v;
      var u = a.v;
      var v = n.v;
      return { t: 's', v: i.slice(u - 1, u - 1 + v) };
    }
    r.MID = Ru;
    r.MIDB = Ru;
    function Vu(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (r[1] !== undefined && a.t !== 'n') return { t: 'e', v: 15 };
      var n = a.t === 'n' ? a.v : 1;
      if (n === 0) return { t: 's', v: '' };
      var i = e.v;
      return { t: 's', v: i.slice(-n) };
    }
    r.RIGHT = Vu;
    r.RIGHTB = Vu;
    function Fu(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      return { t: 's', v: e.v.toLowerCase() };
    }
    r.LOWER = Fu;
    function Pu(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      return {
        t: 's',
        v: e.v.replace(/\b([^\sA-Za-z]*)([A-Za-z])([^\b\s]*)\b/g, function (t, r, e, a) {
          return r.toLowerCase() + e.toUpperCase() + a.toLowerCase();
        }),
      };
    }
    r.PROPER = Pu;
    function Xu(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      return { t: 's', v: e.v.toUpperCase() };
    }
    r.UPPER = Xu;
    function Bu(r) {
      return t.Util._cast_str(r[0]);
    }
    r.ASC = Bu;
    function Gu(t) {
      return { t: 'e', v: 29 };
    }
    r.JIS = Gu;
    function Hu(r) {
      return t.Util._box_err('#N/A');
    }
    r.PHONETIC = Hu;
    function zu(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      if (e.v <= 0 || e.v >= 256) return { t: 'e', v: 15 };
      return { t: 's', v: String.fromCharCode(t.State.CodeTable[e.v]) };
    }
    r.CHAR = zu;
    function qu(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      var a = t.State.CodeTable.indexOf(e.v.charCodeAt(0));
      if (a < 1) return { t: 'e', v: 15 };
      return t.Util._box_num(a);
    }
    r.CODE = qu;
    function $u(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return e;
      if (e.v === 0) return { t: 'e', v: 15 };
      return { t: 's', v: String.fromCharCode(e.v) };
    }
    r.UNICHAR = $u;
    function Wu(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      var a = e.v.charCodeAt(0);
      if (isNaN(a) || a === 0) return { t: 'e', v: 15 };
      return t.Util._box_num(a);
    }
    r.UNICODE = Wu;
    var Yu = /(?:\.0*|(\.\d*[1-9])0+)$/;
    var Ku = function (t) {
      return t.indexOf('.') === -1 ? t : t.replace(Yu, '$1');
    };
    function Zu(r) {
      var e = '';
      for (var a = 0; a < r.length; ++a) {
        var n = t.Util._cast_str(r[a]);
        if (n.t === 'e') return n;
        e += r[a].t === 'n' ? Ku(r[a].v.toPrecision(15)) : n.v;
      }
      return { t: 's', v: e };
    }
    r.CONCATENATE = Zu;
    r.CONCAT = Zu;
    function Qu(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_str(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      return { t: 'b', v: e.v === a.v };
    }
    r.EXACT = Qu;
    function Ju(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t === 'e') return e;
      return t.Util._box_num(e.v.length);
    }
    r.LEN = Ju;
    r.LENB = Ju;
    function ju(r) {
      var e = '';
      var a = t.Util._cast_str(r[0]);
      if (a.t === 'e') return t.Util._box_err('#VALUE!');
      var n = a.v;
      var i = t.Util._cast_bool(r[1]);
      if (i.t === 'e') return t.Util._box_err('#VALUE!');
      var u = i.v;
      for (var v = 2; v < r.length; ++v) {
        var c = t.Util._cast_str(r[v]);
        if (c.t === 'e') return c;
        if (u && !c.v) continue;
        e += (e ? n : '') + c.v;
      }
      return { t: 's', v: e };
    }
    r.TEXTJOIN = ju;
    function tv(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_str(r[1]);
      var n = t.Util._cast_str(r[2]);
      var i = -1;
      if (r[3]) {
        var u = t.Util._cast_num(r[3]);
        if (u.t === 'e') return { t: 'e', v: 15 };
        i = u.v | 0;
      }
      if (e.t === 'e' || a.t === 'e' || n.t === 'e') return { t: 'e', v: 15 };
      var v = new RegExp(a.v, 'g');
      return {
        t: 's',
        v:
          i === -1
            ? e.v.replace(v, n.v)
            : e.v.replace(v, function (t) {
                return --i === 0 ? n.v : t;
              }),
      };
    }
    r.SUBSTITUTE = tv;
    function rv(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_num(r[1]);
      var n = t.Util._cast_num(r[2]);
      var i = t.Util._cast_str(r[3]);
      if (e.t === 'e' || a.t === 'e' || n.t === 'e' || i.t === 'e') return { t: 'e', v: 15 };
      var u = e.v;
      return { t: 's', v: u.slice(0, a.v - 1) + i.v + u.slice(a.v + n.v - 1) };
    }
    r.REPLACE = rv;
    r.REPLACEB = rv;
    function ev(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t !== 's') return { t: 'e', v: 15 };
      return { t: 's', v: e.v.replace(/[\x00-\x1F]+/g, '') };
    }
    r.CLEAN = ev;
    function av(r) {
      var e = t.Util._cast_str(r[0]);
      if (e.t !== 's') return { t: 'e', v: 15 };
      return { t: 's', v: e.v.trim() };
    }
    r.TRIM = av;
    function nv(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_num(r[1]);
      if (a.t !== 'n') return { t: 'e', v: 15 };
      var n = Math.floor(a.v);
      if (n < 0) return { t: 'e', v: 15 };
      if (n === 0) return { t: 's', v: '' };
      var i = e.v;
      if (i.length * n > 32767) return { t: 'e', v: 15 };
      var u = '';
      while (n-- > 0) u += i;
      return { t: 's', v: u };
    }
    r.REPT = nv;
    function iv(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_str(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = 0;
      if (r.length > 2) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v - 1;
        if (n < 0 || n >= a.v.length) return t.Util._box_err('#VALUE!');
      }
      var u = a.v.slice(n).indexOf(e.v);
      if (u < 0) return t.Util._box_err('#VALUE!');
      return t.Util._box_num(u + 1);
    }
    r.FIND = iv;
    r.FINDB = iv;
    function uv(r) {
      var e = t.Util._cast_str(r[0]);
      var a = t.Util._cast_str(r[1]);
      if (e.t === 'e' || a.t === 'e') return { t: 'e', v: 15 };
      var n = 0;
      if (r.length > 2) {
        var i = t.Util._cast_num(r[2]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v - 1;
        if (n < 0 || n >= a.v.length) return t.Util._box_err('#VALUE!');
      }
      var u = a.v.slice(n).toLowerCase().indexOf(e.v.toLowerCase());
      if (u < 0) return t.Util._box_err('#VALUE!');
      return t.Util._box_num(u + 1);
    }
    r.SEARCH = uv;
    r.SEARCHB = uv;
    function vv(t) {
      return { t: 'e', v: 29 };
    }
    r.YEN = vv;
    function cv(r) {
      if (r[0].t === 'e') return r[0];
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      return t.Util._box_str(t.Spec.BAHTTEXT(e.v));
    }
    r.BAHTTEXT = cv;
    function fv(t) {
      switch (t[0].t) {
        case 's':
          return t[0];
        case 'e':
          return t[0];
        default:
          return { t: 's', v: '' };
      }
    }
    r.T = fv;
    function _v(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = e.v;
      var n = 2;
      if (r.length >= 2) {
        var i = t.Util._cast_num(r[1]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v | 0;
      }
      var u = new Array(n + 1).join('0');
      var v = '$#,##0'.concat(u ? '.' + u : '', ';($#,##0').concat(u ? '.' + u : '', ')');
      return { t: 's', v: t.XLSXLib.SSF.format(v, a) };
    }
    r.DOLLAR = _v;
    function sv(r) {
      var e = t.Util._cast_num(r[0]);
      if (e.t === 'e') return t.Util._box_err('#VALUE!');
      var a = e.v;
      var n = 2;
      if (r.length >= 2) {
        var i = t.Util._cast_num(r[1]);
        if (i.t === 'e') return t.Util._box_err('#VALUE!');
        n = i.v | 0;
      }
      var u = true;
      if (r.length >= 3) {
        var v = t.Util._cast_bool(r[2]);
        if (v.t === 'e') return t.Util._box_err('#VALUE!');
        u = !v.v;
      }
      if (n < 0) {
        a = Number((a / Math.pow(10, -n)).toFixed(0)) * Math.pow(10, -n);
        n = 0;
      }
      var c = u ? '#,##0' : '0';
      var f = new Array(n + 1).join('0');
      var _ = ''.concat(c).concat(f ? '.' + f : '');
      var s = t.XLSXLib.SSF.format(_, a);
      return { t: 's', v: s };
    }
    r.FIXED = sv;
    function ov(r) {
      var e = t.Util._cast_val(r[0]);
      switch (e.t) {
        case 'e':
          return e;
        case 'z':
          return { t: 's', v: '' };
      }
      var a = e.v;
      var n = t.Util._cast_str(r[1]);
      if (n.t === 'e') return t.Util._box_err('#VALUE!');
      var i = n.v;
      if (t.XLSXLib.SSF.normalize != null) i = t.XLSXLib.SSF.normalize(i);
      var u = '';
      try {
        u = t.XLSXLib.SSF.format(i, a);
      } catch (r) {
        u = t.XLSXLib.SSF.format('General', a);
      }
      return { t: 's', v: u };
    }
    r.TEXT = ov;
    function lv(r) {
      var a = r[0];
      switch (a.t) {
        case 'n':
          return a;
        case 'e':
          return a;
        case 'z':
          return t.Util._box_num(0);
        case 's':
          break;
        default:
          return t.Util._box_err('#VALUE!');
      }
      try {
        var n = a.v;
        var i = '.';
        var u = ',';
        if (r.length >= 2) {
          var v = t.Util._cast_str(r[1]);
          if (v.t === 'e') return t.Util._box_err('#VALUE!');
          i = v.v;
        }
        if (r.length >= 3) {
          var c = t.Util._cast_str(r[2]);
          if (c.t === 'e') return t.Util._box_err('#VALUE!');
          u = c.v;
        }
        if (i !== '.') n = n.replace(e(i, true), '.');
        n = n.replace(e(u), '');
        var f = t.Calc.evaluate(n);
        if (f.t === 'n') return f;
        return t.Util._box_err('#VALUE!');
      } catch (r) {
        return t.Util._box_err('#VALUE!');
      }
    }
    r.NUMBERVALUE = lv;
    function Uv(r) {
      return t.Util._cast_num(r[0]);
    }
    r.VALUE = Uv;
    function hv(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.CALL = hv;
    function bv(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.EUROCONVERT = bv;
    function mv(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.REGISTER_DOT_ID = mv;
    function Ev(r) {
      if (typeof encodeURI === 'undefined') return t.Util._box_err('#VALUE!');
      var e = t.Util._cast_str(r[0]);
      return e.t === 's' ? { t: 's', v: encodeURI(e.v) } : t.Util._box_err('#VALUE!');
    }
    r.ENCODEURL = Ev;
    function pv(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.FILTERXML = pv;
    function Av(r) {
      return t.Util._box_err('#VALUE!');
    }
    r.WEBSERVICE = Av;
    function xv(t) {
      return t[0] || { t: 'e', v: 15 };
    }
    r.ANCHORARRAY = xv;
  })((r = t.Func || (t.Func = {})));
  [
    'NETWORKDAYS.INTL',
    'WORKDAY.INTL',
    'ERF.PRECISE',
    'ERFC.PRECISE',
    'ERROR.TYPE',
    'CEILING.MATH',
    'CEILING.PRECISE',
    'ECMA.CEILING',
    'FLOOR.MATH',
    'FLOOR.PRECISE',
    'ISO.CEILING',
    'BETA.DIST',
    'BETA.INV',
    'BINOM.DIST',
    'BINOM.DIST.RANGE',
    'BINOM.INV',
    'CHISQ.DIST',
    'CHISQ.DIST.RT',
    'CHISQ.INV',
    'CHISQ.INV.RT',
    'CHISQ.TEST',
    'CONFIDENCE.NORM',
    'CONFIDENCE.T',
    'COVARIANCE.P',
    'COVARIANCE.S',
    'EXPON.DIST',
    'F.DIST',
    'F.DIST.RT',
    'F.INV',
    'F.INV.RT',
    'F.TEST',
    'FORECAST.ETS',
    'FORECAST.ETS.CONFINT',
    'FORECAST.ETS.SEASONALITY',
    'FORECAST.ETS.STAT',
    'FORECAST.LINEAR',
    'GAMMA.DIST',
    'GAMMA.INV',
    'GAMMALN.PRECISE',
    'HYPGEOM.DIST',
    'LOGNORM.DIST',
    'LOGNORM.INV',
    'MODE.MULT',
    'MODE.SNGL',
    'NEGBINOM.DIST',
    'NIGBINOM.DIST',
    'NORM.DIST',
    'NORM.INV',
    'NORM.S.DIST',
    'NORM.S.INV',
    'PERCENTILE.EXC',
    'PERCENTILE.INC',
    'PERCENTRANK.EXC',
    'PERCENTRANK.INC',
    'POISSON.DIST',
    'QUARTILE.EXC',
    'QUARTILE.INC',
    'RANK.AVG',
    'RANK.EQ',
    'SKEW.P',
    'STDEV.P',
    'STDEV.S',
    'T.DIST',
    'T.DIST.2T',
    'T.DIST.RT',
    'T.INV',
    'T.INV.2T',
    'T.TEST',
    'VAR.P',
    'VAR.S',
    'WEIBULL.DIST',
    'Z.TEST',
    'REGISTER.ID',
  ].forEach(function (t) {
    r[t] = r[t.replace(/\./g, '_DOT_')];
  });
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (r) {
    var e = function (r) {
      var e = [];
      var a = t.Calc._tokenize(r);
      var n = -1;
      for (var i = 0; i < a.length; ++i) {
        if (i < n) continue;
        var u = a[i];
        switch (u.t) {
          case '3':
          case 'j':
          case 'a':
            var v = u.v;
            n = i + 1;
            while (a[n] && a[n].t === ':') {
              v += ':' + a[n + 1].v;
              n += 2;
            }
            e.push([v, u.t]);
            break;
          case 'i':
            if (i + 1 < a.length && a[i + 1].t === 'p' && a[i + 1].v === '(') break;
            e.push([u.v, 'i']);
        }
      }
      return e;
    };
    var a = t.Util.COLUMN_NAMES;
    var n = Array.from({ length: 105e4 }, function (t, r) {
      return String(r + 1);
    });
    var i = function (r, e, i, u, v) {
      if (e === 'i') return;
      var c = r.replace(/\$/g, '').split('!');
      var f = c[c.length - 1];
      var _ = (c.length > 1 ? c[0] : v).replace(/'/g, '');
      var s = i.Sheets[_];
      var o = v + '::' + u;
      if (f.indexOf(':') === -1) {
        var l = s[f] || (s[f] = []);
        l.push(o);
      } else {
        var U = 1e4;
        var h = 16384;
        var b = 0;
        var m = 0;
        var E = f.split(':');
        for (var p = 0; p < E.length; ++p) {
          var A = t.Util.decode_cell(E[p]);
          if (A.c < h) h = A.c;
          if (A.r < U) U = A.r;
          if (A.c > m) m = A.c;
          if (A.r > b) b = A.r;
        }
        if (h < 0) h = 0;
        if (h > 16383) h = 16383;
        if (m < 0) m = 16383;
        if (m > 16383) m = 16383;
        if (U < 0) U = 0;
        if (U > 1e4) U = 1e4;
        if (b < 0) b = 1e5;
        if (b > 1e4) b = 1e4;
        if (h > m || U > b) return;
        for (var x = h; x <= m; ++x) {
          var S = a[x];
          for (var d = U; d <= b; ++d) {
            var M = S + n[d];
            var N = s[M] || (s[M] = []);
            N.push(o);
          }
        }
      }
    };
    function u(r, e) {
      if (!e.Ref) return;
      var i = { ws: (e.Sheet && r.SheetNames[e.Sheet]) || '', name: e };
      var u = [];
      try {
        u = t.Calc._tokenize(e.Ref);
      } catch (t) {
        console.error('Error tokenizing '.concat(e.Ref, ': ').concat(t.message || t));
        return;
      }
      if (u.length === 1 && u[0].t === '3') {
        var v = u[0].v;
        var c = v.replace(/\$/g, '').split('!');
        var f = c[c.length - 1];
        var _ = c.length > 1 ? r.Sheets[c[0].replace(/'/g, '')] : r.Sheets[i.ws];
        if (!_) {
          console.error('Error finding '.concat(e.Ref));
          return;
        }
        var s = Array.isArray(_);
        if (f.indexOf(':') === -1) {
          var o = t.Util.decode_cell(f);
          var l = s ? _[o.r] && _[o.r][o.c] : _[f];
          if (l) {
            if (!l.depcells) l.depcells = new Set();
            l.depcells.add(i);
          }
        } else {
          var U = 1e5;
          var h = 16384;
          var b = 0;
          var m = 0;
          var E = f.split(':');
          var p = false;
          var A = false;
          for (var x = 0; x < E.length; ++x) {
            var S = t.Util.decode_cell(E[x]);
            if (E[x].match(/^\d/)) A = true;
            if (E[x].match(/[A-Z]$/)) p = true;
            if (S.c < h) h = S.c;
            if (S.r < U) U = S.r;
            if (S.c > m) m = S.c;
            if (S.r > b) b = S.r;
          }
          if (h < 0 || A) h = 0;
          if (h > 16383) h = 16383;
          if (m < 0 || A) m = 16383;
          if (m > 16383) m = 16383;
          if (U < 0 || p) U = 0;
          if (U > 1e5) U = 1e5;
          if (b < 0 || p) b = 1e5;
          if (b > 1e5) b = 1e5;
          if (h > m || U > b) return;
          for (var d = h; d <= m; ++d) {
            var M = a[d];
            for (var N = U; N <= b; ++N) {
              var g = M + n[N];
              var l = s ? _[N] && _[N][d] : _[g];
              if (l) {
                if (!l.depcells) l.depcells = new Set();
                l.depcells.add(i);
              }
            }
          }
        }
      }
    }
    function v(r, i, u, v, c) {
      var f = r.Sheets[i];
      var _ = Array.isArray(f);
      var s = t.Util.decode_cell(u);
      var o = _ ? f[s.r][s.c] : f[u];
      var l = { ws: i, addr: u, cell: o };
      var U = e(v);
      for (var h = 0; h < U.length; ++h) {
        var b = U[h][0];
        var m = b.replace(/\$/g, '').split('!');
        var E = m[m.length - 1];
        if (U[h][1] === 'i') {
          var p = t.Util.get_defined_name(r, E, m.length === 1 ? i : m[0].replace(/'/g, ''));
          if (!p) continue;
          if (!p.depcells) p.depcells = new Set();
          p.depcells.add(l);
          continue;
        }
        var A = m.length > 1 ? r.Sheets[m[0].replace(/'/g, '')] : f;
        if (E.indexOf(':') === -1) {
          var x = t.Util.decode_cell(E);
          var S = _ ? A[x.r] && A[x.r][x.c] : A[E];
          if (S) {
            if (!S.depcells) S.depcells = new Set();
            S.depcells.add(l);
          }
        } else {
          var d = 1e5;
          var M = 16384;
          var N = 0;
          var g = 0;
          var L = E.split(':');
          var I = false;
          var k = false;
          for (var T = 0; T < L.length; ++T) {
            var w = t.Util.decode_cell(L[T]);
            if (L[T].match(/^\d/)) k = true;
            if (L[T].match(/[A-Z]$/)) I = true;
            if (w.c < M) M = w.c;
            if (w.r < d) d = w.r;
            if (w.c > g) g = w.c;
            if (w.r > N) N = w.r;
          }
          if (M < 0 || k) M = 0;
          if (M > 16383) M = 16383;
          if (g < 0 || k) g = 16383;
          if (g > 16383) g = 16383;
          if (d < 0 || I) d = 0;
          if (d > 1e5) d = 1e5;
          if (N < 0 || I) N = 1e5;
          if (N > 1e5) N = 1e5;
          if (M > g || d > N) continue;
          for (var y = M; y <= g; ++y) {
            var O = a[y];
            for (var D = d; D <= N; ++D) {
              var C = O + n[D];
              var S = _ ? A[D] && A[D][y] : A[C];
              if (S) {
                if (!S.depcells) S.depcells = new Set();
                S.depcells.add(l);
              }
            }
          }
        }
      }
    }
    function c(r) {
      var e = Array.isArray(r.Sheets[r.SheetNames[0]]);
      var i = { Sheets: {}, Names: {}, GlobalNames: [] };
      r.SheetNames.forEach(function (t) {
        if (!i.Sheets[t]) i.Sheets[t] = {};
      });
      r.SheetNames.map(function (t) {
        return [t, r.Sheets[t]];
      }).forEach(function (u) {
        var c = u[0],
          f = u[1];
        if (!f || !f['!ref']) return;
        var _ = t.Util.safe_decode_range(f['!ref']);
        for (var s = _.s.c; s <= _.e.c; ++s) {
          var o = a[s];
          for (var l = _.s.r; l <= _.e.r; ++l) {
            var U = o + n[l];
            var h = e ? f[l] && f[l][s] : f[U];
            if (!h) continue;
            var b = h.f;
            if (!b) continue;
            if (!h.F) v(r, c, U, b, i);
            else {
              var m = t.Util.safe_decode_range(h.F);
              for (var E = m.s.c; E <= m.e.c; ++E) {
                var p = a[E];
                for (var A = m.s.r; A <= m.e.r; ++A) {
                  v(r, c, p + n[A], b, i);
                }
              }
            }
          }
        }
      });
      if (r.Workbook && r.Workbook.Names)
        r.Workbook.Names.forEach(function (t) {
          u(r, t);
        });
      return i;
    }
    r.build_d = c;
    var f = function (r, e, a, n) {
      var i = 0;
      var u = r.Sheets[e];
      var v = Array.isArray(u);
      var c = t.Util.decode_cell(a);
      var f = new Set();
      var _ = { ws: e, addr: a, cell: v ? u[c.r][c.c] : u[a] };
      f.add(_);
      f.forEach(function (e) {
        var a = e.ws,
          n = e.addr,
          u = e.cell,
          c = e.name;
        f.delete(e);
        var _ = true;
        if (c) {
        } else {
          try {
            if (u.F) {
              var s = t.Calc.evaluate_array(u.f, r, a, t.Util.decode_cell(n));
              var o = void 0;
              if (s.t === 'A') o = s;
              if (!o) {
                switch (s.t) {
                  case 'b':
                  case 'e':
                  case 'n':
                  case 'd':
                  case 's':
                  case 'z':
                    {
                      if (u.D) u.F = n;
                      if (u.t === s.t && u.v === s.v) {
                        _ = false;
                        break;
                      }
                      u.t = s.t;
                      if (s.t !== 'z') u.v = s.v;
                      delete u.w;
                      delete u.h;
                    }
                    break;
                  case 'C':
                  case 'R':
                  case 'U':
                  case 'y':
                  default:
                    throw 'Dynamic Array Formula!';
                }
              } else {
                var l = r.Sheets[a];
                var U = t.XLSXLib.utils.decode_range(l['!ref']);
                var h = t.XLSXLib.utils.decode_cell(n);
                U.e.r = Math.max(h.r + o.v.length - 1, U.e.r);
                U.e.c = Math.max(
                  h.c +
                    o.v.reduce(function (t, r) {
                      return Math.max(t, r.length);
                    }, 1) -
                    1,
                  U.e.c,
                );
                l['!ref'] = t.XLSXLib.utils.encode_range(U);
                if (u.D)
                  u.F = t.XLSXLib.utils.encode_range({
                    s: { c: h.c, r: h.r },
                    e: {
                      r: Math.max(h.r + o.v.length - 1, h.r),
                      c: Math.max(
                        h.c +
                          o.v.reduce(function (t, r) {
                            return Math.max(t, r.length);
                          }, 1) -
                          1,
                        h.c,
                      ),
                    },
                  });
                o.v.forEach(function (r, e) {
                  r.forEach(function (r, a) {
                    var n = t.XLSXLib.utils.encode_cell({ c: h.c + a, r: h.r + e });
                    if (v && !l[h.r + e]) l[h.r + e] = [];
                    var i = v ? l[h.r + e][h.c + a] : l[n];
                    if (!i) {
                      if (v) i = l[h.r + e][h.c + a] = JSON.parse(JSON.stringify(r));
                      else i = l[n] = JSON.parse(JSON.stringify(r));
                      i.F = u.F;
                    } else {
                      i.F = u.F;
                      if (i.t !== r.t || i.v !== r.v) {
                        i.t = r.t;
                        i.v = r.v;
                        delete i.w;
                        delete i.h;
                        if (i && i.depcells)
                          i.depcells.forEach(function (t) {
                            if (f.has(t)) f.delete(t);
                            f.add(t);
                          });
                      }
                    }
                  });
                });
              }
            } else if (u.f) {
              var b = u.v;
              var s = t.Calc.evaluate(u.f, r, a, t.Util.decode_cell(n));
              u.v = s.v;
              if (u.v === b) _ = false;
              else {
                delete u.w;
                delete u.h;
              }
              if (u.t !== s.t) _ = true;
              u.t = s.t;
            } else if (i > 0) _ = false;
          } catch (t) {
            throw t;
          }
        }
        if (_) {
          if (u && u.depcells)
            u.depcells.forEach(function (t) {
              if (f.has(t)) f.delete(t);
              f.add(t);
            });
          if (c && c.depcells)
            c.depcells.forEach(function (t) {
              if (f.has(t)) f.delete(t);
              f.add(t);
            });
        }
        ++i;
      });
    };
    function _(r, e, a, n, i) {
      if (!i) i = c(r);
      var u = typeof e === 'string' ? e : r.SheetNames[e];
      var v = typeof a === 'string' ? a : t.Util.encode_cell(a);
      var _ = false;
      var s = r.Sheets[u];
      var o = Array.isArray(s);
      var l = t.Util.decode_cell(v);
      var U;
      U = o ? s[l.r] && s[l.r][l.c] : s[v];
      if (!U) {
        _ = true;
        if (o) {
          if (!s[l.r]) s[l.r] = [];
          if (!s[l.r][l.c]) U = s[l.r][l.c] = { t: 'z' };
        } else {
          U = s[v] = { t: 'z' };
        }
        var h = t.Util.safe_decode_range(s['!ref']);
        h.s.c = Math.min(h.s.c, l.c);
        h.e.c = Math.max(h.e.c, l.c);
        h.s.r = Math.min(h.s.r, l.r);
        h.e.r = Math.max(h.e.r, l.r);
        s['!ref'] = t.XLSXLib.utils.encode_range(h);
      }
      if (U.f) {
        _ = true;
        delete U.f;
      }
      if (U.v !== n) {
        _ = true;
        delete U.w;
        U.v = n;
      }
      switch (typeof n) {
        case 'boolean':
          if (U.t !== 'b') {
            _ = true;
            U.t = 'b';
          }
          break;
        case 'number':
          if (U.t !== 'n') {
            _ = true;
            U.t = 'n';
          }
          break;
        case 'string':
          if (U.t !== 's') {
            _ = true;
            U.t = 's';
          }
          break;
      }
      if (!_) return i;
      f(r, u, v, i);
      return i;
    }
    r.update_v = _;
    function s(r, e, a, n, i) {
      if (!i) i = c(r);
      var u = typeof e === 'string' ? e : r.SheetNames[e];
      var _ = typeof a === 'string' ? a : t.Util.encode_cell(a);
      var s = false;
      var o = r.Sheets[u];
      var l = Array.isArray(o);
      var U = t.Util.decode_cell(_);
      var h;
      h = l ? o[U.r] && o[U.r][U.c] : o[_];
      if (!h) {
        s = true;
        if (l) {
          if (!o[U.r]) o[U.r] = [];
          if (!o[U.r][U.c]) h = o[U.r][U.c] = { t: 'z' };
        } else {
          h = o[_] = { t: 'z' };
        }
        var b = t.Util.safe_decode_range(o['!ref']);
        b.s.c = Math.min(b.s.c, U.c);
        b.e.c = Math.max(b.e.c, U.c);
        b.s.r = Math.min(b.s.r, U.r);
        b.e.r = Math.max(b.e.r, U.r);
        o['!ref'] = t.XLSXLib.utils.encode_range(b);
      }
      if (h.f !== n) {
        s = true;
        h.f = n;
      }
      if (!s) return i;
      v(r, u, _, h.f, i);
      f(r, u, _, i);
      return i;
    }
    r.update_f = s;
    function o(r, e) {
      if (!e) e = c(r);
      r.SheetNames.map(function (t) {
        return [t, r.Sheets[t]];
      }).forEach(function (r) {
        var e = r[0],
          i = r[1];
        if (!i || !i['!ref']) return;
        var u = Array.isArray(i);
        var v = t.Util.safe_decode_range(i['!ref']);
        for (var c = v.s.c; c <= v.e.c; ++c) {
          var f = a[c];
          for (var _ = v.s.r; _ <= v.e.r; ++_) {
            var s = u ? i[_] && i[_][c] : i[f + n[_]];
            if (!s) continue;
            if (!s.f) continue;
            delete s.v;
            delete s.w;
            delete s.h;
          }
        }
      });
      r.SheetNames.map(function (t, e) {
        return [t, e, r.Sheets[t]];
      }).forEach(function (i) {
        var u = i[0],
          v = i[1],
          c = i[2];
        if (!c || !c['!ref']) return;
        var f = Array.isArray(c);
        var _ = t.Util.safe_decode_range(c['!ref']);
        for (var o = _.s.r; o <= _.e.r; ++o) {
          for (var l = _.s.c; l <= _.e.c; ++l) {
            var U = a[l] + n[o];
            var h = f ? c[o] && c[o][l] : c[U];
            if (!h) continue;
            if (!h.f) continue;
            var b = h.f;
            delete h.f;
            try {
              s(r, v, U, b, e);
            } catch (t) {}
          }
        }
      });
      return e;
    }
    r.recalc = o;
  })((r = t.Deps || (t.Deps = {})));
  t.update_value = r.update_v;
  t.update_formula = r.update_f;
  t.recalculate = r.recalc;
  t.build_deps = r.build_d;
})(S5SCalc || (S5SCalc = {}));
var S5SCalc;
(function (t) {
  var r;
  (function (r) {
    var e = [['^'], ['*', '/'], ['+', '-'], ['&'], ['=', '>', '<', '>=', '<=', '<>']];
    var a = ['i', 'a', 'n'];
    var n = ['i', 'a'];
    var i = new Map();
    var u =
      /^((?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})?|(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5}))(?![A-Za-z0-9_])/;
    var v =
      /^\$?((?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})?|(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5}))(?![A-Za-z0-9_])/;
    var c =
      /^\$(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})/;
    var f = /^\$(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\b/;
    var _ = /^\$(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})\b/;
    var s =
      /^(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})\b/;
    var o = /[_a-zA-Z\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]/;
    var l = /[_a-zA-Z0-9\.\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]/;
    var U =
      /^(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})/;
    var h = /^(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})(?![A-Za-z_])/;
    var b = /^[_a-zA-Z0-9\.\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]+/;
    function m(t) {
      var r = [];
      var e = 0;
      var a = 0;
      while (a < t.length) {
        var n = t.charAt(a);
        switch (n) {
          case '#':
            if (t.slice(a, a + 6) === '#NULL!') {
              r.push({ t: 'e', v: 0 });
              a += 6;
            } else if (t.slice(a, a + 7) === '#DIV/0!') {
              r.push({ t: 'e', v: 7 });
              a += 7;
            } else if (t.slice(a, a + 7) === '#VALUE!') {
              r.push({ t: 'e', v: 15 });
              a += 7;
            } else if (t.slice(a, a + 5) === '#REF!') {
              r.push({ t: 'e', v: 23 });
              a += 5;
              var i = t.slice(a).match(v);
              if (i != null) {
                a += i[0].length;
                while (t.charAt(a) === ':') {
                  ++a;
                  i = t.slice(a).match(v);
                  if (i != null) a += i[0].length;
                }
              }
            } else if (t.slice(a, a + 6) === '#NAME?') {
              r.push({ t: 'e', v: 29 });
              a += 6;
            } else if (t.slice(a, a + 5) === '#NUM!') {
              r.push({ t: 'e', v: 36 });
              a += 5;
            } else if (t.slice(a, a + 4) === '#N/A') {
              r.push({ t: 'e', v: 42 });
              a += 4;
            } else if (t.slice(a, a + 13) === '#GETTING_DATA') {
              r.push({ t: 'e', v: 43 });
              a += 13;
            } else throw 'bad error literal : |'.concat(t.slice(a, a + 10), '|');
            e = 1;
            break;
          case '(':
          case ')':
            r.push({ t: 'p', v: n });
            if (n === '(') e = 0;
            else e = 1;
            ++a;
            break;
          case ',':
            r.push({ t: ',' });
            ++a;
            if (e === 1) e = 0;
            break;
          case ';':
            r.push({ t: ';' });
            ++a;
            break;
          case '+':
          case '-':
            if (e === 0 || r[r.length - 1].t === '2') r.push({ t: '1', v: n });
            else {
              r.push({ t: '2', v: n });
              if (e === 0) e = 1;
            }
            ++a;
            break;
          case '%':
            r.push({ t: '1', v: n });
            ++a;
            break;
          case '*':
          case '/':
          case '&':
          case '^':
            r.push({ t: '2', v: n });
            if (e === 0) e = 1;
            ++a;
            break;
          case '=':
            r.push({ t: '2', v: n });
            ++a;
            break;
          case '<':
            if (t.charAt(a + 1) === '>') {
              r.push({ t: '2', v: '<>' });
              a += 2;
              break;
            }
          case '>':
            if (t.charAt(a + 1) === '=') {
              r.push({ t: '2', v: n === '>' ? '>=' : '<=' });
              ++a;
            } else r.push({ t: '2', v: n });
            ++a;
            break;
          case '"': {
            ++a;
            var m = '';
            while (((n = t.charAt(a)) !== '"' || t.charAt(a + 1) === '"') && a < t.length) {
              m += n;
              a += n === '"' ? 2 : 1;
            }
            if (a >= t.length) throw 'unterminated string literal |'.concat(t, '|');
            r.push({ t: 's', v: m });
            ++a;
            e = 1;
            break;
          }
          case "'": {
            e = 1;
            ++a;
            var m = '';
            while (((n = t.charAt(a)) !== "'" || t.charAt(a + 1) === "'") && a < t.length) {
              m += n;
              a += n === "'" ? 2 : 1;
            }
            if ((n = t.charAt(++a)) !== '!') throw 'expected ! saw '.concat(n);
            m += '!';
            ++a;
            if ((n = t.charAt(a)) === '$') {
              a++;
              m += '$';
            }
            if (t.charAt(a) === '#') {
              if (t.slice(a, a + 6) === '#NULL!') {
                r.push({ t: 'e', v: 0 });
                a += 6;
                break;
              } else if (t.slice(a, a + 7) === '#DIV/0!') {
                r.push({ t: 'e', v: 7 });
                a += 7;
                break;
              } else if (t.slice(a, a + 7) === '#VALUE!') {
                r.push({ t: 'e', v: 15 });
                a += 7;
                break;
              } else if (t.slice(a, a + 5) === '#REF!') {
                r.push({ t: 'e', v: 23 });
                a += 5;
                break;
              } else if (t.slice(a, a + 6) === '#NAME?') {
                r.push({ t: 'e', v: 29 });
                a += 6;
                break;
              } else if (t.slice(a, a + 5) === '#NUM!') {
                r.push({ t: 'e', v: 36 });
                a += 5;
                break;
              } else if (t.slice(a, a + 4) === '#N/A') {
                r.push({ t: 'e', v: 42 });
                a += 4;
                break;
              } else if (t.slice(a, a + 13) === '#GETTING_DATA') {
                r.push({ t: 'e', v: 43 });
                a += 13;
                break;
              }
            }
            var E = t.slice(a).match(u);
            if (!E) {
              try {
                E = t.slice(a).match(/^[A-Za-z_\\][A-Za-z0-9_\.$]*/);
                if (E) {
                  m += E[0];
                  a += E[0].length;
                  if (m.indexOf(':') > -1 && m.indexOf("'") === -1) m = "'" + m.replace('!', "'!");
                  r.push({ t: 'I', v: m });
                  break;
                }
                throw 'wafuq';
              } catch (r) {
                throw 'Expected Cell Reference or ID at: ' + t;
              }
            }
            m += E[0];
            a += E[0].length;
            if (t.charAt(a) === ':') {
              ++a;
              m += ':';
              E = t.slice(a).match(v);
              if (E) {
                m += E[0];
                a += E[0].length;
              } else throw 'expected cell reference |'.concat(t.slice(a), '|');
            }
            r.push({ t: '3', v: m });
            break;
          }
          case '$': {
            var p = t.slice(a).match(c);
            if (!p) p = t.slice(a).match(f);
            if (!p) p = t.slice(a).match(_);
            if (p) {
              r.push({ t: 'a', v: p[0] });
              e = 1;
              a += p[0].length;
              break;
            }
            throw '|'
              .concat(t, '| $ only valid in cell/range address: ')
              .concat(a, '...')
              .concat(t.slice(a, a + 3));
          }
          case ' ':
          case '\n':
          case '\r': {
            while ([' ', '\n', '\r'].indexOf(t.charAt(a)) >= 0) ++a;
            r.push({ t: ' ' });
            break;
          }
          case ':':
            r.push({ t: ':' });
            ++a;
            break;
          case '{':
          case '}':
            r.push({ t: 'c', v: n });
            ++a;
            break;
          case '[':
            if (t.slice(a, a + 4) === '[0]!') {
              a += 4;
              break;
            }
          default: {
            e = 1;
            var A = t.slice(a).match(/^(?:(?:\d+(?:\.\d*)?)|(?:\.\d+))(?:E[+-]?\d+)?(?![\d:])/);
            if (A) {
              r.push({ t: 'n', v: parseFloat(A[0]) });
              a += A[0].length;
              break;
            }
            var E = t.slice(a).match(s);
            if (E) {
              a += E[0].length;
              r.push({ t: 'a', v: E[0] });
              break;
            } else if (n.match(o)) {
              while (t.charAt(a + 1).match(l)) n += t.charAt(++a);
              ++a;
              if (t.charAt(a) === '!') {
                n += '!';
                ++a;
                if (t.charAt(a) === '$') {
                  n += '$';
                  ++a;
                }
                E = t.slice(a).match(U);
                if (E) {
                  n += E[0];
                  a += E[0].length;
                  r.push({ t: '3', v: n });
                } else {
                  E = t.slice(a).match(h);
                  if (E) {
                    n += E[0];
                    a += E[0].length;
                    if ((E = t.slice(a).match(/^:\$?(?:[A-W][A-Z]{2}|X[A-E][A-Z]|XF[A-D]|[A-Z]{1,2})(?![A-Za-z0-9_\.])/))) {
                      n += E[0];
                      a += E[0].length;
                    }
                    r.push({ t: 'k', v: n });
                  } else {
                    E = t.slice(a).match(/^(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})/);
                    if (E) {
                      n += E[0];
                      a += E[0].length;
                      if (
                        (E = t.slice(a).match(/^:\$?(?:10[0-3]\d{4}|104[0-7]\d{3}|1048[0-4]\d{2}|10485[0-6]\d|104857[0-6]|[1-9]\d{0,5})/))
                      ) {
                        n += E[0];
                        a += E[0].length;
                      }
                      r.push({ t: 'j', v: n });
                    } else {
                      E = t.slice(a).match(b);
                      if (E) {
                        n += E[0];
                        a += E[0].length;
                        r.push({ t: '3', v: n });
                      }
                    }
                  }
                }
                if (!E)
                  throw '|'
                    .concat(t, '| bad char |')
                    .concat(n, ' ')
                    .concat(n.charCodeAt(0), '| ')
                    .concat(a, ': :::')
                    .concat(t.slice(a, a + 3));
              } else {
                var x = n.toLowerCase();
                if (x === 'false' && t.charAt(a) !== '(') r.push({ t: 'b', v: false });
                else if (x === 'true' && t.charAt(a) !== '(') r.push({ t: 'b', v: true });
                else r.push({ t: 'i', v: n });
              }
              break;
            } else if (t.slice(a).match(/\d+:\d+/)) {
              var S = t.slice(a).match(/(\d+):(\d+)/);
              r.push({ t: 'i', v: S[1] });
              r.push({ t: ':' });
              r.push({ t: 'i', v: S[2] });
              a += S[0].length;
              break;
            }
            throw '|'
              .concat(t, '| bad char |')
              .concat(n, ' ')
              .concat(n.charCodeAt(0), '| ')
              .concat(a, ': ...')
              .concat(t.slice(a, a + 3));
          }
        }
      }
      for (var d = 1; d < r.length - 1; ++d) {
        if (r[d].t === ':' && r[d - 1].t === '3' && r[d + 1].t === '3') {
          var M = r[d - 1].v.replace(/!.*$/, '');
          var N = r[d - 1].v.replace(/!.*$/, '');
          if (M.toLowerCase() === N.toLowerCase()) {
            r.splice(d - 1, 3, { t: '3', v: r[d - 1].v + ':' + r[d + 1].v.replace(N + '!', '') });
          }
        }
      }
      return r;
    }
    r._tokenize = m;
    function E(r, e) {
      if (!e) return t.Util._box_err('#VALUE!');
      var a = r.a
        .replace(/^[?]*/, '')
        .split('?')
        .map(function (r) {
          return r ? t.Util.safe_decode_range(r) : null;
        });
      if (r.v.length === 1) {
        if (a[1]) {
          if (a[1].s.c !== a[0].s.c) return t.Util._box_err('#VALUE!');
          if (a[1].e.c !== a[0].e.c) return t.Util._box_err('#VALUE!');
        }
        if (a[0].s.c > e.c) return t.Util._box_err('#VALUE!');
        if (a[0].e.c < e.c) return t.Util._box_err('#VALUE!');
        return r.v[0][e.c - a[0].s.c];
      }
      for (var n = 0; n < r.v.length; ++n) {
        if (r.v[n].length !== 1) return t.Util._box_err('#VALUE!');
      }
      if (a[1]) {
        if (a[1].s.r !== a[0].s.r) return t.Util._box_err('#VALUE!');
        if (a[1].e.r !== a[0].e.r) return t.Util._box_err('#VALUE!');
      }
      if (a[0].s.r > e.r) return t.Util._box_err('#VALUE!');
      if (a[0].e.r < e.r) return t.Util._box_err('#VALUE!');
      return r.v[e.r - a[0].s.r][0];
    }
    function p(r, e, a) {
      var n;
      if (Array.isArray(r)) {
        var i = t.Util.decode_cell(e);
        n = r[i.r] && r[i.r][i.c];
      } else n = r[e];
      if (!n) return a ? { t: 'n', v: 0, a: e } : { t: 'z', a: e };
      return { t: n.t, v: n.v, a: e };
    }
    function A(r, e, a) {
      var n = { a: e, t: 'A', v: [] };
      if (a) {
        n.ws = r;
        n.deferred = true;
        return n;
      }
      var i = t.Util.decode_multi_range(e);
      if (r && r['!ref']) {
        var u = t.Util.safe_decode_range(r['!ref']);
        if (i.e.c > u.e.c) {
          i.e.c = u.e.c;
          n.x = true;
        }
        if (i.e.r > u.e.r) {
          i.e.r = u.e.r;
          n.x = true;
        }
      }
      for (var v = i.s.r; v <= i.e.r; ++v) {
        if (!n.v[v - i.s.r]) n.v[v - i.s.r] = [];
      }
      for (var c = i.s.c; c <= i.e.c; ++c) {
        var f = t.XLSXLib.utils.encode_col(c);
        for (var v = i.s.r; v <= i.e.r; ++v) {
          var _ = f + (v + 1);
          var s = Array.isArray(r) ? r[v] && r[v][c] : r[_];
          if (!s) n.v[v - i.s.r][c - i.s.c] = { t: 'z' };
          else n.v[v - i.s.r][c - i.s.c] = { t: s.t, v: s.v };
        }
      }
      return n;
    }
    function x(r, e, a, n, i) {
      if (e.match(/^[A-Z]+[0-9]+$/)) {
        if (!r || !r.Sheets || !r.Sheets[r.SheetNames[a]]) return t.Util._box_err('#REF!');
        return p(r.Sheets[r.SheetNames[a]], e);
      }
      if (e === 'TRUE' || e === 'FALSE') return { t: 'b', v: e === 'TRUE' };
      if (!r) return t.Util._box_err('#REF!');
      var u = t.Util.get_defined_name(r, e, a);
      if (!u) return t.Util._box_err('#NAME?');
      var v = u.Ref;
      try {
        var c = t.Util.book_get_range(r, v);
        if (v.indexOf(':') === -1) {
          var f = 'z';
          switch (typeof c[0][0]) {
            case 'number':
              f = 'n';
              break;
            case 'string':
              f = 's';
              break;
            case 'boolean':
              f = 'b';
              break;
          }
          return { t: f, v: c[0][0] };
        }
      } catch (t) {}
      try {
        if (i) return q(v, r, a, n);
        return H(v, r, a, n);
      } catch (t) {}
      throw 'Unsupported ID: '.concat(e, ' ').concat(JSON.stringify(u), ' ').concat(i);
    }
    function S(t) {
      return t.map(function (t) {
        return t.map(function (t) {
          switch (typeof t) {
            case 'number':
              return { t: 'n', v: t };
            case 'boolean':
              return { t: 'b', v: t };
            case 'string':
              return { t: 's', v: t };
            default:
              throw 'cannot lift '.concat(t, ' to cell result');
          }
        });
      });
    }
    function d(r, e, a, n, i) {
      if (e.match(/^[A-Z]+[0-9]+$/)) {
        if (!r || !r.Sheets || !r.Sheets[r.SheetNames[a]]) return t.Util._box_err('#REF!');
        return p(r.Sheets[r.SheetNames[a]], e);
      }
      if (e === 'TRUE' || e === 'FALSE') return { t: 'b', v: e === 'TRUE' };
      if (!r) return t.Util._box_err('#REF!');
      var u = t.Util.get_defined_name(r, e, a);
      if (!u) return t.Util._box_err('#NAME?');
      var v = u.Ref;
      try {
        var c = t.Util.book_get_range(r, v);
        if (v.indexOf(':') === -1) {
          var f = 'z';
          switch (typeof c[0][0]) {
            case 'number':
              f = 'n';
              break;
            case 'string':
              f = 's';
              break;
            case 'boolean':
              f = 'b';
              break;
          }
          return { t: f, v: c[0][0] };
        }
        return { t: 'A', a: v.slice(v.indexOf('!') + 1), v: S(c) };
      } catch (t) {
        return i ? q(v, r, a, n) : q(v, r, a, n);
      }
    }
    var M = [];
    var N = {};
    function g(t, r) {
      if (M.indexOf(t) > -1) return false;
      M.push(t);
      N[t] = r;
      return true;
    }
    r.add_custom_func = g;
    function L(t) {
      var r = M.indexOf(t);
      if (r === -1) return false;
      delete N[t];
      M.splice(r, 1);
      return true;
    }
    r.remove_custom_func = L;
    function I() {
      return M.map(function (t) {
        return [t, N[t]];
      });
    }
    r.get_custom_funcs = I;
    var k = ['INDIRECT', 'OFFSET'];
    var T = ['SUM', 'AVERAGE', 'MAX', 'GROWTH', 'TREND', 'LOOKUP', 'INDEX', 'MATCH', 'TRANSPOSE', 'MIN', 'MAX', 'OFFSET'];
    var w = ['VLOOKUP'];
    function y(r, e, a, n, i, u) {
      var v = e.shift();
      var c = v.v.replace(/^_xlfn\./, '');
      var f = c.toUpperCase();
      var _ = w.indexOf(f) > -1;
      var s = [];
      var o = 0;
      var l = 0;
      var U = ['SUMPRODUCT'].indexOf(f) > -1 ? true : u;
      var h = 2;
      for (var b = 0; b < e.length; ++b) {
        if (e[b].t === ',' && l === 0) {
          var m = true;
          if (f === 'IF') {
            if (s.length === 0) {
              s.push(P(r, e.slice(o, b), a, n, i + 1, U, _));
              m = false;
              var p = t.Util._cast_bool(s[0]);
              h = p.t === 'e' ? 2 : p.v ? 1 : 0;
            } else if (s.length === 1 && h !== 1) {
              m = false;
              s.push(t.Util._box_err('#VALUE!'));
            } else if (s.length === 2 && h !== 0) {
              m = false;
              s.push(t.Util._box_err('#VALUE!'));
            }
          }
          if (m) s.push(P(r, e.slice(o, b), a, n, i + 1, U, _));
          if (k.indexOf(e[o].v) > -1) {
            var x = s.pop();
            if (x.t === 'e') s.push(x);
            else s.push(P(r, [{ t: 'a', v: x.v }], a, n, i + 1, U, _));
          }
          o = b + 1;
        } else if (e[b].t === 'p') l += e[b].v === '(' ? 1 : -1;
        else if (e[b].t === 'c') l += e[b].v === '{' ? 1 : -1;
      }
      if (o < e.length || (e.length > 0 && e[o - 1] && e[o - 1].t === ',')) {
        if (o === e.length) e.push({ t: ' ' });
        var m = true;
        if (f === 'IF') {
          if (s.length === 0) {
            s.push(P(r, e.slice(o), a, n, i + 1, U, _));
            m = false;
            var p = t.Util._cast_bool(s[0]);
            h = p.t === 'e' ? 2 : p.v ? 1 : 0;
          } else if (s.length === 1 && h !== 1) {
            m = false;
            s.push(t.Util._box_err('#VALUE!'));
          } else if (s.length === 2 && h !== 0) {
            m = false;
            s.push(t.Util._box_err('#VALUE!'));
          }
        }
        if (f === 'ANCHORARRAY') {
          if (e[o].t === 'a') {
            var S = r.Sheets[r.SheetNames[a]];
            var d = void 0;
            if (S) {
              var M = e[o].v;
              if (Array.isArray(S)) {
                var g = t.Util.decode_cell(M);
                d = S[g.r] && S[g.r][g.c];
              } else d = S[M];
            }
            if (d) {
              if (d.F) {
                var L = d.F.split(':');
                return P(r, [{ t: 'a', v: L[0] }, { t: ':' }, { t: 'a', v: L[1] }], a, n, i + 1, U);
              }
            }
          }
        }
        if (m) s.push(P(r, e.slice(o), a, n, i + 1, U, _));
        if (k.indexOf(e[o].v) > -1) {
          var I = s.pop();
          if (I.t !== 's') s.push(I);
          else s.push(P(r, [{ t: 'a', v: I.v }], a, n, i + 1, U, _));
        }
      }
      if (!N[c] && !t.Func[c]) {
        return t.Util._box_err('#NAME?');
      }
      var y = N[c] || t.Func[c];
      if (!u && ['SUMPRODUCT', 'COUNTIF'].indexOf(f) === -1) {
        for (var O = 0; O < s.length; ++O)
          if (s[O].t === 'A') {
            if ((s[O].a || '').charAt(0) !== '?') continue;
            s[O] = E(s[O], typeof n === 'string' ? t.XLSXLib.utils.decode_cell(n) : n);
          }
      }
      if (!u || ['SUMPRODUCT', 'COUNTIF'].indexOf(f) > -1) {
        var D = y(s, r, a, n, u);
        if (f === 'OFFSET' && D.t === 's') {
          return A(r.Sheets[r.SheetNames[a]], D.v);
        }
        return D;
      }
      if (T.indexOf(f) > -1) {
        var D = y(s, r, a, n, u);
        if (k.indexOf(f) === -1) return D;
        return D;
      }
      var C = { t: 'A', a: '', v: [] };
      var R = Infinity;
      var V = -Infinity;
      var F = Infinity;
      var X = -Infinity;
      s.forEach(function (t) {
        if (t.t !== 'A' || t.v.length === 0) return;
        R = Math.min(t.v.length, R);
        F = Math.min(t.v[0].length, F);
        V = Math.max(t.v.length, V);
        X = Math.max(t.v[0].length, X);
      });
      if (R === Infinity || F === Infinity) {
        return y(s, r, a, n);
      }
      var B = function (e) {
        C.v[e] = [];
        var i = function (i) {
          var u = [];
          s.forEach(function (r, a) {
            if (r.t !== 'A') u[a] = r;
            else {
              u[a] = { t: r.v[e][i].t, v: r.v[e][i].v };
              var n = r.a ? t.Util.decode_multi_range(r.a) : null;
              if (n) u[a].a = t.Util.encode_cell({ r: e + n.s.r, c: i + n.s.c });
            }
          });
          C.v[e][i] = y(u, r, a, n);
        };
        for (var u = 0; u < F; ++u) {
          i(u);
        }
      };
      for (var G = 0; G < R; ++G) {
        B(G);
      }
      return C;
    }
    function O(r, e, a, n, i) {
      switch (e.t) {
        case 'i':
          return x(r, e.v, a, n, i);
        case 'a':
          if (!r || !r.Sheets || !r.Sheets[r.SheetNames[a]]) return t.Util._box_err('#REF!');
          if (Array.isArray(e.v)) {
            if (e.v.length !== 1 || e.v[0].length !== 1) throw 'Bad name '.concat(e);
            return JSON.parse(JSON.stringify(e.v[0][0]));
          }
          return p(r.Sheets[r.SheetNames[a]], e.v.replace(/\$/g, ''));
      }
    }
    function D(r, e, a) {
      if (r.t === 'e') return r;
      if (e.t === 'e') return e;
      if (r.t === 'n' && e.t === 'n')
        switch (a) {
          case '+':
            return t.Util._box_num(r.v + e.v);
          case '*':
            return t.Util._box_num(r.v * e.v);
          case '^':
            return t.Util._box_num(Math.pow(r.v, e.v));
          case '-':
            return t.Util._box_num(r.v - e.v);
          case '/': {
            if (e.v === 0 || isNaN(r.v / e.v)) return { t: 'e', v: 7 };
            return t.Util._box_num(r.v / e.v);
          }
        }
      return { t: 'e', v: 15 };
    }
    function C(t, r, e) {
      if (t.t === 'z' || r.t === 'z') {
        if (t.t === r.t) return { t: 'b', v: e.indexOf('=') > -1 };
        if (t.t === 'e') return t;
        if (r.t === 'e') return r;
        var a = t.t === 'z' ? r.v : t.v;
        switch (e) {
          case '=':
            return { t: 'b', v: a === 0 || a === '' || a === false };
          case '<>':
            return { t: 'b', v: !(a === 0 || a === '' || a === false) };
        }
        var n = false;
        var i = null;
        switch (typeof a) {
          case 'boolean':
            i = false;
            break;
          case 'number':
            i = 0;
            break;
          case 'string':
            i = '';
            break;
        }
        if (i == null) return { t: 'b', v: n };
        switch (e) {
          case '<=':
            n = t.t === 'z' ? i <= a : a <= i;
            break;
          case '>=':
            n = t.t === 'z' ? i >= a : a >= i;
            break;
          case '<':
            n = t.t === 'z' ? i < a : a < i;
            break;
          case '>':
            n = t.t === 'z' ? i > a : a > i;
            break;
        }
        return { t: 'b', v: n };
      }
      if (t.t === 'n' && r.t === 's') r = { t: 'n', v: Infinity };
      if (t.t === 's' && r.t === 'n') t = { t: 'n', v: Infinity };
      var u = t.t === 's' ? t.v.toLowerCase() : t.v;
      var v = r.t === 's' ? r.v.toLowerCase() : r.v;
      switch (e) {
        case '=':
          return { t: 'b', v: u === v };
        case '>':
          return { t: 'b', v: u > v };
        case '<':
          return { t: 'b', v: u < v };
        case '>=':
          return { t: 'b', v: u >= v };
        case '<=':
          return { t: 'b', v: u <= v };
        case '<>':
          return { t: 'b', v: u !== v };
      }
      throw 'cannot evaluate '.concat(JSON.stringify(t), ' ').concat(e, ' ').concat(JSON.stringify(r));
    }
    function R(t, r, e) {
      if (t.t === 's' && r.t === 's')
        switch (e) {
          case '&':
            return { t: 's', v: t.v + r.v };
        }
      throw 'cannot evaluate '.concat(JSON.stringify(t), ' ').concat(e, ' ').concat(JSON.stringify(r));
    }
    function V(r, e, a, n, i) {
      if (n) return F(r, e, a, i);
      if (e.t === 'A') {
        switch (r.t) {
          case 'n':
          case 's':
          case 'e':
          case 'd':
          case 'b':
            var u = { t: 'A', v: [], a: '?' + (e.a || '') + '?' };
            e.v.forEach(function (t, e) {
              u.v[e] = [];
              t.forEach(function (t, i) {
                u.v[e][i] = V(r, t, a, n);
              });
            });
            return u;
          case 'A': {
            var v = F(r, e, a, i);
            v.a = '?' + e.a + '?' + r.a;
            return v;
          }
        }
      }
      if (r.t === 'A') {
        switch (e.t) {
          case 'n':
          case 's':
          case 'e':
          case 'd':
          case 'b':
            var c = { t: 'A', v: [], a: '?' + (r.a || '') + '?' };
            r.v.forEach(function (t, r) {
              c.v[r] = [];
              t.forEach(function (t, i) {
                c.v[r][i] = V(t, e, a, n);
              });
            });
            return c;
          case 'A': {
            var v = F(r, e, a, i);
            v.a = '?' + e.a + '?' + r.a;
            return v;
          }
        }
      }
      try {
        switch (a) {
          case '&':
            return r.t === 'e' ? r : e.t === 'e' ? e : R(t.Util._cast_str(r), t.Util._cast_str(e), a);
          case '+':
          case '*':
          case '-':
          case '/':
          case '^':
            if ((r.t === 's' && (!r.v || isNaN(Number(r.v)))) || (e.t === 's' && (!e.v || isNaN(Number(e.v)))))
              return t.Util._box_err('#VALUE!');
            return D(t.Util._cast_num(r), t.Util._cast_num(e), a);
          case '=':
          case '>':
          case '<':
          case '>=':
          case '<=':
          case '<>':
            if (r.t === 'e') return r;
            if (e.t === 'e') return e;
            return C(r.t === 'z' ? r : t.Util._cast_val(r), e.t === 'z' ? e : t.Util._cast_val(e), a);
        }
      } catch (t) {
        return { t: 'e', v: 15 };
      }
      throw 'unrecognized binop '.concat(a);
    }
    function F(r, e, a, n) {
      if (r.t !== 'A' && e.t !== 'A') return V(r, e, a, false);
      var i = { t: 'A', v: [], a: '' };
      try {
        switch (a) {
          case '&':
            if (r.t === 'A') {
              r.v.forEach(function (r, n) {
                if (!i.v[n]) i.v[n] = [];
                r.forEach(function (r, u) {
                  try {
                    var v = e.t === 'A' ? e.v[n][u] : e;
                    i.v[n][u] = R(t.Util._cast_str(r), t.Util._cast_str(v), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            } else if (e.t === 'A') {
              e.v.forEach(function (e, n) {
                if (!i.v[n]) i.v[n] = [];
                e.forEach(function (e, u) {
                  try {
                    i.v[n][u] = R(t.Util._cast_str(r), t.Util._cast_str(e), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            }
            return i;
          case '+':
          case '*':
          case '-':
          case '/':
          case '^':
            if (r.t === 'A') {
              r.v.forEach(function (r, n) {
                if (!i.v[n]) i.v[n] = [];
                r.forEach(function (r, u) {
                  try {
                    var v = e.t === 'A' ? e.v[n][u] : e;
                    i.v[n][u] = D(t.Util._cast_num(r), t.Util._cast_num(v), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            } else if (e.t === 'A') {
              e.v.forEach(function (e, n) {
                if (!i.v[n]) i.v[n] = [];
                e.forEach(function (e, u) {
                  try {
                    i.v[n][u] = D(t.Util._cast_num(r), t.Util._cast_num(e), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            }
            return i;
          case '=':
          case '>':
          case '<':
          case '>=':
          case '<=':
          case '<>':
            if (r.t === 'A') {
              r.v.forEach(function (r, n) {
                if (!i.v[n]) i.v[n] = [];
                r.forEach(function (r, u) {
                  try {
                    var v = e.t === 'A' ? e.v[n][u] : e;
                    i.v[n][u] = C(t.Util._cast_num(r), t.Util._cast_num(v), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            } else if (e.t === 'A') {
              e.v.forEach(function (e, n) {
                if (!i.v[n]) i.v[n] = [];
                e.forEach(function (e, u) {
                  try {
                    i.v[n][u] = C(t.Util._cast_num(r), t.Util._cast_num(e), a);
                  } catch (t) {
                    i.v[n][u] = { t: 'e', v: 15 };
                  }
                });
              });
            }
            return i;
        }
      } catch (t) {
        return { t: 'e', v: 15 };
      }
      throw 'unrecognized binop '.concat(a);
    }
    function P(r, n, i, u, v, c, f) {
      var _ = [];
      var s = ((r || {}).Sheets || {})[((r || {}).SheetNames || [])[i]];
      for (var o = 0; o < n.length; ++o) {
        var l = n[o];
        switch (l.t) {
          case '1':
            _.push(l);
            break;
          case '2':
            _.push(l);
            break;
          case 'a':
          case 'i': {
            if (o + 2 < n.length && n[o + 1].t === ':' && (n[o + 2].t === 'a' || n[o + 2].t === 'i')) {
              var U = { t: 'r', v: l.v };
              while (o + 2 < n.length && n[o + 1].t === ':' && (n[o + 2].t === 'a' || n[o + 2].t === 'i')) {
                U.v += ':' + n[o + 2].v;
                o += 2;
              }
              _.push(U);
            } else _.push(l);
            break;
          }
          case '3':
            if (o + 2 < n.length && n[o + 1].t === ':' && n[o + 2].t === 'a') {
              _.push({ t: '3', v: l.v + ':' + n[o + 2].v });
              o += 2;
            } else _.push(l);
            break;
          case 'n':
          case 's':
          case 'e':
          case 'b':
            _.push(l);
            break;
          case ':':
            _.push(l);
            break;
          case 'c': {
            var h = { t: 'A', v: [[]], a: '' };
            var b = 0;
            var m = [];
            while (++o < n.length && n[o].t !== 'c')
              switch (n[o].t) {
                case 'p':
                  b += n[o].v === '(' ? 1 : -1;
                  m.push(n[o]);
                  break;
                case ',':
                  if (b > 0) {
                    m.push(n[o]);
                    break;
                  }
                  h.v[h.v.length - 1].push(X(r, m, i, u, false));
                  m = [];
                  break;
                case ';':
                  h.v[h.v.length - 1].push(X(r, m, i, u, false));
                  m = [];
                  h.v.push([]);
                  break;
                default:
                  m.push(n[o]);
                  break;
              }
            h.v[h.v.length - 1].push(X(r, m, i, u, false));
            _.push(h);
            break;
          }
          case 'p':
            if (l.v === '(') {
              var E = _.pop();
              var x = o + 1;
              for (var b = 1; x < n.length && b !== 0; ++x) {
                if (n[x].t === 'p') b += n[x].v === '(' ? 1 : -1;
                else if (n[x].t === 'c') b += n[x].v === '{' ? 1 : -1;
              }
              var S = n.slice(o, x);
              S.pop();
              var M = false;
              if (E)
                switch (E.t) {
                  case 'a':
                  case 'i':
                    S.shift();
                    S.unshift(E);
                    _.push(y(r, S, i, u, v, c));
                    if (k.indexOf(E.v) > -1 && !(v > 0)) {
                      var N = _.pop();
                      if (N.t === 'e') _.push(N);
                      else _.push({ t: N.v.indexOf(':') > -1 ? 'r' : 'a', v: N.v, q: 1 });
                    }
                    M = true;
                }
              if (!M) {
                S.shift();
                if (E) _.push(E);
                _.push(P(r, S, i, u, v + 1, c, f));
              }
              o = x - 1;
            }
            break;
          case ',':
            _.push(l);
            break;
          case ' ':
            if (!n[o - 1] || !n[o + 1]) break;
            if ([' ', '(', ','].indexOf(n[o - 1].t) > -1) break;
            if (n[o + 1] && [' ', ')', ','].indexOf(n[o + 1].t) > -1) break;
            _.push(l);
            break;
          case 'I':
            try {
              var g = l.v.split('!');
              var L = H(g[1], r, g[0].replace(/'/g, ''));
              _.push(L);
              break;
            } catch (t) {}
          case 'j':
          case 'k':
            _.push(l);
            break;
          default:
            throw 'bad token '.concat(JSON.stringify(l));
        }
        if (_.length > 1 && _[_.length - 2].t === ' ') {
          if (['2'].indexOf(_[_.length - 1].t) > -1) _.splice(_.length - 2, 1);
          else if (_.length > 2 && ['2'].indexOf(_[_.length - 3].t) > -1) _.splice(_.length - 2, 1);
        }
        if (_.length >= 3) {
          for (var I = 1; I < _.length - 1; ++I) {
            var T = _[I];
            if (T.t === ',') {
              var w = { t: 'U', v: [] };
              if (_[I - 1].t === 'U') w.v = w.v.concat(_[I - 1].v);
              else if (_[I - 1].t === 'A') w.v.push(_[I - 1]);
              else if (_[I - 1].t === 'r') w.v.push(A(s, _[I - 1].v.replace(/\$/g, '')));
              else if (_[I - 1].t === 'a') w.v.push(A(s, _[I - 1].v.replace(/\$/g, '').replace(/^.*$/, '$&:$&')));
              else throw 'Cannot add '.concat(JSON.stringify(_[I - 1]), ' to union');
              if (_[I + 1].t === 'U') w.v = w.v.concat(_[I + 1].v);
              else if (_[I + 1].t === 'A') w.v.push(_[I + 1]);
              else if (_[I + 1].t === 'r') w.v.push(A(s, _[I + 1].v.replace(/\$/g, '')));
              else if (_[I + 1].t === 'a') w.v.push(A(s, _[I + 1].v.replace(/\$/g, '').replace(/^.*$/, '$&:$&')));
              else throw 'Cannot add '.concat(JSON.stringify(_[I + 1]), ' to union');
              _.splice(I - 1, 3, w);
              I = 0;
            } else if (T.t === ' ') {
              if (_[I - 1].t === 'a') {
                _[I - 1].t = 'r';
                _[I - 1].v = _[I - 1].v + ':' + _[I - 1].v;
              }
              if (_[I + 1].t === 'a') {
                _[I + 1].t = 'r';
                _[I + 1].v = _[I + 1].v + ':' + _[I + 1].v;
              }
              if (_[I - 1].t === 'r' && _[I + 1].t === 'r') {
                var D = t.Util.decode_multi_range(_[I - 1].v);
                var C = t.Util.decode_multi_range(_[I + 1].v);
                var R = {
                  s: { c: Math.max(D.s.c, C.s.c), r: Math.max(D.s.r, C.s.r) },
                  e: { c: Math.min(D.e.c, C.e.c), r: Math.min(D.e.r, C.e.r) },
                };
                var F = R.e.c >= R.s.c && R.e.r >= R.s.r ? t.XLSXLib.utils.encode_range(R) : '';
                if (F === '') {
                  var B = t.Util._box_err('#NULL!');
                  B.a = '';
                  _.splice(I - 1, 3, B);
                } else {
                  var G = { t: 'r', v: F };
                  _.splice(I - 1, 3, G);
                }
              } else throw 'bad intersection '.concat(JSON.stringify(_));
            } else if (T.t === ':') {
              var z = true;
              if (_[I - 1].t === '3' && _[I + 1].t === '3') {
                var q = _[I - 1].v.replace(/!.*$/, '');
                var $ = _[I - 1].v.replace(/!.*$/, '');
                if (q.toLowerCase() === $.toLowerCase()) {
                  _.splice(I - 1, 3, { t: '3', v: _[I - 1].v + ':' + _[I + 1].v.replace($ + '!', '') });
                  z = false;
                }
              }
              if (z) {
                console.log(_);
                throw 'bad parse of : token';
              }
            }
          }
        }
        while (
          _.length >= 2 &&
          _[_.length - 2].t === '1' &&
          (o >= n.length - 1 || !(n[o + 1].t === 'p' && n[o + 1].v === '(')) &&
          (_.length === 2 || a.indexOf(_[_.length - 3].t) === -1)
        ) {
          var W = _.pop();
          var Y = _.pop();
          if (Y.t === '1' && Y.v === '+' && W.t === '1' && W.v === '-') {
            _.push(W);
            continue;
          }
          switch (W.t) {
            case 'i':
            case 'a': {
              if (n[o + 1] && n[o + 1].t === 'p' && n[o + 1].v === '(') {
                _.push(Y);
                _.push(W);
                break;
              }
              var K = O(r, W, i, u, c);
              if (Y.v === '+') {
                if (K.t === 'z') _.push({ t: 'n', v: 0 });
                else _.push(K);
              } else
                switch (K.t) {
                  case 'b':
                    _.push({ t: 'n', v: K.v ? -1 : 0 });
                    break;
                  case 'e':
                    _.push(K);
                    break;
                  case 'n':
                    K.v = -K.v;
                    _.push(K);
                    break;
                  case 'd':
                    break;
                  case 's':
                    {
                      var Z = +K.v;
                      if (isNaN(Z)) _.push({ t: 'e', v: 15 });
                      else _.push(t.Util._box_num(Y.v === '-' ? -Z : Y.v === '%' ? +Z / 100 : +Z));
                    }
                    break;
                  case 'z':
                    _.push({ t: 'n', v: 0 });
                    break;
                }
              break;
            }
            case 'A':
              if (Y.t === '1' && Y.v === '-') {
                if (W.a) W.a = '';
                W.v = W.v.map(function (r) {
                  return r.map(function (r) {
                    switch (r.t) {
                      case 'b':
                        return { t: 'n', v: r.v ? -1 : 0 };
                      case 'n':
                        return { t: 'n', v: -r.v };
                      default:
                        return t.Util._box_err('#VALUE!');
                    }
                  });
                });
                _.push(W);
                break;
              }
              throw 'Cannot apply '.concat(JSON.stringify(Y), ' to Array');
            case 'n':
              if (Y.v === '+') _.push(W);
              else _.push({ t: 'n', v: -W.v });
              break;
            case 'b':
              switch (Y.v) {
                case '+':
                  _.push(W);
                  break;
                case '-':
                  _.push({ t: 'n', v: W.v ? -1 : 0 });
                  break;
                case '%':
                  _.push({ t: 'n', v: W.v ? 0.01 : 0 });
                  break;
              }
              break;
            case 'z':
              _.push({ t: 'n', v: 0 });
              break;
            case 's':
              var Q = +W.v;
              if (isNaN(Q)) _.push(Y.v === '+' ? W : { t: 'e', v: 15 });
              else _.push(t.Util._box_num(Y.v === '-' ? -Q : Y.v === '%' ? +Q / 100 : +Q));
              break;
            case '3':
              var J = W.v.split('!');
              if (!r || !r.Sheets || !r.Sheets[J[0]]) {
                _.push(t.Util._box_err('#REF!'));
                break;
              }
              var j = r.Sheets[J[0]];
              var tt = p(j, J[1].replace(/\$/g, ''));
              switch (Y.v) {
                case '+':
                  _.push(tt);
                  break;
                case '-':
                  switch (tt.t) {
                    case 'b':
                      _.push({ t: 'n', v: tt.v ? -1 : 0 });
                      break;
                    case 'e':
                      _.push(tt);
                      break;
                    case 'n':
                      tt.v = -tt.v;
                      _.push(tt);
                      break;
                    case 'z':
                      _.push(t.Util._box_num(0));
                      break;
                    case 's': {
                      if (!tt.v || isNaN(Number(tt.v))) _.push(t.Util._box_err('#VALUE!'));
                      else _.push(t.Util._box_num(-Number(tt.v)));
                    }
                  }
              }
              break;
            case 'e':
              _.push(W);
              break;
            default:
              throw 'bad prefix '.concat(JSON.stringify(Y), ' ').concat(JSON.stringify(W));
          }
        }
        if (_.length >= 2 && _[_.length - 1].t === '1') {
          var Y = _.pop();
          var W = _.pop();
          if (Y.v === '%') {
            switch (W.t) {
              case 'i':
              case 'a': {
                var K = O(r, W, i, u, c);
                switch (K.t) {
                  case 'b':
                    _.push({ t: 'n', v: (K.v ? 1 : 0) / 100 });
                    break;
                  case 'e':
                    _.push(K);
                    break;
                  case 'n':
                    K.v = K.v / 100;
                    _.push(K);
                    break;
                  case 'd':
                    break;
                  case 's':
                    break;
                  case 'z':
                    _.push({ t: 'n', v: 0 });
                    break;
                }
                break;
              }
              case 'n':
                W.v = W.v / 100;
                _.push(W);
                break;
            }
          } else {
            _.push(W);
            _.push(Y);
          }
        }
      }
      if (_.length >= 3)
        e.forEach(function (e) {
          for (var a = 1; a < _.length - 1; ++a) {
            if (_[a].t === '2' && e.indexOf(_[a].v) > -1) {
              if (_[a - 1].t === 'e') {
                _.splice(a - 1, 3, _[a - 1]);
                a = 0;
                continue;
              }
              if (_[a + 1].t === 'e') {
                _.splice(a - 1, 3, _[a + 1]);
                a = 0;
                continue;
              }
              if (_[a - 1].t === 'i' || _[a - 1].t === 'a') _[a - 1] = O(r, _[a - 1], i, u, c);
              else if (_[a - 1].t === '3') {
                var n = _[a - 1].v.split('!');
                if (!r || !r.Sheets || !r.Sheets[n[0]]) _[a - 1] = t.Util._box_err('#REF!');
                else {
                  var v = r.Sheets[n[0]];
                  if (_[a - 1].v.indexOf(':') === -1) {
                    _[a - 1] = p(v, n[1].replace(/\$/g, ''));
                  } else {
                    _[a - 1] = A(v, n[1].replace(/\$/g, ''));
                  }
                }
              }
              if (_[a + 1].t === 'i' || _[a + 1].t === 'a') _[a + 1] = O(r, _[a + 1], i, u, c);
              else if (_[a + 1].t === '3') {
                var f = _[a + 1].v.split('!');
                if (!r || !r.Sheets || !r.Sheets[f[0]]) _[a + 1] = t.Util._box_err('#REF!');
                else {
                  var v = r.Sheets[f[0]];
                  if (_[a + 1].v.indexOf(':') === -1) {
                    _[a + 1] = p(v, f[1].replace(/\$/g, ''));
                  } else {
                    _[a + 1] = A(v, f[1].replace(/\$/g, ''));
                  }
                }
              }
              {
                if (_[a - 1].t === 'r') _[a - 1] = A(s, _[a - 1].v.replace(/\$/g, ''));
                if (_[a + 1].t === 'r') _[a + 1] = A(s, _[a + 1].v.replace(/\$/g, ''));
              }
              var o = _.splice(a - 1, 3, V(_[a - 1], _[a + 1], _[a].v, c, typeof u === 'string' ? t.XLSXLib.utils.decode_cell(u) : u));
              a = 0;
            }
          }
        });
      if (_.length === 1)
        switch (_[0].t) {
          case 'n':
            return _[0];
          case 'b':
            return _[0];
          case 's':
            return _[0];
          case 'e':
            return _[0];
          case 'a':
            return p(s, _[0].v.replace(/\$/g, ''), _[0].q || 0);
          case '3':
          case 'k':
          case 'j': {
            var J = _[0].v.split('!');
            if (!r || !r.Sheets || !r.Sheets[J[0]]) return t.Util._box_err('#REF!');
            else {
              var rt = r.Sheets[J[0]];
              switch (_[0].t) {
                case '3':
                  if (J[1].indexOf(':') > -1) return A(rt, J[1].replace(/\$/g, ''), f);
                  return p(rt, J[1].replace(/\$/g, ''));
                case 'j':
                case 'k':
                  return A(rt, J[1].replace(/\$/g, ''), f);
              }
            }
          }
          case 'r':
            return A(s, _[0].v.replace(/\$/g, ''), f);
          case 'i':
            return d(r, _[0].v, i, u, c);
          case 'A':
            return _[0];
          case 'U':
            return _[0];
          case 'z':
            return _[0];
        }
      if (_.length === 0) return { t: 'y' };
      throw 'whoops! '.concat(i, ' ').concat(JSON.stringify(n), ' ! ').concat(JSON.stringify(_), ' ');
    }
    function X(r, e, a, n, i) {
      var u = P(r, e, a, n, 0, i, false);
      switch (u.t) {
        case 'U':
          break;
        case 'A':
          if (i) return u;
          break;
        case 'C':
        case 'R':
          break;
        case 'y':
          break;
        default:
          return u;
      }
      if (!n || !u.a)
        switch (u.t) {
          case 'A':
            return u.v[0][0];
          case 'C':
            return u.v[0];
          case 'R':
            return u.v[0];
        }
      if (n)
        switch (u.t) {
          case 'A':
            return E(u, typeof n === 'string' ? t.XLSXLib.utils.decode_cell(n) : n);
        }
      throw 'bad result type '
        .concat(u.t, ' ')
        .concat(
          e
            .map(function (t) {
              return JSON.stringify(t);
            })
            .join('|'),
          ' ',
        )
        .concat(JSON.stringify(u));
    }
    function B(e, a, n, i) {
      if (a.match(/^[A-Z]+[0-9]+$/)) {
        if (!e || !e.Sheets || !e.Sheets[e.SheetNames[n]]) return t.Util._box_err('#REF!');
        return p(e.Sheets[e.SheetNames[n]], a);
      }
      var u = r._tokenize(a);
      return X(e, u, n, i, false);
    }
    function G(e, a, n, i) {
      if (a.match(/^[A-Z]+[0-9]+$/)) {
        if (!e || !e.Sheets || !e.Sheets[e.SheetNames[n]]) return t.Util._box_err('#REF!');
        return p(e.Sheets[e.SheetNames[n]], a);
      }
      var u = r._tokenize(a);
      return X(e, u, n, i, true);
    }
    function H(t, r, e, a) {
      var n = z(t, r, e, a);
      return n.t === 'z' ? { t: 'n', v: 0 } : n;
    }
    r.evaluate = H;
    function z(t, r, e, a) {
      var n;
      if (e == null) n = B(r, t, -1, a);
      else if (typeof e === 'number') n = B(r, t, e, a);
      else if (typeof e === 'string') {
        var i = r.SheetNames.map(function (t) {
          return t.toLowerCase();
        }).indexOf(e.toLowerCase());
        if (i > -1) n = B(r, t, i, a);
      }
      if (!n) throw 'Could not find worksheet |'.concat(e, '|');
      return n;
    }
    r.evaluate_stub = z;
    function q(t, r, e, a) {
      if (e == null) return G(r, t, -1, a);
      if (typeof e === 'number') return G(r, t, e, a);
      else if (typeof e === 'string') {
        var n = r.SheetNames.map(function (t) {
          return t.toLowerCase();
        }).indexOf(e.toLowerCase());
        if (n > -1) return G(r, t, n, a);
      }
      throw 'Could not find worksheet |'.concat(e, '|');
    }
    r.evaluate_array = q;
    function $(r) {
      switch (r.t) {
        case 'b':
          return r.v;
        case 'n':
          return r.v;
        case 's':
          return r.v;
        case 'z':
          return 0;
        case 'e':
          var e = new Error(t.Util.ErrorName[r.v]);
          e.code = r.v;
          return e;
        case 'A':
          if (r.v.length === 1 && r.v[0].length === 1) return $(r.v[0][0]);
      }
      throw 'Cannot extract value from '.concat(JSON.stringify(r));
    }
    r.extract_value = $;
    function W(r) {
      switch (r.t) {
        case 'b':
          return [[r.v]];
        case 'n':
          return [[r.v]];
        case 's':
          return [[r.v]];
        case 'z':
          return [[0]];
        case 'e':
          var e = new Error(t.Util.ErrorName[r.v]);
          e.code = r.v;
          return [[e]];
        case 'A':
          return r.v.map(function (t) {
            return t.map(function (t) {
              return $(t);
            });
          });
      }
      throw 'Cannot extract aoa from '.concat(JSON.stringify(r));
    }
    r.extract_aoa = W;
  })((r = t.Calc || (t.Calc = {})));
  function e(t, e, a, n) {
    return r.extract_value(r.evaluate(t, e, a, n));
  }
  t.calculate = e;
  function a(t, e, a, n) {
    return r.extract_aoa(r.evaluate_array(t, e, a, n));
  }
  t.calculate_array = a;
})(S5SCalc || (S5SCalc = {}));

// mod simon
//if (typeof module !== 'undefined') module.exports = S5SCalc;

export default S5SCalc;
