import { useRef, useState, useEffect } from 'react';
import * as _ from 'lodash';
import { useSpreadsheetTemplate } from './use-spreadsheet-template';
import { mondayClient } from '../services/monday';
import { getItemsFromMultipleBoards, Board } from '@gorilla/common/src/lib/monday-api/api';
import {
  exportTo,
  FieldGroupBoardWrapper,
  getReferencedBoardIds,
  getFieldGroups,
  replaceBoardsFromContext,
} from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import { boardItemMondayToBoardItemEngine } from '@gorilla/common/src/lib/engine/engine';
import { getNormalizedBoardFiltersGraphQL, areBoardFiltersValid } from '@gorilla/common/src/lib/rules';
import { boardBatchResolver } from '../shared/hooks/use-boards';
import { getMondayContext } from './use-monday';
import { OutputFormat, Template } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';

interface MyAbortController extends AbortController {
  templateId?: number;
}

export function useSpreadsheetLoaderByTemplate(
  template?: Template,
  outputFormat?: OutputFormat,
  withMetadata = false,
  replaceContextBoards = true
) {
  const [status, setStatus] = useState('initializing');
  const [progress, setProgress] = useState<number | null>(null);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<ReturnType<typeof exportTo> | null>(null);
  const abortControllerRef = useRef<MyAbortController | null>(null);
  //const { template, isLoading, referencedBoardIds } = useSpreadsheetTemplate(templateId);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (template) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }

      const abortController = new AbortController();
      abortControllerRef.current = abortController;

      setStatus('initializing');
      setProgress(null);

      (async () => {
        try {
          let boards;

          const mondayContext = await getMondayContext();
          const templateConfig = replaceContextBoards ? replaceBoardsFromContext(template.config, mondayContext.boardId) : template.config;
          const referencedBoardIds = getReferencedBoardIds(templateConfig);

          try {
            boards = await boardBatchResolver.resolveItems(referencedBoardIds);
          } catch (err) {
            throw new Error(
              `Some of the boards referenced in the spreadsheet template could not be loaded. This may be due to a permissions issue or the board no longer exists. Please check the board references in the spreadsheet template and try again.`
            );
          }

          const boardsById = _.keyBy(boards, 'id');
          const fieldGroups = getFieldGroups(templateConfig, true);
          let fieldGroupIdsToItems: Awaited<ReturnType<typeof getItemsFromMultipleBoards>>;

          try {
            const boardIdsAndOptions = fieldGroups.map((fieldGroup) => {
              const board = boardsById[fieldGroup.board_id];

              if (!board) {
                throw new Error(`Board ${fieldGroup.board_id} not found`);
              }

              if (!areBoardFiltersValid(board, fieldGroup.board_filters)) {
                throw new Error(`Invalid board filters for board ${fieldGroup.board_id}. Please check the board filters and try again.`);
              }

              return {
                id: fieldGroup.id,
                boardId: fieldGroup.board_id,
                options: {
                  queryParams: getNormalizedBoardFiltersGraphQL(boardsById[fieldGroup.board_id], fieldGroup.board_filters),
                  abortSignal: abortController.signal,
                  onProgress: (progress) => {
                    setStatus('fetching-items');
                    setProgress(progress);
                  },
                  itemsPerPage: 250,
                },
              };
            });

            fieldGroupIdsToItems = await getItemsFromMultipleBoards(mondayClient, boardIdsAndOptions);
          } catch (err) {
            console.log('Failed to fetch board items in spreadsheet loader', err, referencedBoardIds);
            throw err;
          }

          const fieldGroupBoardWrappers: FieldGroupBoardWrapper[] = fieldGroups.map((fieldGroup) => {
            let items = fieldGroupIdsToItems.get(fieldGroup.id);
            const board = boardsById[fieldGroup.board_id];

            if (!items) {
              throw new Error(`Failed to fetch items for board ${fieldGroup.board_id}`);
            }

            if (board.type === 'sub_items_board') {
              // filter out all subitems that don't have a parent item
              items = items.filter((item) => item.parent_item);
            }

            return {
              field_group_id: fieldGroup.id,
              board: board as Board,
              items: items.map((item) => boardItemMondayToBoardItemEngine(board, item)),
            };
          });

          const data = exportTo(outputFormat ? outputFormat : 'sheetjs', templateConfig, fieldGroupBoardWrappers, withMetadata);
          setData(data);
        } catch (err) {
          console.log('Failed to fetch board items', err);
          setError(err);
          setStatus('error');
          setProgress(null);
        }
      })();
    }
  }, [template]);

  let loadingMessage = 'Initializing...';

  if (status === 'error') {
    loadingMessage = 'Loading failed';
  } else if (progress) {
    loadingMessage = `Loaded ${progress}%`;
  }

  return {
    error,
    status,
    progress,
    loadingMessage,
    data,
  };
}

export function useSpreadsheetLoader(templateId?: number, outputFormat?: OutputFormat, withMetadata = false, replaceContextBoards = true) {
  const { template } = useSpreadsheetTemplate(templateId);
  return useSpreadsheetLoaderByTemplate(template, outputFormat, withMetadata, replaceContextBoards);
}
