import React from 'react';
import 'monday-ui-react-core/dist/main.css';
import { Flex, Icon, Dialog, DialogContentContainer } from 'monday-ui-react-core';
import CheckIcon from 'monday-ui-react-core/dist/icons/Check';
import LearnMore from 'monday-ui-react-core/dist/icons/LearnMore';

type WhatsThisDialog = {
  headline: React.ReactNode;
  bulletpoints: string[];
  footer: React.ReactNode;
};

export function WhatsThisDialog({ headline, bulletpoints, footer }: WhatsThisDialog) {
  return (
    <Dialog
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            mainAxis: false,
          },
        },
      ]}
      zIndex={1005}
      shouldShowOnMount={false}
      showTrigger={[Dialog.hideShowTriggers.MOUSE_ENTER]}
      hideTrigger={[Dialog.hideShowTriggers.MOUSE_LEAVE]}
      position={Dialog.positions.BOTTOM_END}
      tooltip={true}
      content={
        <>
          <DialogContentContainer type={DialogContentContainer.types.POPOVER} size={DialogContentContainer.sizes.LARGE}>
            <h5 style={{ fontWeight: 600 }}>{headline}</h5>
            <div style={{ marginTop: '10px' }}>
              {bulletpoints.map((point, index) => (
                <Flex key={index} gap={8}>
                  <Icon icon={CheckIcon} iconSize={16} ignoreFocusStyle />
                  {point}
                </Flex>
              ))}
            </div>
            <div style={{ marginTop: '10px', color: 'rgb(175, 176, 189)' }}>{footer}</div>
          </DialogContentContainer>
        </>
      }
    >
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
        }}
      >
        <span>What's this</span>
        <Icon icon={LearnMore} iconSize={18} ignoreFocusStyle />
      </div>
    </Dialog>
  );
}

export function SpreadsheetTemplatesWhatsThisDialog() {
  return (
    <WhatsThisDialog
      headline={
        <>
          Templates are your central hub to create
          <br />
          and maintain all of your spreadsheets.
        </>
      }
      bulletpoints={[
        'Combine boards in worksheets',
        'Map board to spreadsheet columns',
        'Calculate with advanced formulas',
        'Update board items with formulas',
      ]}
      footer={
        <>
          Spreadsheet templates are visible across all
          <br />
          board views, but set to private by default.
        </>
      }
    />
  );
}

export function SpreadsheetsWhatsThisDialog() {
  return (
    <WhatsThisDialog
      headline={
        <>
          Saved spreadsheets must be seen like
          <br />
          exported files, but stored in the cloud.
        </>
      }
      bulletpoints={[
        'Created from spreadsheet templates',
        'Detached from their template origin',
        'Accessible in the spreadsheet viewer',
        'Currently for experimental usage',
      ]}
      footer={
        <>
          Saved spreadsheets are always attached to
          <br />
          board views and therefore only visible there.
        </>
      }
    />
  );
}

export function LuckysheetWhatsThisDialog() {
  return (
    <WhatsThisDialog
      headline={
        <>
          This is Spreadsheet Gorilla's embedded <br />
          but experimental spreadsheet viewer <br />
          and primarily intended to be used to:
        </>
      }
      bulletpoints={[
        'Preview your template output',
        'Check your template configuration',
        'View archived spreadsheet reports',
        'Create self-contained spreadsheets',
        'Apply advanced custom formatting',
        'Export formatted Excel spreadsheets',
      ]}
      footer={
        <>
          All saved spreadsheets on monday.com are <br />
          detached from their template origin. They can
          <br />
          be updated by importing a template, but any
          <br />
          custom formatting made here will be lost.
        </>
      }
    />
  );
}
