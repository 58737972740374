import React from 'react';
import * as _ from 'lodash';
import { Modal, ModalHeader, ModalFooter, Button, ModalContent, Flex, Icon, Label } from 'monday-ui-react-core';
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';
import CompletedIcon from 'monday-ui-react-core/dist/icons/Completed';

type ExperimentalSpreadsheetModalProps = {
  show: boolean;
  onClose: () => void;
};

export default function ExperimentalSpreadsheetModal({ show, onClose }: ExperimentalSpreadsheetModalProps) {
  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={600}
      alertDialog={true}
      id="experimental--dialog"
    >
      <ModalHeader title="" hideCloseButton={true} />
      <ModalContent>
        <Flex gap={10}>
          {/* 
              <div style={{ marginRight: '-3px', transform: 'rotate(90deg) translate3d(0,5px,0)' }}>
                <Icon icon={StatusIcon} iconSize={24} ignoreFocusStyle clickable={false} />
              </div>
              */}
          <h2>Spreadsheet viewer</h2>
          <Label text="Experimental" color={Label.colors.DARK} isAnimationDisabled={true} />
        </Flex>
        <p>
          Spreadsheet Gorilla's embedded but experimental spreadsheet
          <br />
          viewer is primarily intended for the following use cases:
        </p>
        <div style={{ marginTop: '15px' }}>
          <Flex gap={20}>
            <div>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> Preview your template output
              </Flex>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> Check your template configuration
              </Flex>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> View archived spreadsheet reports
              </Flex>
            </div>
            <div>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> Create self-contained spreadsheets
              </Flex>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> Apply advanced custom formatting
              </Flex>
              <Flex gap={8}>
                <Icon icon={CompletedIcon} iconSize={16} ignoreFocusStyle clickable={false} /> Export formatted Excel spreadsheets
              </Flex>
            </div>
          </Flex>
        </div>
      </ModalContent>
      <ModalFooter>
        <Flex gap={20} align={Flex.align?.CENTER} justify={Flex.justify?.END}>
          <div style={{ color: 'rgb(175, 176, 189)' }}>
            Saved spreadsheets are detached from templates. They can be updated by importing a template, but any formatting will be lost.
          </div>
          <Button
            kind={Button.kinds?.PRIMARY as any}
            size={Button.sizes?.MEDIUM as any}
            onClick={() => {
              onClose();
            }}
          >
            Got it
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
