import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { DurationColumnType } from '../../monday-api/api';

export type DurationColumnValue = {
  text: string | null;
  duration: number | null;
  //start_date: Date;
  //additional_value: Array<any>; // this will contain more information about the time tracking sessions
};

const handler: ColumnHandler<DurationColumnType, DurationColumnValue> = {
  type: 'time_tracking',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['time_tracking_paused', 'time_tracking_running'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['time_tracking_paused', 'time_tracking_running'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const duration = parseNumber(_.get(value, 'duration'));

    return {
      text,
      duration,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (!engineValue.duration) {
      return 0;
    }

    if (modifier === 'Minutes') {
      return engineValue.duration / 60;
    } else if (modifier === 'Hours') {
      return engineValue.duration / 60 / 60;
    } else {
      return engineValue.duration;
    }
  },
};

export default handler;
