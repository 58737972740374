import boardNameFieldHandler from './board-name_field_handler';
import boardRelationFieldHandler from './board-relation_field_handler';
import booleanFieldHandler from './boolean_field_handler';
import buttonFieldHandler from './button_field_handler';
import colorPickerFieldHandler from './color-picker_field_handler';
import countryFieldHandler from './country_field_handler';
import dateFieldHandler from './date_field_handler';
import dependencyFieldHandler from './dependency_field_handler';
import dropdownFieldHandler from './dropdown_field_handler';
import durationFieldHandler from './duration_field_handler';
import emailFieldHandler from './email_field_handler';
import fileFieldHandler from './file_field_handler';
import formulaFieldHandler from './formula_field_handler';
import hourFieldHandler from './hour_field_handler';
import itemNameFieldHandler from './item-name_field_handler';
import itemGroupNameFieldHandler from './item-group-name_field_handler';
import itemIdFieldHandler from './item-id_field_handler';
import itemParentIdFieldHandler from './item-parent-id_field_handler';
import linkFieldHandler from './link_field_handler';
import locationFieldHandler from './location_field_handler';
import longTextFieldHandler from './long-text_field_handler';
import lookupFieldHandler from './lookup_field_handler';
import multiplePersonFieldHandler from './multiple-person_field_handler';
import numericFieldHandler from './numeric_field_handler';
import phoneFieldHandler from './phone_field_handler';
import pulseIdFieldHandler from './pulse-id_field_handler';
import pulseLogFieldHandler from './pulse-log_field_handler';
import pulseUpdatedFieldHandler from './pulse-updated_field_handler';
import ratingFieldHandler from './rating_field_handler';
import statusFieldHandler from './status_field_handler';
import tagFieldHandler from './tag_field_handler';
import textFieldHandler from './text_field_handler';
import timerangeFieldHandler from './timerange_field_handler';
import timezoneFieldHandler from './timezone_field_handler';
import votesFieldHandler from './votes_field_handler';
import weekFieldHandler from './week_field_handler';
import customFormulaFieldHandler from './custom-formula_field_handler';
import blankFieldHandler from './blank_field_handler';

export type HandlerTypes =
  | typeof boardNameFieldHandler
  | typeof boardRelationFieldHandler
  | typeof booleanFieldHandler
  | typeof buttonFieldHandler
  | typeof colorPickerFieldHandler
  | typeof countryFieldHandler
  | typeof dateFieldHandler
  | typeof dependencyFieldHandler
  | typeof dropdownFieldHandler
  | typeof durationFieldHandler
  | typeof emailFieldHandler
  | typeof fileFieldHandler
  | typeof formulaFieldHandler
  | typeof hourFieldHandler
  | typeof itemNameFieldHandler
  | typeof itemIdFieldHandler
  | typeof itemParentIdFieldHandler
  | typeof itemGroupNameFieldHandler
  | typeof linkFieldHandler
  | typeof locationFieldHandler
  | typeof longTextFieldHandler
  | typeof lookupFieldHandler
  | typeof multiplePersonFieldHandler
  | typeof numericFieldHandler
  | typeof phoneFieldHandler
  | typeof pulseIdFieldHandler
  | typeof pulseLogFieldHandler
  | typeof pulseUpdatedFieldHandler
  | typeof ratingFieldHandler
  | typeof statusFieldHandler
  | typeof tagFieldHandler
  | typeof textFieldHandler
  | typeof timerangeFieldHandler
  | typeof timezoneFieldHandler
  | typeof votesFieldHandler
  | typeof weekFieldHandler
  | typeof customFormulaFieldHandler
  | typeof blankFieldHandler;

export const handlers = [
  boardNameFieldHandler,
  boardRelationFieldHandler,
  booleanFieldHandler,
  buttonFieldHandler,
  colorPickerFieldHandler,
  countryFieldHandler,
  dateFieldHandler,
  dependencyFieldHandler,
  dropdownFieldHandler,
  durationFieldHandler,
  emailFieldHandler,
  fileFieldHandler,
  formulaFieldHandler,
  hourFieldHandler,
  itemNameFieldHandler,
  itemIdFieldHandler,
  itemParentIdFieldHandler,
  itemGroupNameFieldHandler,
  linkFieldHandler,
  locationFieldHandler,
  longTextFieldHandler,
  lookupFieldHandler,
  multiplePersonFieldHandler,
  numericFieldHandler,
  phoneFieldHandler,
  pulseIdFieldHandler,
  pulseLogFieldHandler,
  pulseUpdatedFieldHandler,
  ratingFieldHandler,
  statusFieldHandler,
  tagFieldHandler,
  textFieldHandler,
  timerangeFieldHandler,
  timezoneFieldHandler,
  votesFieldHandler,
  weekFieldHandler,
  customFormulaFieldHandler,
  blankFieldHandler,
];

export const handlersByType = handlers.reduce(
  (acc, handler) => {
    acc[handler.type] = handler;
    return acc;
  },
  {} as Record<string, HandlerTypes>,
);
