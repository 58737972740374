import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString } from '../converters';
import { TimerangeColumnType } from '../../monday-api/api';

export type TimerangeColumnValue = {
  text: string | null;
  from: string | null; // TODO: or Date? What about timezone?
  to: string | null;
  changed_at: string | null;
};

const handler: ColumnHandler<TimerangeColumnType, TimerangeColumnValue> = {
  type: 'timeline',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: [
        'current',
        'due_today',
        'future_timeline',
        'past_timeline',
        'done_on_time',
        'overdue',
        'done_overdue',
        'milestone',
        'blank_value',
      ],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: [
        'current',
        'due_today',
        'future_timeline',
        'past_timeline',
        'done_on_time',
        'overdue',
        'done_overdue',
        'milestone',
        'blank_value',
      ],
    },
    {
      operator: 'equals',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'greater_than',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'greater_than_or_equals',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'lower_than',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'lower_than_or_equal',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'between',
      compare_attributes_ids: ['start_date', 'end_date'],
      compare_value_ids: [
        'today',
        'tomorrow',
        'yesterday',
        'this_week',
        'one_week_ago',
        'one_week_from_now',
        'this_month',
        'one_month_ago',
        'one_month_from_now',
        'past_datetime',
        'future_datetime',
        'upcoming',
        'overdue',
        'done_on_time',
        'done_overdue',
        'exact_date',
      ],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const from = parseString(_.get(value, 'from'));
    const to = parseString(_.get(value, 'to'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      from,
      to,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'End') {
      return engineValue.to;
    } else {
      return engineValue.from;
    }
  },
};

export default handler;
