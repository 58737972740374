import React from 'react';
import 'monday-ui-react-core/dist/main.css';
import { EditTemplate } from '../../components/EditTemplate';
import './EditTemplatePage.css';
import ScrollToTop from '../../shared/components/ScrollToTop';

function EditTemplatePage() {
  return (
    <div className="EditTemplate">
      <ScrollToTop />
      <EditTemplate />
    </div>
  );
}

export default EditTemplatePage;
