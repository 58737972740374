import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { BoardRelationColumnType, BoardRelationColumnValue as BoardRelationColumnValueGraphql } from '../../monday-api/api';

export type BoardRelationColumnValue = {
  text: string | null;
  linked_pulse_ids: number[] | null;
};

export type BoardRelationColumnSettings = {
  boardIds: number[];
};

const handler: ColumnHandler<
  BoardRelationColumnType,
  BoardRelationColumnValue,
  BoardRelationColumnSettings,
  BoardRelationColumnValueGraphql
> = {
  type: 'board_relation',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['item_id'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['item_id'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'not_contains_text',
      compare_value_ids: ['text'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.display_value);
    const value = parseJson(columnValue.value);
    let linkedPulseIds: number[] | null = null;

    if (Array.isArray(_.get(value, 'linkedPulseIds'))) {
      linkedPulseIds = ((_.get(value, 'linkedPulseIds') || []) as Array<{ linkedPulseId: number }>)
        .map(({ linkedPulseId }) => parseNumber(linkedPulseId))
        .filter((linkedPulseId) => linkedPulseId !== null) as number[];
    }

    return {
      text,
      linked_pulse_ids: linkedPulseIds,
    };
  },

  convertSettingsToEngine: (settings: string) => {
    return parseJson(settings) as BoardRelationColumnSettings;
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Count') {
      return engineValue.linked_pulse_ids?.length || 0;
    }

    return engineValue.text === null ? '' : engineValue.text;
  },
};

export default handler;
