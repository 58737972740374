import asyncPool from 'tiny-async-pool';
import * as _ from 'lodash';
import { MondayClient, extractApiResult, MondayRequesterError, AbortedError, getAllResults } from './common';

export type AutoNumberColumnType = 'autonumber';
export type ButtonColumnType = 'button';
export type BooleanColumnType = 'checkbox';
export type ColorPickerColumnType = 'color_picker';
export type BoardRelationColumnType = 'board_relation';
export type CountryColumnType = 'country';
export type PulseLogColumnType = 'creation_log';
export type DateColumnType = 'date';
export type DependencyColumnType = 'dependency';
export type DropdownColumnType = 'dropdown';
export type EmailColumnType = 'email';
export type FileColumnType = 'file';
export type FormulaColumnType = 'formula';
export type HourColumnType = 'hour';
export type PulseIdColumnType = 'item_id';
export type PulseUpdatedColumnType = 'last_updated';
export type LinkColumnType = 'link';
export type LongTextColumnType = 'long_text';
export type MultiplePersonColumnType = 'people';
export type PhoneColumnType = 'phone';
export type RatingColumnType = 'rating';
export type LocationColumnType = 'location';
export type MirrorColumnType = 'mirror';
export type NumericColumnType = 'numbers';
export type ProgressTrackingColumnType = 'progress-tracking'; // TODO: double check if this is correct
export type StatusColumnType = 'status';
export type TagsColumnType = 'tags';
export type TeamColumnType = 'team';
export type TextColumnType = 'text';
export type TimerangeColumnType = 'timeline';
export type DurationColumnType = 'time_tracking';
export type VotesColumnType = 'vote';
export type WeekColumnType = 'week';
export type TimezoneColumnType = 'world_clock';
export type SubtasksColumnType = 'subtasks';

export type AnyBaseColumnType =
  | AutoNumberColumnType
  | ButtonColumnType
  | BooleanColumnType
  | ColorPickerColumnType
  | CountryColumnType
  | PulseLogColumnType
  | DateColumnType
  | DropdownColumnType
  | EmailColumnType
  | FormulaColumnType
  | HourColumnType
  | PulseIdColumnType
  | PulseUpdatedColumnType
  | LinkColumnType
  | LongTextColumnType
  | MultiplePersonColumnType
  | PhoneColumnType
  | RatingColumnType
  | LocationColumnType
  | NumericColumnType
  | ProgressTrackingColumnType
  | StatusColumnType
  | TeamColumnType
  | TextColumnType
  | TimerangeColumnType
  | DurationColumnType
  | VotesColumnType
  | WeekColumnType
  | TimezoneColumnType
  | SubtasksColumnType;

export type ColumnType =
  | AnyBaseColumnType
  | BoardRelationColumnType
  | DependencyColumnType
  | MirrorColumnType
  | TagsColumnType
  | FileColumnType;

type AbstractBoardColumnValue = {
  id: string;
  text: string | null;
  value: string | null;
};

type BaseBoardColumnValue = AbstractBoardColumnValue & {
  type: AnyBaseColumnType;
};

export type BoardRelationColumnValue = AbstractBoardColumnValue & {
  type: BoardRelationColumnType;
  display_value: string;
};

export type DependencyColumnValue = AbstractBoardColumnValue & {
  type: DependencyColumnType;
  display_value: string;
};

export type MirrorColumnValue = AbstractBoardColumnValue & {
  type: MirrorColumnType;
  display_value: string;
};

export type TagsColumnValue = AbstractBoardColumnValue & {
  type: TagsColumnType;
  text: string;
};

export type FileColumnValue = AbstractBoardColumnValue & {
  type: FileColumnType;
  files: string[];
};

export type BoardColumnValue =
  | BaseBoardColumnValue
  | BoardRelationColumnValue
  | DependencyColumnValue
  | MirrorColumnValue
  | TagsColumnValue
  | FileColumnValue;

function idToNumber<T extends { id: string | number }>(obj: T) {
  if (!obj || !obj.id) {
    return obj;
  }

  return { ...obj, id: _.toNumber(obj.id) };
}

function transformBoardItem(item: BoardItem) {
  if (item.parent_item && item.parent_item.id) {
    item.parent_item.id = _.toNumber(item.parent_item.id);
  }

  item.id = _.toNumber(item.id);

  return item;
}

export type BoardItem = {
  id: number;
  created_at: string;
  creator_id: string;
  name: string;
  state: 'all' | 'active' | 'archived' | 'deleted';
  updated_at: string;
  email: string;
  relative_link: string;
  group: {
    id: string;
    title: string;
  };
  parent_item: {
    id: number;
  } | null;
  column_values: BoardColumnValue[];
};

export const itemBaseFields = `
  id
  created_at
  creator_id
  name
  state
  updated_at
  email
  relative_link
  group {
    id
    title
  }
  parent_item {
    id
  }
  column_values {
    id
    text
    type
    value
    ... on BoardRelationValue {
      display_value
    }
    ... on DependencyValue {
      display_value
    }
    ... on MirrorValue {
      display_value
    }
    ... on TagsValue {
      text
    }
    ... on FileValue {
      files
    }
  }
`;

export async function getFirstBoardItemsPage(mondayClient: MondayClient, boardId: number, limit: number, queryParams?: string) {
  const result = await mondayClient.query(
    `
    query getFirstBoardItems($boardId: ID!, $limit: Int!) {
      complexity {
        query
      }
      boards(ids: [$boardId]) {
        items_page (limit: $limit${queryParams ? `, query_params: ${queryParams}` : ''}) {
          cursor
          items {
            ${itemBaseFields}
          }
        }
      }
    }
  `,
    { boardId, limit },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  // TODO: test what happens if there is only one item in the board. is cursor null?
  return extractApiResult<{ cursor: string; items: BoardItem[] }>(result, 'boards[0].items_page', (data) => ({
    ...data,
    items: data.items.map((item) => transformBoardItem(item)),
  }));
}

export async function getNextBoardItems(mondayClient: MondayClient, cursor: string, limit: number) {
  const result = await mondayClient.query(
    `
    query getNextBoardItems($cursor: String!, $limit: Int!) {
      complexity {
        query
      }
      next_items_page(cursor: $cursor, limit: $limit) {
        cursor
        items {
          ${itemBaseFields}
        }
      }
    }
  `,
    { cursor, limit },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<BoardItem[]>(result, 'next_items_page.items', (items) => items.map((item: any) => transformBoardItem(item)));
}

async function getNextBoardItemsWithRetries(mondayClient: MondayClient, cursor: string, limit: number, retries: number = 3) {
  let currentRetries = 0;
  let lastError: any;

  while (currentRetries < retries) {
    try {
      return await getNextBoardItems(mondayClient, cursor, limit);
    } catch (err) {
      lastError = err;
      currentRetries++;
    }

    await new Promise((resolve) => setTimeout(resolve, currentRetries * 5000));
  }

  throw lastError;
}

export async function getNextBoardItemsPage(mondayClient: MondayClient, cursor: string, limit: number) {
  const result = await mondayClient.query(
    `
    query getNextBoardItems($cursor: String!, $limit: Int!) {
      complexity {
        query
      }
      next_items_page(cursor: $cursor, limit: $limit) {
        cursor
        items {
          ${itemBaseFields}
        }
      }
    }
  `,
    { cursor, limit },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  // TODO: test what happens if there is only one item in the board. is cursor null?
  return extractApiResult<{ cursor: string; items: BoardItem[] }>(result, 'next_items_page', (data) => ({
    ...data,
    items: data.items.map((item) => transformBoardItem(item)),
  }));
}

async function getAllCursors(mondayClient: MondayClient, firstCursor: string, limit: number, abortSignal?: AbortSignal) {
  const cursors: Array<string> = [];

  let currentCursor = firstCursor;

  while (currentCursor) {
    cursors.push(currentCursor);

    if (abortSignal?.aborted) {
      throw new AbortedError('Aborted loading all cursors');
    }

    const result = await mondayClient.query(
      `
      query getNextPageCursor($cursor: String!, $limit: Int!) {
        complexity {
          query
        }
        next_items_page(cursor: $cursor, limit: $limit) {
          cursor
        }
      }
    `,
      { cursor: currentCursor, limit },
    );

    if (result.type === 'error') {
      throw new MondayRequesterError(result);
    }

    currentCursor = _.get(result, 'data.next_items_page.cursor') as string;
  }

  return cursors;
}

type GetBoardItemsOptions = {
  itemsPerPage: number;
  abortSignal?: AbortSignal;
  queryParams?: string;
  onProgress: (progress: number) => void;
  maxParallelRequests: number;
  removeDuplicates: boolean;
};

const getBoardItemsDefaultOptions: GetBoardItemsOptions = {
  itemsPerPage: 250,
  abortSignal: undefined,
  queryParams: undefined,
  onProgress: () => {},
  maxParallelRequests: 50,
  removeDuplicates: true,
};

export async function getBoardItems(mondayClient: MondayClient, boardId: number, optionsArg: Partial<GetBoardItemsOptions> = {}) {
  const options = { ...getBoardItemsDefaultOptions, ...optionsArg };
  const allItems: BoardItem[] = [];
  let progress = 0;

  const firstResult = await getFirstBoardItemsPage(mondayClient, boardId, 1, options.queryParams);
  allItems.push(...firstResult.items);

  progress = 5;
  options.onProgress(progress);

  if (options.abortSignal?.aborted) {
    throw new AbortedError('Aborted loading board items');
  }

  const cursors = await getAllCursors(mondayClient, firstResult.cursor, options.itemsPerPage, options?.abortSignal);

  progress = 20;
  options.onProgress(progress);

  const progressPerCursor = (100 - progress) / cursors.length;

  for await (const itemsResult of asyncPool(options.maxParallelRequests, cursors, async (cursor) => {
    return await getNextBoardItemsWithRetries(mondayClient, cursor, options.itemsPerPage, 3);
  })) {
    if (options.abortSignal?.aborted) {
      throw new AbortedError('Aborted loading board items');
    }

    allItems.push(...itemsResult);
    progress += progressPerCursor;
    options.onProgress(Math.min(Math.ceil(progress), 100));
  }

  options.onProgress(100);

  if (options.removeDuplicates) {
    return _.uniqBy(allItems, 'id');
  }

  return allItems;
}

export async function getItemsFromMultipleBoards(
  mondayClient: MondayClient,
  boardIdsAndOptions: { id: string; boardId: number; options: Partial<GetBoardItemsOptions> }[],
  logger?: (message: string, type: 'info' | 'warning' | 'error') => void,
  fallbackOptionsOverride: Partial<GetBoardItemsOptions> = { itemsPerPage: 50 }, // by default, try it with less items per page the second time
) {
  const idsToItems = new Map<string, BoardItem[]>();

  const progressPerBoard = 1 / boardIdsAndOptions.length;

  for await (const entry of boardIdsAndOptions) {
    const options = { ...getBoardItemsDefaultOptions, ...(entry.options || {}) };

    if (options.abortSignal?.aborted) {
      throw new AbortedError('Aborted loading board items');
    }

    if (logger) {
      logger(`Started fetching items for board ${entry.boardId}`, 'info');
    }

    try {
      const items = await getBoardItems(mondayClient, entry.boardId, {
        ...options,
        onProgress: (progress) => {
          options.onProgress(Math.min(100, Math.ceil(idsToItems.size * progressPerBoard * 100 + progressPerBoard * progress)));
        },
      });

      idsToItems.set(entry.id, items);

      if (logger) {
        logger(`Fetched ${items.length} items of board ${entry.boardId}`, 'info');
      }
    } catch (err) {
      console.log(`Failed to fetch items for board ${entry.boardId}. Trying a second time.`, err);

      if (logger) {
        logger(`Failed to fetch items for board ${entry.boardId}. Trying a second time.`, 'info');
      }

      try {
        const items = await getBoardItems(mondayClient, entry.boardId, {
          ...options,
          ...fallbackOptionsOverride,
          onProgress: (progress) => {
            options.onProgress(Math.min(100, Math.ceil(idsToItems.size * progressPerBoard * 100 + progressPerBoard * progress)));
          },
        });

        idsToItems.set(entry.id, items);

        if (logger) {
          logger(`Fetched ${items.length} items of board ${entry.boardId}`, 'info');
        }
      } catch (err2) {
        if (logger) {
          logger(`Failed to fetch items for board ${entry.boardId}.`, 'error');
        }

        throw err2;
      }
    }
  }

  return idsToItems;
}

export type BoardGroup = {
  id: string;
  title: string;
  position: string;
};

export type Workspace = {
  id: number;
  name: string;
};

export type BoardBasic = {
  id: number;
  board_kind: 'public' | 'private' | 'share';
  name: string;
  description: string;
  item_terminology: string;
  updated_at: string;
  type: string;
  board_folder_id: number | null;
  workspace: Workspace;
};

export type BoardColumn = {
  id: string;
  archived: boolean;
  settings_str: string;
  title: string;
  description: string | null;
  type: ColumnType;
  width: number | null;
};

export type Board = BoardBasic & {
  columns: BoardColumn[];
  groups: BoardGroup[];
};

const boardBasicFields = `
  id
  board_kind
  name
  description
  item_terminology
  type
  updated_at
  board_folder_id
  workspace {
    id
    name
  }
`;

const boardFields = `
  ${boardBasicFields}
  groups {
    id
    title
    position
  }
  columns {
    archived
    id
    settings_str
    title
    description
    type
    width
  }
`;

export async function getBoards(mondayClient: MondayClient, boardIds: number[]) {
  if (boardIds.length > 100) {
    throw new Error(`Can't fetch more than 100 boards at a time`);
  }

  const result = await mondayClient.query(
    `
    query getBoards($boardIds: [ID!]!) {
      complexity {
        query
      }
      boards(ids: $boardIds, limit: 100) {
        ${boardFields}
      }
    }
  `,
    { boardIds },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<Board[]>(result, 'boards', (boards) => boards.map((board: any) => idToNumber(board)));
}

export async function getBoard(mondayClient: MondayClient, boardId: number) {
  const boards = await getBoards(mondayClient, [boardId]);

  if (boards.length === 0) {
    throw new Error(`Board ${boardId} not found`);
  }

  return boards[0]!;
}

// TODO: filter out boards that are not of type 'sub_items_board' or 'board' when calling this function
export async function getAllBoards(mondayClient: MondayClient, abortSignal?: AbortSignal) {
  return await getAllResults(
    async (page, limit) => {
      const result = await mondayClient.query(
        `
          query getBoards($page: Int!, $limit: Int!) {
            complexity {
              query
            }
            boards(page: $page, limit: $limit, order_by: used_at) {
              ${boardBasicFields}
            }
          }
        `,
        { page, limit },
      );

      if (result.type === 'error') {
        throw new MondayRequesterError(result);
      }

      return extractApiResult<BoardBasic[]>(result, 'boards', (boards) => boards.map((board: any) => idToNumber(board)));
    },
    1000,
    abortSignal,
  );
}

export async function updateItemValue(mondayClient: MondayClient, boardId: number, itemId: number, columnId: string, value: any) {
  const result = await mondayClient.query(
    `
    mutation updateValue($boardId: ID!, $itemId: ID!, $columnId: String!, $value: String!) {
      complexity {
        query
      }
      change_simple_column_value (board_id: $boardId, item_id: $itemId, column_id: $columnId, value: $value) {
        id
      }
    }
  `,
    { boardId, itemId, columnId, value },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult(result, 'change_simple_column_value');
}

export async function createNotification(mondayClient: MondayClient, boardId: number, userId: number, text: string) {
  const result = await mondayClient.query(
    `
    mutation createNotification($userId: ID!, $boardId: ID!, $text: String!) {
      complexity {
        query
      }
      create_notification (user_id: $userId, target_id: $boardId, text: $text, target_type: Project) {
        text
      }
    }
  `,
    { boardId, userId, text },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult(result, 'create_notification');
}

export type Folder = {
  id: number;
  name: string;
  parent: {
    id: number;
    name: string;
  };
  workspace: {
    id: number;
    name: string;
  };
};

export async function getFolders(mondayClient: MondayClient, folderIds: number[]) {
  if (folderIds.length > 100) {
    throw new Error(`Can't fetch more than 100 folders at a time`);
  }

  const result = await mondayClient.query(
    `
    query getFolders($folderIds: [ID!]!) {
      complexity {
        query
      }
      folders(ids: $folderIds, limit: 100) {
        id
        name
        parent {
          id
          name
        }
        workspace {
          id
          name
        }
      }
    }
  `,
    { folderIds },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<Folder[]>(result, 'folders');
}

export type AppSubscription = {
  billing_period: string;
  days_left: number;
  is_trial: boolean;
  plan_id: string;
  renewal_date: string;
};

export async function getSubscription(mondayClient: MondayClient) {
  const result = await mondayClient.query(
    `
      query {
        complexity {
          query
        }
        app_subscription {
          billing_period
          days_left
          is_trial
          plan_id
          renewal_date
        }
      }
    `,
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return _.get(result, 'data.app_subscription.0') as AppSubscription | undefined;
}

export type User = {
  id: number;
  name: string;
  email: string;
  photo_small: string;
  time_zone_identifier: string;
};

const userFields = `
  id
  name
  email
  photo_small
  time_zone_identifier
`;

export async function getMe(mondayClient: MondayClient) {
  const result = await mondayClient.query(
    `
    query {
      complexity {
        query
      }
      me {
        ${userFields}
      }
    }
  `,
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User>(result, 'me');
}

export type Account = {
  id: number;
  slug: string;
};

export async function getAccount(mondayClient: MondayClient) {
  const result = await mondayClient.query(
    `
    query {
      complexity {
        query
      }
      account {
        id
        slug
      }
    }
  `,
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<Account>(result, 'account');
}

export async function getUsers(mondayClient: MondayClient, userIds: number[]) {
  if (userIds.length > 200) {
    throw new Error(`Can't fetch more than 200 users at a time`);
  }

  const result = await mondayClient.query(
    `
    query getUsers($userIds: [ID!]!) {
      complexity {
        query
      }
      users(limit: 200, ids: $userIds) {
        ${userFields}
      }
    }
  `,
    { userIds },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User[]>(result, 'users');
}

export async function getUsersByTeamId(mondayClient: MondayClient, teamId: number) {
  const result = await mondayClient.query(
    `
    query getUsersByTeamId($teamId: ID!) {
      complexity {
        query
      }
      teams(ids: [$teamId]) {
        users(limit: 200) {
          ${userFields}
        }
      }
    }
  `,
    { teamId },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User[]>(result, 'teams[0].users');
}

export async function getBoardSubscribers(mondayClient: MondayClient, boardId: number) {
  const result = await mondayClient.query(
    `
    query getBoardSubscribers($boardId: ID!) {
      complexity {
        query
      }
      boards(ids: [$boardId]) {
        subscribers {
          ${userFields}
        }
      }
    }
  `,
    { boardId },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User[]>(result, 'boards[0].subscribers');
}

export async function getBoardOwners(mondayClient: MondayClient, boardId: number) {
  const result = await mondayClient.query(
    `
    query getBoardSubscribers($boardId: ID!) {
      complexity {
        query
      }
      boards(ids: [$boardId]) {
        owners {
          ${userFields}
        }
      }
    }
  `,
    { boardId },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User[]>(result, 'boards[0].owners');
}

export async function getBoardItemSubscribers(mondayClient: MondayClient, itemId: number) {
  const result = await mondayClient.query(
    `
    query getBoardItemSubscribers($itemId: ID!) {
      complexity {
        query
      }
      items(ids: [$itemId]) {
        subscribers {
          ${userFields}
        }
      }
    }
  `,
    { itemId },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<User[]>(result, 'items[0].subscribers');
}

export async function getBoardItem(mondayClient: MondayClient, itemId: number, additionalFields = '') {
  const result = await mondayClient.query(
    `
    query getBoardItem($itemId: ID!) {
      complexity {
        query
      }
      items(ids: [$itemId]) {
        ${itemBaseFields}
        ${additionalFields}
      }
    }
  `,
    { itemId },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<BoardItem>(result, 'items[0]', transformBoardItem);
}

export type Asset = {
  created_at: string;
  file_extension: string;
  file_size: number;
  id: string;
  name: string;
  url: string;
  url_thumbnail: string;
  public_url: string;
};

export async function getAssets(mondayClient: MondayClient, assetIds: number[]) {
  const result = await mondayClient.query(
    `
    query getAssets($assetIds: [ID!]!) {
      complexity {
        query
      }
      assets(ids: $assetIds) {
        created_at
        file_extension
        file_size
        id
        name
        original_geometry
        public_url
        url
        url_thumbnail
      }
    }
  `,
    { assetIds },
  );

  if (result.type === 'error') {
    throw new MondayRequesterError(result);
  }

  return extractApiResult<Asset[]>(result, 'assets');
}

type OAuthToken = {
  token_type: 'bearer';
  access_token: string;
  scope: string;
};

export async function getOauthToken(clientId: string, clientSecret: string, code: string, redirectUri: string) {
  const response = await fetch('https://auth.monday.com/oauth2/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      client_id: clientId,
      client_secret: clientSecret,
      code,
      redirect_uri: redirectUri,
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to get oauth token: ${response.statusText}`);
  }

  const data = await response.json();

  return data as OAuthToken;
}
