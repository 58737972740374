import * as _ from 'lodash';

function createDeferred<T>() {
  let resolve: (value: T | PromiseLike<T>) => void;
  let reject: (reason?: any) => void;

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolve: resolve!,
    reject: reject!,
  };
}

export function createBatchResolver<T extends string | number, P>(
  resolverFn: (ids: T[]) => Promise<Record<T, P>>,
  waitMs: number = 10,
  maxBatchSize: number = 100
) {
  const itemState = {} as Record<T, ReturnType<typeof createDeferred<P>>>;
  const itemsQueue = [] as T[];

  function triggerResolver() {
    setTimeout(async () => {
      const itemIdsToProcess = [...itemsQueue];
      itemsQueue.length = 0;

      const chunks = _.chunk(itemIdsToProcess, maxBatchSize);

      for (const chunk of chunks) {
        const result = await resolverFn(chunk);

        for (const id of chunk) {
          if (id in result) {
            itemState[id].resolve(result[id]);
          } else {
            const err = new Error('could not resolve item');
            // @ts-ignore
            err.do_not_track = true;

            itemState[id].reject(err);
          }
        }
      }
    }, waitMs);
  }

  function resolveItem(id: T): Promise<P> {
    if (id in itemState) {
      return itemState[id].promise;
    }

    itemState[id] = createDeferred<P>();

    itemsQueue.push(id);

    if (itemsQueue.length === 1) {
      triggerResolver();
    }

    return itemState[id].promise;
  }

  function resolveItems(ids: T[]): Promise<P[]> {
    return Promise.all(ids.map((id) => resolveItem(id)));
  }

  return {
    resolveItem,
    resolveItems,
  };
}
