import { z } from 'zod';

export function parseJson(value: null | string) {
  if (!value) {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (err) {
    return null;
  }
}

export function parseNumber(value: unknown) {
  const parsedValue = z.number().safeParse(value);
  return parsedValue.success ? parsedValue.data : null;
}

export function parseDatetime(value: unknown) {
  const parsedValue = z.string().datetime().safeParse(value);
  return parsedValue.success ? parsedValue.data : null;
}

export function parseString(value: unknown) {
  const parsedValue = z.string().safeParse(value);
  return parsedValue.success ? parsedValue.data : null;
}

export function parseBoolean(value: unknown) {
  let normalizedValue = value;

  if (value === 'true') {
    normalizedValue = true;
  } else if (value === 'false') {
    normalizedValue = false;
  }

  const parsedValue = z.boolean().safeParse(normalizedValue);
  return parsedValue.success ? parsedValue.data : null;
}

export function isString(value: string | null): boolean {
  return z.string().safeParse(value).success;
}
