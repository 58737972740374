import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString } from '../converters';
import { ColorPickerColumnType } from '../../monday-api/api';

export type ColorPickerColumnValue = {
  hex: string | null;
  text: string | null;
  changed_at: string | null;
};

const handler: ColumnHandler<ColorPickerColumnType, ColorPickerColumnValue> = {
  type: 'color_picker',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const hex = parseString(_.get(value, 'color.hex'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      hex,
      changed_at: changedAt,
    };
  },

  // this field is not supported by monday in formulas
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
