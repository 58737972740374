import { useState } from 'react';
import React from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  Flex,
  IconButton,
  Loader,
  Icon,
  Dialog,
  DialogContentContainer,
  Tooltip,
} from 'monday-ui-react-core';
import DropdownChevronDownIcon from 'monday-ui-react-core/dist/icons/DropdownChevronDown';
import AddIcon from 'monday-ui-react-core/dist/icons/Add';
import Delete from 'monday-ui-react-core/dist/icons/Delete';
import Edit from 'monday-ui-react-core/dist/icons/Edit';
import { TemplateConfig, Sheet as SheetType, Template } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { EditWorksheetNameModal } from './EditWorksheetNameModal';
import { Board } from '@gorilla/common/src/lib/monday-api/api';
import { Sheet } from './Sheet';
import { createRandomId, createSheetName } from '../lib/utils';

type SheetsProps = {
  boards?: Board[];

  config: TemplateConfig;

  template: Template;

  onSave: () => void;

  onSheetAdd: (sheet: SheetType) => void;

  onSheetChange: (sheet: SheetType) => void;

  onSheetRemove: (sheetId: string) => void;

  onOpenBoardManager: () => void;
};

export function Sheets({ config, onSheetAdd, onSheetRemove, onSheetChange, boards, onOpenBoardManager, template, onSave }: SheetsProps) {
  const [selectedSheetId, setSelectedSheetId] = useState(config.sheets.length ? config.sheets[0].id : undefined);
  const selectedSheetIdx = config.sheets.findIndex(({ id }) => id === selectedSheetId);
  const selectedSheet = selectedSheetIdx !== undefined ? config.sheets[selectedSheetIdx] : undefined;
  const [editWorksheetNameModal, showEditWorksheetNameModal] = useState<string | false>(false);
  const selectedEditNameSheet = config.sheets.find(({ id }) => id === editWorksheetNameModal);
  //const [actionsButtonActive, setActionsButtonActive] = useState(false);

  if (!boards) {
    return (
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '32px',
          width: '100%',
          height: 'calc(100vh - 210px)',
        }}
      >
        <Loader size={Loader.sizes?.SMALL as any} color={Loader.colors?.DARK as any} />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: 'inline-flex',
          flexWrap: 'nowrap',
          flexDirection: 'row',
          alignItems: 'stretch',
          margin: '32px 32px 0 32px',
          width: 'auto',
          border: '1px solid #d0d4e4',
          borderBottom: 0,
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
        }}
      >
        {config.sheets.map((sheet, i) => {
          return (
            <div
              key={sheet.id}
              className={`sheet--tab ${sheet.id === selectedSheetId ? 'sheet--active' : ''}`}
              onClick={(e) => {
                setSelectedSheetId(sheet.id as string);
              }}
            >
              <div
                style={{
                  padding: '16px 10px 13px 20px',
                }}
              >
                <Flex gap={5}>
                  <div>{sheet.name}</div>
                  <div
                    style={{
                      transform: 'translate3d(0,2px,0)',
                    }}
                  >
                    <Dialog
                      modifiers={[
                        {
                          name: 'preventOverflow',
                          options: {
                            mainAxis: false,
                          },
                        },
                      ]}
                      zIndex={105}
                      shouldShowOnMount={false}
                      showTrigger={[Dialog.hideShowTriggers.CLICK]}
                      hideTrigger={[Dialog.hideShowTriggers.CLICK_OUTSIDE]}
                      position={Dialog.positions.BOTTOM_START}
                      onDialogDidShow={() =>
                        //setActionsButtonActive(true)
                        null
                      }
                      onDialogDidHide={() =>
                        //setActionsButtonActive(false)
                        null
                      }
                      content={
                        <>
                          <DialogContentContainer type={DialogContentContainer.types.POPOVER} size={DialogContentContainer.sizes.SMALL}>
                            <List>
                              <ListItem
                                size={ListItem.sizes?.XS}
                                onClick={() => {
                                  showEditWorksheetNameModal(sheet.id);
                                }}
                              >
                                <ListItemIcon icon={Edit} />
                                Edit worksheet name
                              </ListItem>
                              <ListItem
                                size={ListItem.sizes?.XS}
                                disabled={config.sheets.length === 1}
                                onClick={(ev) => {
                                  ev.preventDefault();
                                  ev.stopPropagation();

                                  onSheetRemove(sheet.id);

                                  if (selectedSheetId === sheet.id) {
                                    const nextSheet = config.sheets.find((s) => s.id !== sheet.id);

                                    if (nextSheet) {
                                      setSelectedSheetId(nextSheet.id);
                                    }
                                  }
                                }}
                              >
                                <ListItemIcon icon={Delete} />
                                Delete worksheet
                              </ListItem>
                            </List>
                          </DialogContentContainer>
                        </>
                      }
                    >
                      <IconButton
                        //active={actionsButtonActive}
                        icon={DropdownChevronDownIcon}
                        kind={IconButton.kinds?.TERTIARY as any}
                        size={IconButton.sizes?.XS as any}
                      />
                    </Dialog>
                  </div>
                </Flex>
              </div>
            </div>
          );
        })}

        {selectedEditNameSheet ? (
          <EditWorksheetNameModal
            show={!!editWorksheetNameModal}
            sheet={selectedEditNameSheet}
            onClose={(newSheetName) => {
              if (newSheetName) {
                onSheetChange({
                  ...selectedEditNameSheet,
                  name: newSheetName,
                });
              }
              showEditWorksheetNameModal(false);
            }}
          />
        ) : null}

        {config.sheets.length < 6 ? (
          <>
            <div
              className="sheet--tab"
              onClick={() => {
                const newSheetId = createRandomId();
                const newSheetName = createSheetName(config.sheets.map(({ name }) => name));

                onSheetAdd({
                  id: newSheetId,
                  name: newSheetName, // TODO: create better name
                  field_groups: [],
                });

                setSelectedSheetId(newSheetId);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div
                  style={{
                    pointerEvents: 'none',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Icon icon={AddIcon} iconSize={16} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>

      <div
        style={{
          margin: '0 32px 32px 32px',
          boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
          border: '1px solid #d0d4e4',
          borderTopRightRadius: '8px',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          backgroundColor: '#f6f7fb',
        }}
      >
        <div>
          {selectedSheet ? (
            <Sheet
              sheet={selectedSheet}
              template={template}
              config={config}
              boards={boards}
              onSave={onSave}
              onOpenBoardManager={onOpenBoardManager}
              onRemove={() => {
                onSheetRemove(selectedSheet.id);
                setSelectedSheetId(config.sheets[selectedSheetIdx - 1].id);
              }}
              onFieldGroupAdd={(fieldGroup) => {
                onSheetChange({
                  ...selectedSheet,
                  field_groups: [...selectedSheet.field_groups, fieldGroup],
                });
              }}
              onFieldGroupRemove={(fieldGroup) => {
                onSheetChange({
                  ...selectedSheet,
                  field_groups: selectedSheet.field_groups.filter((fg) => fg.id !== fieldGroup.id),
                });
              }}
              onFieldGroupChange={(fieldGroup) => {
                onSheetChange({
                  ...selectedSheet,
                  field_groups: selectedSheet.field_groups.map((fg) => (fg.id === fieldGroup.id ? fieldGroup : fg)),
                });
              }}
              onNameChange={(newSheetName) => {
                onSheetChange({
                  ...selectedSheet,
                  name: newSheetName,
                });
              }}
              isRemoveable={selectedSheetIdx > 0}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}
