import * as Sentry from '@sentry/react';
import * as _ from 'lodash';
import { monday } from './services/monday';

if (import.meta.env.MODE !== 'development') {
  Sentry.init({
    dsn: 'https://bdaa97ac37a3400e9473076f39e304d9@o149455.ingest.sentry.io/4505605572591616',
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['localhost'],
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  monday.get('context').then(({ data }) => {
    const id = `${_.get(data, 'account.id')}_${_.get(data, 'user.id')}`;
    Sentry.setUser({ id: id, user_id: _.get(data, 'user.id'), account_id: _.get(data, 'account.id') });
  });
}
