import LZString from 'lz-string';
import { monday } from './monday';

type GetResponse = Awaited<ReturnType<typeof monday.storage.instance.getItem>>;
type SetResponse = Awaited<ReturnType<typeof monday.storage.instance.setItem>>;

export type SpreadsheetsIndexEntry = {
  id: string;
  title: string;
  updated_at: number;
  created_at: number;
};

export type SpreadsheetsIndex = SpreadsheetsIndexEntry[];

export type SpreadsheetValue = {
  title: string;
  data: any;
};

function handleResponseError(response: GetResponse | SetResponse) {
  if (!response.data.success) {
    if (response.errorMessage) {
      throw new Error(response.errorMessage);
    } else if ('reason' in response.data) {
      throw new Error(response.data.reason as any);
    } else {
      throw new Error('could not fetch spreadsheets');
    }
  }
}

export async function getSpreadsheetsIndex() {
  const response = await monday.storage.instance.getItem('spreadsheets-index');
  handleResponseError(response);

  try {
    return {
      version: response.data.version,
      value: (response.data.value ? JSON.parse(response.data.value) : []) as SpreadsheetsIndex,
    };
  } catch (err) {
    throw new Error('could not parse list');
  }
}

export async function saveSpreadsheetsIndex(index: SpreadsheetsIndex, prevVersionId?: string) {
  const response = await monday.storage.instance.setItem(`spreadsheets-index`, JSON.stringify(index), { previous_version: prevVersionId });
  handleResponseError(response);
}

export async function getSpreadsheet(spreadsheetId: string) {
  const response = await monday.storage.instance.getItem(`spreadsheet-${spreadsheetId}`);

  handleResponseError(response);

  const value = JSON.parse(response.data.value);

  if (value.compressed) {
    value.data = JSON.parse(LZString.decompressFromBase64(value.data));
  }

  try {
    return {
      version: response.data.version,
      value: value as SpreadsheetValue,
    };
  } catch (err) {
    throw new Error('could not parse spreadsheet data');
  }
}

export async function saveSpreadsheet(spreadsheetId: string, value: SpreadsheetValue, prevVersionId?: string) {
  const indexResponse = await getSpreadsheetsIndex();
  const index = indexResponse.value;
  const updatedAt = Date.now();
  const existingEntryIdx = indexResponse.value.findIndex((entry) => entry.id === spreadsheetId);

  if (existingEntryIdx > -1) {
    index[existingEntryIdx].title = value.title;
    index[existingEntryIdx].updated_at = updatedAt;
  } else {
    index.push({
      id: spreadsheetId,
      title: value.title,
      updated_at: updatedAt,
      created_at: updatedAt,
    });
  }

  const dataStr = JSON.stringify(value.data);
  const dataStrCompressed = LZString.compressToBase64(dataStr);

  //console.log(`data size: ${dataStr.length}, data size compressed: ${dataStrCompressed.length}`);

  const response = await monday.storage.instance.setItem(
    `spreadsheet-${spreadsheetId}`,
    JSON.stringify({ ...value, data: dataStrCompressed, compressed: true, updated_at: updatedAt }),
    { previous_version: prevVersionId }
  );

  handleResponseError(response);

  // TODO: make this code more robust
  await saveSpreadsheetsIndex(index, indexResponse.version);

  return index;
}

export async function deleteSpreadsheet(spreadsheetId: string) {
  await monday.storage.instance.deleteItem(`spreadsheet-${spreadsheetId}`);

  const indexResponse = await getSpreadsheetsIndex();
  const index = indexResponse.value.filter(({ id }) => spreadsheetId !== id);

  // TODO: make this code more robust
  await saveSpreadsheetsIndex(index, indexResponse.version);
}
