import useSWR from 'swr';
import * as _ from 'lodash';
import { monday, mondayClient } from '../services/monday';
import { getSubscription } from '@gorilla/common/src/lib/monday-api/api';

interface MondayContext {
  boardId: number;
  user: {
    id: string;
    isAdmin: boolean;
  };
}

export const BETA_EXPIRE_DATE = new Date(import.meta.env.MODE === 'development' ? '2040-01-01' : '2023-12-17');

type APP_MODE = null | 'beta' | 'beta_expired' | 'trial' | 'trial_expired' | 'full' | 'expired';

export async function getMondayContext() {
  return (await monday.get('context')).data as MondayContext;
}

export default function useMonday() {
  const { data: mondayContext } = useSWR(
    `/monday-context`,
    async () => {
      const mondayContext = await getMondayContext();

      return mondayContext;
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  const { data: subscription } = useSWR(
    `/monday-subscription`,
    async () => {
      return getSubscription(mondayClient);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    }
  );

  const subscriptionIsLoading = !subscription;
  let appMode: APP_MODE = null;

  if (!subscriptionIsLoading) {
    if (!subscription) {
      if (Date.now() > BETA_EXPIRE_DATE.getTime()) {
        appMode = 'beta_expired';
      } else {
        appMode = 'beta';
      }
    } else {
      if (subscription.is_trial) {
        if (subscription.days_left === 0) {
          appMode = 'trial_expired';
        } else {
          appMode = 'trial';
        }
      } else {
        if (subscription.days_left === 0) {
          appMode = 'expired';
        } else {
          appMode = 'full';
        }
      }
    }
  }

  return {
    mondayContext,
    subscriptionIsLoading,
    subscription,
    appMode,
  };
}
