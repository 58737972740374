import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseString } from '../converters';
import { LinkColumnType } from '../../monday-api/api';

export type LinkColumnValue = {
  text: string | null;
  label: string | null;
  url: string | null;
};

const handler: ColumnHandler<LinkColumnType, LinkColumnValue> = {
  type: 'link',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['empty_string', 'text'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['empty_string', 'text'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'contains_text',
      compare_value_ids: ['text'],
    },
    {
      operator: 'not_contains_text',
      compare_value_ids: ['text'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const url = parseString(_.get(value, 'url'));
    const label = parseString(_.get(value, 'text'));

    return {
      text,
      url,
      label,
    };
  },

  // currently not supported by monday
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
