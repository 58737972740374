import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import {
  TextField,
  List,
  ListItem,
  EditableHeading,
  Button,
  Box,
  Flex,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Icon,
  IconButton,
  Tooltip,
} from 'monday-ui-react-core';
import { Template, TemplateConfig, Message } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { useSpreadsheetLoaderByTemplate } from '../hooks/use-spreadsheet-loader';
import { MessageBodyEditor } from './MessageBodyEditor';
import { createRandomId } from '../lib/utils';
import WarningIcon from 'monday-ui-react-core/dist/icons/Warning';
import DeleteIcon from 'monday-ui-react-core/dist/icons/Delete';
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';

const INITIAL_MESSAGE_BODY_VALUE = [
  {
    type: 'paragraph',
    children: [
      { text: 'Type a message here and insert formulas ' },
      {
        type: 'formula',
        children: [{ text: '=SUM(A1:A100)' }],
      },
      { text: ' inline, that reference right to columns from your spreadsheet template.' },
    ],
  },
];

type MessagesModalProps = {
  show: boolean;
  onClose: (newConfig?: TemplateConfig) => void;
  template: Template;
};

export function MessagesModal({ show, onClose, template }: MessagesModalProps) {
  const [loadItems, setLoadItems] = useState(false);
  const { data, progress, loadingMessage } = useSpreadsheetLoaderByTemplate(loadItems ? template : undefined, 'sheetjs', false);
  //const data = null;
  const [selectedMessageId, setSelectedMessageId] = useState<string | null>(null);
  const [messages, setMessages] = useState(_.cloneDeep(template.config.messages || []));
  const normalizedSelectedMessageId =
    selectedMessageId && messages.find((messageTemplate) => messageTemplate.id === selectedMessageId)
      ? selectedMessageId
      : messages[0]?.id || null;
  const selectedMessage = normalizedSelectedMessageId ? messages.find((message) => message.id === normalizedSelectedMessageId) : null;
  const [hasMessages, setHasMessages] = useState<boolean>(false);
  const [hasMaxMessages, setMaxMessages] = useState<boolean>(false);

  useEffect(() => {
    setHasMessages(messages.length > 0);
    setMaxMessages(messages.length > 7);
  }, [messages]);

  function updateMessageTemplate(newMessage: Message) {
    setMessages(
      messages.map((message) => {
        if (message.id === newMessage.id) {
          return newMessage;
        }

        return message;
      })
    );
  }

  let modalContent: React.ReactNode | null = null;

  if (data || progress === null) {
    modalContent = (
      <>
        {selectedMessage ? (
          <Flex gap={32} align={Flex.align?.STRETCH}>
            <div style={{ width: '200px' }}>
              <div style={{ marginBottom: '16px' }}>
                <Button
                  style={{ width: '100%' }}
                  disabled={hasMaxMessages}
                  onClick={() => {
                    const messageId = createRandomId();

                    setMessages(
                      messages.concat({
                        id: messageId,
                        title: 'New message',
                        subject: 'Subject',
                        body: INITIAL_MESSAGE_BODY_VALUE,
                      })
                    );

                    setSelectedMessageId(messageId);
                  }}
                >
                  Create message
                </Button>
              </div>
              <List>
                {messages.map((message) => {
                  return (
                    <ListItem
                      key={message.id}
                      selected={message.id === normalizedSelectedMessageId}
                      size={ListItem.sizes?.XS}
                      onClick={() => {
                        setSelectedMessageId(message.id);
                      }}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          width: '140px',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {message.title}
                      </div>
                      <div
                        style={{
                          marginLeft: 'auto',
                        }}
                      >
                        <IconButton
                          icon={DeleteIcon}
                          kind={IconButton.kinds.TERTIARY}
                          size={IconButton.sizes?.XS}
                          onClick={() => {
                            setMessages(messages.filter((message) => message.id !== normalizedSelectedMessageId));
                          }}
                        />
                      </div>
                    </ListItem>
                  );
                })}
              </List>
            </div>
            <div style={{ flex: 1 }}>
              <Box
                border={Box.borders?.DEFAULT}
                borderColor={Box.borderColors?.UI_BORDER_COLOR}
                rounded={Box.roundeds?.SMALL}
                shadow={Box.shadows?.XS}
                className="messagebox"
              >
                <div style={{ position: 'absolute', top: '-15px', left: '50%', transform: 'translate3d(-50%,0,0)' }}>
                  <div style={{ display: 'inline-block' }}>
                    <EditableHeading
                      maxLength={30}
                      autoSize={true}
                      onFinishEditing={(value) => {
                        updateMessageTemplate({
                          ...selectedMessage,
                          title: value || 'New message',
                        });
                      }}
                      type={EditableHeading.types?.h4}
                      value={selectedMessage?.title}
                      className="messageTitle"
                    />
                  </div>
                </div>
                <div style={{ padding: '24px 24px 24px 24px' }}>
                  <div style={{ display: 'flex', gap: '24px' }}>
                    <div style={{ width: '50%' }}>
                      <TextField
                        placeholder="Subject"
                        title="Subject"
                        value={selectedMessage?.subject}
                        debounceRate={100}
                        onBlur={(ev) => {
                          const newSubject = (ev.target as any).value;

                          updateMessageTemplate({
                            ...selectedMessage,
                            subject: newSubject || '',
                          });
                        }}
                      />
                    </div>
                    <div style={{ width: '50%' }}></div>
                  </div>
                  <div style={{ position: 'relative', marginTop: '16px' }}>
                    <MessageBodyEditor
                      key={selectedMessage.id}
                      workbook={data}
                      value={selectedMessage.body}
                      onChange={(newBody) => {
                        updateMessageTemplate({
                          ...selectedMessage,
                          body: newBody,
                        });
                      }}
                    />
                    {progress === null ? (
                      <div style={{ position: 'absolute', right: '16px', bottom: '16px' }}>
                        <Tooltip content="Load board data to preview formula results" position={Tooltip.positions?.RIGHT} zIndex={20000}>
                          <Button
                            size={Button.sizes?.SMALL}
                            kind={Button.kinds?.PRIMARY}
                            onClick={() => {
                              setLoadItems(true);
                            }}
                          >
                            Load results
                          </Button>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Box>
            </div>
            <div style={{ width: '260px' }}>
              <div className="messageboxInfo">
                <Flex gap={6}>
                  <Icon icon={WarningIcon} clickable={false} />
                  <strong>Info</strong>
                </Flex>
                <div style={{ marginTop: '10px' }}>
                  When using this message in an email sending automation, the sender will be <strong>automation@getgorilla.app</strong>.
                  Make sure to set it as trusted email address across your organization to avoid these emails being flagged as spam.
                </div>
                <div style={{ marginTop: '10px' }}>
                  When using this message in a notification automation, the sender will be the person that configured and set up the
                  automation.
                </div>
                <div style={{ marginTop: '35px' }}>
                  <strong>
                    Learn more about troubleshooting automations and about technical limitations{' '}
                    <a
                      href="https://getgorilla.app/products/spreadsheet-gorilla/automations"
                      target="_blank"
                      style={{ textDecoration: 'underline', fontWeight: 'bold', color: '#323338' }}
                    >
                      here
                    </a>
                    .
                  </strong>
                </div>
              </div>
            </div>
          </Flex>
        ) : (
          <Flex
            gap={24}
            align={Flex.align?.CENTER}
            justify={Flex.justify?.CENTER}
            direction={Flex.directions?.COLUMN}
            style={{ height: 'inherit' }}
          >
            <h2 style={{ color: '#0073ea' }}>You haven't created any messages yet.</h2>
            <p style={{ margin: '-15px 0 5px 0', textAlign: 'center' }}>
              Messages are used in your automations and can contain <br />
              formulas, that reference to this spreadsheet template.
            </p>
            <Button
              onClick={() => {
                const messageId = createRandomId();

                setMessages(
                  messages.concat({
                    id: messageId,
                    title: 'New message',
                    subject: 'Subject',
                    body: INITIAL_MESSAGE_BODY_VALUE,
                  })
                );

                setSelectedMessageId(messageId);
              }}
            >
              Create message
            </Button>
          </Flex>
        )}
      </>
    );
  } else {
    modalContent = (
      <Flex align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} style={{ height: 'inherit' }}>
        {loadingMessage}
      </Flex>
    );
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={Modal.width?.FULL_WIDTH as any}
      alertDialog={true}
      data-testid="message-modal"
    >
      <ModalHeader title="Edit messages" closeButtonAriaLabel="" hideCloseButton={true} />
      <ModalContent>
        <div
          style={{
            marginTop: '24px',
            width: '100%',
            height: '390px',
          }}
        >
          {modalContent}
        </div>
      </ModalContent>
      <ModalFooter>
        <Flex gap={12} justify={Flex.justify?.END as any}>
          <Box marginEnd={Box.marginEnds?.AUTO as any}>
            <div style={{ color: '#afb0bd' }}>
              Define messages here that will be sent to recipients in automations. You can have up to 8 messages at the moment.
            </div>
          </Box>
          <Button
            disabled={!hasMessages}
            onClick={() => {
              onClose({ ...template.config, messages });
            }}
          >
            Save
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
