import React from 'react';
import { useState } from 'react';
import * as _ from 'lodash';
import { Modal, ModalHeader, ModalContent, ModalFooter, Button, Heading, Box, Flex, IconButton, ColorPicker } from 'monday-ui-react-core';
import Dropdown from 'monday-ui-react-core/dist/Dropdown';
import BoldIcon from 'monday-ui-react-core/dist/icons/Bold';
import ItalicIcon from 'monday-ui-react-core/dist/icons/Italic';
import UnderlineIcon from 'monday-ui-react-core/dist/icons/Underline';
import TextcolorIcon from 'monday-ui-react-core/dist/icons/Textcolor';
import HighlightColorBucketIcon from 'monday-ui-react-core/dist/icons/HighlightColorBucket';
import { BoardColumnConfig, BoardField } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';

const FONT_SIZE_OPTIONS = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '14',
    value: 14,
  },
  {
    label: '16',
    value: 16,
  },
  {
    label: '24',
    value: 24,
  },
  {
    label: '36',
    value: 36,
  },
];

const FONT_TYPE_OPTIONS = [
  {
    label: 'Times New Roman',
    value: 'Times New Roman',
  },
  {
    label: 'Arial',
    value: 'Arial',
  },
  {
    label: 'Tahoma',
    value: 'Tahoma',
  },
  {
    label: 'Verdana',
    value: 'Verdana',
  },
];

const HORIZONTAL_ALIGNMENT_OPTIONS = [
  {
    label: 'Left',
    value: 'left',
  },
  {
    label: 'Center',
    value: 'center',
  },
  {
    label: 'Right',
    value: 'right',
  },
];

type ColumnConfigModalProps = {
  show: boolean;
  columnName: string;
  fieldName: string;
  onClose: (newConfig: BoardColumnConfig | null) => void;
  config: BoardColumnConfig | null;
  field: BoardField;
};

export function ColumnConfigModal({ show, columnName, fieldName, onClose, field, config: initialConfig }: ColumnConfigModalProps) {
  const [config, setConfig] = useState<BoardColumnConfig | null>(initialConfig ? _.cloneDeep(initialConfig) : {});
  const [showTextColor, setTextColor] = useState(false);
  const [showBackgroundColor, setBackgroundColor] = useState(false);

  const selectedFontSizeOption = FONT_SIZE_OPTIONS.find(({ value }) => value === config?.cell_font_size);
  const selectedFontType = FONT_TYPE_OPTIONS.find(({ value }) => value === config?.cell_font_type);
  const selectedHorizontalAlignment = HORIZONTAL_ALIGNMENT_OPTIONS.find(({ value }) => value === config?.cell_horizontal_alignment);

  const lastPathFragment = field.path[field.path.length - 1];
  const isFormulaField = lastPathFragment.type === 'field' && lastPathFragment.field_id === 'custom-formula';

  return (
    <Modal data-testid="" show={show} onClose={() => onClose(null)} width={Modal.width?.DEFAULT as any} alertDialog={false}>
      <ModalHeader title={`Column ${columnName}`} description={fieldName} hideCloseButton={true} closeButtonAriaLabel="" />
      <ModalContent>
        {/* 
        {isFormulaField ? (
          <Box
            padding={Box.paddings?.MEDIUM as any}
            border={Box.borders?.DEFAULT as any}
            borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
            rounded={Box.roundeds?.SMALL as any}
            marginBottom={Box.marginBottoms?.MEDIUM as any}
          >
            <Box marginBottom={Box.marginBottoms?.MEDIUM as any}>
              <Heading type={Heading.types?.h5 as any} value="Formula settings" />
            </Box>
            <TextField
              value={config?.formula || ''}
              onChange={(newFormula) => {
                setConfig({
                  ...config,
                  formula: newFormula,
                });
              }}
              size={TextField.sizes?.LARGE}
              placeholder="Formula"
            />
          </Box>
        ) : (
          <></>
        )}
        */}
        <Box
          padding={Box.paddings?.MEDIUM as any}
          border={Box.borders?.DEFAULT as any}
          borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
          rounded={Box.roundeds?.SMALL as any}
        >
          <Box marginBottom={Box.marginBottoms?.MEDIUM as any}>
            <Heading type={Heading.types?.h5 as any} value="Font settings" />
          </Box>
          <Flex gap={Flex.gaps?.SMALL as any}>
            <div style={{ flex: 1 }}>
              <Flex gap={Flex.gaps?.SMALL as any}>
                <div style={{ width: '70%' }}>
                  <Dropdown
                    options={FONT_TYPE_OPTIONS}
                    value={selectedFontType}
                    placeholder="Select"
                    insideOverflowContainer={true}
                    clearable={false}
                    onChange={(newFontType) => {
                      setConfig({
                        ...config,
                        cell_font_type: newFontType.value,
                      });
                    }}
                  />
                </div>
                <div style={{ width: '30%' }}>
                  <Dropdown
                    options={FONT_SIZE_OPTIONS}
                    value={selectedFontSizeOption}
                    placeholder="Select"
                    insideOverflowContainer={true}
                    clearable={false}
                    onChange={(newSize) => {
                      setConfig({
                        ...config,
                        cell_font_size: newSize.value,
                      });
                    }}
                  />
                </div>
              </Flex>
            </div>
            <IconButton
              icon={BoldIcon}
              kind={IconButton.kinds?.SECONDARY as any}
              ariaLabel="Bold"
              active={config?.cell_font_bold}
              onClick={() => {
                setConfig({
                  ...config,
                  cell_font_bold: !config?.cell_font_bold,
                });
              }}
            />
            <IconButton
              icon={ItalicIcon}
              kind={IconButton.kinds?.SECONDARY as any}
              ariaLabel="Italic"
              active={config?.cell_font_italic}
              onClick={() => {
                setConfig({
                  ...config,
                  cell_font_italic: !config?.cell_font_italic,
                });
              }}
            />
            <IconButton
              icon={UnderlineIcon}
              kind={IconButton.kinds?.SECONDARY as any}
              ariaLabel="Underline"
              active={config?.cell_font_underline}
              onClick={() => {
                setConfig({
                  ...config,
                  cell_font_underline: !config?.cell_font_underline,
                });
              }}
            />
            <IconButton
              icon={TextcolorIcon}
              kind={IconButton.kinds?.SECONDARY as any}
              ariaLabel="Text color"
              onClick={() => {
                showTextColor ? setTextColor(false) : setTextColor(true);
              }}
              active={showTextColor}
            />
          </Flex>
          {showTextColor ? (
            <ColorPicker
              colorSize={ColorPicker.colorSizes!.SMALL}
              numberOfColorsInLine={12}
              noColorText="Clear color"
              value={config?.cell_font_color}
              onSave={(val) => {
                setConfig({
                  ...config,
                  cell_font_color: val ? val[0] : undefined,
                });
              }}
            />
          ) : (
            <></>
          )}
        </Box>
        <Box
          marginTop={Box.marginTops?.MEDIUM as any}
          padding={Box.paddings?.MEDIUM as any}
          border={Box.borders?.DEFAULT as any}
          borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
          rounded={Box.roundeds?.SMALL as any}
        >
          <Box marginBottom={Box.marginBottoms?.MEDIUM as any}>
            <Heading type={Heading.types?.h5 as any} value="Cell settings" />
          </Box>
          <Flex gap={Flex.gaps?.SMALL as any}>
            <div style={{ flex: 1 }}>
              <Flex gap={Flex.gaps?.SMALL as any}>
                <div style={{ width: '100%' }}>
                  <Dropdown
                    options={HORIZONTAL_ALIGNMENT_OPTIONS}
                    value={selectedHorizontalAlignment}
                    placeholder="Alignment"
                    insideOverflowContainer={true}
                    clearable={false}
                    onChange={(alignment) => {
                      setConfig({
                        ...config,
                        cell_horizontal_alignment: alignment.value,
                      });
                    }}
                  />
                </div>
              </Flex>
            </div>
            <IconButton
              icon={HighlightColorBucketIcon}
              kind={IconButton.kinds?.SECONDARY as any}
              ariaLabel="Background color"
              onClick={() => {
                showBackgroundColor ? setBackgroundColor(false) : setBackgroundColor(true);
              }}
              active={showBackgroundColor}
            />
          </Flex>
          {showBackgroundColor ? (
            <ColorPicker
              value={config?.cell_bg_color}
              colorSize={ColorPicker.colorSizes!.SMALL}
              numberOfColorsInLine={12}
              noColorText="Clear color"
              onSave={(val) => {
                setConfig({
                  ...config,
                  cell_bg_color: val ? val[0] : undefined,
                });
              }}
            />
          ) : (
            <></>
          )}
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose(null);
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  onClose(config);
                }}
              >
                Confirm
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
