import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString } from '../converters';
import { MirrorColumnType, MirrorColumnValue as MirrorColumnValueGraphql } from '../../monday-api/api';

export type LookupColumnValue = {
  text: string | null;
};

const handler: ColumnHandler<MirrorColumnType, LookupColumnValue, unknown, MirrorColumnValueGraphql> = {
  type: 'mirror',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.display_value);

    return {
      text,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
