import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString } from '../converters';
import { VotesColumnType } from '../../monday-api/api';

export type VotesColumnValue = {
  text: string | null;
  voters_ids: number[] | null;
  changed_at: string | null;
};

const handler: ColumnHandler<VotesColumnType, VotesColumnValue> = {
  type: 'vote',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['user_id', 'votes_empty'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['user_id', 'votes_empty'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const votersIds = (_.get(value, 'votersIds') as number[]) || null;
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      voters_ids: votersIds,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Count') {
      return engineValue.voters_ids?.length || 0;
    }

    if (modifier === 'Names') {
      throw new Error('Names modifier not supported yet');
    }

    return engineValue.text === null ? '' : engineValue.text;
  },
};

export default handler;
