import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseDatetime, parseString } from '../converters';
import { ButtonColumnType } from '../../monday-api/api';

export type ButtonColumnValue = {
  text: string | null;
  clicks: number | null;
  changed_at: string | null;
};

const handler: ColumnHandler<ButtonColumnType, ButtonColumnValue> = {
  type: 'button',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const clicks = parseNumber(_.get(value, 'clicks'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      clicks,
      changed_at: changedAt,
    };
  },

  // this field is not supported by monday in formulas
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
