import { useState, useMemo } from 'react';
import * as _ from 'lodash';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import {
  getReferencedBoardIds,
  getBoardFields,
  isBlankBoardField,
  isCustomFormulaBoardField,
  isParentIdField,
  isFormulaField,
} from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import { Template, TemplateConfig } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { createRandomId, handleErrors } from '../lib/utils';
import { getBoard, Board } from '@gorilla/common/src/lib/monday-api/api';
import { getTemplate, updateTemplate, deleteTemplate as deleteTemplateApi } from '../services/templates';
import { mondayClient } from '../services/monday';

export async function getDefaultColumns(boardId: number) {
  let board: Board | null = null;

  try {
    board = await getBoard(mondayClient, boardId);
  } catch (err) {
    throw new Error(`Failed to fetch metadata for board ${boardId}: ${err.message}`);
  }

  const fields = getBoardFields(board);

  return fields
    .filter(
      (field) =>
        field.path.length === 1 &&
        !isBlankBoardField(field) &&
        !isCustomFormulaBoardField(field) &&
        !isParentIdField(field) &&
        !isFormulaField(field)
    )
    .map((field) => ({
      id: createRandomId(),
      path: field.path,
    }));
}

export const getDefaultTemplate = async (boardId: number, showHeadlines = false) => {
  let board: Board | null = null;

  try {
    board = await getBoard(mondayClient, boardId);

    if (!board) {
      throw new Error('Board not found');
    }
  } catch (err) {
    throw new Error(
      `There was a problem with the monday API that prevented the app from fetching the metadata of the board with ID "${boardId}", try again later: ${err.message}`
    );
  }

  const fields = getBoardFields(board);

  return {
    name: `"${board.name}" Template`,
    type: 'spreadsheet',
    private: true,
    config: {
      boards: [boardId], // TODO: add the ID of the current board
      sheets: [
        {
          id: 'sheet1',
          name: 'Sheet1',
          field_groups: [
            {
              id: createRandomId(),
              board_id: boardId,
              show_headlines: showHeadlines,
              columns: fields
                .filter(
                  (field) =>
                    field.path.length === 1 &&
                    !isBlankBoardField(field) &&
                    !isCustomFormulaBoardField(field) &&
                    !isParentIdField(field) &&
                    !isFormulaField(field)
                )
                .map((field) => ({
                  id: createRandomId(),
                  path: field.path,
                })),
            },
          ],
        },
      ],
    },
  };
};

export function useSpreadsheetTemplate(templateId?: number) {
  const [draftTemplate, setDraftTemplate] = useState<Template>();

  const {
    data: template,
    isLoading,
    mutate,
    error,
  } = useSWR(
    _.isNumber(templateId) ? `/api/get-template/${templateId}` : null,
    async () => {
      return getTemplate(templateId!);
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
    }
  );

  const {
    trigger: updateTemplateTrigger,
    isMutating: isUpdatingTemplate,
    error: updateTemplateError,
  } = useSWRMutation(`/api/update-template/${templateId}`, (key, { arg }) => handleErrors(updateTemplate)(arg));

  const workingTemplate = draftTemplate || template;

  function updateTemplateDraftConfig(config: TemplateConfig) {
    if (!workingTemplate) {
      throw new Error('No template loaded');
    }

    const newTemplate = _.cloneDeep(_.omit(workingTemplate, 'config')) as Template;
    newTemplate.config = config;

    setDraftTemplate(newTemplate);
  }

  function updateTemplateDraft(partialTemplate: Partial<Template>) {
    if (!workingTemplate) {
      throw new Error('No template loaded');
    }

    const newTemplate = { ...workingTemplate, ...partialTemplate };
    setDraftTemplate(newTemplate);
  }

  const { trigger: deleteTemplate, isMutating: isDeletingTemplate } = useSWRMutation('/api/delete-template', () => {
    if (templateId) {
      return handleErrors(deleteTemplateApi)(templateId);
    }
  });

  async function saveTemplate(templateArg?: Template) {
    const templateToSave = templateArg || draftTemplate;

    if (!templateToSave) {
      return;
    }

    await updateTemplateTrigger(templateToSave as any);
    mutate(templateToSave);
    setDraftTemplate(undefined);
  }

  const referencedBoardIds = useMemo(() => {
    if (!workingTemplate) {
      return [];
    }

    return getReferencedBoardIds(workingTemplate.config);
  }, [workingTemplate]);

  return {
    template: workingTemplate,
    config: workingTemplate?.config,
    hasDraft: !!draftTemplate,
    referencedBoardIds,
    updateTemplateDraft,
    updateTemplateDraftConfig,
    saveTemplate,
    deleteTemplate,
    error,
    isUpdatingTemplate,
    isLoading,
    isDeletingTemplate,
  };
}
