import React from 'react';
import 'monday-ui-react-core/dist/main.css';
import { Loader } from 'monday-ui-react-core';

function Loading() {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 'inherit',
      }}
    >
      <Loader size={Loader.sizes?.MEDIUM as any} color={Loader.colors?.DARK as any} />
    </div>
  );
}

export default Loading;
