import * as _ from 'lodash';
import * as XLSX from 'xlsx-js-style';
import { handlers as fieldHandlers, handlersByType as fieldHandlersByType } from './field_handlers';
import { Board } from '@gorilla/common/src/lib/monday-api/api';
import { EngineBoardItem } from '@gorilla/common/src/lib/engine/engine';
import S5SCalc from '../external/sjscalc.js';
export { getReferencedBoardIds, getFieldGroups, replaceBoardsFromContext } from './utils';

import {
  FieldWithType,
  LuckysheetCellValue,
  SheetjsCellValue,
  BoardFieldPath,
  BoardField,
  Sheet,
  TemplateConfig,
  OutputFormat,
  BoardColumn,
} from './types';

export interface BoardWrapper {
  board: Board;
  items: EngineBoardItem[];
}

export interface FieldGroupBoardWrapper {
  field_group_id: string;
  board: Board;
  items: EngineBoardItem[];
}

export function getBoardFieldIdByPath(path: BoardFieldPath): string {
  return path
    .map((pathFragment) => {
      if (pathFragment.type === 'sub_board') {
        return `sub_board:${pathFragment.board_id}`;
      } else if (pathFragment.type === 'parent_board') {
        return `parent_board:${pathFragment.board_id}`;
      } else {
        return `field:${pathFragment.board_id}:${pathFragment.field_id}`;
      }
    })
    .join('.');
}

export function isBlankBoardField(boardField: BoardField) {
  if (boardField.type === 'board') {
    const path = boardField.path;

    if (path.length === 1) {
      const pathFragment = path[0];

      return pathFragment!.type === 'field' && pathFragment!.field_id === 'blank';
    }
  }

  return false;
}

export function isParentIdField(boardField: BoardField) {
  if (boardField.type === 'board') {
    const path = boardField.path;

    if (path.length === 1) {
      const pathFragment = path[0];
      return pathFragment!.type === 'field' && pathFragment!.field_id === 'item-property_parent-id';
    }
  }

  return false;
}

export function isFormulaField(boardField: BoardField) {
  return boardField.type === 'board' && boardField.board_field_type === 'formula';
}

export function isCustomFormulaBoardField(boardField: BoardField) {
  if (boardField.type === 'board') {
    const path = boardField.path;

    if (path.length === 1) {
      const pathFragment = path[0];

      return pathFragment!.type === 'field' && pathFragment!.field_id === 'custom-formula';
    }
  }

  return false;
}

export function getBoardFieldId(column: BoardColumn): string {
  return getBoardFieldIdByPath(column.path);
}

function getBoardFieldLabel(path: BoardFieldPath, board: Board): string {
  const fieldsWithType = getBoardFieldsWithType(board);
  const lastPathFragment = path[path.length - 1];

  if (!lastPathFragment || lastPathFragment.type !== 'field') {
    return 'unsupported';
  }

  const field = fieldsWithType.find(({ id }) => id === lastPathFragment.field_id);

  if (!field) {
    return 'unsupported';
  }

  return field.label;
}

function getBoardFieldType(path: BoardFieldPath, board: Board) {
  const lastPathFragment = path[path.length - 1];

  if (!lastPathFragment || lastPathFragment.type !== 'field' || !board) {
    return null;
  }

  const column = board.columns.find(({ id }) => id === lastPathFragment.field_id);

  if (!column) {
    return null;
  }

  return column.type;
}

function getBoardFieldsWithType(board: Board): FieldWithType[] {
  const fields: FieldWithType[] = [];

  for (const fieldHandler of fieldHandlers) {
    const extractedFields = fieldHandler.extractFieldsFromBoard(board);

    for (const extractedField of extractedFields) {
      fields.push({
        ...extractedField,
        type: fieldHandler.type,
      });
    }
  }

  const fieldsById = _.keyBy(fields, 'id');
  const columnFields: FieldWithType[] = [];
  const otherFields: FieldWithType[] = [];

  for (const column of board.columns) {
    const field = fieldsById[column.id];

    if (field) {
      columnFields.push(field);
      delete fieldsById[column.id];
    }
  }

  for (const fieldId of ['blank', 'custom-formula', 'item-property_id', 'item-property_name', 'board-property_name']) {
    const field = fieldsById[fieldId];

    if (field) {
      otherFields.push(field);
      delete fieldsById[fieldId];
    }
  }

  return [...otherFields, ...columnFields, ...Object.values(fieldsById)];
}

// create a flat list of paths to all fields in a board
function getBoardFieldPaths(board: Board): BoardFieldPath[] {
  let paths: BoardFieldPath[] = [];
  const fieldsWithType = getBoardFieldsWithType(board);

  for (const fieldWithTypes of fieldsWithType) {
    const path: BoardFieldPath = [{ type: 'field', board_id: board.id, field_id: fieldWithTypes.id }];
    paths.push(path);
  }

  return paths;
}

export function getBoardFields(board: Board): BoardField[] {
  const boardPaths = getBoardFieldPaths(board);

  let filteredPaths = boardPaths.filter((path) => {
    if (path.length === 1) {
      return true;
    }

    const lastPathFragment = path[path.length - 1];

    if (!lastPathFragment) {
      return false;
    }

    if (lastPathFragment.type === 'field' && (lastPathFragment.field_id === 'blank' || lastPathFragment.field_id === 'custom-formula')) {
      // only show blank and custom-formula fields in the first hierachy level
      return false;
    }

    return true;
  });

  const boardFields = filteredPaths.map((path) => {
    return {
      id: getBoardFieldIdByPath(path),
      label: getBoardFieldLabel(path, board),
      board_field_type: getBoardFieldType(path, board),
      path,
      type: 'board',
    };
  }) as BoardField[];

  return boardFields;
}

type FieldGroupDetails = {
  field_group_id: string;
  board: Board;
  items: EngineBoardItem[];
  itemsById: Record<string, EngineBoardItem>;
  fieldsById: Record<string, FieldWithType>;
};

interface LuckysheetCell {
  c: number;
  r: number;
  v: any;
}

// TODO: throw error if field could not be resolved

interface BoardItemFragment {
  board_id: number;
  item_id: number;
  field_id: string;
}

// TODO: refactor this function... we won't support paths anymore
function resolveBoardPath(itemId: number, boardFieldPath: BoardFieldPath, fieldGroupDetails: FieldGroupDetails): BoardItemFragment {
  const lastBoardFieldPath = boardFieldPath[boardFieldPath.length - 1];

  if (lastBoardFieldPath && lastBoardFieldPath.type === 'field') {
    const board = fieldGroupDetails.board;

    if (!(itemId in fieldGroupDetails.itemsById)) {
      throw new Error(`item with id "${itemId}" not found in board with id "${lastBoardFieldPath.board_id}"`);
    }

    const item = fieldGroupDetails.itemsById[itemId];
    const field = fieldGroupDetails.fieldsById[lastBoardFieldPath.field_id];

    if (!field) {
      throw new Error(`field with id "${lastBoardFieldPath.field_id}" not found in board with id "${lastBoardFieldPath.board_id}"`);
    }

    return { board_id: board.id, item_id: item!.id, field_id: field.id };
  }

  throw new Error(`unknown path fragment type`);
}

function getCellValue(
  outputFormat: OutputFormat,
  itemId: number,
  boardFieldPath: BoardFieldPath,
  fieldGroupDetails: FieldGroupDetails,
  config: any,
) {
  const boardItemFragment = resolveBoardPath(itemId, boardFieldPath, fieldGroupDetails);
  const item = fieldGroupDetails.itemsById[boardItemFragment.item_id];

  if (!item) {
    throw new Error(`item with id "${boardItemFragment.item_id}" not found in board with id "${boardItemFragment.board_id}"`);
  }

  const field = fieldGroupDetails.fieldsById[boardItemFragment.field_id];

  if (!field) {
    throw new Error(`field with id "${boardItemFragment.field_id}" not found in board with id "${boardItemFragment.board_id}"`);
  }

  const fieldHandler = fieldHandlersByType[field.type];

  if (!fieldHandler) {
    throw new Error(`no field handler for field type "${field.type}"`);
  }

  return fieldHandler.exportTo(outputFormat, field.id, item, fieldGroupDetails.board, config ?? fieldHandler.defaultConfig);
}

export function chatatABC(n) {
  let orda = 'a'.charCodeAt(0);
  let ordz = 'z'.charCodeAt(0);
  let len = ordz - orda + 1;
  let s = '';

  while (n >= 0) {
    s = String.fromCharCode((n % len) + orda) + s;
    n = Math.floor(n / len) - 1;
  }

  return s.toUpperCase();
}

export function getSheetJsSheet(sheet: Sheet, fieldGroupDetailsById: Record<string, FieldGroupDetails>, withMetadata: boolean) {
  const targetSheet = {};

  let rowIdx = 0;
  let colIdx = 0;
  let largestColIdx = 0;

  function addCell(row, col, value) {
    if (col > largestColIdx) {
      largestColIdx = col;
    }

    if (_.get(value, 'v') === null || _.get(value, 'v') === undefined) {
      return;
    }

    const key = `${chatatABC(col)}${row + 1}`;
    targetSheet[key] = value;
  }

  for (const fieldGroup of sheet.field_groups) {
    if (fieldGroup.disable) {
      continue;
    }

    const fieldGroupDetails = fieldGroupDetailsById[fieldGroup.id];

    if (!fieldGroupDetails) {
      throw new Error(`invalid field group: ${fieldGroup.id}`);
    }

    if (fieldGroup.show_headlines) {
      colIdx = 0;

      const fields = getBoardFields(fieldGroupDetails.board);
      const fieldsById = _.keyBy(fields, 'id');

      for (const column of fieldGroup.columns) {
        const fieldId = getBoardFieldId(column);
        const field = fieldsById[fieldId];
        let value: SheetjsCellValue | null = null;

        // TODO: make bold?
        if (field) {
          value = {
            t: 's',
            v: field.label,
            s: {
              font: {
                bold: true,
              },
            },
          };
        } else {
          value = {
            t: 's',
            v: null,
          };
        }

        addCell(rowIdx, colIdx, value);

        colIdx++;
      }

      rowIdx++;
    }

    for (const item of fieldGroupDetails.items) {
      const row: any[] = [];

      for (const column of fieldGroup.columns) {
        let value;

        try {
          value = getCellValue('sheetjs', item.id, column.path, fieldGroupDetails, column.config);
          //console.log('getCellValue', column.path, value);
        } catch (err) {
          //console.log('failed to get cell value', err);
          value = {
            t: 'e',
            v: 0x0f, // #VALUE!
          };
        }

        row.push(value);
      }

      // TODO: this logic should be not depended on the output format (luckysheet in this case)
      const rowObj = row.reduce((acc, value, idx) => {
        if (value) {
          acc[idx] = value.v;
        } else {
          acc[idx] = null;
        }
        return acc;
      }, {});

      row.forEach((value, colIdx) => {
        addCell(rowIdx, colIdx, value);
      });

      if (withMetadata) {
        targetSheet[`!row_${rowIdx}_item`] = item;

        if (!_.isNumber(targetSheet[`!field_group_${fieldGroup.id}_start_row_idx`])) {
          targetSheet[`!field_group_${fieldGroup.id}_start_row_idx`] = rowIdx;
        }
      }

      rowIdx++;
    }

    if (withMetadata && _.isNumber(targetSheet[`!field_group_${fieldGroup.id}_start_row_idx`])) {
      targetSheet[`!field_group_${fieldGroup.id}_end_row_idx`] = rowIdx - 1;
    }
  }

  targetSheet['!last_row'] = rowIdx;
  targetSheet['!last_col'] = largestColIdx;
  targetSheet['!ref'] = `A1:${chatatABC(largestColIdx)}${Math.max(rowIdx, 1)}`;
  targetSheet['!sheetId'] = sheet.id;
  targetSheet['!sheetName'] = sheet.name;

  return targetSheet;
}

function fieldGroupBoardWrapperToBoardDetails(fieldGroupBoardWrapper: FieldGroupBoardWrapper): FieldGroupDetails {
  if (!fieldGroupBoardWrapper.board || !fieldGroupBoardWrapper.items) {
    throw new Error('board or items not available');
  }

  const itemsById = _.keyBy(fieldGroupBoardWrapper.items, 'id');
  const fieldsWithType = getBoardFieldsWithType(fieldGroupBoardWrapper.board);

  return {
    field_group_id: fieldGroupBoardWrapper.field_group_id,
    board: fieldGroupBoardWrapper.board,
    items: fieldGroupBoardWrapper.items,
    fieldsById: _.keyBy(fieldsWithType, 'id'),
    itemsById,
  };
}

function fontTypeToIdx(fontType: string) {
  return ['Times New Roman', 'Arial', 'Tahoma', 'Verdana'].indexOf(fontType);
}

function horizontalAlignmentToIdx(alignment: string) {
  return ['center', 'left', 'right'].indexOf(alignment);
}

function sheetjsCellStylesToLuckysheetCellStyles(sheetjsCellStyles: any) {
  const luckysheetCellStyles: any = {};

  if (_.get(sheetjsCellStyles, 'fill.fgColor.rgb')) {
    luckysheetCellStyles.bg = `#${sheetjsCellStyles.fill.fgColor.rgb}`;
  }

  if (_.get(sheetjsCellStyles, 'font.sz')) {
    luckysheetCellStyles.fs = _.get(sheetjsCellStyles, 'cellStyles.font.sz');
  }

  if (_.get(sheetjsCellStyles, 'font.name')) {
    const idx = fontTypeToIdx(_.get(sheetjsCellStyles, 'font.name'));

    if (idx > -1) {
      luckysheetCellStyles.ff = idx;
    }
  }

  if (_.get(sheetjsCellStyles, 'font.color.rgb')) {
    luckysheetCellStyles.fc = `#${sheetjsCellStyles.font.color.rgb}`;
  }

  if (_.get(sheetjsCellStyles, 'font.bold')) {
    luckysheetCellStyles.bl = 1;
  }

  if (_.get(sheetjsCellStyles, 'font.italic')) {
    luckysheetCellStyles.it = 1;
  }

  if (_.get(sheetjsCellStyles, 'font.strike')) {
    luckysheetCellStyles.cl = 1;
  }

  if (_.get(sheetjsCellStyles, 'font.underline')) {
    luckysheetCellStyles.un = 1;
  }

  if (_.get(sheetjsCellStyles, 'alignment.horizontal')) {
    const idx = horizontalAlignmentToIdx(_.get(sheetjsCellStyles, 'alignment.horizontal'));

    if (idx > -1) {
      luckysheetCellStyles.ht = idx;
    }
  }

  return luckysheetCellStyles;
}

function sheetjsCellValueToLuckysheetCellValue(sheetjsValue: SheetjsCellValue): LuckysheetCellValue {
  const styles = sheetjsCellStylesToLuckysheetCellStyles(sheetjsValue.s || {});
  const extraProps = {
    ct: {} as Record<string, any>,
  };

  if (sheetjsValue.t === 'e') {
    return {
      ...styles,
      ...extraProps,
      v: `ERROR: failed to process value (code: ${sheetjsValue.v})`,
    };
  }

  if (sheetjsValue.t === 'b') {
    return {
      ...styles,
      ...extraProps,
      v: sheetjsValue.v ? 1 : 0,
    };
  }

  if (sheetjsValue.z) {
    extraProps.ct.fa = sheetjsValue.z;
    extraProps.ct.t = 'n';
  } else if (sheetjsValue.t === 'd') {
    extraProps.ct.t = 'd';
  } else if (sheetjsValue.t === 's') {
    extraProps.ct.t = 's';
    extraProps.ct.fa = '@'; // makes sure that the value is treated as a plain text
  }

  return {
    ...styles,
    ...extraProps,
    v: sheetjsValue.v,
  };
}

function sheetjsWorkbookToLuckysheet(workbook: XLSX.WorkBook) {
  const sheets: any[] = [];

  workbook.SheetNames.forEach((sheetName) => {
    const sheet = workbook.Sheets[sheetName];
    const range = XLSX.utils.decode_range(sheet['!ref']!);
    const celldata: any[] = [];

    for (let row = range.s.r; row <= range.e.r; row++) {
      for (let col = range.s.c; col <= range.e.c; col++) {
        const cell = sheet[XLSX.utils.encode_cell({ c: col, r: row })];

        if (cell) {
          celldata.push({
            r: row,
            c: col,
            v: sheetjsCellValueToLuckysheetCellValue(cell),
          });
        }
      }
    }

    sheets.push({
      name: sheetName,
      color: '',
      index: sheets.length,
      status: sheets.length === 0 ? 1 : 0,
      order: sheets.length,
      celldata: celldata,
      config: {},
    });
  });

  return sheets;
}

function calculateSheetjsCustomFormulas(workbook: ReturnType<typeof exportToSheetJs>) {
  workbook.SheetNames.forEach((sheetName, sheetIdx) => {
    const sheet = workbook.Sheets[sheetName]!;

    const lastRow = sheet['!last_row'];
    const lastCol = sheet['!last_col'];

    for (let row = 0; row <= lastRow; row++) {
      for (let col = 0; col <= lastCol; col++) {
        const cell = sheet[`${chatatABC(col)}${row + 1}`];

        if (cell?.__is_custom_formula) {
          const formula = (cell.v || '').replaceAll('$$', row + 1);

          if (formula[0] === '=') {
            cell.f = formula.substr(1);
          }
        }
      }
    }
  });

  S5SCalc.recalculate(workbook);

  workbook.SheetNames.forEach((sheetName, sheetIdx) => {
    const sheet = workbook.Sheets[sheetName];

    const lastRow = sheet['!last_row'];
    const lastCol = sheet['!last_col'];

    for (let row = 0; row <= lastRow; row++) {
      for (let col = 0; col <= lastCol; col++) {
        const cell = sheet[`${chatatABC(col)}${row + 1}`];

        if (cell?.__is_custom_formula && cell.f) {
          // remove formula, only keep value
          delete cell.f;
        }
      }
    }
  });

  return workbook;
}

function exportToSheetJs(config: TemplateConfig, fieldGroupBoardWrappers: FieldGroupBoardWrapper[], withMetadata: boolean) {
  const fieldGroupsWithDetail = _.keyBy(
    fieldGroupBoardWrappers.flatMap((fieldGroupBoardWrapper) => {
      try {
        return [fieldGroupBoardWrapperToBoardDetails(fieldGroupBoardWrapper)];
      } catch (err) {
        // TODO: handle error....
        console.log('err', err);
        return [];
      }
    }),
    ({ field_group_id }) => field_group_id,
  );

  const workbook = XLSX.utils.book_new();

  config.sheets.forEach((sheet, idx) => {
    const sheetjsSheet = getSheetJsSheet(sheet, fieldGroupsWithDetail, withMetadata);

    workbook.SheetNames.push(sheet.name);
    workbook.Sheets[sheet.name] = sheetjsSheet;
  });

  calculateSheetjsCustomFormulas(workbook);

  return workbook;
}

export function exportTo(
  outputFormat: OutputFormat,
  config: TemplateConfig,
  fieldGroupBoardWrappers: FieldGroupBoardWrapper[],
  withMetadata: boolean = false,
) {
  switch (outputFormat) {
    case 'luckysheet':
      const workbook = exportToSheetJs(config, fieldGroupBoardWrappers, true);
      return sheetjsWorkbookToLuckysheet(workbook);
    case 'sheetjs':
      return exportToSheetJs(config, fieldGroupBoardWrappers, withMetadata);
    default:
      throw new Error(`output format "${outputFormat}" not supported`);
  }
}
