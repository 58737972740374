import React from 'react';
import Dropdown from 'monday-ui-react-core/dist/Dropdown';
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
import { Avatar, Tooltip } from 'monday-ui-react-core';

import PersonIcon from 'monday-ui-react-core/dist/icons/Person';
import StatusIcon from 'monday-ui-react-core/dist/icons/Status';
import TextCopyIcon from 'monday-ui-react-core/dist/icons/TextCopy';
import CalendarIcon from 'monday-ui-react-core/dist/icons/Calendar';
import CounterIcon from 'monday-ui-react-core/dist/icons/Counter';
import TimelineIcon from 'monday-ui-react-core/dist/icons/Timeline';
import TagsIcon from 'monday-ui-react-core/dist/icons/Tags';
import MirrorIcon from 'monday-ui-react-core/dist/icons/Mirror';
import FavoriteIcon from 'monday-ui-react-core/dist/icons/Favorite';
import DropdownIcon from 'monday-ui-react-core/dist/icons/Dropdown';
import GlobeIcon from 'monday-ui-react-core/dist/icons/Globe';
import CheckboxIcon from 'monday-ui-react-core/dist/icons/Checkbox';
import LinkIcon from 'monday-ui-react-core/dist/icons/Link';
import NumbersIcon from 'monday-ui-react-core/dist/icons/Numbers';
import CountryIcon from 'monday-ui-react-core/dist/icons/Country';
import LocationIcon from 'monday-ui-react-core/dist/icons/Location';
import GanttIcon from 'monday-ui-react-core/dist/icons/Gantt';
import LongTextIcon from 'monday-ui-react-core/dist/icons/LongText';
import MobileIcon from 'monday-ui-react-core/dist/icons/Mobile';
import BoltIcon from 'monday-ui-react-core/dist/icons/Bolt';
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';
import HighlightColorBucketIcon from 'monday-ui-react-core/dist/icons/HighlightColorBucket';
import RecurringIcon from 'monday-ui-react-core/dist/icons/Recurring';
import VersioningIcon from 'monday-ui-react-core/dist/icons/Versioning';
import FileIcon from 'monday-ui-react-core/dist/icons/File';
import MentionIcon from 'monday-ui-react-core/dist/icons/Mention';
import TimeIcon from 'monday-ui-react-core/dist/icons/Time';
import { BoardField } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';

const BOARD_COLUMNS_META = {
  people: {
    icon: PersonIcon,
    tooltip: 'People column',
  },
  status: {
    icon: StatusIcon,
    tooltip: 'Status column',
  },
  text: {
    icon: TextCopyIcon,
    tooltip: 'Text column',
  },
  date: {
    icon: CalendarIcon,
    tooltip: 'Date column',
  },
  numbers: {
    icon: CounterIcon,
    tooltip: 'Numbers column',
  },
  timeline: {
    icon: TimelineIcon,
    tooltip: 'Timeline column',
  },
  'timeline-numeric': {
    icon: TimelineIcon,
    tooltip: 'Timeline + Numeric column',
  },
  tags: {
    icon: TagsIcon,
    tooltip: 'Tags column',
  },
  'connect-boards': {
    icon: MirrorIcon,
    tooltip: 'Connect Boards column',
  },
  mirror: {
    icon: MirrorIcon,
    tooltip: 'Mirror column',
  },
  rating: {
    icon: FavoriteIcon,
    tooltip: 'Rating column',
  },
  dropdown: {
    icon: DropdownIcon,
    tooltip: 'Dropdown column',
  },
  'world-clock': {
    icon: GlobeIcon,
    tooltip: 'World Clock column',
  },
  checkbox: {
    icon: CheckboxIcon,
    tooltip: 'Checkbox column',
  },
  link: {
    icon: LinkIcon,
    tooltip: 'Link column',
  },
  'auto-number': {
    icon: NumbersIcon,
    tooltip: 'Auto Number column',
  },
  country: {
    icon: CountryIcon,
    tooltip: 'Country column',
  },
  location: {
    icon: LocationIcon,
    tooltip: 'Location column',
  },
  week: {
    icon: CalendarIcon,
    tooltip: 'Week column',
  },
  'progress-tracking': {
    icon: GanttIcon,
    tooltip: 'Progress Tracking column',
  },
  'long-text': {
    icon: LongTextIcon,
    tooltip: 'Long Text column',
  },
  phone: {
    icon: MobileIcon,
    tooltip: 'Phone column',
  },
  'item-id': {
    icon: BoltIcon,
    tooltip: 'Item ID column',
  },
  formula: {
    icon: FormulaIcon,
    tooltip: 'Formula column',
  },
  'color-picker': {
    icon: HighlightColorBucketIcon,
    tooltip: 'Color Picker column',
  },
  'last-updated': {
    icon: RecurringIcon,
    tooltip: 'Last Updated column',
  },
  'time-tracking': {
    icon: VersioningIcon,
    tooltip: 'Time Tracking column',
  },
  file: {
    icon: FileIcon,
    tooltip: 'File column',
  },
  email: {
    icon: MentionIcon,
    tooltip: 'Email column',
  },
  hour: {
    icon: TimeIcon,
    tooltip: 'Hour column',
  },
};

type FieldPickerProps = {
  fields: BoardField[];

  value?: string;

  onChange: (field: BoardField) => void;
};

export function FieldPicker({ fields, value, onChange }: FieldPickerProps) {
  const options = fields.map((field) => {
    const columnMeta = BOARD_COLUMNS_META[field.board_field_type as string];
    const leftIcon = columnMeta?.icon || TextCopyIcon;

    if ('path' in field) {
      const lastPathSegment = field.path[field.path.length - 1];

      if (lastPathSegment.type === 'field') {
        if (lastPathSegment.field_id === 'custom-formula') {
          //leftIcon = FormulaIcon;
        }

        if (lastPathSegment.field_id === 'blank') {
          //leftIcon = PageIcon;
        }
      }
    }

    return {
      //label: field.label,
      label: (
        <>
          <div style={{ marginRight: '5px' }}>
            <Tooltip content={columnMeta ? columnMeta.tooltip : ''} position={Tooltip.positions.RIGHT}>
              <Avatar
                withoutBorder
                square
                size={Avatar.sizes?.SMALL}
                type={Avatar.types?.ICON}
                icon={leftIcon}
                customBackgroundColor="#323338"
              />
            </Tooltip>
          </div>
          <div style={{ flex: 1 }}>{field.label}</div>
          {field.board_field_type === 'formula' ? (
            <div>
              <Tooltip
                content="Formula columns aren't fully supported yet, due to monday.com's technical limitations."
                position={Tooltip.positions.LEFT}
              >
                <Avatar
                  withoutBorder
                  square
                  size={Avatar.sizes?.SMALL}
                  type={Avatar.types?.ICON}
                  icon={AlertIcon}
                  customBackgroundColor="#FFCC00"
                  className="avatar-type avatar-type--warning"
                />
              </Tooltip>
            </div>
          ) : null}
        </>
      ),
      value: field.id,
      //leftIcon
    };
  });

  let selectedOption = options.find((option) => option.value === value);

  if (!selectedOption) {
    const inaccessibleFieldOption = {
      label: (
        <>
          <div style={{ marginRight: '5px' }}>
            <Avatar
              withoutBorder
              square
              size={Avatar.sizes?.SMALL}
              type={Avatar.types?.ICON}
              icon={AlertIcon}
              customBackgroundColor="#FFCC00"
              className="avatar-type avatar-type--warning"
            />
          </div>
          <div style={{ flex: 1 }}>Warning: Field was deleted or you lack permissions</div>
        </>
      ),
      value: '___inaccessible_field___',
      //leftIcon: AlertIcon,
    };

    options.unshift(inaccessibleFieldOption);

    selectedOption = inaccessibleFieldOption;
  }

  return (
    <>
      <div
        style={{
          position: 'relative', // z-index fix for dropdowns
          width: '100%',
        }}
      >
        <Dropdown
          onOptionSelect={(ev: any) => {
            const fieldId = ev.value;
            const field = fields.find(({ id }) => id === fieldId);

            if (field) {
              onChange(field);
            }
          }}
          value={selectedOption}
          options={options}
          placeholder="Select field"
          insideOverflowContainer
          clearable={false}
          className="board-column-name"
          dropdownMenuWrapperClassName="dropdown_content_indexed"
        />
      </div>
    </>
  );
}
