import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'board_relation',

  extractFieldsFromBoard: (board) => {
    return board.columns.flatMap((column) => {
      if (column.type === 'board_relation') {
        return [
          {
            id: column.id,
            label: column.title,
          },
        ];
      }

      return [];
    });
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    const columnValue = boardItem.values.find(({ column_id }) => fieldId === column_id);

    if (!columnValue || columnValue.column_type !== 'board_relation' || columnValue.value_type === 'error') {
      return null;
    }

    if (outputFormat === 'sheetjs') {
      return {
        ...convertConfigToSheetjs(config),
        v: columnValue.value.text,
        t: 's',
      };
    }
  },
};

export default handler;
