import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'task-name',

  extractFieldsFromBoard: (board) => {
    return [
      {
        id: 'board-property_name',
        label: 'Board Name',
      },
    ];
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    if (outputFormat === 'sheetjs') {
      return {
        ...convertConfigToSheetjs(config),
        v: board.name,
        t: 's',
      };
    }
  },
};

export default handler;
