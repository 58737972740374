import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'item-id',

  extractFieldsFromBoard: (board) => {
    return [
      {
        id: 'item-property_id',
        label: 'Item ID',
      },
    ];
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    if (outputFormat === 'sheetjs') {
      return {
        ...convertConfigToSheetjs(config),
        v: boardItem.id,
        t: 'n',
      };
    }
  },
};

export default handler;
