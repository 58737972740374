import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { DependencyColumnType, DependencyColumnValue as DependencyColumnValueGraphql } from '../../monday-api/api';

export type DependencyColumnValue = {
  text: string | null;
  linked_pulse_ids: number[] | null;
};

const handler: ColumnHandler<DependencyColumnType, DependencyColumnValue, unknown, DependencyColumnValueGraphql> = {
  type: 'dependency',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['item_id'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['item_id'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.display_value);
    const value = parseJson(columnValue.value);
    let linkedPulseIds: null | number[] = null;

    if (Array.isArray(_.get(value, 'linkedPulseIds'))) {
      linkedPulseIds = ((_.get(value, 'linkedPulseIds') || []) as Array<{ linkedPulseId: number }>)
        .map(({ linkedPulseId }) => parseNumber(linkedPulseId))
        .filter((linkedPulseId) => linkedPulseId !== null) as number[];
    }

    return {
      text,
      linked_pulse_ids: linkedPulseIds,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Count') {
      return engineValue.linked_pulse_ids?.length || 0;
    } else {
      return engineValue.text === null ? '' : engineValue.text;
    }
  },
};

export default handler;
