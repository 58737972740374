import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';

function uppercaseFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'item-name',

  extractFieldsFromBoard: (board) => {
    return [
      {
        id: 'item-property_name',
        label: board.item_terminology ? uppercaseFirstLetter(board.item_terminology) : 'Item Name',
      },
    ];
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    if (outputFormat === 'sheetjs') {
      return {
        ...convertConfigToSheetjs(config),
        v: boardItem.name,
        t: 's',
      };
    }
  },
};

export default handler;
