import mondaySdk from 'monday-sdk-js';

import { createMondaySdkRequester, createMondayClient } from '@gorilla/common/src/lib/monday-api/common';

export const monday = mondaySdk();

monday.setApiVersion('2024-01');

export async function getSessionToken() {
  return ((await monday.get('sessionToken')) as any).data;
}

export const mondayClient = createMondayClient(createMondaySdkRequester(monday));
