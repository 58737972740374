import React, { useEffect } from 'react';
import 'monday-ui-react-core/dist/main.css';
import { useParams } from 'react-router-dom';
import _ from 'lodash';
import Luckysheet from '../../components/Luckysheet';
import { useSpreadsheetLoader } from '../../hooks/use-spreadsheet-loader';
import LoadingSpreadsheet from '../../components/LoadingSpreadsheet';
import ErrorMessage from '../../components/ErrorMessage';
import { createSignal } from '../../services/signals';
import './RenderTemplatePage.css';

function RenderTemplatePage() {
  const params = useParams();
  const { loadingMessage, data, error } = useSpreadsheetLoader(
    params.templateId !== undefined ? parseInt(params.templateId, 10) : undefined,
    'luckysheet'
  );

  useEffect(() => {
    createSignal('spreadsheet_preview');
  }, []);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <div className="Spreadsheet">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          width: '100%',
          height: '100%',
          minHeight: 'inherit',
        }}
      >
        {data ? (
          <Luckysheet templateId={params.templateId} initialData={data} preview={true} title="New Spreadsheet" />
        ) : (
          <LoadingSpreadsheet message={<>{loadingMessage}</>} />
        )}
      </div>
    </div>
  );
}

export default RenderTemplatePage;
