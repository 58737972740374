import * as _ from 'lodash';
import { createDeferred } from './utils';

export interface QueueItem {
  status: 'pending' | 'resolved' | 'rejected';
  error: null | Error;
  promise: Promise<any>;
  fn: () => Promise<any>;
}

export function createExecutionQueue(onProgress?: Function, abortSignal?: AbortSignal) {
  const queue = new Map<string | number, QueueItem>();
  let isRunning = false;

  function add(id: number | string, fn: () => Promise<any>) {
    if (queue.has(id)) {
      return queue.get(id)!.promise;
    }

    const deferred = createDeferred();

    const item: QueueItem = {
      status: 'pending',
      promise: deferred.promise,
      error: null,
      fn: async () => {
        try {
          const res = await fn();
          deferred.resolve(res);
        } catch (err) {
          deferred.reject(err);
          throw err;
        }
      },
    };

    queue.set(id, item);

    if (!isRunning) {
      isRunning = true;
      run();
    }

    return deferred.promise;
  }

  function getPendingItemEntries() {
    return [...queue.entries()].filter(([id, { status }]) => status === 'pending');
  }

  function getQueueSize() {
    return getPendingItemEntries().length;
  }

  function getItemStatus(id: string | number) {
    return queue.get(id)?.status;
  }

  function getQueue() {
    return queue;
  }

  async function run() {
    if (onProgress) {
      onProgress();
    }

    while (!abortSignal || !abortSignal.aborted) {
      const pendingItemEntries = getPendingItemEntries();

      if (!pendingItemEntries.length) {
        break;
      }

      const nextItemEntry = pendingItemEntries.shift()!;

      try {
        await nextItemEntry[1].fn();

        queue.set(nextItemEntry[0], {
          ...nextItemEntry[1],
          status: 'resolved',
        });
      } catch (err) {
        queue.set(nextItemEntry[0], {
          ...nextItemEntry[1],
          status: 'rejected',
          error: err,
        });
      }

      if (onProgress) {
        onProgress();
      }
    }

    isRunning = false;
  }

  return {
    add,
    getQueueSize,
    getQueue,
    isRunning: () => isRunning,
    getItemStatus,
  };
}
