import './sentry';
import './init';
import * as _ from 'lodash';
import * as Sentry from '@sentry/react';
import { SnackbarProvider } from 'notistack';
import { SWRConfig } from 'swr';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider, Outlet } from 'react-router-dom';
import HomePage from './pages/home/HomePage';
import EditTemplatePage from './pages/edit-template/EditTemplatePage';
import RenderTemplatePage from './pages/render-template/RenderTemplatePage';
import RenderSpreadsheetPage from './pages/render-spreadsheet/RenderSpreadsheetPage';
import ErrorMessage from './components/ErrorMessage';
import './index.css';

function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const ErrorBoundaryLayout = () => (
  <Sentry.ErrorBoundary fallback={({ error }) => <ErrorMessage error={error} />}>
    <SWRConfig
      value={{
        onError: (error) => {
          const extra: Record<string, any> = {};

          if (_.get(error, 'do_not_track')) {
            return;
          }

          if (_.get(error, 'info')) {
            extra.info = error.info;
          }

          if (_.get(error, 'status')) {
            extra.status = error.status;
          }

          Sentry.captureException(error, { extra });
        },
      }}
    >
      <Outlet />
    </SWRConfig>
  </Sentry.ErrorBoundary>
);

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    element: <ErrorBoundaryLayout />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/template/:templateId/edit',
        element: <EditTemplatePage />,
      },
      {
        path: '/template/:templateId/render',
        element: <RenderTemplatePage />,
      },
      {
        path: '/spreadsheet/:spreadsheetId/render',
        element: <RenderSpreadsheetPage />,
      },
    ],
  },
]);

const rootEl = document.getElementById('root');

if (!rootEl) {
  throw new Error('root element not found');
}

if (getParameterByName('sessionToken')) {
  // only load app if inside monday iframe
  ReactDOM.createRoot(rootEl).render(
    <SnackbarProvider>
      <RouterProvider router={router} />
    </SnackbarProvider>
  );
} else {
  ReactDOM.createRoot(rootEl).render(
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', height: '100vh', textAlign: 'center' }}>
      <div style={{ transform: 'translate3d(0,-30%,0)' }}>
        <div style={{ margin: '0 auto 20px auto', width: '70px' }}>
          <img src="/app_icon.png" width="50" height="50" alt="" />
        </div>
        <h1>Oops, something went wrong.</h1>
        <p>
          You can't access Spreadsheet Gorilla directly. If you're <br />
          on monday.com, reload your browser to try again.
        </p>
      </div>
    </div>
  );
}
