import React from 'react';
import * as _ from 'lodash';
import { Avatar, Heading, Box, Button, Flex, Divider } from 'monday-ui-react-core';
import CheckIcon from 'monday-ui-react-core/dist/icons/Check';
import BoardIcon from 'monday-ui-react-core/dist/icons/Board';
import Locked from 'monday-ui-react-core/dist/icons/Locked';
import LayoutIcon from 'monday-ui-react-core/dist/icons/Layout';
import DuplicateIcon from 'monday-ui-react-core/dist/icons/Duplicate';
import SwitchIcon from 'monday-ui-react-core/dist/icons/Switch';
import CloseSmallIcon from 'monday-ui-react-core/dist/icons/CloseSmall';
import { useNavigate } from 'react-router-dom';
import CreatedBy from '../shared/components/CreatedBy';

type TemplatesListProps = {
  mondayContext: { boardId: number };
  templates: any[];
  onDeleteClick: (template: any) => void;
  onDuplicateClick: (template: any) => void;
};

export function TemplatesList({ templates, onDeleteClick, onDuplicateClick, mondayContext }: TemplatesListProps) {
  const navigate = useNavigate();

  return (
    <Box>
      {_.orderBy(templates, 'name').map((template, i) => (
        <div key={template.id}>
          <Box>
            <Box padding={Box.paddings?.LARGE as any} paddingEnd={Box.paddingEnds?.XL as any} className="list--element">
              <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                <Avatar
                  type={Avatar.types?.ICON as any}
                  size={Avatar.sizes?.MEDIUM as any}
                  withoutBorder={true}
                  icon={LayoutIcon}
                  customBackgroundColor="#0073ea"
                  square
                />

                <Flex gap={Flex.gaps?.LARGE} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                  <Box paddingEnd={Box.paddingEnds?.XS as any}>
                    <Flex gap={Flex.gaps?.XS}>
                      <Heading type={Heading.types?.h5 as any} value={template.name} />
                      <>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                            gap: '8px',
                            height: '28px',
                          }}
                        >
                          {(template.boards || []).some((boardId) => boardId === mondayContext.boardId) ? (
                            <>
                              <Avatar
                                withoutBorder
                                size={Avatar.sizes?.SMALL}
                                type={Avatar.types?.ICON}
                                icon={BoardIcon}
                                customBackgroundColor="#00ca72"
                                ariaLabel="This board is used in this template"
                                className="avatar-indicator"
                              />
                            </>
                          ) : null}

                          {template.private ? (
                            <>
                              <Avatar
                                withoutBorder
                                size={Avatar.sizes?.SMALL}
                                type={Avatar.types?.ICON}
                                icon={Locked}
                                customBackgroundColor="#ffb900"
                                ariaLabel="Private template is hidden to others"
                                className="avatar-indicator"
                              />
                            </>
                          ) : null}
                        </div>
                      </>
                    </Flex>
                    <CreatedBy userId={template.creatorId} time={template.createdAt} userAvatar={false} />
                  </Box>
                </Flex>

                <Box marginStart={Box.marginStarts?.AUTO as any}>
                  <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                    <Button
                      size={Button.sizes?.MEDIUM as any}
                      kind={Button.kinds?.TERTIARY as any}
                      onClick={() => {
                        navigate(`/template/${template.id}/render`);
                      }}
                    >
                      Preview
                    </Button>
                    <Flex gap={Flex.gaps?.MEDIUM} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                      <Button
                        leftIcon={DuplicateIcon}
                        size={Button.sizes?.MEDIUM as any}
                        kind={Button.kinds?.SECONDARY as any}
                        onClick={() => {
                          onDuplicateClick(template);
                        }}
                      >
                        Duplicate
                      </Button>
                      <Button
                        size={Button.sizes?.MEDIUM as any}
                        kind={Button.kinds?.PRIMARY as any}
                        onClick={() => {
                          navigate(`/template/${template.id}/edit`);
                        }}
                      >
                        Edit template
                      </Button>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
          {/* {templates.length > 3 && templates.length === i + 1 ? null : <Divider withoutMargin={true} />} */}
          {templates.length === i + 1 ? null : <Divider withoutMargin={true} />}
        </div>
      ))}
    </Box>
  );
}
