import React, { useState } from 'react';
import * as _ from 'lodash';
import useSWR from 'swr';
import { Modal, ModalHeader, ModalContent, ModalFooter, Button, Box, Flex, Heading, Icon, Divider, Loader } from 'monday-ui-react-core';
import CheckIcon from 'monday-ui-react-core/dist/icons/Check';
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
import Dropdown from 'monday-ui-react-core/dist/Dropdown';
import { TemplateBasic } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { exportTo } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import { getTemplates } from '../services/templates';
import Loading from './Loading';
import { useSpreadsheetLoader } from '../hooks/use-spreadsheet-loader';

interface ImportStepProps {
  template: TemplateBasic;
  mode: string;
  onClose: (data?: any) => void;
}

export function ImportStep({ template, mode, onClose }: ImportStepProps) {
  const { loadingMessage, data, error } = useSpreadsheetLoader(_.get(template, 'id'), 'luckysheet');

  function capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  return (
    <>
      <ModalContent>
        <Box
          border={Box.borders?.DEFAULT as any}
          borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
          rounded={Box.roundeds?.SMALL as any}
        >
          <>
            <Box padding={Box.paddings?.MEDIUM as any}>
              <Flex gap={8}>
                <div>
                  <strong>Type:</strong> {capitalizeFirstLetter(mode)}{' '}
                  {mode === 'overwrite' ? 'worksheets with the same name' : 'worksheets to the spreadsheet'}
                </div>
                <div className="statusChecked">
                  <Icon icon={CheckIcon} iconSize={10} />
                </div>
              </Flex>
            </Box>
            <Divider withoutMargin={true} />
            <Box padding={Box.paddings?.MEDIUM as any}>
              <Flex gap={8}>
                <div>
                  <strong>Template:</strong> {template.name}
                </div>
                <div className="statusChecked">
                  <Icon icon={CheckIcon} iconSize={10} />
                </div>
              </Flex>
            </Box>
            <Divider withoutMargin={true} />
            <Box padding={Box.paddings?.MEDIUM as any}>
              <Flex gap={8}>
                <div>
                  <strong>Items:</strong> {error ? error.message : loadingMessage}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  {error ? (
                    <div className="statusChecked">
                      <Icon icon={AlertIcon} iconSize={10} />
                    </div>
                  ) : (
                    <></>
                  )}

                  {data ? (
                    <div className="statusChecked">
                      <Icon icon={CheckIcon} iconSize={10} />
                    </div>
                  ) : (
                    <Loader size={Loader.sizes?.XS} color={Loader.colors?.PRIMARY} />
                  )}
                </div>
              </Flex>
            </Box>
          </>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!data}
                loading={!data}
                onClick={async () => {
                  onClose(data || undefined);
                }}
              >
                Confirm
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

interface SelectionStepProps {
  templates: TemplateBasic[];
  templateId: number | null;
  mode: string;
  onTemplateIdChange: (newTemplateId: number) => void;
  onModeChange: (newMode: string) => void;
  onClose: () => void;
  onStepChange: (newStep: string) => void;
}

export function SelectionStep({
  templates,
  templateId,
  onTemplateIdChange,
  mode,
  onModeChange,
  onClose,
  onStepChange,
}: SelectionStepProps) {
  const templateOptions: { label: string | React.ReactNode; value: number }[] = _.orderBy(templates, 'name').map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const selectedTemplateOption = templateOptions.find((option) => option.value === templateId);

  const modeOptions = [
    { label: 'Overwrite Sheets', value: 'overwrite' },
    { label: 'Append Sheets', value: 'append' },
  ];

  //const selectedModeOption = modeOptions.find((option) => option.value === mode);

  return (
    <>
      <ModalContent>
        <div>
          <Flex gap={24}>
            <div
              className={mode === 'overwrite' ? 'multiTypeSelected multiType' : 'multiType'}
              onClick={() => {
                onModeChange('overwrite');
              }}
            >
              <div className="checkTypeIcon">
                <Icon icon={CheckIcon} iconSize={16} />
              </div>
              <Heading type={Heading.types?.h5} value="Overwrite worksheets" />
              <div
                style={{
                  fontWeight: 300,
                  color: '#676879',
                }}
              >
                with the same name
              </div>
            </div>
            <div
              className={mode === 'append' ? 'multiTypeSelected multiType' : 'multiType'}
              onClick={() => {
                onModeChange('append');
              }}
            >
              <div className="checkTypeIcon">
                <Icon icon={CheckIcon} iconSize={16} />
              </div>
              <Heading type={Heading.types?.h5} value="Append worksheets" />
              <div
                style={{
                  fontWeight: 300,
                  color: '#676879',
                }}
              >
                to the spreadsheet
              </div>
            </div>
          </Flex>
        </div>

        <Box paddingTop={Box.paddingTops?.MEDIUM as any}>
          <Dropdown
            onOptionSelect={(ev: any) => {
              onTemplateIdChange(ev.value);
            }}
            options={templateOptions}
            placeholder="Select template"
            insideOverflowContainer
            value={selectedTemplateOption}
            clearable={false}
            size={Dropdown.size.LARGE}
          />
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <div style={{ color: '#afb0bd' }}>Choose from the options above and select a template</div>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!templateId}
                onClick={() => {
                  onStepChange('import');
                }}
              >
                Next
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

type ImportTemplateModalProps = {
  onClose: (result?: { data: ReturnType<typeof exportTo>; mode: string }) => void;
  show: boolean;
};

export function ImportTemplateModal({ show, onClose }: ImportTemplateModalProps) {
  const { data: templates, error } = useSWR('/api/get-templates', async () => getTemplates());
  const [mode, setMode] = useState('overwrite');
  const [step, setStep] = useState('selection');
  const [selectedTemplateId, setSelectedTemplateId] = useState(templates && templates.length ? templates[0].id : null);

  let content: React.ReactNode = null;

  if (!templates) {
    content = (
      <>
        <ModalContent>
          <Box
            padding={Box.paddings?.MEDIUM as any}
            border={Box.borders?.DEFAULT as any}
            borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
            rounded={Box.roundeds?.SMALL as any}
          >
            <Box padding={Box.paddings?.XXXL as any}>
              <Loading />
            </Box>
          </Box>
        </ModalContent>
      </>
    );
  } else {
    if (step === 'selection') {
      content = (
        <>
          <SelectionStep
            templates={templates}
            templateId={selectedTemplateId}
            onTemplateIdChange={(newTemplateId) => {
              setSelectedTemplateId(newTemplateId);
            }}
            mode={mode}
            onModeChange={(newMode) => {
              setMode(newMode);
            }}
            onClose={onClose}
            onStepChange={(newStep) => {
              setStep(newStep);
            }}
          />
        </>
      );
    } else if (step === 'import' && selectedTemplateId) {
      content = (
        <ImportStep
          template={templates.find((template) => template.id === selectedTemplateId) as TemplateBasic}
          mode={mode}
          onClose={(data) => {
            if (data) {
              onClose({ data, mode });
            } else {
              onClose();
            }
          }}
        />
      );
    }
  }

  return (
    <Modal show={show} onClose={() => onClose()} width={Modal.width?.DEFAULT as any} alertDialog={false}>
      <ModalHeader title={`Import from template`} hideCloseButton={true} />
      <>{content}</>
    </Modal>
  );
}
