import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseString } from '../converters';
import { FileColumnType } from '../../monday-api/api';

export type FileColumnValue = {
  text: string | null;
  asset_ids: number[] | null;
  // links: string[] | null;
};

const handler: ColumnHandler<FileColumnType, FileColumnValue> = {
  type: 'file',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    let assetIds: number[] | null = null;

    if (Array.isArray(_.get(value, 'files'))) {
      assetIds = ((_.get(value, 'files') || []) as Array<{ assetId: number }>)
        .map(({ assetId }) => parseNumber(assetId))
        .filter((assetId) => assetId !== null) as number[];
    }

    return {
      text,
      asset_ids: assetIds,
    };
  },

  // currently not supported by monday
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
