import React from 'react';
import * as _ from 'lodash';
import { Button, Box, Flex, Modal, ModalHeader, ModalContent, ModalFooter } from 'monday-ui-react-core';
import { SingleBoardPicker } from '../shared/components/SingleBoardPicker';

type BoardPickerModalProps = {
  show: boolean;
  onClose: (boardId?: number) => void;
};

export function BoardPickerModal({ show, onClose }: BoardPickerModalProps) {
  if (!show) {
    return null;
  }

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={500 as any}
      alertDialog={false}
      data-testid="board-picker-modal"
    >
      <ModalHeader title="" hideCloseButton={true} data-testid="board-picker-modal-header" />
      <ModalContent>
        <Box marginTop={Box.marginTops?.SMALL as any}>
          <SingleBoardPicker
            onBoardSelect={(boardId) => {
              onClose(boardId);
            }}
          />
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex gap={12} justify={Flex.justify?.END as any}>
          <Box marginEnd={Box.marginEnds?.AUTO as any}>
            <div style={{ color: '#afb0bd' }}>Search for boards to append to your current template.</div>
          </Box>
          <Button
            kind={Button.kinds?.SECONDARY}
            onClick={() => {
              onClose();
            }}
          >
            Close
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
