import React from 'react';
import 'monday-ui-react-core/dist/main.css';
import { Loader } from 'monday-ui-react-core';

function LoadingSpreadsheet({
  message = (
    <>
      Loading spreadsheet...
      <br />
      This can take up to a few minutes
    </>
  ),
}: {
  message?: React.ReactNode;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        minHeight: 'inherit',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Loader size={Loader.sizes?.MEDIUM as any} color={Loader.colors?.DARK as any} />
        <div style={{ textAlign: 'center', marginTop: 20 }}>{message}</div>
      </div>
    </div>
  );
}

export default LoadingSpreadsheet;
