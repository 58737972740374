import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';
import { formulaExecuter } from '@gorilla/common/src/lib/engine/column_handlers/formula_column_handler';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'date',

  extractFieldsFromBoard: (board) => {
    return board.columns.flatMap((column) => {
      if (column.type === 'date') {
        return [
          {
            id: column.id,
            label: column.title,
          },
        ];
      }

      return [];
    });
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    const columnValue = boardItem.values.find(({ column_id }) => fieldId === column_id);

    if (!columnValue || columnValue.column_type !== 'date' || columnValue.value_type === 'error') {
      return null;
    }

    if (!columnValue.value.date) {
      return null;
    }

    if (outputFormat === 'sheetjs') {
      try {
        const value = formulaExecuter(`DATEVALUE("${columnValue.value.date}")`);
        return {
          ...convertConfigToSheetjs(config),
          v: value,
          t: 'n',
          z: 'yyyy-mm-dd',
        };
      } catch (err) {
        console.log('failed to convert date', columnValue.value.date, err);
        return null;
      }
    }
  },
};

export default handler;
