import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString } from '../converters';
import { LocationColumnType } from '../../monday-api/api';

export type LocationColumnValue = {
  text: string | null;
  lat: string | null; // TODO: or number?
  lng: string | null; // TODO: or number?
  city_long_name: string | null;
  city_short_name: string | null;
  address: string | null;
  country_long_name: string | null;
  country_short_name: string | null;
  street_long_name: string | null;
  street_short_name: string | null;
  street_number_long_name: string | null;
  street_number_short_name: string | null;
  place_id: string | null; // placeId => place_id
  changed_at: string | null;
};

const handler: ColumnHandler<LocationColumnType, LocationColumnValue> = {
  type: 'location',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const lat = parseString(_.get(value, 'lat'));
    const lng = parseString(_.get(value, 'lng'));
    const cityLongName = parseString(_.get(value, 'city.long_name'));
    const cityShortName = parseString(_.get(value, 'city.short_name'));
    //const street = parseString(_.get(value, 'street'));
    const address = parseString(_.get(value, 'address'));
    const countryLongName = parseString(_.get(value, 'country.long_name'));
    const countryShortName = parseString(_.get(value, 'country.short_name'));
    const placeId = parseString(_.get(value, 'placeId'));
    const streetNumberLongName = parseString(_.get(value, 'streetNumber.long_name'));
    const streetNumberShortName = parseString(_.get(value, 'streetNumber.short_name'));
    const streetLongName = parseString(_.get(value, 'street.long_name'));
    const streetShortName = parseString(_.get(value, 'street.short_name'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      lat,
      lng,
      city_long_name: cityLongName,
      city_short_name: cityShortName,
      street_long_name: streetLongName,
      street_short_name: streetShortName,
      address,
      country_long_name: countryLongName,
      country_short_name: countryShortName,
      place_id: placeId,
      street_number_long_name: streetNumberLongName,
      street_number_short_name: streetNumberShortName,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue, modifier) => {
    if (modifier === 'Address') {
      return engineValue.address ? engineValue.address : '';
    }

    if (modifier === 'Country') {
      return engineValue.country_long_name ? engineValue.country_long_name : '';
    }

    if (modifier === 'CountryShort') {
      return engineValue.country_short_name ? engineValue.country_short_name : '';
    }

    if (modifier === 'City') {
      return engineValue.city_long_name ? engineValue.city_long_name : '';
    }

    if (modifier === 'CityShort') {
      return engineValue.city_short_name ? engineValue.city_short_name : '';
    }

    if (modifier === 'Street') {
      return engineValue.street_long_name ? engineValue.street_long_name : '';
    }

    if (modifier === 'StreetShort') {
      return engineValue.street_short_name ? engineValue.street_short_name : '';
    }

    if (modifier === 'StreetNumber') {
      return engineValue.street_number_long_name ? engineValue.street_number_long_name : '';
    }

    return engineValue.text === null ? '' : engineValue.text;
  },
};

export default handler;
