import { useRef, useState, useEffect } from 'react';
import { createExecutionQueue } from '../utils/execution-queue';

export function useExecutionQueue() {
  const [forceUpdate, setForceUpdate] = useState<boolean>();
  const queueRef = useRef<ReturnType<typeof createExecutionQueue>>();
  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  if (!abortControllerRef.current) {
    abortControllerRef.current = new AbortController();
  }

  if (!queueRef.current) {
    queueRef.current = createExecutionQueue(() => {
      setForceUpdate((prevVal) => !prevVal);
    }, abortControllerRef.current.signal);
  }

  return {
    add: queueRef.current.add,
    queueSize: queueRef.current.getQueueSize(),
    isRunning: queueRef.current.isRunning(),
    queue: queueRef.current.getQueue(),
  };
}
