import React from 'react';
import * as _ from 'lodash';
import { Modal, ModalHeader, ModalContent } from 'monday-ui-react-core';
import FormulaIcon from 'monday-ui-react-core/dist/icons/Formula';

type FormulaDocumentationModalProps = {
  show: boolean;
  onClose: () => void;
};

export function FormulaDocumentationModal({ show, onClose }: FormulaDocumentationModalProps) {
  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={Modal.width?.FULL_WIDTH as any}
      alertDialog={false}
    >
      <ModalHeader icon={FormulaIcon} title="Documentation" />
      <ModalContent>
        <div className="formula--documentation">
          <div className="sidebar">
            <ul>
              <li>
                <h4>Get started</h4>
              </li>
              <li>
                <a href="#introduction">Introduction</a>
              </li>
              <li>
                <a href="#examples">Examples</a>
              </li>
              <li>
                <h4>Functions</h4>
              </li>
              <li>
                <a href="#date-and-time">Date and time</a>
              </li>
              <li>
                <a href="#information">Information</a>
              </li>
              <li>
                <a href="#logical">Logical</a>
              </li>
              <li>
                <a href="#lookup-and-reference">Lookup and reference</a>
              </li>
              <li>
                <a href="#math-and-trigonometry">Math and trigonometry</a>
              </li>
              <li>
                <a href="#statistical">Statistical</a>
              </li>
              <li>
                <a href="#text">Text</a>
              </li>
            </ul>
          </div>
          <div className="content">
            <h3 id="introduction">
              <a href="#introduction" className="header-anchor">
                #
              </a>{' '}
              Introduction
            </h3>
            <p>
              Spreadsheet Gorilla lets you define custom formulas that can be used to perform calculations across different monday boards.
              The resulting values of these formulas can then be transferred back to monday.
            </p>
            <p>
              Check out{' '}
              <a className="ytlink" href="https://www.youtube.com/watch?v=zgDFN3FS4qA" target="_blank">
                this video
              </a>{' '}
              to get an overview of how it works.
            </p>
            <h3 id="examples">
              <a href="#examples" className="header-anchor">
                #
              </a>{' '}
              Examples
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Example</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    Get the value that's in column C of the current row. $$ automatically gets replaced by the number of the current row.
                  </td>
                  <td>=C$$</td>
                </tr>
                <tr>
                  <td>Get the sum of the values A1 to A10 in Sheet2.</td>
                  <td>=SUM(Sheet2!A1:A10)</td>
                </tr>
                <tr>
                  <td>Search for a value in Sheet2 by using the value in column A of the current row as a lookup value.</td>
                  <td>=VLOOKUP(A$$,Sheet2!A1:B10,2,FALSE)</td>
                </tr>
              </tbody>
            </table>
            <h3 id="date-and-time">
              <a href="#date-and-time" className="header-anchor">
                #
              </a>{' '}
              Date and time
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>DATE</td>
                  <td>
                    Returns the specified date as the number of full days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .
                  </td>
                  <td>DATE(Year, Month, Day)</td>
                </tr>
                <tr>
                  <td>DATEDIF</td>
                  <td>Calculates distance between two dates, in provided unit parameter.</td>
                  <td>DATEDIF(Date1, Date2, Units)</td>
                </tr>
                <tr>
                  <td>DATEVALUE</td>
                  <td>
                    Parses a date string and returns it as the number of full days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .<br />
                    <br />
                    Accepts formats set by the{' '}
                    <a href="/api/interfaces/configparams.html#dateformats" className="">
                      <code>dateFormats</code>
                    </a>{' '}
                    option.
                  </td>
                  <td>DATEVALUE(Datestring)</td>
                </tr>
                <tr>
                  <td>DAY</td>
                  <td>Returns the day of the given date value.</td>
                  <td>DAY(Number)</td>
                </tr>
                <tr>
                  <td>DAYS</td>
                  <td>Calculates the difference between two date values.</td>
                  <td>DAYS(Date2, Date1)</td>
                </tr>
                <tr>
                  <td>DAYS360</td>
                  <td>Calculates the difference between two date values in days, in 360-day basis.</td>
                  <td>DAYS360(Date2, Date1[, Format])</td>
                </tr>
                <tr>
                  <td>EDATE</td>
                  <td>
                    Shifts the given startdate by given number of months and returns it as the number of full days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .
                    <sup className="footnote-ref">
                      <a href="#fn1" id="fnref1">
                        [1]
                      </a>
                    </sup>
                  </td>
                  <td>EDATE(Startdate, Months)</td>
                </tr>
                <tr>
                  <td>EOMONTH</td>
                  <td>
                    Returns the date of the last day of a month which falls months away from the start date. Returns the value in the form
                    of number of full days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .
                    <sup className="footnote-ref">
                      <a href="#fn1" id="fnref1:1">
                        [1:1]
                      </a>
                    </sup>
                  </td>
                  <td>EOMONTH(Startdate, Months)</td>
                </tr>
                <tr>
                  <td>HOUR</td>
                  <td>Returns hour component of given time.</td>
                  <td>HOUR(Time)</td>
                </tr>
                <tr>
                  <td>INTERVAL</td>
                  <td>Returns interval string from given number of seconds.</td>
                  <td>INTERVAL(Seconds)</td>
                </tr>
                <tr>
                  <td>ISOWEEKNUM</td>
                  <td>Returns an ISO week number that corresponds to the week of year.</td>
                  <td>ISOWEEKNUM(Date)</td>
                </tr>
                <tr>
                  <td>MINUTE</td>
                  <td>Returns minute component of given time.</td>
                  <td>MINUTE(Time)</td>
                </tr>
                <tr>
                  <td>MONTH</td>
                  <td>Returns the month for the given date value.</td>
                  <td>MONTH(Number)</td>
                </tr>
                <tr>
                  <td>NETWORKDAYS</td>
                  <td>Returns the number of working days between two given dates.</td>
                  <td>NETWORKDAYS(Date1, Date2[, Holidays])</td>
                </tr>
                <tr>
                  <td>NETWORKDAYS.INTL</td>
                  <td>Returns the number of working days between two given dates.</td>
                  <td>NETWORKDAYS.INTL(Date1, Date2[, Mode [, Holidays]])</td>
                </tr>
                <tr>
                  <td>NOW</td>
                  <td>
                    Returns current date + time as a number of days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .
                  </td>
                  <td>NOW()</td>
                </tr>
                <tr>
                  <td>SECOND</td>
                  <td>Returns second component of given time.</td>
                  <td>SECOND(Time)</td>
                </tr>
                <tr>
                  <td>TIME</td>
                  <td>Returns the number that represents a given time as a fraction of full day.</td>
                  <td>TIME(Hour, Minute, Second)</td>
                </tr>
                <tr>
                  <td>TIMEVALUE</td>
                  <td>
                    Parses a time string and returns a number that represents it as a fraction of a full day.
                    <br />
                    <br />
                    Accepts formats set by the{' '}
                    <a href="/api/interfaces/configparams.html#timeformats" className="">
                      <code>timeFormats</code>
                    </a>{' '}
                    option.
                  </td>
                  <td>TIMEVALUE(Timestring)</td>
                </tr>
                <tr>
                  <td>TODAY</td>
                  <td>
                    Returns an integer representing the current date as the number of full days since{' '}
                    <a href="/api/interfaces/configparams.html#nulldate" className="">
                      <code>nullDate</code>
                    </a>
                    .
                  </td>
                  <td>TODAY()</td>
                </tr>
                <tr>
                  <td>WEEKDAY</td>
                  <td>Computes a number between 1-7 representing the day of week.</td>
                  <td>WEEKDAY(Date, Type)</td>
                </tr>
                <tr>
                  <td>WEEKNUM</td>
                  <td>Returns a week number that corresponds to the week of year.</td>
                  <td>WEEKNUM(Date, Type)</td>
                </tr>
                <tr>
                  <td>WORKDAY</td>
                  <td>Returns the working day number of days from start day.</td>
                  <td>WORKDAY(Date, Shift[, Holidays])</td>
                </tr>
                <tr>
                  <td>WORKDAY.INTL</td>
                  <td>Returns the working day number of days from start day.</td>
                  <td>WORKDAY(Date, Shift[, Mode[, Holidays]])</td>
                </tr>
                <tr>
                  <td>YEAR</td>
                  <td>Returns the year as a number according to the internal calculation rules.</td>
                  <td>YEAR(Number)</td>
                </tr>
                <tr>
                  <td>YEARFRAC</td>
                  <td>Computes the difference between two date values, in fraction of years.</td>
                  <td>YEARFRAC(Date2, Date1[, Format])</td>
                </tr>
              </tbody>
            </table>
            <h3 id="information">
              <a href="#information" className="header-anchor">
                #
              </a>{' '}
              Information
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ISBLANK</td>
                  <td>Returns TRUE if the reference to a cell is blank.</td>
                  <td>ISBLANK(Value)</td>
                </tr>
                <tr>
                  <td>ISEVEN</td>
                  <td>Returns TRUE if the value is an even integer, or FALSE if the value is odd.</td>
                  <td>ISEVEN(Value)</td>
                </tr>
                <tr>
                  <td>ISNUMBER</td>
                  <td>Returns TRUE if the value refers to a number.</td>
                  <td>ISNUMBER(Value)</td>
                </tr>
                <tr>
                  <td>ISODD</td>
                  <td>Returns TRUE if the value is odd, or FALSE if the number is even.</td>
                  <td>ISODD(Value)</td>
                </tr>
              </tbody>
            </table>
            <h3 id="logical">
              <a href="#logical" className="header-anchor">
                #
              </a>{' '}
              Logical
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AND</td>
                  <td>Returns TRUE if all arguments are TRUE.</td>
                  <td>AND(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)</td>
                </tr>
                <tr>
                  <td>FALSE</td>
                  <td>Returns the logical value FALSE.</td>
                  <td>FALSE()</td>
                </tr>
                <tr>
                  <td>IF</td>
                  <td>Specifies a logical test to be performed.</td>
                  <td>IF(Test, Then value, Otherwisevalue)</td>
                </tr>
                <tr>
                  <td>IFS</td>
                  <td>Evaluates multiple logical tests and returns a value that corresponds to the first true condition.</td>
                  <td>IFS(Condition1, Value1[, Condition2, Value2[..., Condition_n, Value_n]])</td>
                </tr>
                <tr>
                  <td>NOT</td>
                  <td>Complements (inverts) a logical value.</td>
                  <td>NOT(Logicalvalue)</td>
                </tr>
                <tr>
                  <td>SWITCH</td>
                  <td>Evaluates a list of arguments, consisting of an expression followed by a value.</td>
                  <td>SWITCH(Expression1, Value1[, Expression2, Value2[..., Expression_n, Value_n]])</td>
                </tr>
                <tr>
                  <td>OR</td>
                  <td>Returns TRUE if at least one argument is TRUE.</td>
                  <td>OR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)</td>
                </tr>
                <tr>
                  <td>TRUE</td>
                  <td>The logical value is set to TRUE.</td>
                  <td>TRUE()</td>
                </tr>
                <tr>
                  <td>XOR</td>
                  <td>Returns true if an odd number of arguments evaluates to TRUE.</td>
                  <td>XOR(Logicalvalue1, Logicalvalue2 ...Logicalvalue30)</td>
                </tr>
              </tbody>
            </table>
            <h3 id="lookup-and-reference">
              <a href="#lookup-and-reference" className="header-anchor">
                #
              </a>{' '}
              Lookup and reference
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ADDRESS</td>
                  <td>Returns a cell reference as a string.</td>
                  <td>ADDRESS(Row, Column[, AbsoluteRelativeMode[, UseA1Notation[, Sheet]]])</td>
                </tr>
                <tr>
                  <td>CHOOSE</td>
                  <td>Uses an index to return a value from a list of up to 30 values.</td>
                  <td>CHOOSE(Index, Value1, ..., Value30)</td>
                </tr>
                <tr>
                  <td>COLUMN</td>
                  <td>Returns column number of a given reference or formula reference if argument not provided.</td>
                  <td>COLUMNS([Reference])</td>
                </tr>
                <tr>
                  <td>COLUMNS</td>
                  <td>Returns the number of columns in the given reference.</td>
                  <td>COLUMNS(Array)</td>
                </tr>
                <tr>
                  <td>HLOOKUP</td>
                  <td>Searches horizontally with reference to adjacent cells to the bottom.</td>
                  <td>HLOOKUP(Search_Criterion, Array, Index, Sort_Order)</td>
                </tr>
                <tr>
                  <td>INDEX</td>
                  <td>
                    Returns the contents of a cell specified by row and column number. The column number is optional and defaults to 1.
                  </td>
                  <td>INDEX(Range, Row [, Column])</td>
                </tr>
                <tr>
                  <td>MATCH</td>
                  <td>Returns the relative position of an item in an array that matches a specified value.</td>
                  <td>MATCH(Searchcriterion, Lookuparray [, MatchType])</td>
                </tr>
                <tr>
                  <td>OFFSET</td>
                  <td>Returns the value of a cell offset by a certain number of rows and columns from a given reference point.</td>
                  <td>OFFSET(Reference, Rows, Columns, Height, Width)</td>
                </tr>
                <tr>
                  <td>ROW</td>
                  <td>Returns row number of a given reference or formula reference if argument not provided.</td>
                  <td>ROW([Reference])</td>
                </tr>
                <tr>
                  <td>ROWS</td>
                  <td>Returns the number of rows in the given reference.</td>
                  <td>ROWS(Array)</td>
                </tr>
                <tr>
                  <td>VLOOKUP</td>
                  <td>Searches vertically with reference to adjacent cells to the right.</td>
                  <td>VLOOKUP(Search_Criterion, Array, Index, Sort_Order)</td>
                </tr>
              </tbody>
            </table>
            <h3 id="math-and-trigonometry">
              <a href="#math-and-trigonometry" className="header-anchor">
                #
              </a>{' '}
              Math and trigonometry
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>ABS</td>
                  <td>Returns the absolute value of a number.</td>
                  <td>ABS(Number)</td>
                </tr>
                <tr>
                  <td>ACOS</td>
                  <td>Returns the inverse trigonometric cosine of a number.</td>
                  <td>ACOS(Number)</td>
                </tr>
                <tr>
                  <td>ACOSH</td>
                  <td>Returns the inverse hyperbolic cosine of a number.</td>
                  <td>ACOSH(Number)</td>
                </tr>
                <tr>
                  <td>ACOT</td>
                  <td>Returns the inverse trigonometric cotangent of a number.</td>
                  <td>ACOT(Number)</td>
                </tr>
                <tr>
                  <td>ACOTH</td>
                  <td>Returns the inverse hyperbolic cotangent of a number.</td>
                  <td>ACOTH(Number)</td>
                </tr>
                <tr>
                  <td>ARABIC</td>
                  <td>Converts number from roman form.</td>
                  <td>ARABIC(String)</td>
                </tr>
                <tr>
                  <td>ASIN</td>
                  <td>Returns the inverse trigonometric sine of a number.</td>
                  <td>ASIN(Number)</td>
                </tr>
                <tr>
                  <td>ASINH</td>
                  <td>Returns the inverse hyperbolic sine of a number.</td>
                  <td>ASINH(Number)</td>
                </tr>
                <tr>
                  <td>ATAN</td>
                  <td>Returns the inverse trigonometric tangent of a number.</td>
                  <td>ATAN(Number)</td>
                </tr>
                <tr>
                  <td>ATAN2</td>
                  <td>Returns the inverse trigonometric tangent of the specified x and y coordinates.</td>
                  <td>ATAN2(Numberx, Numbery)</td>
                </tr>
                <tr>
                  <td>ATANH</td>
                  <td>Returns the inverse hyperbolic tangent of a number.</td>
                  <td>ATANH(Number)</td>
                </tr>
                <tr>
                  <td>BASE</td>
                  <td>Converts a positive integer to a specified base into a text from the numbering system.</td>
                  <td>BASE(Number, Radix, [Minimumlength])</td>
                </tr>
                <tr>
                  <td>CEILING</td>
                  <td>Rounds a number up to the nearest multiple of Significance.</td>
                  <td>CEILING(Number, Significance)</td>
                </tr>
                <tr>
                  <td>CEILING.MATH</td>
                  <td>Rounds a number up to the nearest multiple of Significance.</td>
                  <td>CEILING.MATH(Number[, Significance[, Mode]])</td>
                </tr>
                <tr>
                  <td>CEILING.PRECISE</td>
                  <td>Rounds a number up to the nearest multiple of Significance.</td>
                  <td>CEILING.PRECISE(Number[, Significance])</td>
                </tr>
                <tr>
                  <td>COMBIN</td>
                  <td>Returns number of combinations (without repetitions).</td>
                  <td>COMBIN(Number, Number)</td>
                </tr>
                <tr>
                  <td>COMBINA</td>
                  <td>Returns number of combinations (with repetitions).</td>
                  <td>COMBINA(Number, Number)</td>
                </tr>
                <tr>
                  <td>COS</td>
                  <td>Returns the cosine of the given angle (in radians).</td>
                  <td>COS(Number)</td>
                </tr>
                <tr>
                  <td>COSH</td>
                  <td>Returns the hyperbolic cosine of the given value.</td>
                  <td>COSH(Number)</td>
                </tr>
                <tr>
                  <td>COT</td>
                  <td>Returns the cotangent of the given angle (in radians).</td>
                  <td>COT(Number)</td>
                </tr>
                <tr>
                  <td>COTH</td>
                  <td>Returns the hyperbolic cotangent of the given value.</td>
                  <td>COTH(Number)</td>
                </tr>
                <tr>
                  <td>COUNTUNIQUE</td>
                  <td>Counts the number of unique values in a list of specified values and ranges.</td>
                  <td>COUNTUNIQUE(Value1, [Value2, ...])</td>
                </tr>
                <tr>
                  <td>CSC</td>
                  <td>Returns the cosecans of the given angle (in radians).</td>
                  <td>CSC(Number)</td>
                </tr>
                <tr>
                  <td>CSCH</td>
                  <td>Returns the hyperbolic cosecant of the given value.</td>
                  <td>CSCH(Number)</td>
                </tr>
                <tr>
                  <td>DECIMAL</td>
                  <td>Converts text with characters from a number system to a positive integer in the base radix given.</td>
                  <td>DECIMAL("Text", Radix)</td>
                </tr>
                <tr>
                  <td>DEGREES</td>
                  <td>Converts radians into degrees.</td>
                  <td>DEGREES(Number)</td>
                </tr>
                <tr>
                  <td>EVEN</td>
                  <td>Rounds a positive number up to the next even integer and a negative number down to the next even integer.</td>
                  <td>EVEN(Number)</td>
                </tr>
                <tr>
                  <td>EXP</td>
                  <td>Returns constant e raised to the power of a number.</td>
                  <td>EXP(Number)</td>
                </tr>
                <tr>
                  <td>FACT</td>
                  <td>Returns a factorial of a number.</td>
                  <td>FACT(Number)</td>
                </tr>
                <tr>
                  <td>FACTDOUBLE</td>
                  <td>Returns a double factorial of a number.</td>
                  <td>FACTDOUBLE(Number)</td>
                </tr>
                <tr>
                  <td>FLOOR</td>
                  <td>Rounds a number down to the nearest multiple of Significance.</td>
                  <td>FLOOR(Number, Significance)</td>
                </tr>
                <tr>
                  <td>FLOOR.MATH</td>
                  <td>Rounds a number down to the nearest multiple of Significance.</td>
                  <td>FLOOR.MATH(Number[, Significance[, Mode]])</td>
                </tr>
                <tr>
                  <td>FLOOR.PRECISE</td>
                  <td>Rounds a number down to the nearest multiple of Significance.</td>
                  <td>FLOOR.PRECISE(Number[, Significance])</td>
                </tr>
                <tr>
                  <td>GCD</td>
                  <td>Computes greatest common divisor of numbers.</td>
                  <td>GCD(Number1, Number2, ...)</td>
                </tr>
                <tr>
                  <td>INT</td>
                  <td>Rounds a number down to the nearest integer.</td>
                  <td>INT(Number)</td>
                </tr>
                <tr>
                  <td>ISO.CEILING</td>
                  <td>Rounds a number up to the nearest multiple of Significance.</td>
                  <td>ISO.CEILING(Number[, Significance])</td>
                </tr>
                <tr>
                  <td>LCM</td>
                  <td>Computes least common multiplicity of numbers.</td>
                  <td>LCM(Number1, Number2, ...)</td>
                </tr>
                <tr>
                  <td>LN</td>
                  <td>Returns the natural logarithm based on the constant e of a number.</td>
                  <td>LN(Number)</td>
                </tr>
                <tr>
                  <td>LOG</td>
                  <td>Returns the logarithm of a number to the specified base.</td>
                  <td>LOG(Number, Base)</td>
                </tr>
                <tr>
                  <td>LOG10</td>
                  <td>Returns the base-10 logarithm of a number.</td>
                  <td>LOG10(Number)</td>
                </tr>
                <tr>
                  <td>MOD</td>
                  <td>Returns the remainder when one integer is divided by another.</td>
                  <td>MOD(Dividend, Divisor)</td>
                </tr>
                <tr>
                  <td>MROUND</td>
                  <td>Rounds number to the neares multiplicity.</td>
                  <td>MROUND(Number, Base)</td>
                </tr>
                <tr>
                  <td>MULTINOMIAL</td>
                  <td>Returns number of multiset combinations.</td>
                  <td>MULTINOMIAL(Number1, Number2, ...)</td>
                </tr>
                <tr>
                  <td>ODD</td>
                  <td>Rounds a positive number up to the nearest odd integer and a negative number down to the nearest odd integer.</td>
                  <td>ODD(Number)</td>
                </tr>
                <tr>
                  <td>PI</td>
                  <td>Returns 3.14159265358979, the value of the mathematical constant PI to 14 decimal places.</td>
                  <td>PI()</td>
                </tr>
                <tr>
                  <td>POWER</td>
                  <td>Returns a number raised to another number.</td>
                  <td>POWER(Base, Exponent)</td>
                </tr>
                <tr>
                  <td>PRODUCT</td>
                  <td>Returns product of numbers.</td>
                  <td>PRODUCT(Number1, Number2, ..., Number30)</td>
                </tr>
                <tr>
                  <td>QUOTIENT</td>
                  <td>Returns integer part of a division.</td>
                  <td>QUOTIENT(Dividend, Divisor)</td>
                </tr>
                <tr>
                  <td>RADIANS</td>
                  <td>Converts degrees to radians.</td>
                  <td>RADIANS(Number)</td>
                </tr>
                <tr>
                  <td>RAND</td>
                  <td>Returns a random number between 0 and 1.</td>
                  <td>RAND()</td>
                </tr>
                <tr>
                  <td>RANDBETWEEN</td>
                  <td>Returns a random integer between two numbers.</td>
                  <td>RAND(Lowerbound, Upperbound)</td>
                </tr>
                <tr>
                  <td>ROMAN</td>
                  <td>Converts number to roman form.</td>
                  <td>ROMAN(Number[, Mode])</td>
                </tr>
                <tr>
                  <td>ROUND</td>
                  <td>Rounds a number to a certain number of decimal places.</td>
                  <td>ROUND(Number, Count)</td>
                </tr>
                <tr>
                  <td>ROUNDDOWN</td>
                  <td>Rounds a number down, toward zero, to a certain precision.</td>
                  <td>ROUNDDOWN(Number, Count)</td>
                </tr>
                <tr>
                  <td>ROUNDUP</td>
                  <td>Rounds a number up, away from zero, to a certain precision.</td>
                  <td>ROUNDUP(Number, Count)</td>
                </tr>
                <tr>
                  <td>SEC</td>
                  <td>Returns the secant of the given angle (in radians).</td>
                  <td>SEC(Number)</td>
                </tr>
                <tr>
                  <td>SECH</td>
                  <td>Returns the hyperbolic secant of the given angle (in radians).</td>
                  <td>SEC(Number)</td>
                </tr>
                <tr>
                  <td>SERIESSUM</td>
                  <td>Evaluates series at a point.</td>
                  <td>SERIESSUM(Number, Number, Number, Coefficients)</td>
                </tr>
                <tr>
                  <td>SIN</td>
                  <td>Returns the sine of the given angle (in radians).</td>
                  <td>SIN(Number)</td>
                </tr>
                <tr>
                  <td>SINH</td>
                  <td>Returns the hyperbolic sine of the given value.</td>
                  <td>SINH(Number)</td>
                </tr>
                <tr>
                  <td>SIGN</td>
                  <td>Returns sign of a number.</td>
                  <td>SIGN(Number)</td>
                </tr>
                <tr>
                  <td>SQRT</td>
                  <td>Returns the positive square root of a number.</td>
                  <td>SQRT(Number)</td>
                </tr>
                <tr>
                  <td>SQRTPI</td>
                  <td>Returns sqrt of number times pi.</td>
                  <td>SQRTPI(Number)</td>
                </tr>
                <tr>
                  <td>SUBTOTAL</td>
                  <td>Computes aggregation using function specified by number.</td>
                  <td>SUBTOTAL(Function, Number1, Number2, ... Number30)</td>
                </tr>
                <tr>
                  <td>SUM</td>
                  <td>Sums up the values of the specified cells.</td>
                  <td>SUM(Number1, Number2, ..., Number30)</td>
                </tr>
                <tr>
                  <td>SUMIF</td>
                  <td>Sums up the values of cells that belong to the specified range and meet the specified condition.</td>
                  <td>SUMIF(Range, Criteria, Sumrange)</td>
                </tr>
                <tr>
                  <td>SUMIFS</td>
                  <td>Sums up the values of cells that belong to the specified range and meet the specified sets of conditions.</td>
                  <td>SUMIFS(Sum_Range , Criterion_range1 , Criterion1 [ , Criterion_range2 , Criterion2 [,...]])</td>
                </tr>
                <tr>
                  <td>SUMPRODUCT</td>
                  <td>Multiplies corresponding elements in the given arrays, and returns the sum of those products.</td>
                  <td>SUMPRODUCT(Array1, Array2...Array30)</td>
                </tr>
                <tr>
                  <td>SUMSQ</td>
                  <td>Returns the sum of the squares of the arguments</td>
                  <td>SUMSQ(Number1, Number2, ..., Number30)</td>
                </tr>
                <tr>
                  <td>SUMX2MY2</td>
                  <td>Returns the sum of the square differences.</td>
                  <td>SUMX2MY2(Range1, Range2)</td>
                </tr>
                <tr>
                  <td>SUMX2PY2</td>
                  <td>Returns the sum of the square sums.</td>
                  <td>SUMX2PY2(Range1, Range2)</td>
                </tr>
                <tr>
                  <td>SUMXMY2</td>
                  <td>Returns the sum of the square of differences.</td>
                  <td>SUMXMY2(Range1, Range2)</td>
                </tr>
                <tr>
                  <td>TAN</td>
                  <td>Returns the tangent of the given angle (in radians).</td>
                  <td>TAN(Number)</td>
                </tr>
                <tr>
                  <td>TANH</td>
                  <td>Returns the hyperbolic tangent of the given value.</td>
                  <td>TANH(Number)</td>
                </tr>
                <tr>
                  <td>TRUNC</td>
                  <td>Truncates a number by removing decimal places.</td>
                  <td>TRUNC(Number, Count)</td>
                </tr>
              </tbody>
            </table>
            <h3 id="statistical">
              <a href="#statistical" className="header-anchor">
                #
              </a>{' '}
              Statistical
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>AVEDEV</td>
                  <td>Returns the average deviation of the arguments.</td>
                  <td>AVEDEV(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>AVERAGE</td>
                  <td>Returns the average of the arguments.</td>
                  <td>AVERAGE(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>AVERAGEA</td>
                  <td>Returns the average of the arguments.</td>
                  <td>AVERAGEA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>AVERAGEIF</td>
                  <td>Returns the arithmetic mean of all cells in a range that satisfy a given condition.</td>
                  <td>AVERAGEIF(Range, Criterion [, Average_Range ])</td>
                </tr>
                <tr>
                  <td>BESSELI</td>
                  <td>Returns value of Bessel function.</td>
                  <td>BESSELI(x, n)</td>
                </tr>
                <tr>
                  <td>BESSELJ</td>
                  <td>Returns value of Bessel function.</td>
                  <td>BESSELJ(x, n)</td>
                </tr>
                <tr>
                  <td>BESSELK</td>
                  <td>Returns value of Bessel function.</td>
                  <td>BESSELK(x, n)</td>
                </tr>
                <tr>
                  <td>BESSELY</td>
                  <td>Returns value of Bessel function.</td>
                  <td>BESSELY(x, n)</td>
                </tr>
                <tr>
                  <td>BETA.DIST</td>
                  <td>Returns the denisty of Beta distribution.</td>
                  <td>BETA.DIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])</td>
                </tr>
                <tr>
                  <td>BETADIST</td>
                  <td>Returns the denisty of Beta distribution.</td>
                  <td>BETADIST(Number1, Number2, Number3, Boolean[, Number4[, Number5]])</td>
                </tr>
                <tr>
                  <td>BETA.INV</td>
                  <td>Returns the inverse Beta distribution value.</td>
                  <td>BETA.INV(Number1, Number2, Number3[, Number4[, Number5]])</td>
                </tr>
                <tr>
                  <td>BETAINV</td>
                  <td>Returns the inverse of Beta distribution value.</td>
                  <td>BETAINV(Number1, Number2, Number3[, Number4[, Number5]])</td>
                </tr>
                <tr>
                  <td>BINOM.DIST</td>
                  <td>Returns density of binomial distribution.</td>
                  <td>BINOM.DIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>BINOMDIST</td>
                  <td>Returns density of binomial distribution.</td>
                  <td>BINOMDIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>BINOM.INV</td>
                  <td>Returns inverse binomial distribution value.</td>
                  <td>BINOM.INV(Number1, Number2, Number3)</td>
                </tr>
                <tr>
                  <td>CHIDIST</td>
                  <td>Returns probability of chi-square right-side distribution.</td>
                  <td>CHIDIST(X, Degrees)</td>
                </tr>
                <tr>
                  <td>CHIINV</td>
                  <td>Returns inverse of chi-square right-side distribution.</td>
                  <td>CHIINV(P, Degrees)</td>
                </tr>
                <tr>
                  <td>CHIINVRT</td>
                  <td>Returns inverse of chi-square right-side distribution.</td>
                  <td>CHIINVRT(P, Degrees)</td>
                </tr>
                <tr>
                  <td>CHISQ.DIST</td>
                  <td>Returns value of chi-square distribution.</td>
                  <td>CHISQ.DIST(X, Degrees, Mode)</td>
                </tr>
                <tr>
                  <td>CHIDISTRT</td>
                  <td>Returns probability of chi-square right-side distribution.</td>
                  <td>CHIDISTRT(X, Degrees)</td>
                </tr>
                <tr>
                  <td>CHISQ.DIST.RT</td>
                  <td>Returns probability of chi-square right-side distribution.</td>
                  <td>CHISQ.DIST.RT(X, Degrees)</td>
                </tr>
                <tr>
                  <td>CHISQ.INV</td>
                  <td>Returns inverse of chi-square distribution.</td>
                  <td>CHISQ.INV.RT(P, Degrees)</td>
                </tr>
                <tr>
                  <td>CHISQ.INV.RT</td>
                  <td>Returns inverse of chi-square right-side distribution.</td>
                  <td>CHISQ.INV.RT(P, Degrees)</td>
                </tr>
                <tr>
                  <td>CHISQ.TEST</td>
                  <td>Returns chisquared-test value for a dataset.</td>
                  <td>CHISQ.TEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>CHITEST</td>
                  <td>Returns chisquared-test value for a dataset.</td>
                  <td>CHITEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>CONFIDENCE</td>
                  <td>Returns upper confidence bound for normal distribution.</td>
                  <td>CONFIDENCE(Alpha, Stdev, Size)</td>
                </tr>
                <tr>
                  <td>CONFIDENCE.NORM</td>
                  <td>Returns upper confidence bound for normal distribution.</td>
                  <td>CONFIDENCE.NORM(Alpha, Stdev, Size)</td>
                </tr>
                <tr>
                  <td>CONFIDENCE.T</td>
                  <td>Returns upper confidence bound for T distribution.</td>
                  <td>CONFIDENCE.T(Alpha, Stdev, Size)</td>
                </tr>
                <tr>
                  <td>CORREL</td>
                  <td>Returns the correlation coefficient between two data sets.</td>
                  <td>CORREL(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>COUNT</td>
                  <td>Counts how many numbers are in the list of arguments.</td>
                  <td>COUNT(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>COUNTA</td>
                  <td>Counts how many values are in the list of arguments.</td>
                  <td>COUNTA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>COUNTBLANK</td>
                  <td>Returns the number of empty cells.</td>
                  <td>COUNTBLANK(Range)</td>
                </tr>
                <tr>
                  <td>COUNTIF</td>
                  <td>Returns the number of cells that meet with certain criteria within a cell range.</td>
                  <td>COUNTIF(Range, Criteria)</td>
                </tr>
                <tr>
                  <td>COUNTIFS</td>
                  <td>Returns the count of rows or columns that meet criteria in multiple ranges.</td>
                  <td>COUNTIFS(Range1, Criterion1 [, Range2, Criterion2 [, ...]])</td>
                </tr>
                <tr>
                  <td>COVAR</td>
                  <td>Returns the covariance between two data sets, population normalized.</td>
                  <td>COVAR(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>COVARIANCE.P</td>
                  <td>Returns the covariance between two data sets, population normalized.</td>
                  <td>COVARIANCE.P(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>COVARIANCEP</td>
                  <td>Returns the covariance between two data sets, population normalized.</td>
                  <td>COVARIANCEP(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>COVARIANCE.S</td>
                  <td>Returns the covariance between two data sets, sample normalized.</td>
                  <td>COVARIANCE.S(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>COVARIANCES</td>
                  <td>Returns the covariance between two data sets, sample normalized.</td>
                  <td>COVARIANCES(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>CRITBINOM</td>
                  <td>Returns inverse binomial distribution value.</td>
                  <td>CRITBINOM(Number1, Number2, Number3)</td>
                </tr>
                <tr>
                  <td>DEVSQ</td>
                  <td>Returns sum of squared deviations.</td>
                  <td>DEVSQ(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>EXPON.DIST</td>
                  <td>Returns density of a exponential distribution.</td>
                  <td>EXPON.DIST(Number1, Number2, Boolean)</td>
                </tr>
                <tr>
                  <td>EXPONDIST</td>
                  <td>Returns density of a exponential distribution.</td>
                  <td>EXPONDIST(Number1, Number2, Boolean)</td>
                </tr>
                <tr>
                  <td>FDIST</td>
                  <td>Returns probability of F right-side distribution.</td>
                  <td>FDIST(X, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>FINV</td>
                  <td>Returns inverse of F right-side distribution.</td>
                  <td>FINV(P, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>F.DIST</td>
                  <td>Returns value of F distribution.</td>
                  <td>F.DIST(X, Degree1, Degree2, Mode)</td>
                </tr>
                <tr>
                  <td>F.DIST.RT</td>
                  <td>Returns probability of F right-side distribution.</td>
                  <td>F.DIST.RT(X, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>FDISTRT</td>
                  <td>Returns probability of F right-side distribution.</td>
                  <td>FDISTRT(X, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>F.INV</td>
                  <td>Returns inverse of F distribution.</td>
                  <td>F.INV.RT(P, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>F.INV.RT</td>
                  <td>Returns inverse of F right-side distribution.</td>
                  <td>F.INV.RT(P, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>FINVRT</td>
                  <td>Returns inverse of F right-side distribution.</td>
                  <td>FINVRT(P, Degree1, Degree2)</td>
                </tr>
                <tr>
                  <td>FISHER</td>
                  <td>Returns Fisher transformation value.</td>
                  <td>FISHER(Number)</td>
                </tr>
                <tr>
                  <td>FISHERINV</td>
                  <td>Returns inverse Fischer transformation value.</td>
                  <td>FISHERINV(Number)</td>
                </tr>
                <tr>
                  <td>F.TEST</td>
                  <td>Returns f-test value for a dataset.</td>
                  <td>Z.TEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>FTEST</td>
                  <td>Returns f-test value for a dataset.</td>
                  <td>ZTEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>GAMMA</td>
                  <td>Returns value of Gamma function.</td>
                  <td>GAMMA(Number)</td>
                </tr>
                <tr>
                  <td>GAMMA.DIST</td>
                  <td>Returns density of Gamma distribution.</td>
                  <td>GAMMA.DIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>GAMMADIST</td>
                  <td>Returns density of Gamma distribution.</td>
                  <td>GAMMADIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>GAMMALN</td>
                  <td>Returns natural logarithm of Gamma function.</td>
                  <td>GAMMALN(Number)</td>
                </tr>
                <tr>
                  <td>GAMMALN.PRECISE</td>
                  <td>Returns natural logarithm of Gamma function.</td>
                  <td>GAMMALN.PRECISE(Number)</td>
                </tr>
                <tr>
                  <td>GAMMA.INV</td>
                  <td>Returns inverse Gamma distribution value.</td>
                  <td>GAMMA.INV(Number1, Number2, Number3)</td>
                </tr>
                <tr>
                  <td>GAMMAINV</td>
                  <td>Returns inverse Gamma distribution value.</td>
                  <td>GAMMAINV(Number1, Number2, Number3)</td>
                </tr>
                <tr>
                  <td>GAUSS</td>
                  <td>Returns the probability of gaussian variable fall more than this many times standard deviation from mean.</td>
                  <td>GAUSS(Number)</td>
                </tr>
                <tr>
                  <td>GEOMEAN</td>
                  <td>Returns the geometric average.</td>
                  <td>GEOMEAN(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>HARMEAN</td>
                  <td>Returns the harmonic average.</td>
                  <td>HARMEAN(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>HYPGEOMDIST</td>
                  <td>Returns density of hypergeometric distribution.</td>
                  <td>HYPGEOMDIST(Number1, Number2, Number3, Number4, Boolean)</td>
                </tr>
                <tr>
                  <td>HYPGEOM.DIST</td>
                  <td>Returns density of hypergeometric distribution.</td>
                  <td>HYPGEOM.DIST(Number1, Number2, Number3, Number4, Boolean)</td>
                </tr>
                <tr>
                  <td>LARGE</td>
                  <td>Returns k-th largest value in a range.</td>
                  <td>LARGE(Range, K)</td>
                </tr>
                <tr>
                  <td>LOGNORM.DIST</td>
                  <td>Returns density of lognormal distribution.</td>
                  <td>LOGNORM.DIST(X, Mean, Stddev, Mode)</td>
                </tr>
                <tr>
                  <td>LOGNORMDIST</td>
                  <td>Returns density of lognormal distribution.</td>
                  <td>LOGNORMDIST(X, Mean, Stddev, Mode)</td>
                </tr>
                <tr>
                  <td>LOGNORM.INV</td>
                  <td>Returns value of inverse lognormal distribution.</td>
                  <td>LOGNORM.INV(P, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>LOGNORMINV</td>
                  <td>Returns value of inverse lognormal distribution.</td>
                  <td>LOGNORMINV(P, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>LOGINV</td>
                  <td>Returns value of inverse lognormal distribution.</td>
                  <td>LOGINV(P, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>MAX</td>
                  <td>Returns the maximum value in a list of arguments.</td>
                  <td>MAX(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>MAXA</td>
                  <td>Returns the maximum value in a list of arguments.</td>
                  <td>MAXA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>MAXIFS</td>
                  <td>Returns the maximum value of the cells in a range that meet a set of criteria.</td>
                  <td>MAXIFS(Max_Range , Criterion_range1 , Criterion1 [ , Criterion_range2 , Criterion2 [,...]])</td>
                </tr>
                <tr>
                  <td>MEDIAN</td>
                  <td>Returns the median of a set of numbers.</td>
                  <td>MEDIAN(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>MIN</td>
                  <td>Returns the minimum value in a list of arguments.</td>
                  <td>MIN(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>MINA</td>
                  <td>Returns the minimum value in a list of arguments.</td>
                  <td>MINA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>MINIFS</td>
                  <td>Returns the minimum value of the cells in a range that meet a set of criteria.</td>
                  <td>MINIFS(Min_Range , Criterion_range1 , Criterion1 [ , Criterion_range2 , Criterion2 [,...]])</td>
                </tr>
                <tr>
                  <td>NEGBINOM.DIST</td>
                  <td>Returns density of negative binomial distribution.</td>
                  <td>NEGBINOM.DIST(Number1, Number2, Number3, Mode)</td>
                </tr>
                <tr>
                  <td>NEGBINOMDIST</td>
                  <td>Returns density of negative binomial distribution.</td>
                  <td>NEGBINOMDIST(Number1, Number2, Number3, Mode)</td>
                </tr>
                <tr>
                  <td>NORM.DIST</td>
                  <td>Returns density of normal distribution.</td>
                  <td>NORM.DIST(X, Mean, Stddev, Mode)</td>
                </tr>
                <tr>
                  <td>NORMDIST</td>
                  <td>Returns density of normal distribution.</td>
                  <td>NORMDIST(X, Mean, Stddev, Mode)</td>
                </tr>
                <tr>
                  <td>NORM.S.DIST</td>
                  <td>Returns density of normal distribution.</td>
                  <td>NORM.S.DIST(X, Mode)</td>
                </tr>
                <tr>
                  <td>NORMDIST</td>
                  <td>Returns density of normal distribution.</td>
                  <td>NORMSDIST(X, Mode)</td>
                </tr>
                <tr>
                  <td>NORM.INV</td>
                  <td>Returns value of inverse normal distribution.</td>
                  <td>NORM.INV(P, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>NORMINV</td>
                  <td>Returns value of inverse normal distribution.</td>
                  <td>NORMINV(P, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>NORM.S.INV</td>
                  <td>Returns value of inverse normal distribution.</td>
                  <td>NORM.S.INV(P)</td>
                </tr>
                <tr>
                  <td>NORMSINV</td>
                  <td>Returns value of inverse normal distribution.</td>
                  <td>NORMSINV(P)</td>
                </tr>
                <tr>
                  <td>PEARSON</td>
                  <td>Returns the correlation coefficient between two data sets.</td>
                  <td>PEARSON(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>PHI</td>
                  <td>Returns probability densitity of normal distribution.</td>
                  <td>PHI(X)</td>
                </tr>
                <tr>
                  <td>POISSON</td>
                  <td>Returns density of Poisson distribution.</td>
                  <td>POISSON(X, Mean, Mode)</td>
                </tr>
                <tr>
                  <td>POISSON.DIST</td>
                  <td>Returns density of Poisson distribution.</td>
                  <td>POISSON.DIST(X, Mean, Mode)</td>
                </tr>
                <tr>
                  <td>POISSONDIST</td>
                  <td>Returns density of Poisson distribution.</td>
                  <td>POISSONDIST(X, Mean, Mode)</td>
                </tr>
                <tr>
                  <td>RSQ</td>
                  <td>Returns the squared correlation coefficient between two data sets.</td>
                  <td>RSQ(Data1, Data2)</td>
                </tr>
                <tr>
                  <td>SKEW</td>
                  <td>Returns skeweness of a sample.</td>
                  <td>SKEW(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>SKEW.P</td>
                  <td>Returns skeweness of a population.</td>
                  <td>SKEW.P(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>SKEWP</td>
                  <td>Returns skeweness of a population.</td>
                  <td>SKEWP(Number1, Number2, ...Number30)</td>
                </tr>
                <tr>
                  <td>SLOPE</td>
                  <td>Returns the slope of a linear regression line.</td>
                  <td>SLOPE(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>SMALL</td>
                  <td>Returns k-th smallest value in a range.</td>
                  <td>SMALL(Range, K)</td>
                </tr>
                <tr>
                  <td>STANDARDIZE</td>
                  <td>Returns normalized value wrt expected value and standard deviation.</td>
                  <td>STANDARDIZE(X, Mean, Stddev)</td>
                </tr>
                <tr>
                  <td>STDEV</td>
                  <td>Returns standard deviation of a sample.</td>
                  <td>STDEV(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEVA</td>
                  <td>Returns standard deviation of a sample.</td>
                  <td>STDEVA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEVP</td>
                  <td>Returns standard deviation of a population.</td>
                  <td>STDEVP(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEV.P</td>
                  <td>Returns standard deviation of a population.</td>
                  <td>STDEV.P(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEVPA</td>
                  <td>Returns standard deviation of a population.</td>
                  <td>STDEVPA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEV.S</td>
                  <td>Returns standard deviation of a sample.</td>
                  <td>STDEV.S(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STDEVS</td>
                  <td>Returns standard deviation of a sample.</td>
                  <td>STDEVS(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>STEYX</td>
                  <td>Returns standard error for predicted of the predicted y value for each x value.</td>
                  <td>STEYX(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>TDIST</td>
                  <td>Returns density of Student-t distribution, both-sided or right-tailed.</td>
                  <td>TDIST(X, Degrees, Mode)</td>
                </tr>
                <tr>
                  <td>T.DIST</td>
                  <td>Returns density of Student-t distribution.</td>
                  <td>T.DIST(X, Degrees, Mode)</td>
                </tr>
                <tr>
                  <td>T.DIST.2T</td>
                  <td>Returns density of Student-t distribution, both-sided.</td>
                  <td>T.DIST.2T(X, Degrees)</td>
                </tr>
                <tr>
                  <td>TDIST2T</td>
                  <td>Returns density of Student-t distribution, both-sided.</td>
                  <td>TDIST2T(X, Degrees)</td>
                </tr>
                <tr>
                  <td>T.DIST.RT</td>
                  <td>Returns density of Student-t distribution, right-tailed.</td>
                  <td>T.DIST.RT(X, Degrees)</td>
                </tr>
                <tr>
                  <td>TDISTRT</td>
                  <td>Returns density of Student-t distribution, right-tailed.</td>
                  <td>TDISTRT(X, Degrees)</td>
                </tr>
                <tr>
                  <td>TINV</td>
                  <td>Returns inverse Student-t distribution, both-sided.</td>
                  <td>TINV(P, Degrees)</td>
                </tr>
                <tr>
                  <td>T.INV</td>
                  <td>Returns inverse Student-t distribution.</td>
                  <td>T.INV(P, Degrees)</td>
                </tr>
                <tr>
                  <td>T.INV.2T</td>
                  <td>Returns inverse Student-t distribution, both-sided.</td>
                  <td>T.INV.2T(P, Degrees)</td>
                </tr>
                <tr>
                  <td>TINV2T</td>
                  <td>Returns inverse Student-t distribution, both-sided.</td>
                  <td>TINV2T(P, Degrees)</td>
                </tr>
                <tr>
                  <td>TTEST</td>
                  <td>Returns t-test value for a dataset.</td>
                  <td>TTEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>T.TEST</td>
                  <td>Returns t-test value for a dataset.</td>
                  <td>T.TEST(Array1, Array2)</td>
                </tr>
                <tr>
                  <td>VAR</td>
                  <td>Returns variance of a sample.</td>
                  <td>VAR(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VARA</td>
                  <td>Returns variance of a sample.</td>
                  <td>VARA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VARP</td>
                  <td>Returns variance of a population.</td>
                  <td>VARP(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VAR.P</td>
                  <td>Returns variance of a population.</td>
                  <td>VAR.P(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VARPA</td>
                  <td>Returns variance of a population.</td>
                  <td>VARPA(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VAR.S</td>
                  <td>Returns variance of a sample.</td>
                  <td>VAR.S(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>VARS</td>
                  <td>Returns variance of a sample.</td>
                  <td>VARS(Value1, Value2, ... Value30)</td>
                </tr>
                <tr>
                  <td>WEIBULL</td>
                  <td>Returns density of Weibull distribution.</td>
                  <td>WEIBULL(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>WEIBULL.DIST</td>
                  <td>Returns density of Weibull distribution.</td>
                  <td>WEIBULL.DIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>WEIBULLDIST</td>
                  <td>Returns density of Weibull distribution.</td>
                  <td>WEIBULLDIST(Number1, Number2, Number3, Boolean)</td>
                </tr>
                <tr>
                  <td>Z.TEST</td>
                  <td>Returns z-test value for a dataset.</td>
                  <td>Z.TEST(Array, X[, Sigma])</td>
                </tr>
                <tr>
                  <td>ZTEST</td>
                  <td>Returns z-test value for a dataset.</td>
                  <td>ZTEST(Array, X[, Sigma])</td>
                </tr>
              </tbody>
            </table>
            <h3 id="text">
              <a href="#text" className="header-anchor">
                #
              </a>{' '}
              Text
            </h3>
            <table>
              <thead>
                <tr>
                  <th>Function ID</th>
                  <th>Description</th>
                  <th>Syntax</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>CHAR</td>
                  <td>Converts a number into a character according to the current code table.</td>
                  <td>CHAR(Number)</td>
                </tr>
                <tr>
                  <td>CLEAN</td>
                  <td>Returns text that has been "cleaned" of line breaks and other non-printable characters.</td>
                  <td>CLEAN("Text")</td>
                </tr>
                <tr>
                  <td>CODE</td>
                  <td>Returns a numeric code for the first character in a text string.</td>
                  <td>CODE("Text")</td>
                </tr>
                <tr>
                  <td>CONCATENATE</td>
                  <td>Combines several text strings into one string.</td>
                  <td>CONCATENATE("Text1", ..., "Text30")</td>
                </tr>
                <tr>
                  <td>EXACT</td>
                  <td>Returns TRUE if both text strings are exactly the same.</td>
                  <td>EXACT(Text, Text)</td>
                </tr>
                <tr>
                  <td>FIND</td>
                  <td>Returns the location of one text string inside another.</td>
                  <td>FIND( "Text1", "Text2"[, Number])</td>
                </tr>
                <tr>
                  <td>LEFT</td>
                  <td>Extracts a given number of characters from the left side of a text string.</td>
                  <td>LEFT("Text", Number)</td>
                </tr>
                <tr>
                  <td>LEN</td>
                  <td>Returns length of a given text.</td>
                  <td>LEN("Text")</td>
                </tr>
                <tr>
                  <td>LOWER</td>
                  <td>Returns text converted to lowercase.</td>
                  <td>LOWER(Text)</td>
                </tr>
                <tr>
                  <td>MID</td>
                  <td>Returns substring of a given length starting from Start_position.</td>
                  <td>MID(Text, Start_position, Length)</td>
                </tr>
                <tr>
                  <td>PROPER</td>
                  <td>Capitalizes words given text string.</td>
                  <td>PROPER("Text")</td>
                </tr>
                <tr>
                  <td>REPLACE</td>
                  <td>Replaces substring of a text of a given length that starts at given position.</td>
                  <td>REPLACE(Text, Start_position, Length, New_text)</td>
                </tr>
                <tr>
                  <td>REPT</td>
                  <td>Repeats text a given number of times.</td>
                  <td>REPT("Text", Number)</td>
                </tr>
                <tr>
                  <td>RIGHT</td>
                  <td>Extracts a given number of characters from the right side of a text string.</td>
                  <td>RIGHT("Text", Number)</td>
                </tr>
                <tr>
                  <td>SEARCH</td>
                  <td>Returns the location of Search_string inside Text. Case-insensitive. Allows the use of wildcards.</td>
                  <td>SEARCH(Search_string, Text[, Start_position])</td>
                </tr>
                <tr>
                  <td>SPLIT</td>
                  <td>
                    Divides the provided text using the space character as a separator and returns the substring at the zero-based position
                    specified by the second argument.
                    <br />
                    <code>SPLIT("Lorem ipsum", 0) -&gt; "Lorem"</code>
                    <br />
                    <code>SPLIT("Lorem ipsum", 1) -&gt; "ipsum"</code>
                  </td>
                  <td>SPLIT(Text, Index)</td>
                </tr>
                <tr>
                  <td>SUBSTITUTE</td>
                  <td>
                    Returns string where occurrences of Old_text are replaced by New_text. Replaces only specific occurrence if last
                    parameter is provided.
                  </td>
                  <td>SUBSTITUTE(Text, Old_text, New_text, [Occurrence])</td>
                </tr>
                <tr>
                  <td>T</td>
                  <td>Returns text if given value is text, empty string otherwise.</td>
                  <td>T(Value)</td>
                </tr>
                <tr>
                  <td>TEXT</td>
                  <td>
                    Converts a number into text according to a given format.
                    <br />
                    <br />
                    By default, accepts the same formats that can be passed to the{' '}
                    <a href="/api/interfaces/configparams.html#dateformats" className="">
                      <code>dateFormats</code>
                    </a>{' '}
                    option, but can be further customized with the{' '}
                    <a href="/api/interfaces/configparams.html#stringifydatetime" className="">
                      <code>stringifyDateTime</code>
                    </a>{' '}
                    option.
                  </td>
                  <td>TEXT(Number, Format)</td>
                </tr>
                <tr>
                  <td>TRIM</td>
                  <td>Strips extra spaces from text.</td>
                  <td>TRIM("Text")</td>
                </tr>
                <tr>
                  <td>UNICHAR</td>
                  <td>Returns the character created by using provided code point.</td>
                  <td>UNICHAR(Number)</td>
                </tr>
                <tr>
                  <td>UNICODE</td>
                  <td>Returns the Unicode code point of a first character of a text.</td>
                  <td>UNICODE(Text)</td>
                </tr>
                <tr>
                  <td>UPPER</td>
                  <td>Returns text converted to uppercase.</td>
                  <td>UPPER(Text)</td>
                </tr>
              </tbody>
            </table>
            <p
              style={{
                margin: '24px 0 0 0',
              }}
            >
              <sup>[1]</sup> The return value of this function is compliant with the OpenDocument standard, but the return type is not.
            </p>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
}
