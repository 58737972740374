export async function handleFetchErrorResponse(message: string, res: Response) {
  if (!res.ok) {
    const error = new Error(message);
    // Attach extra info to the error object.
    try {
      // @ts-ignore
      error.info = await res.text();
    } catch (err) {}

    // @ts-ignore
    error.status = res.status;

    throw error;
  }
}
