import * as _ from 'lodash';
import { BoardColumnConfig, TemplateConfig } from './types';

function colorNameToHex(color: string) {
  var colors = {
    grass_green: '#377d51',
    'done-green': '#5ac57d',
    'bright-green': '#a8d24b',
    saladish: '#c7b756',
    egg_yolk: '#f7cd46',
    working_orange: '#f1af54',
    'dark-orange': '#ed6e40',
    peach: '#f3b1af',
    sunset: '#ee7d79',
    'stuck-red': '#d1515f',
    'dark-red': '#ad3e56',
    sofia_pink: '#ea3989',
    lipstick: '#ec66c0',
    bubble: '#eea5ed',
    purple: '#9961d5',
    dark_purple: '#724dca',
    berry: '#763f86',
    dark_indigo: '#3b178e',
    indigo: '#5659d7',
    navy: '#2e4f8d',
    'bright-blue': '#699af5',
    'dark-blue': '#3b84bb',
    aquamarine: '#73cac5',
    'chili-blue': '#80cafa',
    river: '#75a0ba',
    winter: '#9eadbc',
    explosive: '#c4c4c4',
    american_gray: '#808080',
    blackish: '#333333',
    brown: '#79554a',
    orchid: '#cc79ae',
    tan: '#a9977e',
    sky: '#afe1f4',
    coffee: '#b48472',
    royal: '#4275de',
    teal: '#2c5962',
    lavender: '#baa9f4',
    steel: '#adbee5',
    lilac: '#9d99b7',
    pecan: '#533f3f',
  };

  if (typeof colors[color] != 'undefined') return colors[color];

  return false;
}

function removeHash(color) {
  return color.replace('#', '');
}

export function convertConfigToSheetjs(config: BoardColumnConfig) {
  let cellStyles: Record<string, any> = {};

  if (config.cell_bg_color) {
    const convertedColor = colorNameToHex(config.cell_bg_color);

    if (!cellStyles.fill) {
      cellStyles.fill = {};
    }

    cellStyles.fill.fgColor = {
      rgb: convertedColor ? removeHash(convertedColor) : removeHash(config.cell_bg_color),
    };
  }

  if (config.cell_font_size) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.sz = config.cell_font_size;
  }

  if (config.cell_font_type) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.name = config.cell_font_type;
  }

  if (config.cell_font_color) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    const convertedColor = colorNameToHex(config.cell_font_color);

    cellStyles.font.color = {
      rgb: convertedColor ? removeHash(convertedColor) : removeHash(config.cell_font_color),
    };
  }

  if (config.cell_font_bold) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.bold = true;
  }

  if (config.cell_font_italic) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.italic = true;
  }

  if (config.cell_font_cancelline) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.strike = true;
  }

  if (config.cell_font_underline) {
    if (!cellStyles.font) {
      cellStyles.font = {};
    }

    cellStyles.font.underline = true;
  }

  if (config.cell_horizontal_alignment) {
    if (!cellStyles.alignment) {
      cellStyles.alignment = {};
    }

    cellStyles.alignment.horizontal = config.cell_horizontal_alignment;
  }

  return {
    s: cellStyles,
  };
}

export function getFieldGroups(config: TemplateConfig, removeDisabled = false) {
  if (!config) {
    return [];
  }

  return config.sheets.flatMap((sheet) => sheet.field_groups).filter((fieldGroup) => !removeDisabled || !fieldGroup.disable);
}

export function getReferencedBoardIds(config: TemplateConfig) {
  if (!config) {
    return [];
  }

  const fieldGroups = getFieldGroups(config);
  const fieldGroupBoardIds = fieldGroups.flatMap((fieldGroup) => [fieldGroup.board_id]).filter((boardId) => boardId);

  const allColumns = fieldGroups.flatMap((fieldGroup) => fieldGroup.columns);
  const columnBoardIds = allColumns.flatMap((column) => column.path.map((pathPart) => pathPart.board_id)).filter((boardId) => boardId);

  return _.uniq([...fieldGroupBoardIds, ...columnBoardIds]);
}

export function replaceBoardsFromContext(config: TemplateConfig, contextBoardId: number) {
  const newConfig = _.cloneDeep(config);
  const fieldGroups = getFieldGroups(newConfig).filter((fieldGroup) => fieldGroup.from_context);

  // TODO: handle subboards
  for (const fieldGroup of fieldGroups) {
    fieldGroup.board_id = contextBoardId;

    for (const column of fieldGroup.columns) {
      if (Array.isArray(column.path)) {
        for (const pathFragment of column.path) {
          if (pathFragment.board_id) {
            pathFragment.board_id = contextBoardId;
          }
        }
      }
    }
  }

  return newConfig;
}

export function removeFieldGroupsWithBoardIds(config: TemplateConfig, boardIds: number[]) {
  const newConfig = _.cloneDeep(config);

  for (const sheet of newConfig.sheets) {
    sheet.field_groups = sheet.field_groups.filter((fieldGroup) => !boardIds.includes(fieldGroup.board_id));
  }

  return newConfig;
}
