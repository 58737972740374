import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseString } from '../converters';
import { TagsColumnType } from '../../monday-api/api';

export type TagColumnValue = {
  text: string | null;
  tags: string[] | null;
  tag_ids: number[] | null;
};

const handler: ColumnHandler<TagsColumnType, TagColumnValue> = {
  type: 'tags',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['tag_id', 'tag_blank'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['tag_id', 'tag_blank'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const tags = (text || '')
      .split(',')
      .map((tag) => tag.trim())
      .filter((tag) => tag.length > 0);
    const tagIds = (_.get(value, 'tag_ids') || []) as number[];

    return {
      text,
      tags,
      tag_ids: tagIds,
    };
  },

  // currently not supported by monday
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
