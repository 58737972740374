import * as _ from 'lodash';
import { createBatchResolver } from '../utils/batch-resolver';
import { getUsers, User } from '@gorilla/common/src/lib/monday-api/api';
import { mondayClient } from '../../services/monday';

export const userBatchResolver = createBatchResolver<number, User>(async (userIds) => {
  const users = await getUsers(mondayClient, userIds);

  return _.keyBy(users, 'id');
});
