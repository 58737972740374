import React from 'react';
import { Heading, Box, Flex } from 'monday-ui-react-core';

interface ErrorScreenProps {
  message: string;
}

export function ErrorScreen({ message }: ErrorScreenProps) {
  return (
    <Box>
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'calc(100vh - 40px)',
        }}
      >
        <Flex align={Flex.align?.CENTER} justify={Flex.justify?.CENTER} direction={Flex.directions?.ROW}>
          <div
            style={{
              marginTop: '64px',
              marginBottom: '64px',
              textAlign: 'center',
            }}
          >
            <img
              src="/emptystate.svg"
              alt=""
              style={{
                marginBottom: '20px',
                width: '400px',
              }}
            />
            <Heading type={Heading.types?.h2 as any} value={message} />
          </div>
        </Flex>
      </div>
    </Box>
  );
}
