import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString } from '../converters';
import { PulseLogColumnType } from '../../monday-api/api';

export type PulseLogColumnValue = {
  text: string | null;
  date: string | null;
};

const handler: ColumnHandler<PulseLogColumnType, PulseLogColumnValue> = {
  type: 'creation_log',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_attributes_ids: ['created_by'],
      compare_value_ids: ['user_id'],
    },
    {
      operator: 'not_any_of',
      compare_attributes_ids: ['created_by'],
      compare_value_ids: ['user_id'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const date = text !== null ? new Date(text).toISOString() : null;

    return {
      text,
      date,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
