import React from 'react';
import _ from 'lodash';
import { EditableHeading, Button, Box, Flex, IconButton, Label } from 'monday-ui-react-core';
import MoveArrowLeft from 'monday-ui-react-core/dist/icons/MoveArrowLeft';
import { exportTo } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/spreadsheet-manager';
import { saveSpreadsheet, SpreadsheetValue } from '../services/spreadsheets';
import { createSignal } from '../services/signals';
import { monday } from '../services/monday';
import { createRandomId } from '../lib/utils';
import { luckysheetToSheetJs } from '../lib/luckysheet-utils';
import { ImportTemplateModal } from './ImportTemplateModal';
import { LuckysheetWhatsThisDialog } from './WhatsThisDialog';
import FeedbackBanner from '../shared/components/FeedbackBanner';

type SpreadsheetData = ReturnType<typeof exportTo>;

type LuckysheetProps = {
  spreadsheetId?: string;

  templateId?: string;

  title: string;

  initialData: SpreadsheetData;

  preview: Boolean;

  onSave?: (spreadsheetId?: string) => void;
};

type LuckysheetState = {
  newSpreadsheetId: string | null;
  newSpreadsheetTitle: string | null;
  isSaving: boolean | null;
  saveError: string | null;
  showImportTemplateModal: boolean;
  showExperimentalModal: boolean;
};

class Luckysheet extends React.Component<LuckysheetProps, LuckysheetState> {
  isCreated = false;

  constructor(props) {
    super(props);

    this.state = {
      newSpreadsheetId: null,
      newSpreadsheetTitle: null,
      isSaving: null,
      saveError: null,
      showImportTemplateModal: false,
      showExperimentalModal: false,
    };
  }

  componentDidMount() {
    const luckysheet = (window as any).luckysheet;
    const { initialData, title, preview } = this.props;

    if (this.isCreated) {
      return;
    }

    {
      preview
        ? luckysheet.create({
            container: 'luckysheet',
            title,
            plugins: [],
            data: _.cloneDeep(initialData),
            preview,
            showinfobar: false,
            showstatisticBar: false,
            showstatisticBarConfig: {
              count: false,
              view: false,
              zoom: false,
            },
            showsheetbarConfig: {
              add: true,
              menu: false,
              sheet: true,
            },
            showConfigWindowResize: false,
            showtoolbarConfig: {
              currencyFormat: false,
              chart: false,
              print: false,
              dataVerification: false,
              pivotTable: false,
              screenshot: false,
              protection: false,
              frozenMode: false,
              numberDecrease: false,
              numberIncrease: false,
              exportXlsx: false,
              // mergeCell: false,
            },
            cellRightClickConfig: {
              copy: false, // copy
              copyAs: false, // copy as
              paste: false, // paste
              insertRow: false, // insert row
              insertColumn: false, // insert column
              deleteRow: false, // delete the selected row
              deleteColumn: false, // delete the selected column
              deleteCell: false, // delete cell
              hideRow: false, // hide the selected row and display the selected row
              hideColumn: false, // hide the selected column and display the selected column
              rowHeight: false, // row height
              columnWidth: false, // column width
              clear: false, // clear content
              matrix: false, // matrix operation selection
              sort: false, // sort selection
              filter: false, // filter selection
              chart: false, // chart generation
              image: false, // insert picture
              link: false, // insert link
              data: false, // data verification
              cellFormat: false, // Set cell format
            },
            sheetRightClickConfig: {
              delete: false, //Delete
              copy: false, //Copy
              rename: false, //Rename
              color: false, //Change color
              hide: false, //Hide, unhide
              move: false, //Move to the left, move to the right
            },
          })
        : luckysheet.create({
            container: 'luckysheet',
            title,
            plugins: [],
            data: _.cloneDeep(initialData),
            preview,
            showinfobar: false,
            showstatisticBar: false,
            showstatisticBarConfig: {
              count: false,
              view: false,
              zoom: false,
            },
            showsheetbarConfig: {
              add: true,
              menu: false,
              sheet: true,
            },
            showConfigWindowResize: false,
            showtoolbarConfig: {
              currencyFormat: false,
              chart: false,
              print: false,
              dataVerification: false,
              pivotTable: false,
              screenshot: false,
              protection: false,
              frozenMode: false,
              numberDecrease: false,
              numberIncrease: false,
              exportXlsx: false,
              // mergeCell: false,
            },
          });
    }

    luckysheet.refreshFormula();
    this.isCreated = true;

    monday.execute('valueCreatedForUser');
    createSignal('spreadsheet_view');
  }

  componentWillUnmount(): void {
    //console.log('destroying spreadsheet');
    //const luckysheet = (window as any).luckysheet;
    //luckysheet.destroy();
  }

  save = async () => {
    const title: string | undefined = this.state.newSpreadsheetTitle || this.props.title;
    let spreadsheetId: string | null = this.props.spreadsheetId || this.state.newSpreadsheetId;
    let isNewSpreadsheet = false;

    if (!spreadsheetId) {
      spreadsheetId = createRandomId();
      isNewSpreadsheet = true;

      this.setState({
        newSpreadsheetId: spreadsheetId,
      });
    }

    const luckysheet = (window as any).luckysheet;
    const json = luckysheet.toJson();

    const value: SpreadsheetValue = {
      title,
      data: (json.data || []).map((d) => _.omit(d, ['data', 'visibledatacolumn', 'visibledatarow'])),
    };

    this.setState({
      isSaving: true,
      saveError: null,
    });

    try {
      // TODO: handle case where someone saved the spreadsheet in the meantime
      // currently people will just overwrite each others state

      await saveSpreadsheet(spreadsheetId, value);

      this.setState({
        isSaving: false,
      });

      if (isNewSpreadsheet) {
        createSignal('spreadsheet_create');
      } else {
        createSignal('spreadsheet_update');
      }

      if (this.props.onSave) {
        this.props.onSave(spreadsheetId);
      }
    } catch (err) {
      this.setState({
        isSaving: false,
        saveError: (err as Error).message,
      });
    }
  };

  exportToExcel = () => {
    const luckysheet = (window as any).luckysheet;

    createSignal('spreadsheet_excel_export');
    luckysheetToSheetJs(luckysheet);
  };

  completeTemplateImport = (result: any) => {
    const luckysheet = (window as any).luckysheet;
    const existingSheets = luckysheet.getAllSheets();
    const newSheetNames = result.data.map(({ name }) => name);
    let sheetsToDelete: any[] = [];

    if (result.mode === 'overwrite') {
      sheetsToDelete = existingSheets.filter((sheet) => newSheetNames.includes(sheet.name));

      sheetsToDelete.forEach((sheet, idx) => {
        luckysheet.setSheetName(`__delete_me_${idx}`, { order: sheet.order });
      });
    }

    if (result.mode === 'append' || result.mode === 'overwrite') {
      let nextOrder = Math.max(...existingSheets.map(({ order }) => order)) + 1;

      result.data.forEach((sheet) => {
        const order = nextOrder++;
        const newSheet = _.omit({ ...sheet, order }, ['index']);

        luckysheet.setSheetAdd({ sheetObject: newSheet, order });
      });
    }

    _.orderBy(sheetsToDelete, 'order')
      .reverse()
      .forEach((sheet) => {
        luckysheet.setSheetDelete({ order: sheet.order });
      });

    luckysheet.refreshFormula();
  };

  render() {
    const { title, preview, templateId } = this.props;
    const { isSaving, saveError, showImportTemplateModal, showExperimentalModal } = this.state || {};

    return (
      <>
        {showImportTemplateModal ? (
          <ImportTemplateModal
            show={showImportTemplateModal}
            onClose={(result) => {
              this.setState({
                showImportTemplateModal: false,
              });

              if (result) {
                this.completeTemplateImport(result);
              }
            }}
          />
        ) : null}

        <div
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {saveError ? (
            <div
              style={{
                backgroundColor: '#d83a52',
              }}
            >
              <Box padding={Box.paddings?.LARGE as any} paddingStart={Box.paddingStarts?.XL as any} paddingEnd={Box.paddingEnds?.XL as any}>
                <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      color: '#ffffff',
                    }}
                  >
                    Something went wrong, couldn't save the spreadsheet: {saveError}
                  </div>
                </Flex>
              </Box>
            </div>
          ) : (
            <>{/* <FeedbackBanner /> */}</>
          )}

          {/* HEADER */}

          <div
            style={{
              zIndex: 1000,
              position: 'relative',
              boxShadow: '0px 4px 6px -4px rgba(0, 0, 0, 0.1)',
            }}
          >
            <div
              style={{
                padding: '16px 24px 16px 16px',
                backgroundColor: '#ffffff',
              }}
            >
              <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.STRETCH as any}>
                <div
                  style={{
                    textAlign: 'left',
                  }}
                >
                  <Flex gap={10}>
                    <IconButton
                      kind={IconButton.kinds?.TERTIARY as any}
                      size={IconButton.sizes?.MEDIUM as any}
                      icon={MoveArrowLeft}
                      onClick={() => history.back()}
                    />
                    {!preview ? (
                      <>
                        <Button
                          kind={Button.kinds?.SECONDARY as any}
                          size={Button.sizes?.MEDIUM as any}
                          loading={!!isSaving}
                          onClick={() => {
                            this.save();
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          kind={Button.kinds?.SECONDARY as any}
                          size={Button.sizes?.MEDIUM as any}
                          onClick={() => {
                            this.setState({
                              showImportTemplateModal: true,
                            });
                          }}
                        >
                          Import
                        </Button>

                        <Button
                          kind={Button.kinds?.SECONDARY as any}
                          size={Button.sizes?.MEDIUM as any}
                          onClick={() => {
                            this.exportToExcel();
                          }}
                        >
                          Export
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </div>
                <div
                  style={{
                    flex: 1,
                    textAlign: 'center',
                  }}
                >
                  <Flex gap={10} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
                    <div>
                      {preview ? (
                        <h4 className="heading-component element-type-h4 size-large single-line-ellipsis">Spreadsheet preview</h4>
                      ) : (
                        <EditableHeading
                          type={EditableHeading.types?.h4 as any}
                          autoSize={true}
                          value={title ? title : 'New spreadsheet'}
                          onFinishEditing={(value) => {
                            this.setState({ newSpreadsheetTitle: value });
                          }}
                        />
                      )}
                    </div>
                    {!preview ? (
                      <div>
                        <Label text="Experimental" color={Label.colors.DARK} isAnimationDisabled={true} />
                      </div>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </div>
                <div
                  style={{
                    width: preview ? 'auto' : '300px',
                    textAlign: 'right',
                  }}
                >
                  <Flex gap={10} justify={Flex.justify?.END}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        minHeight: '40px',
                      }}
                    >
                      <LuckysheetWhatsThisDialog />
                    </div>
                  </Flex>
                </div>
              </Flex>
            </div>
            {/* <Divider withoutMargin /> */}
          </div>

          {/* SPREADSHEET */}
        </div>

        <div
          style={{
            flex: 1,
            position: 'relative',
            //transform: preview ? 'translate3d(0,-29px,0)' : 'none',
          }}
        >
          <div
            id="luckysheet"
            className={preview ? 'isPreview' : ''}
            style={{
              position: 'absolute',
              top: 0,
              bottom: 0,
              //bottom: preview ? '-29px' : 0,
              left: 0,
              margin: 0,
              padding: 0,
              width: '100%',
            }}
          />
        </div>
      </>
    );
  }
}

export default Luckysheet;
