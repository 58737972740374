import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseNumber, parseDatetime, parseString } from '../converters';
import { HourColumnType } from '../../monday-api/api';

export type HourColumnValue = {
  text: string | null; // e.g. '08:00 AM'
  hour: number | null;
  minute: number | null;
  changed_at: string | null;
};

const handler: ColumnHandler<HourColumnType, HourColumnValue> = {
  type: 'hour',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['early_morning', 'morning', 'afternoon', 'evening', 'night'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['early_morning', 'morning', 'afternoon', 'evening', 'night'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const hour = parseNumber(_.get(value, 'hour'));
    const minute = parseNumber(_.get(value, 'minute'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      hour,
      minute,
      changed_at: changedAt,
    };
  },

  getFormulaValue: (engineValue) => {
    if (engineValue.hour !== null && engineValue.minute !== null) {
      return `${_.padStart(`${engineValue.hour}`, 2, '0')}:${_.padStart(`${engineValue.minute}`, 2, '0')}`;
    } else {
      return '';
    }
  },
};

export default handler;
