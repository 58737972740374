import * as _ from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { extractErrorText } from '@gorilla/common/src/lib/monday-api/common';
import * as Sentry from '@sentry/react';

export function handleErrors(fn: Function) {
  return async (...args: any[]) => {
    try {
      return await fn(...args);
    } catch (err) {
      if (!_.get(err, 'do_not_track')) {
        const extra: Record<string, any> = {};

        if (_.get(err, 'info')) {
          extra.info = err.info;
        }

        if (_.get(err, 'status')) {
          extra.status = err.status;
        }

        Sentry.captureException(err, extra);
      }

      console.error(err);
      enqueueSnackbar(extractErrorText(err), {
        variant: 'error',
        autoHideDuration: 8000,
        anchorOrigin: { vertical: 'top', horizontal: 'center' },
      });
      throw err;
    }
  };
}

export function createRandomId() {
  return btoa(Math.random().toString()).substring(5, 15);
}

export function createSheetName(existingSheetNames: string[]) {
  // create a new sheet name based on the existing sheet names
  // example existing sheet names: Sheet1, Sheet 2, Sheet-3, sheet (4), Sheet 5
  // example new sheet name: Sheet6

  // get the highest number from the existing sheet names
  const highestNumber = existingSheetNames
    .map((name) => name.match(/\d+/g))
    .filter((match): match is RegExpMatchArray => match !== null)
    .map((match) => parseInt(match[0]))
    .reduce((highest, current) => Math.max(highest, current), 0);

  // create a new sheet name based on the highest number
  return `Sheet${highestNumber + 1}`;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function indexToAlpha(idx: number) {
  let result = '';
  let base = 'A'.charCodeAt(0);
  let numLetters = 26;

  while (idx >= 0) {
    let remainder = idx % numLetters;
    result = String.fromCharCode(base + remainder) + result;
    idx = Math.floor(idx / numLetters) - 1;
  }

  return result;
}
