import React from 'react';
import * as _ from 'lodash';
import { Box } from 'monday-ui-react-core';
import { AttentionBox } from 'monday-ui-react-core';

interface ErrorMessageProps {
  error: Error;
}

function ErrorMessage({ error }: ErrorMessageProps) {
  return (
    <Box>
      <div
        style={{
          display: 'flex',
          flexWrap: 'nowrap',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: 'calc(100vh - 40px)',
        }}
      >
        <div style={{ maxWidth: 600 }}>
          <AttentionBox title="Error" text={_.get(error, 'message') || 'unknown error occured'} type={AttentionBox.types?.DANGER} />
        </div>
      </div>
    </Box>
  );
}

export default ErrorMessage;
