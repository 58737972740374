import * as _ from 'lodash';
import { Board, BoardItem } from '../monday-api/api';
import { handlersByType, ColumnValueWrapped } from './column_handlers';
import { parseJson } from './converters';
import BoardRelationColumnHandler from './column_handlers/board-relation_column_handler';

export type EngineBoardItem = Omit<BoardItem, 'column_values'> & {
  values: ColumnValueWrapped[];
};

function createWrappedColumnValues(board: Board, boardItem: BoardItem): ColumnValueWrapped[] {
  return boardItem.column_values.flatMap((columnValue) => {
    const handler = handlersByType[columnValue.type];

    if (!handler) {
      if (columnValue.type !== 'subtasks') {
        // console.log(`Unknown column type: ${columnValue.type}`);
      }
      return [];
    }

    try {
      // TODO: fix typing
      const value = handler.convertValueFromMondayToEngine(columnValue as any, boardItem, board);

      const columnValueWrapped: ColumnValueWrapped = {
        column_id: columnValue.id,
        column_type: handler.type,
        value_type: 'success',
        value,
      } as ColumnValueWrapped;

      return [columnValueWrapped];
    } catch (err) {
      const columnValueWrapped: ColumnValueWrapped = {
        column_id: columnValue.id,
        column_type: handler.type,
        value_type: 'error',
        value: {
          value: `ERROR: ${(err as Error)?.message}`, // TODO: solve this in a better way
        },
      } as ColumnValueWrapped;

      return [columnValueWrapped];
    }
  });
}

export function boardItemMondayToBoardItemEngine(board: Board, boardItem: BoardItem): EngineBoardItem {
  const columnValuesWrapped = createWrappedColumnValues(board, boardItem);

  return {
    ..._.omit(boardItem, 'column_values'),
    values: columnValuesWrapped,
  };
}

export function getSubboardId(board: Board): number | null {
  const subtasksColumn = board.columns.find(({ type }) => type === 'subtasks');

  if (!subtasksColumn) {
    return null;
  }

  const settings = parseJson(subtasksColumn.settings_str);

  if (Array.isArray(settings.boardIds) && settings.boardIds.length) {
    return settings.boardIds[0];
  }

  return null;
}

export function getConnectedBoardIds(board: Board) {
  if (!board.columns) {
    return [];
  }

  return board.columns.flatMap((column) => {
    if (!BoardRelationColumnHandler.convertSettingsToEngine || column.type !== 'board_relation') {
      return [];
    }

    const settings = BoardRelationColumnHandler.convertSettingsToEngine(column.settings_str);

    return settings.boardIds;
  });
}
