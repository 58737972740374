import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';
import { parseJson } from '@gorilla/common/src/lib/engine/converters';
import { formulaExecuter } from '@gorilla/common/src/lib/engine/column_handlers/formula_column_handler';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'numbers',

  extractFieldsFromBoard: (board) => {
    return board.columns.flatMap((column) => {
      if (column.type === 'numbers') {
        return [
          {
            id: column.id,
            label: column.title,
          },
        ];
      }

      return [];
    });
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    const columnValue = boardItem.values.find(({ column_id }) => fieldId === column_id);

    if (!columnValue || columnValue.column_type !== 'numbers' || columnValue.value_type === 'error') {
      return null;
    }

    const boardColumn = board.columns.find((column) => column.id === fieldId);

    let settings;

    if (boardColumn?.settings_str) {
      settings = parseJson(boardColumn?.settings_str);
    }

    if (outputFormat === 'sheetjs') {
      let v = columnValue.value.value;
      const formatConfig: Record<string, any> = {};

      if (settings?.unit) {
        const unit = settings?.unit?.custom_unit || settings?.unit?.symbol;

        if (unit === '%') {
          formatConfig.z = '0.00%';

          if (v) {
            v = formulaExecuter(`${v}/100`) as number;
          }
        } else if (unit) {
          // TODO: this is untested so far
          formatConfig.z = settings?.unit?.direction === 'left' ? `"${unit}" 0.00` : `0.00 "${unit}"`;
        }
      }

      return {
        ...convertConfigToSheetjs(config),
        ...formatConfig,
        //v: v !== null ? v : undefined,
        v,
        t: 'n',
      };
    }
  },
};

export default handler;
