import * as _ from 'lodash';
import { getSessionToken } from './monday';
import { handleFetchErrorResponse } from './utils';

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function createSignal(type: string) {
  const authHeaders = await getAuthHeaders();

  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/signals`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify({ type }),
  });

  await handleFetchErrorResponse('failed to create signal', response);
}
