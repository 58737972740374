import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Modal,
  ModalHeader,
  ModalContent,
  Table,
  TableHeader,
  TableBody,
  TableHeaderCell,
  TableRow,
  TableCell,
  Label,
  Tooltip,
  Icon,
  Counter,
} from 'monday-ui-react-core';
import ActivityIcon from 'monday-ui-react-core/dist/icons/Activity';
import useMonday, { BETA_EXPIRE_DATE } from '../../hooks/use-monday';
import Username from './Username';

type Execution = {
  id: string;
  created_at: string;
  automation_name: string;
  completed_at: string;
  bucket: string;
  account_id: string;
  user_id: string;
  result?: {
    type: 'success' | 'error';
    message: '';
    report?: { message: string; type: 'info' | 'warning' | 'error'; created_at: number }[];
    attempts?: number;
    executionTime?: number;
    maxTimeout?: number;
  };
};

type ExecutionsResult = {
  scope: 'user' | 'account';
  executions: Execution[];
  executionsCount: number;
  executionsLimit: number;
};

type BucketsToLimit = Record<string, number>;

type ReportModalProps = {
  onClose: () => void;
  execution: Execution;
};

const msToNiceString = (ms: number) => {
  const seconds = Math.floor(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  const minutesStr = minutes ? `${minutes}m ` : '';
  const remainingSecondsStr = remainingSeconds || !minutes ? `${remainingSeconds}s` : '';

  return `${minutesStr} ${remainingSecondsStr}`;
};

function ReportModal({ execution, onClose }: ReportModalProps) {
  return (
    <Modal
      show={true}
      onClose={() => {
        onClose();
      }}
      width={'1100px'}
      alertDialog={false}
      data-testid="report-modal"
    >
      <ModalHeader
        closeButtonAriaLabel=""
        title="Execution Log"
        description="Automation details"
        icon={ActivityIcon}
        iconSize={32}
        hideCloseButton={true}
      />
      <ModalContent>
        <div style={{ overflow: 'hidden', paddingTop: '24px', paddingBottom: '8px', width: '100%', height: '399px' }}>
          {execution.result && execution.result.report ? (
            <Table
              errorState={<></>}
              emptyState={<>No Entries</>}
              columns={[
                {
                  id: 'created_at',
                  loadingStateType: 'medium-text',
                  title: 'Created At',
                  width: 200,
                },
                {
                  id: 'message',
                  loadingStateType: 'medium-text',
                  title: 'Message',
                },
                {
                  id: 'type',
                  loadingStateType: 'medium-text',
                  title: '',
                  width: 100,
                },
              ]}
              style={{
                width: 'calc(100% - 2px)',
              }}
            >
              <TableHeader>
                <TableHeaderCell title="Created" />
                <TableHeaderCell title="Message" />
                <TableHeaderCell title="" />
              </TableHeader>
              <TableBody>
                {execution.result.report.map((entry, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{new Date(entry.created_at).toLocaleString()}</TableCell>
                    <TableCell>{entry.message}</TableCell>
                    <TableCell>
                      {entry.type === 'error' ? <Label text="Error" color={Label.colors.NEGATIVE} isAnimationDisabled /> : null}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : null}
        </div>
      </ModalContent>
    </Modal>
  );
}

type ExecutionsModalProps = {
  scope: 'user' | 'account';
  executions: Execution[];
  onClose: () => void;
};

function ExecutionsModal({ executions, onClose, scope }: ExecutionsModalProps) {
  const [selectedExecution, setSelectedExecution] = useState<Execution | null>(null);

  return (
    <>
      {selectedExecution ? <ReportModal onClose={() => setSelectedExecution(null)} execution={selectedExecution} /> : null}

      <Modal
        show={true}
        onClose={() => {
          onClose();
        }}
        width={'1100px'}
        alertDialog={false}
        data-testid="executions-modal"
      >
        <ModalHeader
          closeButtonAriaLabel=""
          title="Execution history"
          description={`Automations that were executed ${scope === 'user' ? ' by you' : 'on your monday instance'} the last 90 days.`}
          icon={ActivityIcon}
          iconSize={32}
          hideCloseButton={true}
        />
        <ModalContent>
          <div style={{ overflow: 'hidden', paddingTop: '24px', paddingBottom: '8px', width: '100%', height: '399px' }}>
            <Table
              errorState={<></>}
              emptyState={<>No Entries</>}
              columns={[
                {
                  id: 'id',
                  loadingStateType: 'medium-text',
                  title: 'ID',
                  width: 100,
                },
                {
                  id: 'created_at',
                  loadingStateType: 'medium-text',
                  title: 'Created At',
                  width: 200,
                },
                {
                  id: 'type',
                  loadingStateType: 'medium-text',
                  title: 'Type',
                  width: 100,
                },
                {
                  id: 'duration',
                  loadingStateType: 'medium-text',
                  title: 'Duration',
                  width: 100,
                },
                {
                  id: 'duration-limit',
                  loadingStateType: 'medium-text',
                  title: 'Limit',
                  width: 100,
                },
                {
                  id: 'attempts',
                  loadingStateType: 'medium-text',
                  title: 'Attempts',
                  width: 100,
                },
                {
                  id: 'result',
                  loadingStateType: 'long-text',
                  title: 'Result',
                },
                {
                  id: 'details',
                  loadingStateType: 'medium-text',
                  title: 'Details',
                  width: 100,
                },
              ]}
              style={{
                width: 'calc(100% - 2px)',
              }}
            >
              <TableHeader>
                <TableHeaderCell title="ID" />
                <TableHeaderCell title="Created" />
                <TableHeaderCell title="Status" />
                <TableHeaderCell title="Duration" />
                <TableHeaderCell title="Limit" />
                <TableHeaderCell title="Attempts" />
                <TableHeaderCell title="Result" />
                <TableHeaderCell title="Details" />
              </TableHeader>
              <TableBody>
                {executions.map((execution) => {
                  return (
                    <TableRow key={execution.id}>
                      <TableCell>{execution.id.substring(0, 6)}</TableCell>
                      <TableCell>{new Date(execution.created_at).toLocaleString()}</TableCell>
                      <TableCell>
                        {execution.result ? (
                          execution.result.type === 'success' ? (
                            <Label text="Success" color={Label.colors.POSITIVE} isAnimationDisabled />
                          ) : (
                            <Label text="Error" color={Label.colors.NEGATIVE} isAnimationDisabled />
                          )
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell>{execution?.result?.executionTime ? msToNiceString(execution?.result?.executionTime) : 'N/A'}</TableCell>
                      <TableCell>{execution?.result?.maxTimeout ? msToNiceString(execution?.result?.maxTimeout) : 'N/A'}</TableCell>
                      <TableCell>{execution?.result?.attempts || 1}</TableCell>
                      <TableCell>
                        <div style={{ overflow: 'hidden', fontSize: '14px', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                          <Tooltip
                            content={execution.result ? execution.result.message : 'Automation is currently running...'}
                            zIndex={20000}
                          >
                            <>{execution.result ? execution.result.message : 'Automation is currently running...'}</>
                          </Tooltip>
                        </div>
                      </TableCell>
                      <TableCell>
                        {execution.result && execution.result.report ? (
                          <>
                            <Button
                              kind={Button.kinds.SECONDARY}
                              size={Button.sizes.XS}
                              onClick={() => {
                                setSelectedExecution(execution);
                              }}
                            >
                              Show
                            </Button>
                          </>
                        ) : (
                          'N/A'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}

type ExecutionsSummaryProps = {
  bucket: string;
  executionsResult: ExecutionsResult;
};

export function ExecutionsSummary({ executionsResult, bucket }: ExecutionsSummaryProps) {
  const [showDetails, setShowDetails] = useState(false);

  const { executions, executionsCount } = executionsResult;
  const { mondayContext, appMode, subscription } = useMonday();
  const executionsLeft = Math.max(executionsResult.executionsLimit - executionsResult.executionsCount, 0);

  let userName = <Username userId={parseInt(mondayContext?.user.id as any, 10)} />;

  /*
  const executionsErrors = executions.filter((execution) => {
    const createdAt = new Date(execution.created_at);
    const now = new Date();
    const error = execution.result?.type;
    return createdAt.getMonth() === now.getMonth() && createdAt.getFullYear() === now.getFullYear() && error === 'error';
  });
  */

  return (
    <>
      {showDetails ? (
        <ExecutionsModal scope={executionsResult.scope} executions={executions} onClose={() => setShowDetails(false)} />
      ) : null}
      <Box>
        <div
          style={{
            //backgroundColor: '#f6f7fb',
            backgroundColor: '#0073ea',
            //borderBottom: '1px solid rgba(208, 212, 228, 0.25)',
            color: '#ffffff',
          }}
        >
          <Box padding={Box.paddings?.LARGE as any} paddingStart={Box.paddingStarts?.XL as any} paddingEnd={Box.paddingEnds?.XL as any}>
            <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
              <div>
                👋&nbsp; Hi {userName}, you have <strong>{executionsLeft}</strong> export automations left this month.&nbsp;&nbsp;{' '}
                <a
                  href="https://getgorilla.app/products/spreadsheet-gorilla/automations"
                  target="_blank"
                  style={{ borderBottom: '1px solid #fff', textDecoration: 'none', color: 'inherit' }}
                >
                  Learn more
                </a>
              </div>
              <div
                style={{
                  position: 'absolute',
                  right: '24px',
                }}
              >
                <Button
                  kind={Button.kinds.TERTIARY}
                  color={Button.colors.ON_INVERTED_BACKGROUND}
                  size={Button.sizes.SMALL}
                  leftIcon={ActivityIcon}
                  onClick={() => {
                    setShowDetails(!showDetails);
                  }}
                >
                  Show history
                </Button>
              </div>
            </Flex>
          </Box>
        </div>
      </Box>
    </>
  );
}
