import React from 'react';
import { Flex, Box } from 'monday-ui-react-core';
import useMonday, { BETA_EXPIRE_DATE } from '../../hooks/use-monday';
import Username from './Username';

const formattedExpireDate = `${BETA_EXPIRE_DATE.getMonth() + 1}/${BETA_EXPIRE_DATE.getDate()}/${BETA_EXPIRE_DATE.getFullYear()}`;
const randomNum = Math.floor(Math.random() * 100);

type FeedbackBannerProps = {};

export default function FeedbackBanner({}: FeedbackBannerProps) {
  const { mondayContext, appMode, subscription } = useMonday();

  let userName = <Username userId={parseInt(mondayContext?.user.id as any, 10)} />;

  let callToAction: Array<React.ReactNode> = [];

  callToAction.push(
    <>
      👋&nbsp; Hi {userName}, you can now <strong>export</strong> and <strong>email</strong> this template as Excel spreadsheet with{' '}
      <strong>Automations</strong> periodically. &nbsp;&nbsp;{' '}
      <a
        href="https://getgorilla.app/products/spreadsheet-gorilla/automations"
        target="_blank"
        style={{ borderBottom: '1px solid #fff', textDecoration: 'none', color: 'inherit' }}
      >
        Learn more
      </a>
    </>
  );

  {
    /*
  if (appMode === 'trial') {
    return null;

    callToAction.push(
      <>
        👋&nbsp; Hi {userName}, thanks for trying out Spreadsheet Gorilla. Your free trial has started and will expire in{' '}
        {subscription?.days_left} days.
      </>
    );

  } else if (appMode === 'beta') {
    callToAction.push(
      <>
        👋&nbsp; Hi {userName}, the beta period has ended. Make sure to upgrade your subscription until {formattedExpireDate}, if you want
        to keep on using the app.
      </>
    );
  } else if (appMode === 'beta_expired') {
    callToAction.push(
      <>👋&nbsp; Hi {userName}, the beta period has ended. Please update your subscription if you want to keep on using the app.</>
    );
  } else if (appMode === 'trial_expired') {
    callToAction.push(
      <>👋&nbsp; Hi {userName}, the your trial has ended. Please update your subscription if you want to keep on using the app.</>
    );
  } else {
    callToAction.push(
      {/* 
        👋&nbsp; Hi {userName}, thanks for using Spreadsheet Gorilla. If you have questions, just{' '}
        <a href="mailto:hello@getgorilla.app" style={{ color: 'inherit' }}>
          contact us
        </a>{' '}
        anytime. We also would love to get your{' '}
        <a href="https://wkf.ms/3RMU0MH" target="_blank" style={{ color: 'inherit' }}>
          feedback
        </a>
        .
      </>
    );
  }
*/
  }

  return (
    <>
      <div
        style={{
          backgroundColor: '#0073ea',
          //borderBottom: '1px solid rgba(208, 212, 228, 0.25)',
          color: '#ffffff',
        }}
      >
        <Box padding={Box.paddings?.LARGE as any} paddingStart={Box.paddingStarts?.XL as any} paddingEnd={Box.paddingEnds?.XL as any}>
          <Flex gap={Flex.gaps?.MEDIUM as any} align={Flex.align?.CENTER as any} justify={Flex.justify?.CENTER as any}>
            <div
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                transform: 'translate3d(0,-2px,0)',
              }}
            >
              {callToAction[randomNum % callToAction.length]}
            </div>
            {/* 
            <div
              style={{
                whiteSpace: 'nowrap',
                color: '#ffffff',
              }}
            >
              <a href="https://wkf.ms/3RMU0MH" target="_blank" style={{ color: 'inherit' }}>
                Send feedback
              </a>
            </div>
            */}
          </Flex>
        </Box>
      </div>
      {/* 
      <div
        style={{
          height: '72px',
        }}
      ></div>
      */}
    </>
  );
}
