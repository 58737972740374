import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseDatetime, parseString, parseBoolean } from '../converters';
import { BooleanColumnType } from '../../monday-api/api';

export type BooleanColumnValue = {
  text: string | null;
  checked: boolean | null;
  changed_at: string | null;
};

const handler: ColumnHandler<BooleanColumnType, BooleanColumnValue> = {
  type: 'checkbox',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const checked = parseBoolean(_.get(value, 'checked'));
    const changedAt = parseDatetime(_.get(value, 'changed_at'));

    return {
      text,
      checked,
      changed_at: changedAt,
    };
  },

  convertValueFromEngineToUpdateValue: (engineValue) => {
    if (engineValue.checked === null) {
      return null;
    }

    return {
      checked: engineValue.checked,
    };
  },

  getFormulaValue: (engineValue) => !!engineValue.checked,
};

export default handler;
