import * as _ from 'lodash';
import { TemplateBasic, Template } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { getSessionToken } from './monday';
import { handleFetchErrorResponse } from './utils';

function traverseObjectAndMapValues(obj: any, fn: (key: string, value: any) => any) {
  return _.mapValues(obj, (value, key) => {
    if (_.isPlainObject(value)) {
      return traverseObjectAndMapValues(value, fn);
    }

    // handle arrays
    if (_.isArray(value)) {
      return value.map((item) => {
        if (_.isPlainObject(item)) {
          return traverseObjectAndMapValues(item, fn);
        }

        return item;
      });
    }

    return fn(key, value);
  });
}

function boardIdsToNumbers(obj: any) {
  return traverseObjectAndMapValues(obj, (key, value) => {
    if (key === 'board_id') {
      return _.toNumber(value);
    }

    return value;
  });
}

async function getAuthHeaders() {
  const sessionToken = await getSessionToken();

  return {
    Authorization: `${sessionToken}`,
  };
}

export async function getTemplates() {
  const authHeaders = await getAuthHeaders();
  // @ts-ignore
  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/templates`, {
    method: 'GET',
    headers: authHeaders,
  });

  await handleFetchErrorResponse('failed to fetch templates', response);

  return (await response.json()) as TemplateBasic[];
}

export async function getTemplate(templateId: number) {
  const authHeaders = await getAuthHeaders();
  // @ts-ignore
  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/templates/${templateId}`, {
    method: 'GET',
    headers: authHeaders,
  });

  await handleFetchErrorResponse('failed to fetch templates', response);

  const template = (await response.json()) as Template;

  if (template.config) {
    // in earlier versions of the backend, the board IDs were stored as strings
    // this is a workaround to convert them to numbers
    template.config = boardIdsToNumbers(template.config);
  }

  return template;
}

export async function deleteTemplate(templateId: number) {
  const authHeaders = await getAuthHeaders();
  // @ts-ignore
  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/templates/${templateId}`, {
    method: 'DELETE',
    headers: authHeaders,
  });

  await handleFetchErrorResponse('failed to delete template', response);
}

export async function createTemplate(template: Omit<Template, 'id' | 'creatorId' | 'accountId'>) {
  const authHeaders = await getAuthHeaders();
  // @ts-ignore
  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/templates`, {
    method: 'POST',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify(template),
  });

  await handleFetchErrorResponse('failed to create template', response);

  return await response.json();
}

export async function updateTemplate(template: Template) {
  const authHeaders = await getAuthHeaders();
  // @ts-ignore
  const response = await fetch(`${import.meta.env.VITE_APP_BACKEND_URL}/api/templates/${template.id}`, {
    method: 'PUT',
    headers: {
      ...authHeaders,
      'content-type': 'application/json',
    },
    body: JSON.stringify(_.pick(template, ['private', 'name', 'config'])),
  });

  await handleFetchErrorResponse('failed to update template', response);

  return await response.json();
}
