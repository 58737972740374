import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseJson, parseString } from '../converters';
import { WeekColumnType } from '../../monday-api/api';

export type WeekColumnValue = {
  text: string | null;
  start_date: string | null; // TODO: or Date?
  end_date: string | null; // TODO: or Date?
};

const handler: ColumnHandler<WeekColumnType, WeekColumnValue> = {
  type: 'week',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['this_week', 'next_weeks', 'past_weeks'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['this_week', 'next_weeks', 'past_weeks'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const value = parseJson(columnValue.value);
    const startDate = parseString(_.get(value, 'week.startDate'));
    const endDate = parseString(_.get(value, 'week.endDate'));

    return {
      text,
      start_date: startDate,
      end_date: endDate,
    };
  },

  // TODO: properly support this
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
