import React from 'react';
import useSWR from 'swr';
import { userBatchResolver } from '../hooks/use-user';

type UsernameProps = {
  userId: number;
};

export default function Username({ userId }: UsernameProps) {
  const {
    data: user,
    isLoading,
    error,
  } = useSWR(`/username/${userId}`, async () => userBatchResolver.resolveItem(userId), {
    revalidateIfStale: false,
    revalidateOnFocus: false,
  });

  if (isLoading) {
    return <span>loading...</span>;
  }

  if (error || !user) {
    return <span>User</span>;
  }

  let firstName = 'User';

  try {
    firstName = user.name.split(' ')[0];
  } catch (err) {}

  return <span>{firstName}</span>;
}
