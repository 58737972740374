import { MondayClientSdk } from 'monday-sdk-js/types/client-sdk.interface';
import * as _ from 'lodash';

type GraphqlVariables = Record<string, any>;

type MondayRequesterErrorResult = {
  type: 'error';

  errorType: 'unknown' | 'runtime' | 'graphql' | 'empty';
  errorText: string;
  errors?: any[];

  statusCode?: number;
  scope: string;

  graphqlQuery: string;
  graphqlVariables?: GraphqlVariables;
};

type MondayRequesterSuccessResult = {
  type: 'success';
  scope: string;
  graphqlQuery: string;
  graphqlVariables?: GraphqlVariables;
  data: any;
};

type MondayRequesterResult = MondayRequesterSuccessResult | MondayRequesterErrorResult;

export function extractErrorText(err: any) {
  let errorText;

  if (typeof err === 'string') {
    errorText = err;
  } else if (err instanceof Error) {
    errorText = err.message;
  } else if (typeof err === 'object' && err && 'message' in err && typeof err.message === 'string') {
    errorText = err.message;
  } else if (err?.toString) {
    errorText = err.toString();
  } else if (err) {
    try {
      errorText = JSON.stringify(err);
    } catch (e) {}
  }

  if (!errorText) {
    errorText = 'Unknown error';
  }

  return errorText;
}

export class MondayRequesterError extends Error {
  requesterErrorResult: MondayRequesterErrorResult;

  constructor(requesterErrorResult: MondayRequesterErrorResult) {
    super(`${requesterErrorResult.errorType} error: ${requesterErrorResult.errorText}`);

    this.name = 'MondayRequesterError';
    this.requesterErrorResult = requesterErrorResult;

    this.stack = new Error().stack;
  }
}

export class AbortedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AbortedError';

    this.stack = new Error().stack;
  }
}

type MondayRequester = (
  scope: string,
  query: string,
  variables?: GraphqlVariables,
  abortSignal?: AbortSignal,
) => Promise<MondayRequesterResult>;

export function createMondayFetchRequester(authToken: string, apiVersion = '2024-01'): MondayRequester {
  return async (scope, query, variables, abortSignal) => {
    try {
      const response = await fetch('https://api.monday.com/v2', {
        method: 'POST',
        signal: abortSignal,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
          ...(apiVersion ? { 'API-Version': apiVersion } : {}),
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });

      if (response.ok) {
        try {
          const result = (await response.json()) as any;

          if (result.errors) {
            return {
              type: 'error',
              errorType: 'graphql',
              errorText: `GraphQL errors: ${result.errors.map((error: any) => error.message).join(', ')}`,
              graphqlQuery: query,
              graphqlVariables: variables,
              errors: result.errors,
              scope,
            };
          } else {
            return {
              type: 'success',
              data: result.data,
              graphqlQuery: query,
              graphqlVariables: variables,
              scope,
            };
          }
        } catch (err) {
          return {
            type: 'error',
            errorType: 'runtime',
            errorText: `Failed to parse response as JSON`,
            graphqlQuery: query,
            graphqlVariables: variables,
            scope,
          };
        }
      }

      // TODO: handle aborts

      const errorText = await response.text();

      return {
        type: 'error',
        errorType: 'runtime',
        errorText,
        statusCode: response.status,
        graphqlQuery: query,
        graphqlVariables: variables,
        scope,
      };
    } catch (err) {
      return {
        type: 'error',
        errorType: 'unknown',
        errorText: extractErrorText(err),
        graphqlQuery: query,
        graphqlVariables: variables,
        scope,
      };
    }
  };
}

export function createMondaySdkRequester(monday: MondayClientSdk): MondayRequester {
  return async (scope, query, variables) => {
    try {
      const response = await monday.api(query, { variables });

      return {
        type: 'success',
        data: response.data,
        graphqlQuery: query,
        graphqlVariables: variables,
        scope,
      };
    } catch (err) {
      return {
        type: 'error',
        errorType: 'unknown',
        errorText: extractErrorText(err),
        graphqlQuery: query,
        graphqlVariables: variables,
        scope,
      };
    }
  };
}

type QueryHistoryEntry = {
  query: string;
  variables?: GraphqlVariables;
  requestStart: number;
  requestEnd: number;
  result: MondayRequesterResult;
  scope: string;
  complexity?: number;
};

type MondayClientOptions = {
  queryHistorySize?: number;
};

const mondayClientDefaultOptions: MondayClientOptions = {
  queryHistorySize: 20,
};

export function createMondayClient(requester: MondayRequester, options: MondayClientOptions = {}) {
  const { queryHistorySize } = { ...mondayClientDefaultOptions, ...options };

  let queryHistory: QueryHistoryEntry[] = [];

  const api = {
    async query(query: string, variables?: GraphqlVariables, abortSignal?: AbortSignal, scope = 'DEFAULT') {
      const requestStart = Date.now();
      const result = await requester(scope, query, variables, abortSignal);
      const requestEnd = Date.now();
      let complexity;

      if (result.type === 'success') {
        complexity = result.data?.complexity?.query as number | undefined;
      }

      queryHistory.unshift({
        query,
        variables,
        requestStart,
        requestEnd,
        result,
        scope,
        complexity,
      });

      queryHistory = queryHistory.slice(0, queryHistorySize);

      return result;
    },

    getQueryHistory() {
      return queryHistory;
    },

    scopedClient(scope: string) {
      return {
        ...api,

        query(query: string, variables?: GraphqlVariables, abortSignal?: AbortSignal) {
          return api.query(query, variables, abortSignal, scope);
        },
      };
    },
  };

  return api;
}

export type MondayClient = ReturnType<typeof createMondayClient>;

export function extractApiResult<T>(result: MondayRequesterSuccessResult, resultPath: string, dataMapper?: (arg: T) => T): T {
  const res = _.get(result.data, resultPath) as T;

  if (res === undefined) {
    const requesterErrorResult: MondayRequesterErrorResult = {
      type: 'error',
      errorType: 'empty',
      errorText: `Could not find result at path ${resultPath}`,
      graphqlQuery: result.graphqlQuery,
      graphqlVariables: result.graphqlVariables,
      scope: result.scope,
    };

    throw new MondayRequesterError(requesterErrorResult);
  }

  if (dataMapper) {
    return dataMapper(res);
  }

  return res;
}

export async function getAllResults<T>(
  fetcherFn: (page: number, limit: number) => Promise<T[]>,
  perPage: number,
  abortSignal?: AbortSignal,
): Promise<T[]> {
  let page = 0;
  let allResults: T[] = [];
  let resultsLen;

  do {
    const results = await fetcherFn(page, perPage);
    resultsLen = results.length;
    allResults.push(...results);
    page++;
  } while (resultsLen === perPage && !abortSignal?.aborted);

  if (abortSignal?.aborted) {
    throw new AbortedError('Aborted loading');
  }

  return allResults;
}
