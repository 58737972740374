import React from 'react';
import { useState } from 'react';
import { Button, Flex, TextField, Modal, ModalHeader, ModalContent, ModalFooter } from 'monday-ui-react-core';
import { Sheet as SheetType } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';

type EditWorksheetNameModalProps = {
  show: boolean;
  sheet: SheetType;
  onClose: (newSheetName?: string) => void;
};

export function EditWorksheetNameModal({ show, sheet, onClose }: EditWorksheetNameModalProps) {
  const [newSheetName, setNewSheetName] = useState(sheet.name);

  return (
    <Modal
      show={show}
      onClose={() => {
        onClose();
      }}
      width={Modal.width?.DEFAULT as any}
      alertDialog={false}
    >
      <ModalHeader title="Edit worksheet name" hideCloseButton={true} />
      <ModalContent>
        <TextField value={newSheetName} size={TextField.sizes?.LARGE} onChange={setNewSheetName} />
      </ModalContent>
      <ModalFooter>
        <Flex gap={12} justify={Flex.justify?.END}>
          <Button
            kind={Button.kinds?.TERTIARY as any}
            size={Button.sizes?.MEDIUM as any}
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!newSheetName}
            kind={Button.kinds?.PRIMARY as any}
            size={Button.sizes?.MEDIUM as any}
            onClick={() => {
              onClose(newSheetName);
            }}
          >
            Confirm
          </Button>
        </Flex>
      </ModalFooter>
    </Modal>
  );
}
