import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import * as XLSX from 'xlsx-js-style';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import {
  Divider,
  TextField,
  Modal,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Button,
  Box,
  Flex,
  Icon,
  Loader,
  Heading,
  Label,
} from 'monday-ui-react-core';
import Dropdown from 'monday-ui-react-core/dist/Dropdown';
import CheckIcon from 'monday-ui-react-core/dist/icons/Check';
import AlertIcon from 'monday-ui-react-core/dist/icons/Alert';
import { OutputFormat, TemplateBasic } from '@gorilla/spreadsheet-shared/src/lib/spreadsheet-manager/types';
import { useSpreadsheetLoader } from '../hooks/use-spreadsheet-loader';
import { useSpreadsheetTemplate } from '../hooks/use-spreadsheet-template';
import { createRandomId } from '../lib/utils';
import { saveSpreadsheet } from '../services/spreadsheets';
import { getTemplates } from '../services/templates';

interface CreateSpreadsheetModalContext {
  templateId?: number;
  formatId?: string;
  spreadsheetName?: string;
  sheetId?: string;
  excelTemplateFile?: any;
  disableTemplateSelection?: boolean;
}

type Path = string[];

type FormatConfigurationProps = {
  ctx: CreateSpreadsheetModalContext;
  onCtxChange: (ctx: CreateSpreadsheetModalContext) => void;
};

async function readExcelTemplate(excelTemplateFile: any) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsBinaryString(excelTemplateFile);

    reader.onload = (e) => {
      try {
        const data = (e.target as any).result;
        const workbook = XLSX.read(data, { type: 'binary' });
        resolve(workbook);
      } catch (err) {
        reject(err);
      }
    };

    reader.onerror = (err) => {
      reject(err);
    };
  });
}
function InAppConfiguration({ ctx, onCtxChange }: FormatConfigurationProps) {
  return (
    <Box paddingTop={Box.paddingTops?.MEDIUM as any}>
      <TextField
        value={ctx.spreadsheetName}
        onChange={(newSpreadsheetName) => {
          onCtxChange({ ...ctx, spreadsheetName: newSpreadsheetName });
        }}
        size={TextField.sizes?.LARGE}
        placeholder="Spreadsheet name"
        autoFocus
        className="textfield--smallfont"
      />
    </Box>
  );
}

function ExcelConfiguration({ ctx, onCtxChange }: FormatConfigurationProps) {
  useEffect(() => {
    const excelTemplateFileInput = document.getElementById('excel_template_file');
    if (excelTemplateFileInput && ctx.excelTemplateFile) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(ctx.excelTemplateFile);
      (excelTemplateFileInput as any).files = dataTransfer.files;
    }
  }, []);

  return (
    <Box paddingTop={Box.paddingTops?.MEDIUM as any}>
      <div
        style={{
          padding: '16px',
          border: '1px dotted #c3c6d4',
          borderRadius: '4px',
          backgroundColor: '#fff',
        }}
      >
        <input
          id="excel_template_file"
          className="filepicker"
          type="file"
          accept=".xlsx"
          onChange={() => {
            const excelTemplateFileInput = document.getElementById('excel_template_file');
            const excelTemplateFile = (excelTemplateFileInput as any).files[0];

            onCtxChange({ ...ctx, excelTemplateFile: excelTemplateFile });
          }}
        />
        <div
          style={{
            color: '#afb0bd',
          }}
        >
          Optionally choose a .xlsx file to merge with data from your selected template
        </div>
      </div>
    </Box>
  );
}

function SheetSelectConfiguration({ ctx, onCtxChange }: FormatConfigurationProps) {
  const { isLoading, template } = useSpreadsheetTemplate(ctx.templateId);

  const options = template?.config.sheets.map((sheet) => ({
    label: sheet.name,
    value: sheet.id,
  }));

  let selectedOption = options?.find((option) => option.value === ctx.sheetId);

  if (!selectedOption && options?.length) {
    selectedOption = options[0];
  }

  return (
    <Box paddingTop={Box.paddingTops?.MEDIUM as any}>
      <>
        <Dropdown
          disabled={!ctx.templateId}
          isLoading={isLoading}
          onOptionSelect={(ev: any) => {
            onCtxChange({ ...ctx, sheetId: ev.value });
          }}
          value={selectedOption}
          options={options}
          placeholder="Select sheet"
          insideOverflowContainer
          clearable={false}
          size={Dropdown.size.LARGE}
        />
      </>
    </Box>
  );
}

const formats = [
  {
    id: 'excel',
    label: 'Export Excel',
    description: 'and download the file',
    hint: '',
    info: '',
    outputFormat: 'sheetjs',
    configurationComponent: null,
    creationHandler: async (ctx: CreateSpreadsheetModalContext, data: any) => {
      let templateWorkbook: any = null;

      if (ctx.excelTemplateFile) {
        try {
          templateWorkbook = await readExcelTemplate(ctx.excelTemplateFile);
        } catch (err) {
          console.log('failed to load excel template', err);
        }
      }

      if (templateWorkbook) {
        Object.assign(templateWorkbook.Sheets, data.Sheets);
        templateWorkbook.SheetNames = _.uniq([...templateWorkbook.SheetNames, ...data.SheetNames]);
        XLSX.writeFile(templateWorkbook, 'out.xlsx');
      } else {
        XLSX.writeFile(data, 'out.xlsx');
      }
    },
    createButtonLabel: 'Download',
  },
  {
    id: 'csv',
    label: 'Export CSV',
    description: 'and download the file',
    hint: '',
    info: '',
    outputFormat: 'sheetjs',
    configurationComponent: SheetSelectConfiguration,
    creationHandler: async (ctx: CreateSpreadsheetModalContext, data: any) => {
      let sheetId = ctx.sheetId || data.Sheets[data.SheetNames[0]]['!sheetId'];
      const sheet = Object.values(data.Sheets).find((sheet) => (sheet as any)['!sheetId'] === sheetId);
      const sheetName = (sheet as any)['!sheetName'];
      XLSX.writeFile(data, `${sheetName}.csv`, { sheet: sheetName });
    },
    createButtonLabel: 'Download',
  },
  {
    id: 'html',
    label: 'Export HTML',
    description: 'and download the file',
    hint: '',
    info: '',
    outputFormat: 'sheetjs',
    configurationComponent: SheetSelectConfiguration,
    creationHandler: async (ctx: CreateSpreadsheetModalContext, data: any) => {
      let sheetId = ctx.sheetId || data.Sheets[data.SheetNames[0]]['!sheetId'];
      const sheet = Object.values(data.Sheets).find((sheet) => (sheet as any)['!sheetId'] === sheetId);
      const sheetName = (sheet as any)['!sheetName'];
      XLSX.writeFile(data, `${sheetName}.html`, { sheet: sheetName });
    },
    createButtonLabel: 'Download',
  },
  {
    id: 'in-app',
    label: 'Save spreadsheet',
    description: 'to the current board view',
    hint: 'Be aware that saved spreadsheets are experimental, with limited customer support.',
    info: 'Experimental',
    configurationComponent: InAppConfiguration,
    isConfigurationValid: (ctx: CreateSpreadsheetModalContext) => ctx.spreadsheetName,
    outputFormat: 'luckysheet',
    creationHandler: async (ctx: CreateSpreadsheetModalContext, data: any, navigate: NavigateFunction) => {
      const value = {
        title: ctx.spreadsheetName || 'Untitled',
        data: data,
      };

      const id = createRandomId();
      await saveSpreadsheet(id, value);

      navigate(`/spreadsheet/${id}/render`);
    },
    createButtonLabel: 'Create',
  },
];

type SummaryStepProps = {
  templates: TemplateBasic[];
  onClose: () => void;
  ctx: CreateSpreadsheetModalContext;
  onCtxChange: (ctx: CreateSpreadsheetModalContext) => void;
  onPathChange: (path: Path) => void;
  path: Path;
};

function SummaryStep({ ctx, path, onClose, onCtxChange, onPathChange, templates }: SummaryStepProps) {
  const navigate = useNavigate();
  const template = templates.find((t) => t.id === ctx.templateId);
  const format = formats.find((format) => format.id === ctx.formatId);
  const { loadingMessage, data, error } = useSpreadsheetLoader(ctx ? ctx.templateId : undefined, format?.outputFormat as OutputFormat);

  const { trigger: createSpreadsheetTrigger, isMutating: isCreatingSpreadsheet } = useSWRMutation('/api/create-spreadsheet', async () => {
    if (format && format.creationHandler) {
      return await format.creationHandler(ctx, data, navigate);
    }
  });

  return (
    <>
      <ModalContent>
        <Box
          border={Box.borders?.DEFAULT as any}
          borderColor={Box.borderColors?.LAYOUT_BORDER_COLOR as any}
          rounded={Box.roundeds?.SMALL as any}
        >
          <>
            {format && format.id === 'in-app' ? (
              <>
                <Box padding={Box.paddings?.MEDIUM as any}>
                  <Flex gap={8}>
                    <div>
                      <strong>Name:</strong> {ctx.spreadsheetName}
                    </div>
                    <div className="statusChecked">
                      <Icon icon={CheckIcon} iconSize={10} />
                    </div>
                  </Flex>
                </Box>
                <Divider withoutMargin={true} />
              </>
            ) : (
              <></>
            )}
            <Box padding={Box.paddings?.MEDIUM as any}>
              <Flex gap={8}>
                <div>
                  <strong>Template:</strong> {template?.name}
                </div>
                <div className="statusChecked">
                  <Icon icon={CheckIcon} iconSize={10} />
                </div>
              </Flex>
            </Box>
            <Divider withoutMargin={true} />
            <Box padding={Box.paddings?.MEDIUM as any}>
              <Flex gap={8}>
                <div>
                  <strong>Items:</strong> {error ? error.message : loadingMessage}
                </div>
                <div style={{ marginLeft: 'auto' }}>
                  <Flex gap={8}>
                    {error ? (
                      <div className="statusChecked error">
                        <Icon icon={AlertIcon} iconSize={10} />
                      </div>
                    ) : (
                      <></>
                    )}

                    {data ? (
                      <div className="statusChecked">
                        <Icon icon={CheckIcon} iconSize={10} />
                      </div>
                    ) : (
                      <Loader size={Loader.sizes?.XS} color={Loader.colors?.PRIMARY} />
                    )}
                  </Flex>
                </div>
              </Flex>
            </Box>
          </>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                kind={Button.kinds?.SECONDARY as any}
                onClick={() => {
                  onPathChange(['configuration']);
                }}
              >
                Prev
              </Button>
              <Button
                disabled={!data}
                loading={isCreatingSpreadsheet}
                onClick={async () => {
                  await createSpreadsheetTrigger();
                  onClose();
                }}
              >
                {format?.createButtonLabel || 'Create'}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

type ConfigurationStepProps = {
  onClose: () => void;
  ctx: CreateSpreadsheetModalContext;
  onCtxChange: (ctx: CreateSpreadsheetModalContext) => void;
  onPathChange: (path: Path) => void;
  path: Path;
  templates: TemplateBasic[];
  showPrevButton: boolean;
};

function ConfigurationStep({ ctx, path, onClose, onCtxChange, onPathChange, templates, showPrevButton }: ConfigurationStepProps) {
  const templateOptions: { label: string | React.ReactNode; value: number }[] = _.orderBy(templates, 'name').map((template) => ({
    label: template.name,
    value: template.id,
  }));

  const format = formats.find((format) => format.id === ctx.formatId);
  const selectedTemplateOption = templateOptions.find((option) => option.value === ctx.templateId);
  const FormatConfiguration = format && format.configurationComponent ? format.configurationComponent : null;
  const formatConfiguration = FormatConfiguration ? <FormatConfiguration ctx={ctx} onCtxChange={onCtxChange} /> : null;

  return (
    <>
      <ModalContent>
        {format && format.hint !== '' ? (
          <Box>
            <>{format.hint}</>
          </Box>
        ) : (
          <></>
        )}
        <Box paddingTop={Box.paddingTops?.MEDIUM as any}>
          <Dropdown
            disabled={!!ctx.disableTemplateSelection}
            onOptionSelect={(ev: any) => {
              onCtxChange({ ...ctx, templateId: ev.value, sheetId: undefined });
            }}
            options={templateOptions}
            placeholder="Select template"
            insideOverflowContainer
            value={selectedTemplateOption}
            clearable={false}
            size={Dropdown.size.LARGE}
          />
        </Box>
        <>{formatConfiguration}</>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <div style={{ color: '#afb0bd' }}>
            {format?.label || ''} {format?.description || ''}
          </div>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <>
                {showPrevButton ? (
                  <Button
                    kind={Button.kinds?.SECONDARY as any}
                    onClick={() => {
                      onPathChange([]);
                    }}
                  >
                    Prev
                  </Button>
                ) : null}
              </>

              <Button
                disabled={!ctx.templateId || (format && format.isConfigurationValid && !format.isConfigurationValid(ctx))}
                onClick={() => {
                  onPathChange(['configuration', 'summary']);
                }}
              >
                Next
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

type FormatSelectionStepProps = {
  onClose: () => void;
  ctx: CreateSpreadsheetModalContext;
  onCtxChange: (ctx: CreateSpreadsheetModalContext) => void;
  onPathChange: (path: Path) => void;
  path: Path;
};

function FormatSelectionStep({ ctx, path, onClose, onCtxChange, onPathChange }: FormatSelectionStepProps) {
  return (
    <>
      <ModalContent>
        <Box>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              alignItems: 'stretch',
              justifyContent: 'stretch',
              gap: '24px',
            }}
          >
            {formats.map((format, i) => (
              <div
                key={format.id}
                className={ctx.formatId === format.id ? 'multiTypeSelected multiType' : 'multiType'}
                onClick={() => {
                  onCtxChange({ ...ctx, formatId: format.id });
                }}
              >
                {format.info !== '' ? (
                  <Box>
                    <div
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: '50%',
                        transform: 'translate3d(-50%,-12px,0)',
                      }}
                    >
                      <Label
                        text={format.info}
                        color={ctx.formatId === format.id ? Label.colors?.PRIMARY : Label.colors?.DARK}
                        isAnimationDisabled={true}
                      />
                    </div>
                  </Box>
                ) : (
                  <></>
                )}
                <div className="checkTypeIcon">
                  <Icon icon={CheckIcon} iconSize={16} />
                </div>
                <Heading type={Heading.types?.h5} value={format.label} />
                <div
                  style={{
                    fontWeight: 300,
                    color: '#676879',
                  }}
                >
                  {format.description}
                </div>
              </div>
            ))}
          </div>
        </Box>
      </ModalContent>
      <ModalFooter>
        <Flex>
          <div style={{ color: '#afb0bd' }}>Choose from the options above and proceed</div>
          <Box marginStart={Box.marginStarts?.AUTO as any}>
            <Flex gap={12} justify={Flex.justify?.END as any}>
              <Button
                kind={Button.kinds?.TERTIARY as any}
                onClick={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!ctx.formatId}
                onClick={() => {
                  onPathChange(['configuration']);
                }}
              >
                Next
              </Button>
            </Flex>
          </Box>
        </Flex>
      </ModalFooter>
    </>
  );
}

type CreateSpreadsheetModalProps = {
  onClose: () => void;
  show: boolean;
  initialCtx?: CreateSpreadsheetModalContext;
};

export function CreateSpreadsheetModal({ show, onClose, initialCtx }: CreateSpreadsheetModalProps) {
  const [path, setPath] = useState<Path>([...(_.get(initialCtx, 'formatId') ? ['configuration'] : [])]);
  const [ctx, setCtx] = useState<CreateSpreadsheetModalContext>(initialCtx || {});
  const { data: templates } = useSWR('/api/get-templates', async () => getTemplates());

  let modalContent = <></>;

  if (!templates) {
    modalContent = <>Loading...</>;
  } else if (path.length === 0) {
    modalContent = (
      <FormatSelectionStep
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        onPathChange={(path) => setPath(path)}
        path={path}
      />
    );
  } else if (path.length === 1) {
    modalContent = (
      <ConfigurationStep
        templates={templates}
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        onPathChange={(path) => setPath(path)}
        path={path}
        showPrevButton={!_.get(initialCtx, 'formatId')}
      />
    );
  } else if (path.length === 2) {
    modalContent = (
      <SummaryStep
        onClose={onClose}
        ctx={ctx}
        onCtxChange={(ctx) => setCtx(ctx)}
        onPathChange={(path) => setPath(path)}
        path={path}
        templates={templates}
      />
    );
  }

  return (
    <Modal show={show} onClose={() => onClose()} width={Modal.width?.DEFAULT as any} alertDialog={false}>
      <ModalHeader title={`Create spreadsheet`} hideCloseButton={true} />
      {modalContent}
    </Modal>
  );
}
