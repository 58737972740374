// @ts-nocheck
import * as XLSX from 'xlsx-js-style';

let percentageReg = /%$/;

function chatatABC(n) {
  let orda = 'a'.charCodeAt(0);
  let ordz = 'z'.charCodeAt(0);
  let len = ordz - orda + 1;
  let s = '';

  while (n >= 0) {
    s = String.fromCharCode((n % len) + orda) + s;
    n = Math.floor(n / len) - 1;
  }

  return s.toUpperCase();
}

// Get the background color of the cell
function setBackground(row, col, bg, bgConfig) {
  let colA = chatatABC(col);
  let key = colA + (row + 1);

  bgConfig[key] = bg.replace(/\#?/, '');
}

// Judge whether the value type is a percentage
function isPercentage(value) {
  return percentageReg.test(value.m) && value.ct && value.ct.t === 'n';
}

function convertSheetData(sheetData) {
  let arr = []; // two-dimensional array of all cell data
  let cellValue = null;
  let bgConfig = {};

  // Get two-dimensional array

  for (let row = 0; row < sheetData.length; row++) {
    let arrRow = [];
    for (let col = 0; col < sheetData[row].length; col++) {
      if ((cellValue = sheetData[row][col])) {
        // Process the background color of the cell
        if (cellValue.bg) {
          setBackground(row, col, cellValue.bg, bgConfig);
        }
        if (cellValue.ct != null && cellValue.ct.t == 'd') {
          //   d is the time format 2019-01-01 or 2019-01-01 10:10:10
          arrRow.push(new Date(cellValue.m.replace(/\-/g, '/'))); // Compatible with IE
        } else if (cellValue.m && isPercentage(cellValue)) {
          // Percentage problem
          arrRow.push(cellValue.m);
        } else {
          arrRow.push(cellValue.v);
        }
      } else {
        arrRow.push(null);
      }
    }
    arr.push(arrRow);
  }

  return {
    rows: arr,
    bgConfig,
  };
}

function setCellStyles(ws, bgConfig) {
  let reg = /[\u4e00-\u9fa5]/g;

  for (let key in ws) {
    let item = ws[key];
    if (item.t === 'd') {
      if (item.w) {
        // Time format setting
        let arr = item.w.split(' ');
        if (arr[1] && arr[1] == '0:00') {
          ws[key].z = 'm/d/yy';
        } else {
          item.z = 'yyyy/m/dh:mm:ss';
        }
      }
    } else if (item.t === 's') {
      // Percentage setting format
      if (item.v && !item.v.match(reg) && item.v.indexOf('% ') > -1) {
        item.t = 'n';
        item.z = '0.00%';
        item.v = Number.parseFloat(item.v) / 100;
      } else if (item.v && item.v.match(reg)) {
        // Set centered style containing Chinese
        item['s'] = {
          alignment: { vertical: 'center', horizontal: 'center' },
        };
      }
    }
    // Set cell style
    if (bgConfig[key]) {
      ws[key]['s'] = {
        /*alignment: { vertical: 'center', horizontal: 'center' },*/
        fill: { bgColor: { indexed: 32 }, fgColor: { rgb: bgConfig[key] } },
        border: {
          top: { style: 'thin', color: { rgb: '999999' } },
          bottom: { style: 'thin', color: { rgb: '999999' } },
          left: { style: 'thin', color: { rgb: '999999' } },
          right: { style: 'thin', color: { rgb: '999999' } },
        },
      };
    }
  }
}

function convertSheet(sheet) {
  let downOriginData = sheet.data;
  let { rows, bgConfig } = convertSheetData(downOriginData);

  let opts = {
    dateNF: 'm/d/yy h:mm',
    cellDates: true,
    cellStyles: true,
  };

  let ws = XLSX.utils.aoa_to_sheet(rows, opts);

  setCellStyles(ws, bgConfig);

  return {
    name: sheet.name,
    ws: ws,
  };
}

export function luckysheetToSheetJs(luckysheet) {
  let allSheets = luckysheet.getluckysheetfile();
  const convertedSheets = allSheets.map((sheet) => convertSheet(sheet));

  const workbook = {
    SheetNames: convertedSheets.map(({ name }) => name),
    Sheets: convertedSheets.reduce((memo, convertedSheet) => {
      memo[convertedSheet.name] = Object.assign({}, convertedSheet.ws);
      return memo;
    }, {}),
  };

  XLSX.writeFile(workbook, 'export.xlsx');
}
