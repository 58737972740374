import React from 'react';
import * as _ from 'lodash';
import { Heading, Box, Divider, Label, Button, Flex, Avatar } from 'monday-ui-react-core';
import TableIcon from 'monday-ui-react-core/dist/icons/Table';
import MondayLogoOutlineIcon from 'monday-ui-react-core/dist/icons/MondayLogoOutline';
import { useNavigate } from 'react-router-dom';
import { SpreadsheetsIndexEntry } from '../services/spreadsheets';
import TimeAgo from 'react-timeago';

type SpreadsheetsListProps = {
  spreadsheets: SpreadsheetsIndexEntry[];
  onDeleteClick: (spreadsheet: SpreadsheetsIndexEntry) => void;
};

export function SpreadsheetsList({ spreadsheets, onDeleteClick }: SpreadsheetsListProps) {
  const navigate = useNavigate();

  const lessThanTwoDaysAgo = (created) => {
    const TWO_DAYS = 1000 * 60 * 60 * 24 * 2;
    return created > Date.now() - TWO_DAYS;
  };

  return (
    <Box>
      {_.orderBy(spreadsheets, 'updated_at')
        .reverse()
        .map((spreadsheet, i) => (
          <div key={spreadsheet.id}>
            <Box padding={Box.paddings?.LARGE as any} paddingEnd={Box.paddingEnds?.XL as any} className="list--element">
              <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                <Avatar
                  type={Avatar.types?.ICON as any}
                  size={Avatar.sizes?.MEDIUM as any}
                  withoutBorder={true}
                  icon={MondayLogoOutlineIcon}
                  customBackgroundColor="#0073ea"
                  square
                />
                <Box>
                  <Flex gap={Flex.gaps?.SMALL}>
                    <>
                      <Heading type={Heading.types?.h5 as any} value={spreadsheet.title} />
                      {lessThanTwoDaysAgo(spreadsheet.created_at) ? (
                        <Label text="New" color={Label.colors.PRIMARY} isAnimationDisabled={true} />
                      ) : null}
                    </>
                  </Flex>
                  <Flex gap={Flex.gaps?.SMALL as any}>
                    <Box marginStart={Box.marginStarts?.XS as any}>
                      <div
                        style={{
                          marginTop: '2px',
                          color: '#afb0bd',
                        }}
                      >
                        Updated <TimeAgo date={spreadsheet.updated_at} />
                      </div>
                    </Box>
                  </Flex>
                </Box>

                <Box marginStart={Box.marginStarts?.AUTO as any}>
                  <Flex gap={Flex.gaps?.SMALL} align={Flex.align?.CENTER} direction={Flex.directions?.ROW}>
                    <Button
                      kind={Button.kinds?.TERTIARY as any}
                      size={Button.sizes?.MEDIUM as any}
                      onClick={() => onDeleteClick(spreadsheet)}
                    >
                      Delete
                    </Button>
                    <Button
                      kind={Button.kinds?.PRIMARY as any}
                      size={Button.sizes?.MEDIUM as any}
                      onClick={() => {
                        navigate(`/spreadsheet/${spreadsheet.id}/render`);
                      }}
                    >
                      Edit spreadsheet
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            {/* {spreadsheets.length > 3 && spreadsheets.length === i + 1 ? null : <Divider withoutMargin={true} />} */}
            {spreadsheets.length === i + 1 ? null : <Divider withoutMargin={true} />}
          </div>
        ))}
    </Box>
  );
}
