import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString, parseJson } from '../converters';
import { NumericColumnType } from '../../monday-api/api';

export type NumericColumnValue = {
  text: string | null; // TODO: is this needed?
  value: number | null; // needs to converted
  normalized_value: number | string | null;
};

const handler: ColumnHandler<NumericColumnType, NumericColumnValue> = {
  type: 'numbers',

  default_value_field: 'text',

  supported_rules: [
    {
      operator: 'any_of',
      compare_value_ids: ['number', 'blank_value'],
    },
    {
      operator: 'not_any_of',
      compare_value_ids: ['number', 'blank_value'],
    },
    {
      operator: 'is_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'is_not_empty',
      compare_value_ids: ['empty_null'],
    },
    {
      operator: 'greater_than',
      compare_value_ids: ['number'],
    },
    {
      operator: 'greater_than_or_equals',
      compare_value_ids: ['number'],
    },
    {
      operator: 'lower_than',
      compare_value_ids: ['number'],
    },
    {
      operator: 'lower_than_or_equals',
      compare_value_ids: ['number'],
    },
  ],

  convertValueFromMondayToEngine: (columnValue, boardItem, board) => {
    const text = parseString(columnValue.text);

    // TODO: is it a good idea to use floats for numeric values?
    const value = columnValue.value ? parseFloat(parseJson(columnValue.value)) : null;
    let normalizedValue: number | string | null = value;

    if (boardItem && board) {
      const boardColumn = board.columns.find((column) => column.id === columnValue.id);

      let settings;

      if (boardColumn?.settings_str) {
        settings = parseJson(boardColumn?.settings_str);
      }

      const unit = settings?.unit?.custom_unit || settings?.unit?.symbol;

      if (unit === '%') {
        normalizedValue = value !== null ? `(${value}/100)` : null;
      }
    }

    return {
      text,
      value,
      normalized_value: normalizedValue,
    };
  },

  convertValueFromEngineToUpdateValue: (engineValue) => {
    if (engineValue.value === null) {
      return null;
    }

    return `${engineValue.value}`;
  },

  getFormulaValue: (engineValue) => {
    return [engineValue.normalized_value !== null ? engineValue.normalized_value : 0, 'as_number'];
  },
};

export default handler;
