import _ from 'lodash';
import boardRelationColumnHandler from './board-relation_column_handler';
import booleanColumnHandler from './boolean_column_handler';
import buttonColumnHandler from './button_column_handler';
import colorPickerColumnHandler from './color-picker_column_handler';
import countryColumnHandler from './country_column_handler';
import dateColumnHandler from './date_column_handler';
import dependencyColumnHandler from './dependency_column_handler';
import dropdownColumnHandler from './dropdown_column_handler';
import emailColumnHandler from './email_column_handler';
import fileColumnHandler from './file_column_handler';
import hourColumnHandler from './hour_column_handler';
import linkColumnHandler from './link_column_handler';
import locationColumnHandler from './location_column_handler';
import longTextColumnHandler from './long-text_column_handler';
import lookupColumnHandler from './lookup_column_handler';
import multiplePersonColumnHandler from './multiple-person_column_handler';
import numericColumnHandler from './numeric_column_handler';
import phoneColumnHandler from './phone_column_handler';
import durationColumnHandler from './duration_column_handler';
import pulseIdColumnHandler from './pulse-id_column_handler';
import pulseLogColumnHandler from './pulse-log_column_handler';
import pulseUpdatedColumnHandler from './pulse-updated_column_handler';
import ratingColumnHandler from './rating_column_handler';
import statusColumnHandler from './status_column_handler';
import tagColumnHandler from './tag_column_handler';
import textColumnHandler from './text_column_handler';
import timerangeColumnHandler from './timerange_column_handler';
import timezoneColumnHandler from './timezone_column_handler';
import votesColumnHandler from './votes_column_handler';
import weekColumnHandler from './week_column_handler';
import formulaColumnHandler from './formula_column_handler';

export { setFormulaExecuterFn } from './formula_column_handler';

export const handlers = [
  boardRelationColumnHandler,
  booleanColumnHandler,
  buttonColumnHandler,
  colorPickerColumnHandler,
  countryColumnHandler,
  dateColumnHandler,
  dependencyColumnHandler,
  dropdownColumnHandler,
  emailColumnHandler,
  fileColumnHandler,
  hourColumnHandler,
  linkColumnHandler,
  locationColumnHandler,
  longTextColumnHandler,
  lookupColumnHandler,
  multiplePersonColumnHandler,
  numericColumnHandler,
  phoneColumnHandler,
  durationColumnHandler,
  pulseIdColumnHandler,
  pulseLogColumnHandler,
  pulseUpdatedColumnHandler,
  ratingColumnHandler,
  statusColumnHandler,
  tagColumnHandler,
  textColumnHandler,
  timerangeColumnHandler,
  timezoneColumnHandler,
  votesColumnHandler,
  weekColumnHandler,
  formulaColumnHandler,
] as const;

export type HandlerTypes = (typeof handlers)[number];

type CreateColumnValueWrapped<T> = T extends HandlerTypes
  ?
      | {
          column_id: string;
          column_type: T['type'];
          value_type: 'success';
          value: ReturnType<T['convertValueFromMondayToEngine']>;
        }
      | {
          column_id: string;
          column_type: T['type'];
          value_type: 'error';
          value: {
            value: string;
          };
        }
  : never;

export type ColumnValueWrapped = CreateColumnValueWrapped<HandlerTypes>;

export const handlersByType = _.keyBy(handlers, 'type');
