import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString } from '../converters';
import { PulseUpdatedColumnType } from '../../monday-api/api';

export type PulseUpdatedColumnValue = {
  text: string | null;
  date: string | null;
};

const handler: ColumnHandler<PulseUpdatedColumnType, PulseUpdatedColumnValue> = {
  type: 'last_updated',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);
    const date = text !== null ? new Date(text).toISOString() : null;

    return {
      text,
      date,
    };
  },

  // TODO: properly support this
  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
