import { FieldHandler, BoardColumnConfig } from '../types';
import { convertConfigToSheetjs } from '../utils';

export type Config = BoardColumnConfig;

export const handler: FieldHandler<Config> = {
  defaultConfig: {},

  type: 'custom-formula',

  extractFieldsFromBoard: (board) => {
    return [
      {
        id: 'custom-formula',
        label: 'Custom Formula',
      },
    ];
  },

  exportTo: (outputFormat, fieldId, boardItem, board, config) => {
    if (outputFormat === 'sheetjs') {
      return {
        ...convertConfigToSheetjs(config),
        v: config.formula,
        t: 's',
        __is_custom_formula: true,
      };
    }
  },
};

export default handler;
