import * as _ from 'lodash';
import { ColumnHandler } from '../types';
import { parseString } from '../converters';
import { PulseIdColumnType } from '../../monday-api/api';

export type PulseIdColumnValue = {
  text: string | null;
};

const handler: ColumnHandler<PulseIdColumnType, PulseIdColumnValue> = {
  type: 'item_id',

  default_value_field: 'text',

  convertValueFromMondayToEngine: (columnValue) => {
    const text = parseString(columnValue.text);

    return {
      text,
    };
  },

  getFormulaValue: (engineValue) => (engineValue.text === null ? '' : engineValue.text),
};

export default handler;
